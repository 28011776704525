import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Entry from "./components/Entry";
import Home from "./components/Home";
import Login from "./components/Login";
import Password from "./components/Password";
import Signup from "./components/Signup";
import Logout from "./components/Logout";
import Profile from "./components/Profile";
import Reply from "./components/Status";
import Bookmarks from "./components/Bookmarks";
import Verify from "./components/Verify";
import Hashtag from "./components/Hashtag";
import Likes from "./components/Likes";
import Topics from "./components/Topics";
import Subjects from "./components/Subjects";
import Blocked from "./components/Blocked";
import MyPolls from "./components/MyPolls";
import Following from "./components/Following";
import Followers from "./components/Followers";
import Documents from "./components/Documents";
import Media from "./components/Media";
import LikesProfile from "./components/LikesProfile";
import Academics from "./components/Academics";
import Messages from "./components/Messages";
import Chats from "./components/navigation/Chats";
import TopicPosts from "./components/TopicPosts";
import Notifications from "./components/Notifications";
import Mentions from "./components/Mentions";
import X from "./components/X";
import Search from "./components/Search";
import Compose from "./components/Compose";
import Previews from "./components/Previews";
import Interests from "./components/Interests";
// import PostsOnTopicsYouFollow from "./components/PostsOnTopicsYouFollow";
function App() {
  return (
    <Router>
      <Routes>
        <Route path={"/"} exact element={<Entry />} />
        <Route path={"/x/:documents"} element={<X />} />
        <Route path={"/x/:media"} element={<X />} />
        <Route path={"/home"} exact element={<Home />} />
        <Route path={"/hashtag/:post_id/:tag"} exact element={<Hashtag />} />
        <Route path={"/likes"} exact element={<Likes />} />
        <Route path={"/topics"} exact element={<Topics />} />
        <Route path={"/subjects"} exact element={<Subjects />} />
        <Route path={"/topicPosts"} exact element={<TopicPosts />} />
        <Route path={"/blocked/"} exact element={<Blocked />} />
        <Route path={"/polls/"} exact element={<MyPolls />} />
        <Route path={"/following/:username"} exact element={<Following />} />
        <Route path={"/followers/:username"} exact element={<Followers />} />
        <Route path={"/documents/:username"} exact element={<Documents />} />
        <Route path={"/media/:username"} exact element={<Media />} />
        <Route path={"/likes/:username"} exact element={<LikesProfile />} />
        <Route path={"/academics/"} exact element={<Academics />} />
        <Route path={"/messages/"} exact element={<Messages />} />
        <Route path={"/messages/:username"} exact element={<Messages />} />
        <Route
          path={"/home/:PostsOnTopicsYouFollow"}
          exact
          element={<Home />}
        />
        <Route
          path={
            "/home/:user_name/status/:status_id/photo/:photo_id/:index/:media_index"
          }
          exact
          element={<Home />}
        />
        <Route
          path={
            "/preview/:user_name/status/:status_id/photo/:photo_id/:index/:media_index"
          }
          exact
          element={<Previews />}
        />
        <Route path={"/login"} exact element={<Login />} />
        <Route path={"/password"} exact element={<Password />} />
        <Route path={"/signup"} exact element={<Signup />} />
        <Route path={"/logout"} exact element={<Logout />} />
        <Route path={"/profile/:username"} exact element={<Profile />} />
        <Route
          path={"/status/:username/:post_uuid"}
          exact
          element={<Reply />}
        />
        <Route path={"/bookmarks"} exact element={<Bookmarks />} />
        <Route path={"/verify"} exact element={<Verify />} />
        <Route path={"/notifications"} exact element={<Notifications />} />
        <Route path={"/Mentions"} exact element={<Mentions />} />
        <Route path={"/documents/"} exact element={<Documents />} />
        <Route path={"/media/"} exact element={<Documents />} />
        <Route path={"/search"} exact element={<Search />} />
        <Route path={"/compose/:post"} exact element={<Compose />} />
        <Route path={"/interests"} exact element={<Interests />} />
        <Route path={"/:username"} exact element={<Profile />} />
        {/* <Route path={"/subject/:subject"} exact element={Subject} />
        <Route path={"/topic/:topic"} exact element={Topic} /> */}
      </Routes>
    </Router>
  );
}

export default App;
