import axios from "axios";
import React, { useState, useEffect } from "react";
import { Col, Container, Row, Stack, Modal, Alert } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import Header from "./navigation/HeaderPassword";
import Interests from "./Interests";
export default function Verify(props) {
  // const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [alert, setAlert] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [code, setCode] = useState("");
  const baseURL = process.env.REACT_APP_URL;
  let navigate = useNavigate();
  const location = useLocation();
  const info = location.state;
  useEffect(() => {
    document.title =
      "Verify Account | " + process.env.REACT_APP_YOUR_VARIABLE_NAME_HERE;
  });

  function handleClose() {
    setShow(false);
  }
  function handleSignUp() {
    setShow(true);
  }

  function verifyAccount(event) {
    event.preventDefault();
    setSpinner(true);
    setAlert(false);
    const data = new FormData();
    data.append("email", info.email);
    data.append("username", info.username);
    data.append("user_id", info.user_id);
    data.append("first_name", info.first_name);
    data.append("last_name", info.last_name);
    data.append("code", code);

    axios({
      method: "post",
      url: baseURL + "/user/verify",
      data: data,
    })
      .then((response) => {
        if (response.data.status == true) {
          localStorage.setItem("personalized_id", response.data.access_token);
          localStorage.setItem("username", response.data.data.username);
          localStorage.setItem("user_id", response.data.data.user_id);
          localStorage.setItem("first_name", response.data.data.first_name);
          localStorage.setItem("last_name", response.data.data.last_name);
          localStorage.setItem("profile_pic", response.data.data.profile_pic);
          const fullname =
            response.data.data.first_name + " " + response.data.data.last_name;

          localStorage.setItem("fullname", fullname);
          // navigate({
          //   pathname: "/interests",
          //   search: "q_=true",
          // });

          // history.push({
          //   pathname: "/interests",
          //   search: "q_=true",
          // });
          window.location.href = "interests?q_=true";
          return;
        } else {
          setSpinner(false);
          setAlert(true);
          setErrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        setSpinner(false);
        setAlert(true);
        setErrorMessage(error.response.data.message);
      });
  }

  return (
    <div className="body-login">
      <Container className="login password-container">
        <Row className="h-100 align-items-center justify-content-center">
          <Col x={12} lg={6}>
            <form onSubmit={verifyAccount}>
              <Row className={"justify-content-center align-items-center"}>
                <Col xs={12} lg={10}>
                  <Stack gap={2}>
                    <div className="text-center">
                      <h4 className="text-center fontBold">
                        Account verification
                        <br />
                        <label className="footnote fontSize-12 mb-2">
                          Code sent to{" "}
                          <span className="text-bold">{info.email}</span>
                        </label>
                      </h4>
                    </div>
                    <div className="login-elements-container border-hidden p-3">
                      <Stack>
                        {alert && (
                          <Alert variant={"danger"}>{errorMessage}</Alert>
                        )}

                        <label htmlFor="code">
                          <span className="text-bold">
                            Enter verification code
                          </span>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter a code"
                          className="bg-color-white mb-3 mt-1"
                          value={code}
                          onChange={(e) => setCode(e.target.value)}
                        />

                        <Button
                          className={
                            spinner == false
                              ? "login-button mb-3 rounded-pill no-shadow"
                              : "login-button mb-3 disabled rounded-pill no-shadow"
                          }
                          type={spinner == false ? "submit" : "button"}
                        >
                          {spinner == false ? (
                            "Verify account"
                          ) : (
                            <BeatLoader color={"#fff"} />
                          )}
                        </Button>
                      </Stack>
                    </div>
                    {/* <div className="text-center">
                    Discover, share and empower.
                  </div> */}
                  </Stack>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
