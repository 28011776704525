import React, { useState, useEffect } from "react";
import { Col, Container, Modal, Row, Stack } from "react-bootstrap";
import { useParams, useHistory, Link, NavLink } from "react-router-dom";
import Header from "./navigation/Header";
import Posts from "./Posts";
import tagStyle from "./css/tagStyle";
import mentionStyle from "./css/mentionStyle";
import { Linkify } from "react-easy-linkify";
import { SecureLink } from "react-secure-link";
import { Tagify } from "react-tagify";
import Footer from "./navigation/Footer";
export default function X() {
  const [postSentFeedback, setPostSentFeedback] = useState(
    <Link to={"/login"} className="textUnderlineNone underlineHover">
      <div className="fontBold color-black">Log in to engage.</div>
    </Link>
  );

  const [show, setShow] = useState(false);

  useEffect(() => {
    document.title = process.env.REACT_APP_YOUR_VARIABLE_NAME_HERE;
  });

  function handleClose() {
    setShow(false);
  }

  function deletePost() {
    setShow(true);
  }

  const pagination = 50;
  const user_id = 0;

  function block() {
    setShow(true);
  }

  function Bookmark() {
    setShow(true);
  }

  function reply() {
    setShow(true);
  }

  function vote() {
    setShow(true);
  }

  function getPhotos() {
    setShow(true);
  }

  function repostsMedia() {
    setShow(true);
  }

  function sendComplaint() {
    setShow(true);
  }

  function showSend() {
    setShow(true);
  }

  function showMorePost() {
    setShow(true);
  }

  function replyPage() {
    setShow(true);
  }

  function concernClickedTwo() {
    setShow(true);
  }

  function concernClickedOne() {
    setShow(true);
  }

  function fewerFeeds() {
    setShow(true);
  }

  function mutePost() {
    setShow(true);
  }

  function concernText() {
    setShow(true);
  }

  function follow() {
    setShow(true);
  }

  function postSent() {
    setShow(true);
  }

  function addLike() {
    setShow(true);
  }

  function showMoreQuotedPostText() {
    setShow(true);
  }

  function showMoreQuotedPost() {
    setShow(true);
  }

  function showMorePostId() {
    setShow(true);
  }

  function hashtag() {
    setShow(true);
  }

  function PostsOnTopicsYouFollow() {
    setShow(true);
  }

  function PostsOnTopicsYouFollow() {
    setShow(true);
  }

  function dm() {
    setShow(true);
  }

  function showMore() {
    setShow(true);
  }

  localStorage.removeItem("page");
  localStorage.removeItem("unauthorized");

  let endpoint = "";
  if (window.location.pathname == "/x/media") {
    endpoint = "media";
  } else if (window.location.pathname == "/x/documents") {
    endpoint = "documents";
  } else {
    endpoint = "entry";
  }

  const [markLike, setMarkLike] = useState({});
  const [postLiked, setPostLiked] = useState(null);
  const [hidden, setHidden] = useState({});
  const [markBookmark, setMarkBookmark] = useState({});
  const [bookmarkCount, setBookmarkCount] = useState(0);

  return (
    <div className="body-login">
      <Header />
      <Container>
        <Row className="justify-content-center mt-0 ">
          <Col lg={6} className="p-0">
            <Posts
              postLiked={true}
              markLike={markLike}
              markBookmark={markBookmark}
              likeCount={0}
              bookmarkCount={bookmarkCount}
              hidden={hidden}
              postSentFeedback={postSentFeedback}
              postSent={postSent}
              showMore={showMore}
              dm={dm}
              pagination={pagination}
              homePosts={PostsOnTopicsYouFollow ? false : true}
              postType={"entry"}
              endpoint={endpoint}
              user_id={user_id}
              follow={follow}
              concernText={concernText}
              mutePost={mutePost}
              showSend={showSend}
              sendComplaint={sendComplaint}
              fewerFeeds={fewerFeeds}
              concernClickedOne={concernClickedOne}
              concernClickedTwo={concernClickedTwo}
              replyPage={replyPage}
              showMorePost={showMorePost}
              Tagify={Tagify}
              hashtag={hashtag}
              tagStyle={tagStyle}
              mentionStyle={mentionStyle}
              Linkify={Linkify}
              SecureLink={SecureLink}
              showMorePostId={showMorePostId}
              repostsMedia={repostsMedia}
              getPhotos={getPhotos}
              vote={vote}
              showMoreQuotedPost={showMoreQuotedPost}
              showMoreQuotedPostText={showMoreQuotedPostText}
              addLike={addLike}
              reply={reply}
              Bookmark={Bookmark}
              block={block}
              deletePost={deletePost}
            />
          </Col>
        </Row>
      </Container>

      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="example-custom-modal-styling-title"
        // fullscreen={true}
      >
        {/* <Modal.Header closeButton></Modal.Header> */}
        <Modal.Body className="p-0 bgBlack"></Modal.Body>
        <Container>
          <Row>
            <Col lg={12} className="p-3">
              <h1>
                <span className="text-color-brown">
                  Welcome to {process.env.REACT_APP_YOUR_VARIABLE_NAME_HERE}.
                </span>
              </h1>
              <br />
              <p>
                Become an active member of our community and engage in
                conversations that matters, conversations that builds. <br />
                <br />
                Follow subjects and topics you care about, engage scholars and
                academics and take your knowledge to the next level...
              </p>
              <br />
              <p>
                <Link to={"/login"} className="">
                  <label className="w-100 text-center login-button mb-3 rounded-pill no-shadow fontBold">
                    Log In
                  </label>
                </Link>

                <Link to={"/login?q=signup-clicked"} className="">
                  <label className="w-100 text-center native-button-dark-grey cursorPointer rounded-pill no-shadow fontBold">
                    Sign Up
                  </label>
                </Link>
              </p>
            </Col>
          </Row>
        </Container>
      </Modal>
      <Footer />
    </div>
  );
}
