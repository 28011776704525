import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Modal, Container, Row, Col, Button, Stack } from "react-bootstrap";
import {
  IoTrashOutline,
  IoCopyOutline,
  IoCloudDownloadOutline,
} from "react-icons/io5";
import ScrollToBottom from "react-scroll-to-bottom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from "axios";
import HeaderToken from "../HeaderToken";

const headers = {
  Authorization: `${HeaderToken.Authorization}`,
  Accept: `${HeaderToken.Accept}`,
};

const Conversations = (props) => {
  const user_id = localStorage.getItem("user_id");
  const userName = localStorage.getItem("username");
  const [copied, setCopied] = useState(false);
  const [show, setShow] = useState(false);
  const [conversationId, setConversationId] = useState(0);
  const [imgPath, setImgPath] = useState(null);
  const chat_mate_username = props.chat_mate_username;
  const baseURL = process.env.REACT_APP_URL;

  const conversationQuery = useQuery({
    queryKey: ["conversations"],
    queryFn: async () => {
      const response = await axios.get(
        `${baseURL}/conversations/${user_id}/${chat_mate_username}`,
        { headers }
      );
      const data = response.data;
      return data;
    },
  });

  const { refetch } = conversationQuery;

  if (copied == true) {
    setTimeout(() => {
      setCopied(false);
    }, 4000);
  }

  function handleClose() {
    setShow(false);
  }

  useEffect(() => {
    refetch();
  }, [chat_mate_username, props.pageCount, window.location.href]);

  function deleteConverstation(conversation_id) {
    setShow(true);
    setImgPath(null);
    setConversationId(conversation_id);
  }

  function deleteMessage(event) {
    event.preventDefault();
    const data = new FormData();
    data.append("conversationId", conversationId);

    axios({
      method: "POST",
      url: baseURL + "/delete/conversation",
      data: data,
      headers: {
        Authorization: HeaderToken.Authorization,
        Accept: HeaderToken.Accept,
      },
    })
      .then((res) => {
        setShow(false);
        refetch();
      })
      .catch((error) => {});
  }

  function zoomIMG(imgPath) {
    setShow(true);
    setImgPath(imgPath);
  }
  return (
    <Container>
      <Modal
        show={show}
        onHide={handleClose}
        backdropClassName="backdrop-login"
        centered
        size={imgPath ? "md" : "sm"}
        className={"modal_bg_color_white"}
      >
        <Modal.Body>
          <Container className="p-1 pt-0">
            <div classsName={imgPath ? "" : "d-done"}>
              <img src={imgPath} width={"100%"} className={"border-radius-5"} />
            </div>
            <div className={imgPath ? "d-none" : ""}>
              <form onSubmit={deleteMessage}>
                <Row className="align-items-center">
                  <Col lg={12} xs={12} className="mb-3">
                    <div className={"p-0"}>
                      <h5>
                        <span className="text-bold">Delete Message?</span>
                      </h5>
                      <label className={"footnote"}>
                        {" "}
                        This message will be deleted forever. This action cannot
                        be undone.
                      </label>
                      <br />
                      <Button
                        type={"submit"}
                        className={
                          "native-button-red fontBold w-100 no-shadow mb-3 mt-4"
                        }
                      >
                        Delete
                      </Button>
                      <label
                        className="join-now-button text-center rounded-pill"
                        onClick={handleClose}
                      >
                        Cancel
                      </label>
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
          </Container>
        </Modal.Body>
      </Modal>
      <Row className="mt-3">
        <Col lg={12} className={"p-0"}>
          <div className={"p-0"}>
            {conversationQuery.isLoading ? (
              "fetching conversation..."
            ) : (
              <>
                {conversationQuery.data.conversations.map((item, index) => (
                  <Row className="mb-3" key={index}>
                    <Col lg={12}>
                      <label
                        className={
                          item.conversation.sender_user_id == user_id
                            ? "float-right chat-box-blue color-white maxWidth-70"
                            : "chat-box-grey maxWidth-70"
                        }
                      >
                        <>
                          {item.documents == null ? (
                            ""
                          ) : (
                            <Row>
                              {item.documents.map((document, index) => {
                                return (
                                  <Col lg={12} className={"mb-0"} key={index}>
                                    <a
                                      href={
                                        process.env.REACT_APP_URL.replace(
                                          "api",
                                          ""
                                        ) +
                                        "public/messages/documents/" +
                                        document.username +
                                        "/" +
                                        document.file
                                      }
                                      target="_blank"
                                      className={
                                        "cursorPointer textUnderlineNone fontBold fontSize14"
                                      }
                                    >
                                      <label
                                        className={
                                          "document-link-msg mb-2 footnote cursorPointer fontSize14"
                                        }
                                      >
                                        <IoCloudDownloadOutline size={17} />
                                        &nbsp;&nbsp;
                                        {document.title
                                          ? document.title.length > 40
                                            ? document.title.slice(0, 40) +
                                              "...." +
                                              document.format
                                            : document.title +
                                              "." +
                                              document.format
                                          : document.file}
                                      </label>
                                    </a>{" "}
                                    <label
                                      className={
                                        item.conversation.sender_user_id ==
                                        user_id
                                          ? "color-white mb-2 footnote fontSize14"
                                          : "color-box-grey mb-2 footnote fontSize14"
                                      }
                                    >
                                      {document.size / 1024 / 1024.4 < 1
                                        ? (document.size / 1024.4).toFixed(1) +
                                          " KB"
                                        : (
                                            document.size /
                                            1024 /
                                            1024.4
                                          ).toFixed(1) + " MB"}{" "}
                                    </label>
                                  </Col>
                                );
                              })}
                            </Row>
                          )}
                        </>
                        <>
                          {item.media == null ? (
                            ""
                          ) : (
                            <Row>
                              {item.media.map((item, index) => (
                                <Col lg={12} key={index}>
                                  <div
                                    className={
                                      "mb-1 border-radius-5 cursorPointer"
                                    }
                                    style={{
                                      backgroundImage:
                                        "url(" +
                                        process.env.REACT_APP_URL.replace(
                                          "api",
                                          ""
                                        ) +
                                        "public/messages/media/" +
                                        item.username +
                                        "/" +
                                        item.file +
                                        ")",
                                      backgroundSize: "cover",
                                      height: 200,
                                      minWidth: 200,
                                      backgroundPosition: "center",
                                    }}
                                    onClick={() =>
                                      zoomIMG(
                                        process.env.REACT_APP_URL.replace(
                                          "api",
                                          ""
                                        ) +
                                          "public/messages/media/" +
                                          item.username +
                                          "/" +
                                          item.file
                                      )
                                    }
                                  />
                                </Col>
                              ))}
                            </Row>
                          )}
                        </>

                        {item.conversation.message}
                      </label>
                      <label
                        className={
                          item.conversation.sender_user_id == user_id
                            ? "float-right mr-1 mt-2"
                            : "ml-1"
                        }
                      >
                        <span
                          className={
                            item.conversation.sender_user_id == user_id
                              ? "share-icons-chats-del"
                              : "d-none"
                          }
                          onClick={() =>
                            deleteConverstation(item.conversation.id)
                          }
                        >
                          <IoTrashOutline
                            size={17}
                            className={"color-red cursorPointer"}
                          />
                        </span>
                        <span className={"share-icons-chats"}>
                          <CopyToClipboard
                            text={item.conversation.message}
                            onCopy={() => setCopied(true)}
                          >
                            <IoCopyOutline
                              size={17}
                              className={"color-blue cursorPointer"}
                            />
                          </CopyToClipboard>
                        </span>
                      </label>
                    </Col>

                    <label
                      className={
                        item.conversation.sender_user_id == user_id
                          ? "w-100 text-right footnote fontSize-12"
                          : "w-100 footnote fontSize-12"
                      }
                    >
                      {item.conversation.created_at}
                    </label>
                  </Row>
                ))}
              </>
            )}
          </div>
        </Col>
      </Row>
      <label className={copied == true ? "copiedText" : "d-none"}>
        Copied to clipboard
      </label>
    </Container>
  );
};

export default Conversations;
