import React, { useState, useEffect, useRef, useCallback } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Tagify } from "react-tagify";
import Linkify from "react-linkify";
import { SecureLink } from "react-secure-link";
import { Link } from "react-router-dom";

import {
  Col,
  Container,
  Row,
  Stack,
  Modal,
  Button,
  Dropdown,
} from "react-bootstrap";

import { IoAddSharp } from "react-icons/io5";
import axios from "axios";
// import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import HeaderToken from "../HeaderToken";
import { ClipLoader } from "react-spinners";

const headers = {
  Authorization: `${HeaderToken.Authorization}`,
  Accept: `${HeaderToken.Accept}`,
};

export default function AcademicsToFollow(props) {
  const queryClient = useQueryClient();
  const baseURL = process.env.REACT_APP_URL;

  const [hidden, setHidden] = useState({});
  const [count, setCount] = useState(0);
  let mobileSearch = false;
  if (props.search) {
    mobileSearch = true;
  }

  // console.log(mobileSearch);

  const peopleYouMightFollowQuery = useQuery({
    queryKey: ["people"],
    queryFn: async () => {
      const response = await axios.get(
        baseURL +
          "/follow/academics/" +
          props.userID +
          "/less?searchMobile=" +
          mobileSearch,
        {
          headers,
        }
      );
      const data = response.data;
      return data;
    },
  });
  // const fetchAcademics = async () => {
  //   const res = await fetch(
  //     baseURL + "/follow/academics/" + props.userID + "/less",
  //     {
  //       headers,
  //     }
  //   );
  //   return res.json();
  // };

  // const academics = useQuery("academics", fetchAcademics, { retry: 3 });

  const { data, status, refetch } = peopleYouMightFollowQuery;
  // console.log(data);

  useEffect(() => {
    // queryClient.invalidateQueries({ queryKey: ["people"] }, { exact: true });
  }, [count]);

  const follow = (user_id) => {
    setCount(count + 1);

    setHidden({ ...hidden, [user_id]: !hidden[user_id] });
    var following_user_id = localStorage.getItem("user_id");
    var followed_user_id = user_id;

    const formData = new FormData();
    formData.append("following_user_id", following_user_id);
    formData.append("followed_user_id", followed_user_id);
    const url = baseURL;
    axios({
      method: "POST",
      url: url + "/following/create",
      data: formData,
      headers: {
        Authorization: HeaderToken.Authorization,
        Accept: HeaderToken.Accept,
      },
    }).then((response) => {
      queryClient.invalidateQueries({ queryKey: ["people"] }, { exact: true });
      setHidden({ ...hidden, [user_id]: hidden[user_id] });
      // refetch();
    });
  };

  return (
    <>
      {peopleYouMightFollowQuery.isLoading ? (
        <div className="col-12 text-center p-5 color-blue">
          <ClipLoader size={25} color={"#333333"} />
        </div>
      ) : (
        <>
          {peopleYouMightFollowQuery.length == 0 ? (
            <>no data</>
          ) : (
            peopleYouMightFollowQuery.data.academics.map((item, index) => (
              <Row className="justify-content-center align-items-center side-nav-topics mt-0 mb-0">
                <Col lg={12}>
                  <Row className={"align-items-center"}>
                    <Col lg={8} sm={8} xs={8}>
                      <Link
                        to={"/" + item.username}
                        className={"color-black textUnderlineNone"}
                      >
                        <div
                          className={"profile-pic float-left"}
                          style={{
                            backgroundImage:
                              "url(" +
                              process.env.REACT_APP_URL.replace("api", "") +
                              "public/profile/picture/@" +
                              item.username +
                              "/" +
                              item.profile_pic +
                              ")",
                          }}
                        />
                        &nbsp;&nbsp;{" "}
                        <label className="line-height-20">
                          <span className="cursorPointer text-bold">
                            {item.fullname.length > (props.search ? 13 : 8)
                              ? item.fullname.slice(0, props.search ? 13 : 8) +
                                "..."
                              : item.fullname}
                          </span>
                          <br />
                          <label
                            className={"footnote cursorPointer fontSize14"}
                          >
                            @
                            {item.username.length > (props.search ? 15 : 8)
                              ? item.username.slice(0, props.search ? 15 : 8) +
                                "..."
                              : item.username}
                          </label>
                        </label>
                      </Link>
                    </Col>

                    <Col lg={4} sm={4} xs={4}>
                      {!hidden[item.id] && (
                        <Button
                          type="button"
                          className={
                            "native-button-dark-grey btn-follow-user float-right fontBold no-shadow btn-sm"
                          }
                          onClick={() => follow(item.id)}
                        ></Button>
                      )}

                      {!!hidden[item.id] && (
                        <label
                          className={
                            "disabled-bg-color float-right fontBold no-shadow btn-sm btn btn-primary"
                          }
                        >
                          <ClipLoader size={15} color="#000" />
                        </label>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            ))
          )}
        </>
      )}
    </>
  );
}
