import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Container, Row, Col, Button, Stack } from "react-bootstrap";
import BeatLoader from "react-spinners/BeatLoader";
import axios from "axios";
import { Link, NavLink } from "react-router-dom";
import { IoAttachSharp } from "react-icons/io5";
import HeaderToken from "../HeaderToken";
import { ClipLoader } from "react-spinners";

const headers = {
  Authorization: `${HeaderToken.Authorization}`,
  Accept: `${HeaderToken.Accept}`,
};

const Messages = (props) => {
  const user_id = localStorage.getItem("user_id");
  const [searchConvo, setSearchConvo] = useState("");
  const [searchData, setSearchData] = useState([]);

  const url = process.env.REACT_APP_URL;
  const imgURL = url.replace("api", "");

  const chatQuery = useQuery({
    queryKey: ["messages"],
    queryFn: async () => {
      const response = await axios.get(`${url}/${user_id}/recentText`, {
        headers,
      });
      const data = response.data;
      return data;
    },
  });

  // console.log(data);
  useEffect(() => {}, []);

  function searchConversation(e) {
    let query = e.target.value;
    setSearchConvo(query);

    axios
      .get(url + "/" + user_id + "/recentText?keyword=" + query, { headers })
      .then((res) => {
        // console.log(res.data.data);
        setSearchData(res.data.data);
      })
      .catch((error) => {
        setSearchData([]);
      });
  }

  // const sortedMessages = data.data.sort((a, b) => datetimeToTS(b.date, b.time) - datetimeToTS(a.date, a.time))

  return (
    <div className="p-3">
      {chatQuery.isLoading ? (
        <div className="col-12 text-center p-5 color-blue">
          <ClipLoader size={25} color={"#333333"} />
        </div>
      ) : (
        <>
          {chatQuery.data.data.length == 0 ? (
            <div lg={12} className="text-center mb-3 mt-3 fontSize-20">
              <h3>Recent text will appear here.</h3>
              <label className="footnote mb-3">Initiate a conversation.</label>
            </div>
          ) : (
            <Row className={"contacts-messages"}>
              <div className={"mb-3"}>
                <input
                  type="text"
                  className="no-shadow border-circle"
                  placeholder="Search conversations"
                  onChange={searchConversation}
                />
              </div>
              {searchConvo.length > 0 ? (
                searchData.length == 0 ? (
                  <div
                    lg={12}
                    className="text-center mb-3 mt-3 fontSize-20 newMessageListAcademics"
                  >
                    <h4>No conversations found.</h4>
                    <label className="footnote mb-3">
                      Try a different keyword or a name.
                    </label>
                  </div>
                ) : (
                  <>
                    {searchData.map((item, index) => (
                      <Col lg={12} className="mb-0 mt-0" key={index}>
                        <div className="p-0 mb-0 mt-0">
                          <Row className="paddingLeftRight mb-1 mt-0">
                            <NavLink
                              to={`/messages/${item.users.username}`}
                              className={(navData) =>
                                navData.isActive
                                  ? "side-nav-topics-active mb-0 side-nav-topics textUnderlineNone w-100 d-flex mb-0"
                                  : "side-nav-topics textUnderlineNone w-100 d-flex mb-0"
                              }
                            >
                              <Col lg={2}>
                                <div
                                  className={"profile-pic cursorPointer"}
                                  style={{
                                    backgroundImage:
                                      "url(" +
                                      imgURL +
                                      "public/profile/picture/@" +
                                      item.users.username +
                                      "/" +
                                      item.users.profile_pic +
                                      ")",
                                  }}
                                />
                              </Col>
                              <Col lg={10}>
                                <div>
                                  <span className="text-bold cursorPointer">
                                    {item.users.fullname.length > 19
                                      ? item.users.fullname.slice(0, 19) + "..."
                                      : item.users.fullname}{" "}
                                    <label className="color-grey text-normal cursorPointer">
                                      @{item.users.username}
                                    </label>
                                  </span>
                                  <br />
                                </div>

                                {!item.recentText.message ? (
                                  <span className={"fontSize14 footnote w-100"}>
                                    <IoAttachSharp size={20} />
                                    [Attachment]
                                    <br />
                                    <span
                                      className={
                                        "float-right text-normal footnote fontSize-12"
                                      }
                                    >
                                      [{item.recentText.created_at}]
                                    </span>
                                  </span>
                                ) : (
                                  <>
                                    {item.media == 1 || item.document == 1 ? (
                                      <span className={"fontSize14 color-grey"}>
                                        <IoAttachSharp
                                          size={20}
                                          className={"color-grey"}
                                        />
                                        [Attachment]:
                                      </span>
                                    ) : (
                                      ""
                                    )}{" "}
                                    <span
                                      className={
                                        "footnote cursorPointer text-normal"
                                      }
                                    >
                                      {item.recentText.message.length > 70
                                        ? item.recentText.message.slice(0, 70) +
                                          "..."
                                        : item.recentText.message}
                                    </span>
                                    <br />
                                    <span className="float-right text-normal color-grey fontSize-12">
                                      [{item.recentText.created_at}]
                                    </span>
                                  </>
                                )}
                              </Col>
                            </NavLink>
                          </Row>
                        </div>
                      </Col>
                    ))}
                  </>
                )
              ) : (
                <>
                  {chatQuery.data.data.map((item, index) => (
                    <Col
                      lg={12}
                      className={
                        item.users.id == user_id ? "d-none" : "mb-0 mt-0"
                      }
                      key={index}
                    >
                      <div className="p-0 mb-0 mt-0">
                        <Row className="paddingLeftRight mb-1 mt-0">
                          <NavLink
                            to={`/messages/${item.users.username}`}
                            className={(navData) =>
                              navData.isActive
                                ? "side-nav-topics-active mb-0 side-nav-topics textUnderlineNone w-100 d-flex mb-0 p-3"
                                : "side-nav-topics textUnderlineNone w-100 d-flex mb-0 p-3"
                            }
                          >
                            <Col lg={2}>
                              <div
                                className={"profile-pic cursorPointer"}
                                style={{
                                  backgroundImage:
                                    "url(" +
                                    imgURL +
                                    "public/profile/picture/@" +
                                    item.users.username +
                                    "/" +
                                    item.users.profile_pic +
                                    ")",
                                }}
                              />
                            </Col>
                            <Col lg={10}>
                              <div>
                                <span className="text-bold cursorPointer">
                                  {item.users.fullname.length > 14
                                    ? item.users.fullname.slice(0, 14) + "..."
                                    : item.users.fullname}{" "}
                                  <label className="color-grey text-normal cursorPointer">
                                    @{item.users.username}{" "}
                                    {item.recentText.read_at == null ? (
                                      <label className="dot-notification" />
                                    ) : (
                                      ""
                                    )}
                                  </label>
                                </span>
                                <br />
                              </div>

                              {!item.recentText.message ? (
                                <span className={" footnote w-100"}>
                                  <IoAttachSharp size={20} />
                                  [Attachment]
                                  <br />
                                  <span className={" text-normal footnote"}>
                                    {item.recentText.created_at}
                                  </span>
                                </span>
                              ) : (
                                <>
                                  {item.media == 1 || item.document == 1 ? (
                                    <span className={"color-grey"}>
                                      <IoAttachSharp
                                        size={20}
                                        className={"color-grey"}
                                      />
                                      [Attachment]:
                                    </span>
                                  ) : (
                                    ""
                                  )}{" "}
                                  <span
                                    className={
                                      "footnote cursorPointer text-normal"
                                    }
                                  >
                                    {item.recentText.message.length > 40
                                      ? item.recentText.message.slice(0, 40) +
                                        "..."
                                      : item.recentText.message}
                                  </span>
                                  <br />
                                  <span className="text-normal color-grey">
                                    {item.recentText.created_at}
                                  </span>
                                </>
                              )}
                            </Col>
                          </NavLink>
                        </Row>
                      </div>
                    </Col>
                  ))}
                </>
              )}
            </Row>
          )}
        </>
      )}
    </div>
  );
};

export default Messages;
