import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Container, Row, Col, Button, Stack } from "react-bootstrap";
import SideNav from "./navigation/SideNavHome";
import RightNav from "./navigation/SidNavHomeRight";
import Header from "./navigation/HeaderHome";
import Academia from "./features/Academics";
import {
  useParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import axios from "axios";
import BeatLoader from "react-spinners/BeatLoader";
import { IoIosArrowRoundBack } from "react-icons/io";
// import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import HeaderToken from "./HeaderToken";
import { ClipLoader } from "react-spinners";
import Footer from "./navigation/Footer";
import { Helmet } from "react-helmet";

const headers = {
  Authorization: `${HeaderToken.Authorization}`,
  Accept: `${HeaderToken.Accept}`,
};

function Academics(props) {
  const location = useLocation();
  const info = location.state;
  let navigate = useNavigate();
  const baseURL = process.env.REACT_APP_URL;
  const user_id = localStorage.getItem("user_id");

  const [queryParameters] = useSearchParams();
  let verification = queryParameters.get("q");

  const fetchFollowCount = async () => {
    const res = await fetch(`${baseURL}/following/count/${user_id}`, {
      headers,
    });
    return res.json();
  };

  const {
    data: dataCount,
    status: statusCount,
    refetch: refecthCount,
  } = useQuery("count", fetchFollowCount);
  // console.log(statusCount == "success" ? dataCount.count + " head" : "");

  useEffect(() => {
    refecthCount();
  }, []);

  return (
    <div className="body-login">
      <Helmet>
        <title>People | {process.env.REACT_APP_YOUR_VARIABLE_NAME_HERE}</title>
      </Helmet>
      <Header />
      <Container>
        <Row
          className={
            statusCount == "success" && dataCount.count < 5
              ? "mt-4"
              : "forced-margin justify-content-center"
          }
        >
          {/* <SideNav /> */}
          <RightNav />

          <Col lg={6} className="p-0">
            <Stack>
              <div className={"login-elements-container border-hidde mb-5 p-0"}>
                <Row className={"align-items-center pb-0"}>
                  <Col lg={12} className={"pb-0"}>
                    <div className="p-3 pb-0 mb-0 border-botto">
                      <Row className={"align-items-center"}>
                        <Col
                          lg={1}
                          sm={1}
                          xs={2}
                          className={
                            statusCount == "success" && dataCount.count < 5
                              ? "d-none"
                              : ""
                          }
                        >
                          <label
                            className="back-arrow-post mb-0"
                            onClick={() => navigate(-1)}
                          >
                            <IoIosArrowRoundBack size={30} />
                          </label>
                        </Col>

                        <Col
                          lg={
                            statusCount == "success" && dataCount.count < 5
                              ? 10
                              : 11
                          }
                          sm={
                            statusCount == "success" && dataCount.count < 5
                              ? 10
                              : 11
                          }
                          xs={10}
                          className={"line-height-20"}
                        >
                          <span className="text-bold fontSize-20">People</span>
                          {statusCount == "success" && dataCount.count < 5 ? (
                            <span className="color-grey fontSize14">
                              <br />
                              Following {dataCount.count} people, follow at
                              least 5
                            </span>
                          ) : (
                            ""
                          )}
                        </Col>
                        <Col
                          lg={2}
                          className={
                            statusCount == "success" && dataCount.count < 5
                              ? ""
                              : "d-none"
                          }
                        >
                          <label
                            className={
                              statusCount == "success" && dataCount.count < 5
                                ? "d-none"
                                : "fontBold native-button-dark-grey w-100 text-center"
                            }
                          >
                            Next
                          </label>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>

                {user_id > 0 ? (
                  <Academia userID={user_id} />
                ) : (
                  <div className="text-center mb-5 mt-5">
                    <ClipLoader size={25} color={"#333333"} />
                  </div>
                )}
              </div>
            </Stack>
          </Col>
          {/* <RightNav /> */}
        </Row>
      </Container>
      <Footer
        postSentFeedback={info ? info.message : null}
        postSent={info ? info.success : null}
        postType={"compose"}
      />
    </div>
  );
}

export default Academics;
