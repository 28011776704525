import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  Fragment,
} from "react";
// import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Tagify } from "react-tagify";
import Linkify from "react-linkify";
import { SecureLink } from "react-secure-link";
import { MentionsInput, Mention } from "react-mentions";
import _ from "lodash";
import {
  Col,
  Container,
  Row,
  Stack,
  Modal,
  Button,
  Dropdown,
} from "react-bootstrap";
import {
  Link,
  NavLink,
  useParams,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Header from "./navigation/HeaderHome";
import profile from "../assets/profileplaceholder.jpg";
import Emojies from "../components/features/Emojies";
import Polls from "../components/features/Polls";
import Elements from "../components/features/Elements";
import PreviewMedia from "./previews/PreviewMedia";
import {
  IoMdImages,
  IoIosMic,
  IoIosHeartEmpty,
  IoIosHeart,
} from "react-icons/io";
import {
  BsEmojiSmile,
  BsChatRightDots,
  BsFillBookmarkFill,
  BsBookmark,
  BsXLg,
  BsChevronDoubleUp,
  BsChevronDoubleDown,
  BsChevronDoubleLeft,
  BsChevronDoubleRight,
  BsPencil,
  BsHandThumbsUp,
  BsHandThumbsUpFill,
} from "react-icons/bs";
import {
  FaPoll,
  FaRegFileExcel,
  FaRegFilePowerpoint,
  FaRegFilePdf,
  FaRegFileWord,
  FaFileCsv,
  FaFilePdf,
} from "react-icons/fa";
import {
  BiDotsHorizontalRounded,
  BiUserMinus,
  BiFlag,
  BiSad,
  BiShare,
  BiSolidShare,
} from "react-icons/bi";
import {
  HiOutlineDocumentText,
  HiOutlineTrash,
  HiCheck,
  HiOutlineRefresh,
} from "react-icons/hi";
import ReactTooltip from "react-tooltip";
import { BeatLoader, ClipLoader, PuffLoader } from "react-spinners";
import axios from "axios";
import TopBarProgress from "react-topbar-progress-indicator";
import { BsBookmarkPlus } from "react-icons/bs";
import {
  IoChatboxOutline,
  IoShareOutline,
  IoChatbox,
  IoShare,
  IoEllipse,
  IoBookOutline,
  IoPencil,
  IoBanOutline,
  IoCheckmarkCircleOutline,
  IoArrowBack,
  IoArrowForward,
  IoReloadSharp,
} from "react-icons/io5";

import SideNav from "./navigation/SideNavHome";
import RightNav from "./navigation/SidNavHomeRight";
import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";
import PreviewDocuments from "./previews/PreviewDocuments";
import AttemptUploadErrorMessage from "./previews/AttemptUploadErrorMessage";
import Posts from "./Posts";
import Replies from "./features/Replies";
import mentionStyle from "./css/mentionStyle";
import mentionsInputStyle from "./css/mentionsInputStyle";
import tagStyle from "./css/tagStyle";
import HeaderToken from "./HeaderToken";
import Footer from "./navigation/Footer";

const headers = {
  Authorization: `${HeaderToken.Authorization}`,
  Accept: `${HeaderToken.Accept}`,
};

function Previews(props) {
  const inputElement = useRef();
  let navigate = useNavigate();
  const location = useLocation();
  const info = location.state;

  const queryClient = useQueryClient();
  const { status_id } = useParams();
  const { photo_id } = useParams();
  const { user_name } = useParams();
  const { index } = useParams();
  const { media_index } = useParams();
  const { PostsOnTopicsYouFollow } = useParams();

  const [values, setValues] = useState("");
  const [voteSuccessful, setVoteSuccessful] = useState(false);
  const [postSent, setPostSent] = useState(false);
  const [postSentFeedback, setPostSentFeedback] = useState("");
  const [pagination, setPagination] = useState(20);
  const [opMinHide, setOpMinHide] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [subjectSelected, setSubjectSelected] = useState(false);

  const [selectedTopic, setSelectedTopic] = useState("");
  const [topicSelected, setTopicSelected] = useState(false);
  const [resetFunc, setResetFunc] = useState(false);
  const [topicId, setTopicId] = useState(null);
  const [subjectId, setSubjectId] = useState(null);

  const [subjects, setSubjects] = useState([]);
  const [topics, setTopics] = useState([]);
  const [people, setPeople] = useState([]);
  const [posts, setPosts] = useState([]);
  const [media, setMedia] = useState([]);
  const [postCount, setPostCount] = useState(info ? info.postCount : 0);
  const [replyCount, setReplyCount] = useState(0);
  const [likeCount, setLikeCount] = useState(0);
  const [bookmarkCount, setBookmarkCount] = useState(0);
  const [followCount, setFollowCount] = useState(0);
  const [voteCount, setVoteCount] = useState(0);
  const [blockCount, setBlockCount] = useState(0);
  const [promiseLoading, setPromiseLoading] = useState(true);
  const [bookMarkStatus, setBookMarkStatus] = useState(false);
  const [deleteCount, setDeleteCount] = useState(0);
  const [muteCount, setMuteCount] = useState(0);
  const [complaintCount, setComplaintCount] = useState(0);
  const [concernText, setConcernText] = useState(
    "Tell us why you don't want to see this post."
  );

  const [concernClickedOne, setConcernClickedOne] = useState(false);
  const [concernClickedTwo, setConcernClickedTwo] = useState(false);
  const [concernClickedText, setConcernClickedText] = useState("");
  const [photos, setPhotos] = useState(true);
  const [photoCount, setPhotoCount] = useState(info ? info.photoCount : 0);
  const [photoPost, setPhotoPost] = useState(info ? info.photoPost : "");
  const [photoPostId, setPhotoPostId] = useState(
    info ? info.photoPostId : null
  );
  const [photoId, setPhotoId] = useState(info ? info.photoId : null);
  const [photoUsername, setPhotoUsername] = useState(
    info ? info.photoUsername : ""
  );
  const [photoPostDate, setPhotoPostDate] = useState(
    info ? info.photoPostDate : null
  );
  const [selectedPhoto, setSelectedPhoto] = useState(
    info ? info.selectedPhoto : null
  );
  const [photoPath, setPhotoPath] = useState(info ? info.photoPath : "");
  const [photoRepostsCount, setPhotoRepostsCount] = useState(
    info ? info.photoRepostsCount : 0
  );
  const [photoLikesCount, setPhotoLikesCount] = useState(
    info ? info.photoLikesCount : 0
  );
  const [photoBookmarksCount, setPhotoBookmarksCount] = useState(
    info ? info.photoBookmarksCount : 0
  );
  const [photoRepliesCount, setPhotoRepliesCount] = useState(
    info ? info.photoRepliesCount : 0
  );

  const [photoReposted, setPhotoReposted] = useState(
    info ? info.photoReposted : false
  );
  const [photoLiked, setPhotoLiked] = useState(info ? info.photoLiked : false);
  const [photoBookmarked, setPhotoBookmarked] = useState(
    info ? info.photoBookmarked : false
  );
  const [photoReplied, setPhotoReplied] = useState(
    info ? info.photoReplied : false
  );

  const [replySection, setReplySection] = useState(false);
  const [photoReplies, setPhotoReplies] = useState([]);
  const [photoFullname, setPhotoFullname] = useState(
    info ? info.photoFullname : ""
  );
  const [profilePicPath, setProfilePicPath] = useState(
    info ? info.profilePicPath : ""
  );
  const [photoProfilePic, setPhotoProfilePic] = useState(
    info ? info.photoProfilePic : ""
  );
  const [currentPage, setCurrentPage] = useState("");
  const [photosTimelinePreview, setPhotosTimelinePreview] = useState(
    info ? info.photosTimelinePreview : ""
  );
  const [successfulPhotoReplyShow, setSuccessfulPhotoReplyShow] =
    useState(false);
  const [successfulPhotoReply, setSuccessfulPhotoReply] = useState("");
  const [showMorePost, setShowMorePost] = useState(false);
  const [showMorePostId, setShowMorePostId] = useState(0);
  const [showMorePostCount, setShowMorePostCount] = useState(0);
  const [showMoreQuotedPostText, setShowMoreQuotedPostText] = useState(false);
  const [mediaReposting, setMediaReposting] = useState([]);
  const [singleTopic, setSingleTopic] = useState(null);
  const [singleSubject, setSingleSubject] = useState(null);
  const [photoCollection, setPhotoCollection] = useState(
    info ? info.photoCollection : []
  );

  const [photoPolls, setPhotoPolls] = useState(info ? info.photoPolls : []);
  const [photoDidIVote, setPhotoDidIVote] = useState(
    info ? info.photoDidIVote : false
  );
  const [photoDuration, setPhotoDuration] = useState(
    info ? info.photoDuration : null
  );
  const [photoPollResults, setPhotoPollResults] = useState(
    info ? info.photoPollResults : []
  );
  const [photoDocuments, setPhotoDocuments] = useState(
    info ? info.photoDocuments : ""
  );
  const [photoSubjects, setPhotoSubjects] = useState(
    info ? info.photoSubjects : ""
  );
  const [photoTopics, setPhotoTopics] = useState(info ? info.photoTopics : "");
  const [photoCurrentPostPolls, setPhotoCurrentPostPolls] = useState(
    info ? info.photoCurrentPostPolls : ""
  );
  const [photoCurrentPostPollsClose, setPhotoCurrentPostPollsClose] = useState(
    info ? info.photoCurrentPostPollsClose : false
  );
  const [photoVotes, setPhotoVotes] = useState(info ? info.photoVotes : 0);

  const [documentsReposting, setDocumentsReposting] = useState([]);
  const [nextCount, setNextCount] = useState(info ? info.nextCount : 0);
  const [docTitle, setDocTitle] = useState({});
  const [polls, setPolls] = useState([]);
  const [pollResults, setPollResults] = useState([]);
  const [didIvote, setDidIVote] = useState(0);
  const [currentPostPolls, setCurrentPostPolls] = useState(0);
  const [pollDuration, setPollDuration] = useState([]);
  const [pollVotes, setPollVotes] = useState(0);
  const [filterMentions, setFilterMentions] = useState([]);
  let user_id = localStorage.getItem("user_id");
  const [hidden, setHidden] = useState({});

  const baseURL = process.env.REACT_APP_URL;

  let postsOnTopicsClicked = "home";
  if (PostsOnTopicsYouFollow) {
    postsOnTopicsClicked = "postsOnTopicsYouFollow";
  }

  const postQuery = useQuery({
    queryKey: ["posts", window.location.href],
    queryFn: async () => {
      const response = await axios.get(
        `${baseURL}/posts/get/${user_id}/0/${pagination}/0/${postsOnTopicsClicked}`,
        { headers }
      );
      const data = response.data;
      return data;
    },
  });

  const {
    isRefresh,
    data,
    error,
    failureCount,
    isError,
    isFetchedAfterMount,
    isFetching,
    isIdle,
    isLoading,
    isPreviousData,
    isStale,
    isSuccess,
    refetch,
    remove,
    status,
  } = postQuery;

  function showMore(post_id) {
    setShowMorePostCount(showMorePostCount + 1);
    if (showMorePost == true && showMorePostId == post_id) {
      setShowMorePost(false);
      setShowMorePostId(0);
    } else {
      setShowMorePost(true);
      setShowMorePostId(post_id);
    }
  }

  // console.log(error);
  function getPeople() {}
  // console.log(`@${user_name}`);
  let uri = "";

  if (user_name) {
    uri =
      baseURL +
      "/status/photo/get/" +
      photo_id +
      "/" +
      status_id +
      "/" +
      user_name +
      "/" +
      user_id;
  }

  function getPhotos(
    username,
    post_id,
    photo_id,
    collection = [],
    index,
    post,
    reposted,
    replied,
    liked,
    bookmarked,
    postDate,
    name,
    profile_pic,
    repost_count,
    like_count,
    reply_count,
    bookmark_count,
    selectedImg,
    polls = [],
    did_i_vote,
    duration = [],
    pollResults = [],
    votes,
    documents = [],
    subjects,
    topics,
    current_post_polls,
    current_post_polls_polls
  ) {
    setPhotoPolls(polls);
    setPhotoDidIVote(did_i_vote);
    setPhotoDuration(duration);
    setPhotoPollResults(pollResults);
    setPhotoVotes(votes);
    setPhotoDocuments(documents);
    setPhotoSubjects(subjects);
    setPhotoTopics(topics);
    setPhotoCurrentPostPolls(current_post_polls);
    setPhotoCurrentPostPollsClose(current_post_polls_polls);

    setNextCount(index);
    setPostCount(postCount + 1);

    setPhotoCollection(collection);
    setPhotoPostId(post_id);
    setPhotoId(photo_id);
    setPhotoUsername(username);
    setPhotoPost(post);
    setPhotoReposted(reposted);
    setPhotoLiked(liked);
    setPhotoBookmarked(bookmarked);
    setPhotoReplied(replied);

    setPhotoPostDate(postDate);
    setPhotoFullname(name);
    setPhotoRepostsCount(repost_count);
    setPhotoLikesCount(like_count);
    setPhotoRepliesCount(reply_count);
    setPhotoBookmarksCount(bookmark_count);
    setPhotoProfilePic(profile_pic);
    setSelectedPhoto(`public/timeline/media/${username}/${selectedImg}`);
    setProfilePicPath(`public/profile/picture/@${username}/${profile_pic}`);
    setPhotoPath(`public/timeline/media/${username}/${collection[index]}`);
    setPhotoCount(collection.length);

    setPhotosTimelinePreview(true);

    setReplySection(false);
    navigate(
      "/preview/" +
        username +
        "/status/" +
        post_id +
        "/photo/" +
        photo_id +
        "/" +
        collection.length +
        "/" +
        index
    );
    // history.push({
    //   pathname:
    //     "/preview/" +
    //     username +
    //     "/status/" +
    //     post_id +
    //     "/photo/" +
    //     photo_id +
    //     "/" +
    //     collection.length +
    //     "/" +
    //     index,
    // });
  }

  useEffect(() => {
    // if (!photosTimelinePreview) {
    //   history.push({
    //     pathname: "/home",
    //   });
    // }
    // queryClient.invalidateQueries({ queryKey: ["posts"] }, { exact: true });
    document.title = "Home | " + process.env.REACT_APP_YOUR_VARIABLE_NAME_HERE;
    // const user_id = localStorage.getItem("user_id");
    // getPhotos();

    axios
      .get(url + "/subjects/get/" + user_id, { headers })
      .then((response) => {
        if (response.data.subjects.length == 0) {
          setSubjects([]);
        } else {
          setSubjects(response.data.subjects);
        }
      })
      .catch((error) => {
        setSubjects([]);
      });
  }, [nextCount]);

  const [show, setShow] = useState(false);
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [hideEmoji, setHideEmoji] = useState(false);
  const [post, setPost] = useState("");
  const [newPost, setNewPost] = useState("");
  const [replies, setReply] = useState("");
  const [postButton, setPostbutton] = useState("login-button");
  const [disableBtn, setDisableBtn] = useState("disabled");
  const [textearTextSize, setTextearTextSize] = useState(
    "textarea-share-post-text-size-22"
  );
  const [loadPostbtn, setLoadPostbtn] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [progressBar, setProgressBar] = useState(false);
  const [fullname, setFullname] = useState("");
  const [replyUsername, setReplyUsername] = useState("");
  const [replyPic, setReplyPic] = useState(null);
  const [showReply, setshowReply] = useState(false);

  const [replyName, setReplyName] = useState("");
  const [replyPost, setReplyPost] = useState("");
  const [ReplyPostID, setReplyPost_uuid] = useState(false);
  const [action, setAction] = useState("");
  const [pollClose, setPollClose] = useState(false);
  const [followText, setFollowText] = useState("Following...");
  const [followBtn, setFollowBtn] = useState("btn btn-success btn-sm");
  const [warningText, setWarningText] = useState(null);
  const [preview, setPreview] = useState([]);
  const [previewDoc, setPreviewDoc] = useState([]);
  const [previewURLImage, setPreviewURLImage] = useState();
  const [imgWidth, setImgWidth] = useState();
  const [imgHeight, setImgHeight] = useState(250);
  const [images, setImages] = useState(null);
  const [documents, setDocuments] = useState(null);
  const [previewDocURL, setPreviewDocURL] = useState(null);
  const [selectedEmoji, setSelectedEmoji] = useState("");
  const [focusTextarea, setFocusTextarea] = useState(true);
  const [showSend, setShowSend] = useState(false);
  const [photoRepliesLoader, setPhotoRepliesLoader] = useState(true);
  const [tagValues, setTagValues] = useState([]);
  const [repostsMedia, setRepostsMedia] = useState([]);
  const [optionCount, setOptionCount] = useState(2);
  const [openPoll, setOpenPoll] = useState(false);
  const [optionOne, setOptionOne] = useState(null);
  const [optionTwo, setOptionTwo] = useState(null);
  const [optionThree, setOptionThree] = useState(null);
  const [optionFour, setOptionFour] = useState(null);
  const [days, setDays] = useState(1);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [mentions, setMentions] = useState("");

  const [pollQuestionPlaceholderReply, setPollQuestionPlaceholderReply] =
    useState("Post your reply...");
  const [pollQuestionPlaceholder, setPollQuestionPlaceholder] = useState(
    "What's on your mind today?"
  );

  let url = baseURL;

  function sharePost() {
    navigate("/compose/post", {
      state: {
        page: window.location.pathname,
      },
    });
    // history.push({
    //   pathname: "/compose/post",
    //   state: { page: window.location.pathname },
    // });
    // setWarningText(null);
    // setShow(true);
    // setFocusTextarea(true);
    // setHideEmoji(false);
    // setAction("post");
  }

  function handleClose() {
    setShow(false);
    setHideEmoji(false);
    setshowReply(false);

    // if (currentPage == "status_photo_preview") {
    //   navigate(
    //     "/home/" +
    //       user_name +
    //       "/status/" +
    //       status_id +
    //       "/photo/" +
    //       photo_id +
    //       "/" +
    //       photoCollection.length +
    //       "/" +
    //       media_index
    //   );

    //   // history.push({
    //   //   pathname:
    //   //     "/home/" +
    //   //     user_name +
    //   //     "/status/" +
    //   //     status_id +
    //   //     "/photo/" +
    //   //     photo_id +
    //   //     "/" +
    //   //     photoCollection.length +
    //   //     "/" +
    //   //     media_index,
    //   // });
    // } else if (currentPage == "postsOnTopicsYouFollow") {
    // } else {
    //   navigate({ pathname: "/home", search: "" });
    //   // history.push({
    //   //   pathname: "/home",
    //   //   search: "",
    //   // });
    // }
  }
  // const onEmojiClick = (emojiData) => {
  //   if (action == "reply" || action == "quote") {
  //     setReply((prevInput) => prevInput + emojiData.emoji);
  //   } else {
  //     setNewPost((prevInput) => prevInput + emojiData.emoji);
  //   }

  //   setFocusTextarea(true);
  //   setDisableBtn("");
  //   setPostbutton("login-button");
  // };

  const onEmojiClick = (emojiData) => {
    setReply((prevInput) => prevInput + emojiData.emoji);
    setHideEmoji(false);
    if (inputElement.current) {
      inputElement.current.focus();
    }
    setFocusTextarea(true);
    setDisableBtn("");
    setPostbutton("login-button");
  };

  function emojies() {
    setOpenPoll(false);
    if (hideEmoji == true) {
      setHideEmoji(false);
    } else {
      setHideEmoji(true);
    }
  }

  function shareTextarea(e) {
    const value = e.target.value;
    setNewPost(e.target.value);
    if (e.target.value.trim().length > 0) {
      setPostbutton("login-button");
      setDisableBtn("");

      if (e.target.value.trim().length > 190) {
        setTextearTextSize("textarea-share-post-text-size-15");
      } else {
        setTextearTextSize("textarea-share-post-text-size-22");
      }
    } else {
      setPostbutton("login-button");
      setDisableBtn("disabled");
    }

    let newValue = value.split(" ").pop();
    if (newValue.includes("@")) {
      let mention = value.split(" ").pop();
      axios
        .get(url + "/filter/mentions/" + mention, { headers })
        .then((res) => {
          setFilterMentions(res.data.users);
          // console.log(res.data.users);
        })
        .catch((error) => {
          setFilterMentions([]);
          // console.log(error.response.data.message);
        });
    } else {
      setFilterMentions([]);
    }
  }

  function addMention(username) {
    var val = newPost;
    val = val.substring(0, val.lastIndexOf("@"));
    setNewPost(`${val}@${username} `);
    setFilterMentions([]);
  }

  function updateStatus(event) {
    event.preventDefault();
    const user_id = localStorage.getItem("user_id");
    const formData = new FormData();

    if (docTitle.length >= 0) {
      for (let i = 0; i < docTitle.length; i++) {
        formData.append("docTile", docTitle);
      }
    }
    if (images != null) {
      for (let i = 0; i < images.length; i++) {
        formData.append("images[]", images[i]);
      }
    }

    if (documents != null) {
      for (let i = 0; i < documents.length; i++) {
        formData.append("documents[]", documents[i]);
      }
    }

    if (openPoll == true) {
      formData.append("optionOne", optionOne);
      formData.append("optionTwo", optionTwo);
      formData.append("days", days);
      formData.append("hours", hours);
      formData.append("minutes", minutes);

      if (optionCount >= 3) {
        formData.append("optionThree", optionThree);
      }
      if (optionCount == 4) {
        formData.append("optionFour", optionFour);
      }
    }

    formData.append("post", newPost);
    formData.append("user_id", user_id);
    formData.append("subject_id", subjectId);
    formData.append("topic_id", topicId);
    formData.append("query", "POSTSTATUS");

    if (newPost.trim().length <= 0) {
      setWarningText(
        <label className="p-1 alert alert-danger">Please type your post.</label>
      );
      return;
    }
    setProgressBar(true);
    setSpinner(true);
    setLoadPostbtn(false);

    const url = baseURL;
    // const { mutate, isLoading, error } = useMutation();
    axios({
      method: "POST",
      url: url + "/post/create",
      data: formData,
      headers: {
        Authorization: HeaderToken.Authorization,
        Accept: HeaderToken.Accept,
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        queryClient.invalidateQueries({ queryKey: ["posts"] }, { exact: true });
        setWarningText(null);
        setPostSentFeedback("Great! Your post was sent.");
        setPostSent(true);
        setDocTitle({});
        setOpenPoll(false);
        setPollQuestionPlaceholder("What's on your mind today?");

        setSpinner(false);
        setLoadPostbtn(true);
        setProgressBar(false);
        setShow(false);
        setImages(null);
        setPreview([]);
        setPreviewDoc([]);
        setDocuments(null);
        setNewPost("");

        setTopicId(null);
        setSubjectId(null);
        setSelectedTopic("");
        setSelectedSubject("");
        setTopicSelected(false);
        setSubjectSelected(false);
        setResetFunc(false);
        setTopics([]);
        setDays(1);
        setHours(0);
        setMinutes(0);
        setOptionOne(null);
        setOptionTwo(null);
        setOptionThree(null);
        setOptionFour(null);
        // refetch();
      })
      .catch((error) => {
        setPostSentFeedback("Oops! Something went wrong.");
        setPostSent(true);
      });

    setTimeout(() => {
      setPostSent(false);
    }, 6000);
  }

  function replyTextarea(e) {
    setReply(e.target.value);
    if (e.target.value.trim().length > 0) {
      setDisableBtn("");
      setWarningText(null);

      if (e.target.value.trim().length > 190) {
        setTextearTextSize("textarea-share-post-text-size-15");
      } else {
        setTextearTextSize("textarea-share-post-text-size-22");
      }
    } else {
      setDisableBtn("disabled");
    }
  }

  async function addLike(post_id, currentPage) {
    setLikeCount(1);
    const user_id = localStorage.getItem("user_id");
    setCurrentPage(currentPage);
    // setPhotoLikesCount(photoLikesCount + 1);

    const formData = new FormData();
    formData.append("post_id", post_id);
    formData.append("user_id", user_id);

    const response = await axios({
      method: "POST",
      url: baseURL + "/like/add",
      data: formData,
      headers: {
        Authorization: HeaderToken.Authorization,
        Accept: HeaderToken.Accept,
      },
    })
      .then((response) => {
        queryClient.invalidateQueries({ queryKey: ["posts"] }, { exact: true });
        if (currentPage == "status_photo_preview") {
          if (response.data.is_liked == 1) {
            setPhotoLiked(true);
          } else {
            setPhotoLiked(false);
          }
          setPhotoLikesCount(response.data.like_count);
          setSuccessfulPhotoReplyShow(true);
          setSuccessfulPhotoReply(<label>{response.data.message}</label>);

          setTimeout(() => {
            setSuccessfulPhotoReplyShow(false);
          }, 4000);
        }

        // refetch();
      })
      .catch((error) => {});
  }

  function replyPage(post, name, username, post_uuid) {
    var url = window.location.pathname;
    if (url.includes("hashtag")) {
      return;
    } else if (url.includes("profile")) {
      return;
    } else {
      navigate("/status/" + username + "/" + post_uuid);
      // history.push({
      //   pathname: "/status/" + username + "/" + post_uuid,
      // });
    }
  }
  function Bookmark(post_id, currentPage) {
    const user_id = localStorage.getItem("user_id");
    const url = baseURL;

    const data = new FormData();
    data.append("post_id", post_id);
    data.append("user_id", user_id);
    axios({
      method: "POST",
      url: url + "/bookmarks/create",
      data: data,
      headers: {
        Authorization: HeaderToken.Authorization,
        Accept: HeaderToken.Accept,
      },
    })
      .then((response) => {
        queryClient.invalidateQueries({ queryKey: ["posts"] }, { exact: true });
        if (currentPage == "status_photo_preview") {
          if (response.data.is_bookmarked == 1) {
            setPhotoBookmarked(true);
          } else {
            setPhotoBookmarked(false);
          }

          setPhotoBookmarksCount(response.data.bookmark_count);
          setSuccessfulPhotoReplyShow(true);
          setSuccessfulPhotoReply(<label>{response.data.message}</label>);

          setTimeout(() => {
            setSuccessfulPhotoReplyShow(false);
          }, 4000);
        }

        // refetch();
      })
      .catch((error) => {});
  }

  function reply(
    post,
    name,
    username,
    post_uuid,
    pic,
    polls,
    did_i_vote,
    poll_duration,
    poll_results,
    poll_votes,
    media,
    documents,
    subject,
    topic,
    currentPostPolls,
    pollClosed,
    actionTaken,
    currentPage
  ) {
    // console.log(poll_votes);
    setOpenPoll(false);
    setOptionOne(null);
    setOptionTwo(null);
    setOptionThree(null);
    setOptionFour(null);
    setOptionCount(2);
    setCurrentPostPolls(currentPostPolls);
    setPollClose(pollClosed);
    setAction(actionTaken);
    setReplyUsername(username);
    setReplyPic(pic);
    setReplyName(name);
    setReplyPost(post);
    setReplyPost_uuid(post_uuid);
    setshowReply(true);
    setCurrentPage(currentPage);

    setPolls(polls);
    setPollResults(poll_results);
    setDidIVote(did_i_vote);
    setPollDuration(poll_duration);
    setPollVotes(poll_votes);
    setMediaReposting(media);
    setDocumentsReposting(documents);
    setSingleSubject(subject);
    setSingleTopic(topic);

    // const url = baseURL;
  }

  url = baseURL;
  function addReply(event) {
    event.preventDefault();
    const user_id = localStorage.getItem("user_id");
    const formData = new FormData();

    if (docTitle.length >= 0) {
      for (let i = 0; i < docTitle.length; i++) {
        formData.append("docTile", docTitle);
      }
    }
    if (images != null) {
      for (let i = 0; i < images.length; i++) {
        formData.append("images[]", images[i]);
      }
    }

    if (documents != null) {
      for (let i = 0; i < documents.length; i++) {
        formData.append("documents[]", documents[i]);
      }
    }

    if (openPoll == true) {
      formData.append("optionOne", optionOne);
      formData.append("optionTwo", optionTwo);
      formData.append("days", days);
      formData.append("hours", hours);
      formData.append("minutes", minutes);

      if (optionCount >= 3) {
        formData.append("optionThree", optionThree);
      }
      if (optionCount == 4) {
        formData.append("optionFour", optionFour);
      }
    }

    formData.append(
      "reply",
      action == "reply" || action == "quote"
        ? replies
        : action == "replyCurrentStatus"
        ? post
        : replyPost
    );
    formData.append("post_id", ReplyPostID);
    formData.append("user_id", user_id);
    formData.append("subject_id", subjectId);
    formData.append("topic_id", topicId);
    formData.append("action", action);

    if (action == "reply" && replies.trim().length <= 0) {
      setWarningText(
        <label className="p-1 alert alert-danger">
          Please type your reply.
        </label>
      );
      return;
    }
    setProgressBar(true);
    setSpinner(true);
    setLoadPostbtn(false);

    axios({
      method: "POST",
      url: url + "/reply/create",
      data: formData,
      headers: {
        Authorization: HeaderToken.Authorization,
        Accept: HeaderToken.Accept,
        "content-type": "multipart/form-data",
      },
    }).then((response) => {
      queryClient.invalidateQueries({ queryKey: ["posts"] }, { exact: true });
      setPostSentFeedback(
        action == "reply"
          ? "Reply successfully sent."
          : action == "quote"
          ? "Quote successfully sent."
          : "Repost successfully sent."
      );
      setPostSent(true);
      setOpenPoll(false);
      setPollQuestionPlaceholderReply("Post your reply...");
      setDocTitle({});
      setSpinner(false);
      setLoadPostbtn(true);
      setProgressBar(false);
      setshowReply(false);
      setReply("");
      setImages(null);
      setPreview([]);
      setPreviewDoc([]);
      setDocuments(null);
      setTopicId(null);
      setSubjectId(null);
      setSelectedTopic("");
      setSelectedSubject("");
      setTopicSelected(false);
      setSubjectSelected(false);
      setResetFunc(false);
      setTopics([]);
      setDays(1);
      setHours(0);
      setMinutes(0);
      setOptionOne("");
      setOptionTwo("");
      setOptionThree("");
      setOptionFour("");

      if (currentPage == "status_photo_preview") {
        if (action == "repost") {
          setPhotoReposted(true);
        } else if (action == "reply") {
          setPhotoReplied(true);
        }
        setPhotoRepostsCount(response.data.repost_count);
        setPhotoRepliesCount(response.data.reply_count);
        setSuccessfulPhotoReplyShow(true);
        setSuccessfulPhotoReply(response.data.message);

        setTimeout(() => {
          setSuccessfulPhotoReplyShow(false);
        }, 6000);
        // navigate(-1);
        // history.goBack();
      }
      // refetch();
    });

    setTimeout(() => {
      setPostSent(false);
    }, 6000);
  }

  function deletePost(post_id) {
    axios({
      method: "POST",
      url: url + "/post/delete/" + post_id,
      headers: {
        Authorization: HeaderToken.Authorization,
        Accept: HeaderToken.Accept,
      },
    })
      .then((response) => {
        queryClient.invalidateQueries({ queryKey: ["posts"] }, { exact: true });
        // refetch();
      })
      .catch((error) => {});
  }

  function mutePost(post_id) {
    // setUndoMute()
    const data = new FormData();
    data.append("post_id", post_id);
    data.append("user_id", user_id);
    axios({
      method: "POST",
      url: url + "/post/mute",
      data: data,
      headers: {
        Authorization: HeaderToken.Authorization,
        Accept: HeaderToken.Accept,
      },
    })
      .then((response) => {
        queryClient.invalidateQueries({ queryKey: ["posts"] }, { exact: true });
      })
      .catch((error) => {});
    // refetch();
  }

  function block(user_id) {
    var blocking_user_id = localStorage.getItem("user_id");
    var blocked_user_id = user_id;

    axios
      .get(url + "/block/user/" + blocking_user_id + "/" + blocked_user_id, {
        headers,
      })
      .then((res) => {
        queryClient.invalidateQueries({ queryKey: ["posts"] }, { exact: true });
        // refetch();
      });
  }

  function follow(user_id) {
    setHidden({ ...hidden, [user_id]: !hidden[user_id] });
    var following_user_id = localStorage.getItem("user_id");
    var followed_user_id = user_id;

    const formData = new FormData();
    formData.append("following_user_id", following_user_id);
    formData.append("followed_user_id", followed_user_id);
    const url = baseURL;
    axios({
      method: "POST",
      url: url + "/following/create",
      data: formData,
      headers: {
        Authorization: HeaderToken.Authorization,
        Accept: HeaderToken.Accept,
      },
    }).then((response) => {
      setHidden({ ...hidden, [user_id]: hidden[user_id] });
      queryClient.invalidateQueries({ queryKey: ["posts"] }, { exact: true });
      // refetch();
    });
  }

  function unfollowText() {
    setFollowBtn("btn-sm btn btn-outline-danger rounded-pill");
    setFollowText("Unfollow");
  }

  function followingText() {
    setFollowBtn("btn-sm btn btn-success rounded-pill");
    setFollowText("Following...");
  }

  function documentUpload(e) {
    if (e.target.files.length > 4) {
      setDisableBtn("disabled");
      setWarningText(
        <label className="p-1 alert alert-danger">
          <strong>NB:</strong> You can only upload upto 4 documents per post.
        </label>
      );
    } else {
      setOpenPoll(false);
      setHideEmoji(false);
      setPostCount(postCount + 1);
      setPreview([]);
      setImages(null);
      setDocuments(e.target.files);
      let documents = [];
      let documentName = [];
      for (let i = 0; i < e.target.files.length; i++) {
        documents.push(URL.createObjectURL(e.target.files[i]));
        documentName.push(e.target.files[i].name);
      }

      setPreviewDoc(documentName);
      setPreviewDocURL(documents);
      setDisableBtn("");
      setPostbutton("login-button");
      setWarningText(null);
    }
  }

  function imageUpload(e) {
    if (e.target.files.length > 4) {
      setDisableBtn("disabled");
      setWarningText(
        <label className="p-1 alert alert-danger">
          <strong>NB:</strong> You can only upload upto 4 images per post.
        </label>
      );
    } else {
      setOpenPoll(false);
      setHideEmoji(false);
      setPostCount(postCount + 1);
      setPreviewDoc([]);
      setDocuments(null);
      setImages(e.target.files);
      let images = [];
      let uploadImages = [];
      for (let i = 0; i < e.target.files.length; i++) {
        images.push(URL.createObjectURL(e.target.files[i]));
      }

      if (e.target.files.length == 1) {
        setImgWidth("col-lg-12");
        setImgHeight(250);
      } else if (
        e.target.files.length == 2 ||
        e.target.files.length == 4 ||
        e.target.files.length == 3
      ) {
        setImgWidth("col-lg-6");
        setImgHeight(200);
      }

      setPreview(images);
      setDisableBtn("");
      setPostbutton("login-button");
      setWarningText(null);

      setPreviewURLImage("");
    }
  }

  function fewerFeeds(post_id, complaint, option) {
    setConcernClickedText(complaint);

    setConcernText("You'll see fewer posts like this");
    setShowSend(true);

    if (option == 1) {
      if (concernClickedOne == true) {
        setConcernClickedOne(false);
        setShowSend(false);
        setConcernText("Tell us why you don't want to see this post.");
      } else {
        setConcernClickedOne(true);
      }

      setConcernClickedTwo(false);
    } else {
      if (concernClickedTwo == true) {
        setConcernClickedTwo(false);
        setShowSend(false);
        setConcernText("Tell us why you don't want to see this post.");
      } else {
        setConcernClickedTwo(true);
      }

      setConcernClickedOne(false);
    }
  }

  function sendComplaint(post_id) {
    const data = new FormData();
    data.append("post_id", post_id);
    data.append("user_id", user_id);
    data.append("complaint", concernClickedText);
    axios({
      method: "POST",
      url: url + "/post/send/complaint",
      data: data,
      headers: {
        Authorization: HeaderToken.Authorization,
        Accept: HeaderToken.Accept,
      },
    })
      .then((response) => {
        queryClient.invalidateQueries({ queryKey: ["posts"] }, { exact: true });
        // refetch();
      })
      .catch((error) => {});
  }

  function closePhotos() {
    // navigate(-1);
    setPhotosTimelinePreview(false);
    info.param
      ? navigate({
          pathname: `${info.prevPage}`,
          search: `${info.param ? info.param : ""}`,
        })
      : navigate(`${info.prevPage}`);
  }

  function openReplies() {
    setReplySection(true);

    axios
      .get(
        url +
          "/status/photo/get/" +
          photo_id +
          "/" +
          status_id +
          "/" +
          user_name +
          "/" +
          user_id
      )
      .then((response) => {
        setPhotoRepliesLoader(false);
        if (response.data.replies.length == 0) {
          setPhotoReplies([]);
        } else {
          setPhotoReplies(response.data.replies);
        }
      })
      .catch((error) => {});

    setPhotoRepliesLoader(true);
  }

  function closeReplies() {
    setReplySection(false);
  }

  function showMoreQuotedPost() {
    if (showMoreQuotedPostText == true) {
      setShowMoreQuotedPostText(false);
    } else {
      setShowMoreQuotedPostText(true);
    }
  }

  function subject(subject_id, subject) {
    setSubjectId(subject_id);
    setSelectedSubject(subject);
    setSubjectSelected(true);
    setResetFunc(true);

    axios
      .get(url + "/topics/get/" + subject_id, { headers })
      .then((response) => {
        if (response.data.topics.length == 0) {
          setTopics([]);
        } else {
          setTopics(response.data.topics);
        }
      })
      .catch((error) => {});
  }

  function showSubjects() {
    return (
      <>
        {subjects.length == 0 ? (
          <div className={"paddingRight-13 paddingLeft-13 footnote fontSize14"}>
            Subjects you follow will appear here.
            <br />
            <Link to={"/subjects"}>
              <label className="native-button-dark-grey btn-sm rounded-pill mt-2 fontBold fontSize14 w-100 text-center">
                See subjects
              </label>
            </Link>
          </div>
        ) : (
          ""
        )}
        {/* <div
          className={subjects.length > 0 ? "d-none" : "w-100 text-center mt-1"}
        >
          <ClipLoader size={20} />
        </div> */}

        {subjects.map((item, index) => (
          <Dropdown.Item
            onClick={() => subject(item.subject_id, item.subject)}
            key={index}
          >
            {item.subject}
          </Dropdown.Item>
        ))}
      </>
    );
  }

  function showTopics() {
    return (
      <>
        <div
          className={topics.length > 0 ? "d-none" : "w-100 text-center mt-1"}
        >
          <ClipLoader size={20} />
        </div>
        {topics.map((item, index) => (
          <Dropdown.Item onClick={() => topic(item.id, item.topic)} key={index}>
            {item.topic}
          </Dropdown.Item>
        ))}
      </>
    );
  }

  function topic(topic_id, topic) {
    setTopicId(topic_id);
    setSelectedTopic(topic);
    setTopicSelected(true);
  }

  function resetSubject() {
    setTopicId(null);
    setSubjectId(null);
    setSelectedTopic("");
    setSelectedSubject("");
    setTopicSelected(false);
    setSubjectSelected(false);
    setResetFunc(false);
    setTopics([]);
  }

  function tags(value, index) {
    // const { id, value } = e.target;
    setDocTitle((docTitle) => Object.assign([], docTitle, { [index]: value }));

    // console.log(docTitle);
  }

  function clearAttachment() {
    setPostCount(postCount + 1);
    setPreview([]);
    setImages(null);
    setPreviewDoc([]);
    setDocuments(null);
  }

  function removePollOption() {
    setOptionCount(optionCount - 1);
    if (optionCount == 3) {
      setOptionThree(null);
    }
    if (optionCount == 4) {
      setOptionFour(null);
    }
  }

  function addOption() {
    setOptionCount(optionCount + 1);
  }

  function closePoll() {
    setHideEmoji(false);
    if (openPoll == true) {
      setOpenPoll(false);
      setPollQuestionPlaceholder("What's on your mind today?");
      setPollQuestionPlaceholderReply("Post your reply...");
    } else {
      setOpenPoll(true);
      setPollQuestionPlaceholder("Ask a question...");
      setPollQuestionPlaceholderReply("Ask a question...");
    }
  }

  function vote(poll_id, post_id) {
    setVoteSuccessful(true);
    const data = new FormData();
    data.append("poll_id", poll_id);
    data.append("user_id", user_id);
    data.append("post_id", post_id);
    axios({
      method: "POST",
      url: baseURL + "/vote",
      data: data,
      headers: {
        Authorization: HeaderToken.Authorization,
        Accept: HeaderToken.Accept,
      },
    })
      .then((response) => {
        queryClient.invalidateQueries({ queryKey: ["posts"] }, { exact: true });
        // refetch();
      })
      .catch((error) => {});
  }

  function settingDays(e) {
    setDays(e.target.value);
    if (e.target.value == 0 && minutes < 10 && hours == 0) {
      setHours(1);
    } else if (e.target.value == 0 && minutes >= 10 && hours == 0) {
      setOpMinHide("d-none");
    } else {
      setOpMinHide("");
    }
  }

  function settingHours(e) {
    setHours(e.target.value);
    if (e.target.value == 0 && days == 0 && minutes < 10) {
      setOpMinHide("d-none");
      setMinutes(10);
    } else if (e.target.value == 0 && days == 0 && minutes >= 10) {
      setOpMinHide("d-none");
    } else {
      setOpMinHide("");
    }
  }

  function settingMinutes(e) {
    setMinutes(e.target.value);
    if (e.target.value == 0 && days == 0 && hours == 0) {
      setHours(1);
    }
  }

  function previous() {
    // setReplySection(true);
    setNextCount(nextCount - 1);
    let mediaIndex = parseInt(media_index) - 1;
    // console.log(photoCollection[mediaIndex].id + " - indexing");
    // console.log(photoCollection[mediaIndex].file + "-----");

    setSelectedPhoto(
      `public/timeline/media/${photoUsername}/${photoCollection[mediaIndex].file}`
    );
    setPhotoPath(
      `public/timeline/media/${photoUsername}/${photoCollection[mediaIndex].file}`
    );

    setPhotosTimelinePreview(true);
    navigate(
      "/preview/" +
        user_name +
        "/status/" +
        status_id +
        "/photo/" +
        photoCollection[mediaIndex].id +
        "/" +
        index +
        "/" +
        mediaIndex,
      {
        state: {
          photoPolls: polls,
          photoDidIVote: photoDidIVote,
          photoDuration: photoDuration,
          photoPollResults: photoPollResults,
          photoVotes: photoVotes,
          photoDocuments: photoDocuments,
          photoSubjects: photoSubjects,
          photoTopics: photoTopics,
          photoCurrentPostPolls: photoCurrentPostPolls,
          photoCurrentPostPollsClose: photoCurrentPostPollsClose,
          nextCount: nextCount,
          postCount: postCount,
          photoCollection: photoCollection,
          photoPostId: photoPostId,
          photoId: photoId,
          photoUsername: photoUsername,
          photoPost: photoPost,
          photoReposted: photoReposted,
          photoLiked: photoLiked,
          photoBookmarked: photoBookmarked,
          photoReplied: photoReplied,
          photoPostDate: photoPostDate,
          photoFullname: photoFullname,
          photoRepostsCount: photoRepostsCount,
          photoLikesCount: photoLikesCount,
          photoRepliesCount: photoRepliesCount,
          photoBookmarksCount: photoBookmarksCount,
          photoProfilePic: photoProfilePic,
          selectedPhoto: selectedPhoto,
          profilePicPath: profilePicPath,
          photoPath: photoPath,
          photoCount: photoCount,
          photosTimelinePreview: true,
          prevPage: info.prevPage,
          param: `${info.param ? info.param : "back=true"}`,
        },
      }
    );

    // history.push({
    //   pathname:
    //     "/preview/" +
    //     user_name +
    //     "/status/" +
    //     status_id +
    //     "/photo/" +
    //     photoCollection[mediaIndex].id +
    //     "/" +
    //     index +
    //     "/" +
    //     mediaIndex,
    //   state: {
    //     photoPolls: polls,
    //     photoDidIVote: photoDidIVote,
    //     photoDuration: photoDuration,
    //     photoPollResults: photoPollResults,
    //     photoVotes: photoVotes,
    //     photoDocuments: photoDocuments,
    //     photoSubjects: photoSubjects,
    //     photoTopics: photoTopics,
    //     photoCurrentPostPolls: photoCurrentPostPolls,
    //     photoCurrentPostPollsClose: photoCurrentPostPollsClose,
    //     nextCount: nextCount,
    //     postCount: postCount,
    //     photoCollection: photoCollection,
    //     photoPostId: photoPostId,
    //     photoId: photoId,
    //     photoUsername: photoUsername,
    //     photoPost: photoPost,
    //     photoReposted: photoReposted,
    //     photoLiked: photoLiked,
    //     photoBookmarked: photoBookmarked,
    //     photoReplied: photoReplied,
    //     photoPostDate: photoPostDate,
    //     photoFullname: photoFullname,
    //     photoRepostsCount: photoRepostsCount,
    //     photoLikesCount: photoLikesCount,
    //     photoRepliesCount: photoRepliesCount,
    //     photoBookmarksCount: photoBookmarksCount,
    //     photoProfilePic: photoProfilePic,
    //     selectedPhoto: selectedPhoto,
    //     profilePicPath: profilePicPath,
    //     photoPath: photoPath,
    //     photoCount: photoCount,
    //     photosTimelinePreview: true,
    //     prevPage: info.prevPage,
    //     param: `${info.param ? info.param : "back=true"}`,
    //   },
    // });
  }

  function next() {
    setNextCount(nextCount + 1);
    setPhotosTimelinePreview(true);

    let mediaIndex = parseInt(media_index) + 1;

    setSelectedPhoto(
      `public/timeline/media/${photoUsername}/${photoCollection[mediaIndex].file}`
    );
    setPhotoPath(
      `public/timeline/media/${photoUsername}/${photoCollection[mediaIndex].file}`
    );
    navigate(
      "/preview/" +
        user_name +
        "/status/" +
        status_id +
        "/photo/" +
        photoCollection[mediaIndex].id +
        "/" +
        index +
        "/" +
        mediaIndex,
      {
        state: {
          photoPolls: polls,
          photoDidIVote: photoDidIVote,
          photoDuration: photoDuration,
          photoPollResults: photoPollResults,
          photoVotes: photoVotes,
          photoDocuments: photoDocuments,
          photoSubjects: photoSubjects,
          photoTopics: photoTopics,
          photoCurrentPostPolls: photoCurrentPostPolls,
          photoCurrentPostPollsClose: photoCurrentPostPollsClose,
          nextCount: nextCount,
          postCount: postCount,
          photoCollection: photoCollection,
          photoPostId: photoPostId,
          photoId: photoId,
          photoUsername: photoUsername,
          photoPost: photoPost,
          photoReposted: photoReposted,
          photoLiked: photoLiked,
          photoBookmarked: photoBookmarked,
          photoReplied: photoReplied,
          photoPostDate: photoPostDate,
          photoFullname: photoFullname,
          photoRepostsCount: photoRepostsCount,
          photoLikesCount: photoLikesCount,
          photoRepliesCount: photoRepliesCount,
          photoBookmarksCount: photoBookmarksCount,
          photoProfilePic: photoProfilePic,
          selectedPhoto: selectedPhoto,
          profilePicPath: profilePicPath,
          photoPath: photoPath,
          photoCount: photoCount,
          photosTimelinePreview: true,
          prevPage: info.prevPage,
          param: `${info.param ? info.param : "back=true"}`,
        },
      }
    );

    // history.push({
    //   pathname:
    //     "/preview/" +
    //     user_name +
    //     "/status/" +
    //     status_id +
    //     "/photo/" +
    //     photoCollection[mediaIndex].id +
    //     "/" +
    //     index +
    //     "/" +
    //     mediaIndex,
    //   state: {
    //     photoPolls: polls,
    //     photoDidIVote: photoDidIVote,
    //     photoDuration: photoDuration,
    //     photoPollResults: photoPollResults,
    //     photoVotes: photoVotes,
    //     photoDocuments: photoDocuments,
    //     photoSubjects: photoSubjects,
    //     photoTopics: photoTopics,
    //     photoCurrentPostPolls: photoCurrentPostPolls,
    //     photoCurrentPostPollsClose: photoCurrentPostPollsClose,
    //     nextCount: nextCount,
    //     postCount: postCount,
    //     photoCollection: photoCollection,
    //     photoPostId: photoPostId,
    //     photoId: photoId,
    //     photoUsername: photoUsername,
    //     photoPost: photoPost,
    //     photoReposted: photoReposted,
    //     photoLiked: photoLiked,
    //     photoBookmarked: photoBookmarked,
    //     photoReplied: photoReplied,
    //     photoPostDate: photoPostDate,
    //     photoFullname: photoFullname,
    //     photoRepostsCount: photoRepostsCount,
    //     photoLikesCount: photoLikesCount,
    //     photoRepliesCount: photoRepliesCount,
    //     photoBookmarksCount: photoBookmarksCount,
    //     photoProfilePic: photoProfilePic,
    //     selectedPhoto: selectedPhoto,
    //     profilePicPath: profilePicPath,
    //     photoPath: photoPath,
    //     photoCount: photoCount,
    //     photosTimelinePreview: true,
    //     prevPage: info.prevPage,
    //     param: `${info.param ? info.param : "back=true"}`,
    //   },
    // });
  }

  window.onscroll = function (ev) {
    if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight) {
      queryClient.invalidateQueries({ queryKey: ["posts"] }, { exact: true });
      // refetch();
      // console.log(isFetching);
      setPagination(pagination + 10);
      // console.log(postCount);
    }
  };

  function hashtag(value, type, post_id) {
    let hashtagValue;
    if (type == "tag") {
      hashtagValue = value.replace("#", "");
      navigate(`/hashtag/${post_id}/${hashtagValue}`);
      // history.push({ pathname: `/hashtag/${post_id}/${hashtagValue}` });
    } else {
      hashtagValue = value.replace("@", "");
      navigate(`/profile/${hashtagValue}`);
      // history.push({ pathname: `/profile/${hashtagValue}` });
    }

    return;
  }

  function modalFunc() {
    // setFilterMentions([]);
    if (hideEmoji == true) {
      setHideEmoji(false);
    }
  }

  return (
    <div className="body-login">
      {progressBar && <TopBarProgress />}
      {photosTimelinePreview} ----
      {photosTimelinePreview ? (
        <Fragment>
          <Modal
            show={showReply}
            onHide={handleClose}
            backdropClassName="backdrop-login"
            centered
            className={"modal_bg_color_white"}
          >
            <Modal.Body>
              <Container className="p-1 pt-0">
                <form onSubmit={addReply}>
                  <Row className="align-items-center">
                    <Col lg={12} className="whiteSpace mb-2">
                      <textarea
                        className={
                          action == "repost"
                            ? "d-none"
                            : textearTextSize + " textarea-share-post"
                        }
                        placeholder={
                          action == "quote"
                            ? "Say something..."
                            : pollQuestionPlaceholderReply
                        }
                        value={replies}
                        onChange={replyTextarea}
                        onClick={modalFunc}
                        ref={inputElement}
                      />
                      <AttemptUploadErrorMessage
                        warningText={warningText}
                        className={warningText == null ? "d-none" : "col-12"}
                      />
                      <PreviewMedia
                        preview={preview}
                        clearAttachment={clearAttachment}
                        className={preview.length > 0 ? "" : "d-none"}
                      />
                      <PreviewDocuments
                        previewDoc={previewDoc}
                        clearAttachment={clearAttachment}
                        docTitle={docTitle}
                        tags={tags}
                        previewDocURL={previewDocURL}
                        className={previewDoc.length > 0 ? "" : "d-none"}
                      />
                      <div className={openPoll == false ? "d-none" : "mb-3"}>
                        <Polls
                          openPoll={openPoll}
                          optionOne={optionOne}
                          optionTwo={optionTwo}
                          optionThree={optionThree}
                          optionFour={optionFour}
                          optionCount={optionCount}
                          removePollOption={removePollOption}
                          setOptionOne={setOptionOne}
                          setOptionTwo={setOptionTwo}
                          setOptionThree={setOptionThree}
                          setOptionFour={setOptionFour}
                          settingHours={settingHours}
                          settingDays={settingDays}
                          hours={hours}
                          days={days}
                          settingMinutes={settingMinutes}
                          minutes={minutes}
                          addOption={addOption}
                          closePoll={closePoll}
                          opMinHide={opMinHide}
                        />
                      </div>
                      {hideEmoji && (
                        <div className="mb-3 mt-0 col-12">
                          {/* <span
                          className="text-normal float-right share-icons"
                          data-tip={"Close"}
                          data-place={"top"}
                          data-effect={"solid"}
                          onClick={emojies}
                        >
                          <BsXLg size={13} />
                          <ReactTooltip arrowColor={"transparent"} />
                        </span> */}
                          <Emojies
                            handleClick={onEmojiClick}
                            action={"post"}
                            homeReply={true}
                          />
                        </div>
                      )}
                      <Col
                        lg={12}
                        className={action == "repost" ? "d-none" : "mb-3"}
                      >
                        <Elements
                          imageUpload={imageUpload}
                          documentUpload={documentUpload}
                          emojies={emojies}
                          chosenEmoji={chosenEmoji}
                          closePoll={closePoll}
                          subjectSelected={subjectSelected}
                          selectedSubject={selectedSubject}
                          selectedTopic={selectedTopic}
                          topicSelected={topicSelected}
                          showTopics={showTopics}
                          showSubjects={showSubjects}
                          resetFunc={resetFunc}
                          resetSubject={resetSubject}
                          currentPostPolls={currentPostPolls}
                          action={action}
                        />
                      </Col>
                      <div className="w-100">
                        <Row className={"align-items-center"}>
                          <Col lg={2} xs={3} sm={2}>
                            <div
                              className={"profile-pic"}
                              style={{
                                backgroundImage:
                                  "url(" +
                                  process.env.REACT_APP_URL.replace("api", "") +
                                  "public/profile/picture/@" +
                                  replyUsername +
                                  "/" +
                                  replyPic +
                                  ")",
                              }}
                            />
                          </Col>
                          <Col
                            lg={10}
                            xs={9}
                            sm={10}
                            className={"p-0 marginLeft-13"}
                          >
                            <label className={"line-height-20"}>
                              <span className="text-bold">{replyName}</span>
                              <br />
                              <span className="footnote">@{replyUsername}</span>
                            </label>
                          </Col>
                        </Row>

                        <div className={"mb-0 mt-2"}>
                          <Row className={singleTopic == null ? "d-none" : ""}>
                            <Col lg={12} className="pt-0">
                              <Link
                                to={
                                  singleTopic == null
                                    ? ""
                                    : "/topicPosts?clicked=" +
                                      singleTopic +
                                      "&from_post_id=" +
                                      ReplyPostID
                                }
                              >
                                <label
                                  className={
                                    "topics fontSize14 cursorPointer textUnderlineNone underlineHover text-normal"
                                  }
                                >
                                  {singleTopic == null ? (
                                    ""
                                  ) : (
                                    <>
                                      <IoBookOutline size={15} /> {singleTopic}
                                    </>
                                  )}
                                </label>
                              </Link>
                            </Col>
                          </Row>
                          {replyPost == null ? (
                            ""
                          ) : replyPost.length > 150 ? (
                            <>
                              {showMoreQuotedPostText == true ? (
                                <Tagify
                                  onClick={(text, type) =>
                                    hashtag(text, type, ReplyPostID)
                                  }
                                  detectHashtags={true}
                                  detectMentions={true}
                                  tagStyle={tagStyle}
                                  mentionStyle={mentionStyle}
                                  detectLinks={true}
                                >
                                  <Linkify
                                    componentDecorator={(
                                      decoratedHref,
                                      decoratedText,
                                      key
                                    ) => (
                                      <SecureLink
                                        target="_blank"
                                        href={decoratedHref}
                                        key={key}
                                        className={
                                          "color-blue textUnderlineNone"
                                        }
                                      >
                                        {decoratedText}
                                      </SecureLink>
                                    )}
                                  >
                                    {replyPost}
                                  </Linkify>
                                </Tagify>
                              ) : (
                                <Tagify
                                  onClick={(text, type) =>
                                    hashtag(text, type, ReplyPostID)
                                  }
                                  detectHashtags={true}
                                  detectMentions={true}
                                  tagStyle={tagStyle}
                                  mentionStyle={mentionStyle}
                                  detectLinks={true}
                                >
                                  <Linkify
                                    componentDecorator={(
                                      decoratedHref,
                                      decoratedText,
                                      key
                                    ) => (
                                      <SecureLink
                                        target="_blank"
                                        href={decoratedHref}
                                        key={key}
                                        className={
                                          "color-blue textUnderlineNone"
                                        }
                                      >
                                        {decoratedText}
                                      </SecureLink>
                                    )}
                                  >
                                    {replyPost.slice(0, 150)}
                                  </Linkify>
                                </Tagify>
                              )}
                              <span
                                className="color-blue cursorPointer text-normal"
                                onClick={showMoreQuotedPost}
                              >
                                {showMoreQuotedPostText == true ? (
                                  <span className="text-normal">
                                    {" "}
                                    Show less...
                                  </span>
                                ) : (
                                  <span className="text-normal">
                                    <span className="color-black text-normal">
                                      ...
                                    </span>{" "}
                                    Show more
                                  </span>
                                )}
                              </span>
                            </>
                          ) : (
                            <div className={"w-100"}>
                              <Tagify
                                onClick={(text, type) =>
                                  hashtag(text, type, ReplyPostID)
                                }
                                detectHashtags={true}
                                detectMentions={true}
                                tagStyle={tagStyle}
                                mentionStyle={mentionStyle}
                                detectLinks={true}
                              >
                                <Linkify
                                  componentDecorator={(
                                    decoratedHref,
                                    decoratedText,
                                    key
                                  ) => (
                                    <SecureLink
                                      target="_blank"
                                      href={decoratedHref}
                                      key={key}
                                      className={"color-blue textUnderlineNone"}
                                    >
                                      {decoratedText}
                                    </SecureLink>
                                  )}
                                >
                                  {replyPost}
                                </Linkify>
                              </Tagify>
                            </div>
                          )}
                          <div>
                            <Row
                              className={
                                mediaReposting.length > 0 ? "mt-1" : "d-none"
                              }
                            >
                              {mediaReposting.map((img, index) => (
                                <Col
                                  className={
                                    mediaReposting.length == 1
                                      ? "col-12"
                                      : mediaReposting.length == 2
                                      ? "col-6 paddingSides" + index
                                      : mediaReposting.length == 3
                                      ? "col-4 threePaddingSides" + index
                                      : mediaReposting.length == 4
                                      ? "col-6 fourPaddingSides" + index
                                      : "col-6"
                                  }
                                  key={index}
                                >
                                  <div
                                    onClick={() =>
                                      getPhotos(
                                        replyUsername,
                                        mediaReposting.post_id,
                                        img.id,
                                        mediaReposting,
                                        index
                                      )
                                    }
                                    className={
                                      mediaReposting.length == 2
                                        ? "mb-3 border-radius" + index
                                        : mediaReposting.length == 3
                                        ? "borderRadiusImg" + index
                                        : mediaReposting.length == 4
                                        ? "borderRadiusImgFour" + index
                                        : "mb-3 border-radius-5"
                                    }
                                    style={{
                                      backgroundImage:
                                        "url(" +
                                        process.env.REACT_APP_URL.replace(
                                          "api",
                                          ""
                                        ) +
                                        "public/timeline/media/" +
                                        replyUsername +
                                        "/" +
                                        img.file +
                                        ")",
                                      backgroundSize: "cover",
                                      height:
                                        mediaReposting.length == 1
                                          ? 300
                                          : mediaReposting.length == 2
                                          ? 300
                                          : mediaReposting.length == 4
                                          ? 150
                                          : 200,
                                      width: "100%",
                                      border: "thin solid #ececec",

                                      // backgroundRepeat: "no-repeat",
                                      backgroundPosition: "center",
                                    }}
                                  />
                                </Col>
                              ))}
                            </Row>

                            <div
                              className={
                                currentPostPolls == false ? "d-none" : ""
                              }
                            >
                              <Row
                                className={polls.length == 0 ? "d-none" : ""}
                              >
                                <div
                                  className={
                                    didIvote == 1 || pollClose == true
                                      ? "col-12 mt-1"
                                      : "d-none"
                                  }
                                >
                                  {pollResults.map((results, index) => (
                                    <div
                                      className={
                                        results.percentage == 0
                                          ? "w-100 resulst-polls-container-no-votes"
                                          : "w-100 resulst-polls-container"
                                      }
                                      key={index}
                                    >
                                      <label
                                        className={
                                          results.percentage == 0
                                            ? ""
                                            : "polls-voted color-black"
                                        }
                                        style={{
                                          width: results.percentage + "%",
                                        }}
                                      />
                                      <label className={"color-black"}>
                                        {results.options}{" "}
                                        {results.who_i_voted_for == 1 ? (
                                          <IoCheckmarkCircleOutline
                                            size={20}
                                            color={"#66b266"}
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </label>
                                      <label
                                        className={"float-right color-black"}
                                      >
                                        {results.percentage}%
                                      </label>
                                    </div>
                                  ))}
                                </div>
                                <div
                                  className={
                                    polls.length > 0 &&
                                    didIvote == 0 &&
                                    pollClose == false
                                      ? "mt-1"
                                      : "d-none"
                                  }
                                >
                                  <label
                                    className={
                                      !voteSuccessful
                                        ? "d-none"
                                        : "w-100 color-green text-left mb-1"
                                    }
                                  >
                                    Vote successful!
                                  </label>
                                  <div
                                    className={voteSuccessful ? "d-none" : ""}
                                  >
                                    {polls.map((polls, index) => (
                                      <Col lg={12} key={index}>
                                        <div
                                          className={"col-12 polls fontBold"}
                                          onClick={() =>
                                            vote(polls.id, polls.post_id)
                                          }
                                        >
                                          {polls.option}
                                        </div>
                                      </Col>
                                    ))}
                                  </div>
                                </div>
                                <label className={"footnote"}>
                                  {pollVotes}{" "}
                                  {pollVotes == 1 ? "vote" : "votes"} -{" "}
                                  {photoCurrentPostPollsClose == true
                                    ? "Poll closed"
                                    : pollDuration.minutes <= 0 &&
                                      pollDuration.days <= 0 &&
                                      pollDuration.hours <= 0 &&
                                      pollDuration.seconds <= 0
                                    ? "Poll closed."
                                    : pollDuration.days >= 1
                                    ? pollDuration.days + "d left"
                                    : pollDuration.hours >= 1 &&
                                      pollDuration.minutes >= 1
                                    ? pollDuration.hours +
                                      "h and " +
                                      pollDuration.minutes +
                                      "m left"
                                    : pollDuration.hours >= 1 &&
                                      pollDuration.minutes == 0
                                    ? pollDuration.hours + "h left"
                                    : pollDuration.minutes >= 1
                                    ? pollDuration.minutes + "m left"
                                    : "Poll closed."}
                                </label>
                              </Row>
                            </div>
                            <Row
                              className={
                                documentsReposting.length > 0
                                  ? "mt-0 paddingLeft10 paddingRight10"
                                  : "d-none"
                              }
                            >
                              {documentsReposting.map((doc, index) => (
                                <Col lg={12} key={index}>
                                  <a
                                    href={
                                      process.env.REACT_APP_URL.replace(
                                        "api",
                                        ""
                                      ) +
                                      "public/timeline/documents/" +
                                      replyUsername +
                                      "/" +
                                      doc.file
                                    }
                                    target={"_blank"}
                                    className={"textUnderlineNone"}
                                  >
                                    <Row
                                      className={
                                        "align-items-center document-link-timeline"
                                      }
                                    >
                                      <Col
                                        lg={12}
                                        key={index}
                                        className={"p-0"}
                                      >
                                        <div className={""}>
                                          {doc.format == "pdf" ? (
                                            <label className="p-1">
                                              <FaRegFilePdf
                                                size={20}
                                                className={"color-red"}
                                              />
                                            </label>
                                          ) : doc.format == "docx" ? (
                                            <label className="p-1">
                                              <FaRegFileWord
                                                size={20}
                                                className={"color-blue"}
                                              />
                                            </label>
                                          ) : doc.format == "csv" ? (
                                            <label className="p-1">
                                              <FaFileCsv
                                                size={20}
                                                className={"color-green"}
                                              />
                                            </label>
                                          ) : doc.format == "xlsx" ? (
                                            <label className="p-1">
                                              <FaRegFileExcel
                                                size={20}
                                                className={"color-green"}
                                              />
                                            </label>
                                          ) : doc.format == "pptx" ? (
                                            <label className="p-1">
                                              <FaRegFilePowerpoint
                                                size={20}
                                                className={"color-orange"}
                                              />
                                            </label>
                                          ) : (
                                            ""
                                          )}

                                          {doc.title == ""
                                            ? doc.file.length > 30
                                              ? doc.file.slice(0, 30) + "..."
                                              : doc.file
                                            : (doc.title.length > 30
                                                ? doc.title.slice(0, 30) + "..."
                                                : doc.title) +
                                              "." +
                                              doc.format}
                                        </div>
                                      </Col>
                                    </Row>
                                  </a>
                                </Col>
                              ))}
                            </Row>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col lg={12} xs={12} className="mb-0">
                      {action == "repost" ? (
                        <label className="footnote mb-2">
                          <span className="text-bold">NB:</span> Your followers
                          will see this.
                        </label>
                      ) : action == "quote" ? (
                        ""
                      ) : (
                        ""
                      )}

                      {loadPostbtn && (
                        <Button
                          className={
                            action == "repost" || action == "quote"
                              ? "login-button mb-3 rounded-pill"
                              : postButton + " mb-3 text-center rounded-pill"
                          }
                          type="submit"
                          disabled={
                            action == "repost" || action == "quote"
                              ? ""
                              : disableBtn
                          }
                        >
                          {action == "reply"
                            ? "Reply"
                            : action == "quote"
                            ? "Quote"
                            : "Repost"}
                        </Button>
                      )}

                      {spinner && (
                        <Button
                          type="button"
                          disabled={true}
                          className="login-button text-center mb-3 rounded-pill"
                        >
                          <BeatLoader color={"#fff"} />
                        </Button>
                      )}

                      <label
                        className="join-now-button text-center rounded-pill"
                        onClick={handleClose}
                      >
                        Cancel
                      </label>
                    </Col>
                  </Row>
                </form>
              </Container>
            </Modal.Body>
          </Modal>
          <Modal
            show={photos}
            onHide={closePhotos}
            aria-labelledby="example-custom-modal-styling-title"
            fullscreen={true}
          >
            {/* <Modal.Header closeButton></Modal.Header> */}
            <Modal.Body className="p-0 bgBlack">
              <div className={replySection == true ? "col-9" : "col-12"}>
                <Row className={"p-0"}>
                  <Col lg={12} className={"p-0 row justify-content-center"}>
                    <div
                      className={"photos-timeline-preview"}
                      style={{
                        backgroundImage:
                          "url(" +
                          process.env.REACT_APP_URL.replace("api", "") +
                          selectedPhoto +
                          ")",
                      }}
                    >
                      <span
                        className={
                          media_index == 0 ? "d-none" : "color-white previous"
                        }
                        onClick={previous}
                        //   data-tip={"Previous"}
                        //   data-place={"right"}
                        //   data-effect={"solid"}
                      >
                        <IoArrowBack size={20} /> {}
                        {/* <ReactTooltip
                        arrowColor={"transparent"}
                        className="p-1 fontSize-12"
                      /> */}
                      </span>
                      <span
                        className={
                          media_index == parseInt(photoCollection.length) - 1
                            ? "d-none"
                            : "color-white next"
                        }
                        onClick={next}
                        //   data-tip={"Next"}
                        //   data-place={"right"}
                        //   data-effect={"solid"}
                      >
                        <IoArrowForward size={20} />
                        {/* <ReactTooltip
                        arrowColor={"transparent"}
                        className="p-1 fontSize-12"
                      /> */}
                      </span>
                      <span
                        className="closePhotos"
                        onClick={closePhotos}
                        data-tip={"Close"}
                        data-place={"right"}
                        data-effect={"solid"}
                      >
                        <BsXLg size={20} />
                        <ReactTooltip
                          arrowColor={"transparent"}
                          className="p-1 fontSize-12"
                        />
                      </span>
                      <span
                        data-tip={"Show"}
                        data-place={"bottom"}
                        data-effect={"solid"}
                        className={
                          replySection == true ? "d-none" : "openReplies"
                        }
                        onClick={openReplies}
                      >
                        <BsChevronDoubleLeft size={20} />
                        <ReactTooltip
                          arrowColor={"transparent"}
                          className="p-1 fontSize-12"
                        />
                      </span>
                    </div>
                    <div
                      className={
                        replySection == true ? "replySection p-0" : "d-none"
                      }
                    >
                      <span
                        data-tip={"Hide"}
                        data-place={"bottom"}
                        data-effect={"solid"}
                        className="closeReplies"
                        onClick={closeReplies}
                      >
                        <BsChevronDoubleRight size={20} />
                        <ReactTooltip
                          arrowColor={"transparent"}
                          className="p-1 fontSize-12"
                        />
                      </span>
                      <div className="w-100 commentSection">
                        <Row className="p-3">
                          <Col lg={2}>
                            <Link to={"/profile/" + photoUsername}>
                              <div
                                className={"profile-pic"}
                                style={{
                                  backgroundImage:
                                    "url(" +
                                    process.env.REACT_APP_URL.replace(
                                      "api",
                                      ""
                                    ) +
                                    profilePicPath +
                                    ")",
                                }}
                              />
                            </Link>
                          </Col>
                          <Col lg={10}>
                            <Row>
                              <Col lg={12}>
                                <Link
                                  to={"/profile/" + photoUsername}
                                  className={
                                    "color-black textUnderlineNone cursorPointer"
                                  }
                                >
                                  <label className="color-grey cursorPointer ">
                                    <span
                                      className={
                                        "cursorPointer fontBold color-black"
                                      }
                                    >
                                      {photoFullname}
                                    </span>{" "}
                                    <br />@{photoUsername}
                                  </label>
                                </Link>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row className="p-3">
                          <Col lg={12} className="mt-0">
                            <Link
                              to={
                                "/status/" + photoUsername + "/" + photoPostId
                              }
                              className="color-black textUnderlineNone cursorPointer"
                            >
                              <h5 className="whiteSpace">
                                {photoPost == null ? (
                                  ""
                                ) : photoPost.length > 250 ? (
                                  <>
                                    {photoPost.slice(0, 250)}
                                    ...
                                    <label className="color-blue cursorPointer fontSize14">
                                      {" "}
                                      Show more
                                    </label>
                                  </>
                                ) : (
                                  <Tagify
                                    onClick={(text, type) =>
                                      hashtag(text, type, photoPostId)
                                    }
                                    detectHashtags={true}
                                    detectMentions={true}
                                    tagStyle={tagStyle}
                                    mentionStyle={mentionStyle}
                                    detectLinks={true}
                                  >
                                    <Linkify
                                      componentDecorator={(
                                        decoratedHref,
                                        decoratedText,
                                        key
                                      ) => (
                                        <SecureLink
                                          target="_blank"
                                          href={decoratedHref}
                                          key={key}
                                          className={
                                            "color-blue textUnderlineNone"
                                          }
                                        >
                                          {decoratedHref}
                                        </SecureLink>
                                      )}
                                    >
                                      {photoPost}
                                    </Linkify>
                                  </Tagify>
                                )}
                              </h5>
                            </Link>
                            <label className="footnote">{photoPostDate}</label>
                          </Col>
                        </Row>

                        <div className={"timeline_hr_line "} />

                        <Replies
                          reply={reply}
                          postID={photoPostId}
                          photoPreview={true}
                          photoPost={photoPost}
                          photoFullname={photoFullname}
                          user_name={user_name}
                          status_id={status_id}
                          photoProfilePic={photoProfilePic}
                          photoPolls={photoPolls}
                          photoDidIVote={photoDidIVote}
                          photoDuration={photoDuration}
                          photoPollResults={photoPollResults}
                          photoVotes={photoVotes}
                          photoCollection={photoCollection}
                          photoDocuments={photoDocuments}
                          photoSubjects={photoSubjects}
                          photoTopics={photoTopics}
                          photoCurrentPostPolls={photoCurrentPostPolls}
                          photoPollClosed={photoCurrentPostPollsClose}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              <div className={"relativ"}>
                <div className={replySection == true ? "col-9" : "col-12"}>
                  <Row className="mt-3 justify-content-center fontSize14 action-row-wrap">
                    <Col
                      className={
                        replySection == true
                          ? "col-2"
                          : "col-lg-1 col-sm-1 col-xs-1 text-center"
                      }
                    >
                      <label
                        className={
                          photoReplied == true
                            ? "action-replies-true action-replies"
                            : "action-replies"
                        }
                        onClick={() =>
                          reply(
                            photoPost,
                            photoFullname,
                            user_name,
                            status_id,
                            photoProfilePic,
                            photoPolls,
                            photoDidIVote,
                            photoDuration,
                            photoPollResults,
                            photoVotes,
                            photoCollection,
                            photoDocuments,
                            photoSubjects,
                            photoTopics,
                            photoCurrentPostPolls,
                            photoCurrentPostPollsClose,
                            "reply",
                            "status_photo_preview"
                          )
                        }
                      >
                        {photoReplied == true ? (
                          <IoChatbox size={18} />
                        ) : (
                          <IoChatboxOutline size={18} />
                        )}{" "}
                        &nbsp;{photoRepliesCount}&nbsp;{" "}
                        <span className={"mobile-hide"}>Replies</span>
                      </label>
                    </Col>
                    <Col
                      className={
                        replySection == true
                          ? "col-2"
                          : "col-lg-1 col-sm-1 col-xs-1  text-center"
                      }
                    >
                      <label
                        className="action-reposts"
                        onClick={() =>
                          reply(
                            photoPost,
                            photoFullname,
                            user_name,
                            status_id,
                            photoProfilePic,
                            photoPolls,
                            photoDidIVote,
                            photoDuration,
                            photoPollResults,
                            photoVotes,
                            photoCollection,
                            photoDocuments,
                            photoSubjects,
                            photoTopics,
                            photoCurrentPostPolls,
                            photoCurrentPostPollsClose,
                            "repost",
                            "status_photo_preview"
                          )
                        }
                      >
                        {photoReposted == true ? (
                          <BiSolidShare size={18} />
                        ) : (
                          <BiShare size={18} />
                        )}
                        &nbsp;{photoRepostsCount}&nbsp;{" "}
                        <span className={"mobile-hide"}>Reposts</span>
                      </label>
                    </Col>
                    <Col
                      className={
                        replySection == true
                          ? "col-2"
                          : "col-l-g-1 col-sm-1 col-xs-1 text-center"
                      }
                    >
                      <label
                        className={
                          photoLiked == true
                            ? "action-likes-true action-likes"
                            : "action-likes"
                        }
                        onClick={() =>
                          addLike(status_id, "status_photo_preview")
                        }
                      >
                        {photoLiked == true ? (
                          <BsHandThumbsUpFill size={18} />
                        ) : (
                          <BsHandThumbsUp size={18} />
                        )}
                        &nbsp;{photoLikesCount}&nbsp;{" "}
                        <span className={"mobile-hide"}>Likes</span>
                      </label>
                    </Col>
                    <Col
                      className={
                        replySection == true
                          ? "col-2"
                          : "col-lg-1 col-sm-1 col-xs-1 text-center"
                      }
                    >
                      <label
                        className={
                          photoBookmarked == true
                            ? "action-bookmarks-true action-bookmarks"
                            : "action-bookmarks"
                        }
                        onClick={() =>
                          Bookmark(status_id, "status_photo_preview")
                        }
                      >
                        {photoBookmarked == true ? (
                          <BsFillBookmarkFill size={18} />
                        ) : (
                          <BsBookmarkPlus size={18} />
                        )}
                        &nbsp;{photoBookmarksCount}&nbsp;{" "}
                        <span className={"mobile-hide"}>Bookmarks</span>
                      </label>
                    </Col>
                  </Row>

                  {successfulPhotoReplyShow && (
                    <Row className="justify-content-center">
                      <div
                        className="copiedText color-white"
                        style={{ width: "inherit" }}
                      >
                        {successfulPhotoReply}
                      </div>
                    </Row>
                  )}
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </Fragment>
      ) : (
        ""
      )}
      {/* <Footer
        postSentFeedback={info ? info.message : null}
        postSent={info ? info.success : null}
        postType={"compose"}
      /> */}
    </div>
  );
}

TopBarProgress.config({
  barColors: {
    0: "rgb(29, 155, 240)",
    "1.0": "rgb(29, 155, 240)",
  },
  shadowBlur: 0,
});

export default Previews;
