import React, { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { Container, Row, Col, Button, Stack } from "react-bootstrap";
import SideNav from "./navigation/SideNavHome";
import RightNav from "./navigation/SidNavHomeRight";
import Header from "./navigation/HeaderHome";
import BlockedUser from "./features/Blocked";
import { useLocation, useNavigate } from "react-router-dom";

import { IoIosArrowRoundBack } from "react-icons/io";
import Footer from "./navigation/Footer";
function Blocked(props) {
  const location = useLocation();
  const info = location.state;
  useEffect(() => {
    document.title =
      "Blocked list | " + process.env.REACT_APP_YOUR_VARIABLE_NAME_HERE;
  }, []);
  let navigate = useNavigate();
  const user_id = localStorage.getItem("user_id");
  return (
    <div className="body-login">
      <Header />
      <Container>
        <Row className="forced-margin justify-content-center">
          {/* <SideNav /> */}
          <RightNav />

          <Col lg={6} className={"p-0"}>
            <Stack>
              <div className={"login-elements-container border-hidde mb-5 p-0"}>
                <Row className={"align-items-center pb-0"}>
                  <Col lg={12} className={"pb-0"}>
                    <div className="p-3 mb-0 border-botto">
                      <Row className={"align-items-center"}>
                        <Col lg={1} sm={1} xs={2}>
                          <label
                            className="back-arrow-post mb-0"
                            onClick={() => navigate(-1)}
                          >
                            <IoIosArrowRoundBack size={30} />
                          </label>
                        </Col>
                        <Col lg={11} sm={11} xs={10} className={""}>
                          <span className="text-bold fontSize-20">
                            Blocked users
                          </span>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>

                <BlockedUser userID={user_id} />
              </div>
            </Stack>
          </Col>
          {/* <RightNav /> */}
        </Row>
      </Container>
      <Footer
        postSentFeedback={info ? info.message : null}
        postSent={info ? info.success : null}
        postType={"compose"}
      />
    </div>
  );
}

export default Blocked;
