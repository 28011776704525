import React, { Component } from "react";
import {
  Col,
  Container,
  Row,
  Stack,
  Modal,
  Button,
  Dropdown,
} from "react-bootstrap";
import { IoAddSharp } from "react-icons/io5";

export default function Polls(props) {
  return (
    <Row>
      <Col lg={12}>
        <Stack gap={1}>
          <div>
            <label htmlFor="option1 mb-2">
              <span className="text-bold">
                Option 1<sup className="color-red">*</sup>
              </span>
            </label>
            <input
              type="text"
              id="option1"
              placeholder="E.g., Yes"
              className={"bg-color-white rounded-pill mt-2"}
              required={props.openPoll == true ? true : false}
              value={props.optionOne}
              maxLength={30}
              onChange={(e) => props.setOptionOne(e.target.value)}
            />
            <label className={"w-100 text-right fontSize14"}>
              {props.optionOne ? props.optionOne.length : 0}/30
            </label>
          </div>

          <div>
            <label htmlFor="option2 mb-2">
              <span className="text-bold">
                Option 2<sup className="color-red">*</sup>
              </span>
            </label>
            <input
              type="text"
              id="option2"
              placeholder="E.g., No"
              className={"bg-color-white rounded-pill mt-2"}
              required={props.openPoll == true ? true : false}
              value={props.optionTwo}
              maxLength={30}
              onChange={(e) => props.setOptionTwo(e.target.value)}
            />
            <label className={"w-100 text-right fontSize14"}>
              {props.optionTwo ? props.optionTwo.length : 0}/30
            </label>
          </div>

          <div className={props.optionCount >= 3 ? "" : "d-none"}>
            <label htmlFor="option3 mb-0" className={"w-100"}>
              <span className="text-bold">
                Option 3<sup className="color-red">*</sup>
              </span>
              <span
                className={
                  props.optionCount == 3
                    ? "float-right unfollow-btn cursorPointer text-normal fontSize14"
                    : "d-none"
                }
                onClick={props.removePollOption}
              >
                Remove
              </span>
            </label>
            <input
              type="text"
              id="option3"
              placeholder="E.g., No"
              className={
                props.optionCount == 3
                  ? "bg-color-white rounded-pill"
                  : "mt-2 bg-color-white rounded-pill"
              }
              required={props.optionCount >= 3 ? true : false}
              value={props.optionThree}
              maxLength={30}
              onChange={(e) => props.setOptionThree(e.target.value)}
            />
            <label className={"w-100 text-right fontSize14"}>
              {props.optionThree ? props.optionThree.length : 0}/30
            </label>
          </div>

          <div className={props.optionCount == 4 ? "" : "d-none"}>
            <label htmlFor="option4 mb-0" className={"w-100"}>
              <span className="text-bold">
                Option 4<sup className="color-red">*</sup>
              </span>

              <span
                className={
                  props.optionCount == 4
                    ? "float-right unfollow-btn mb-0 cursorPointer text-normal fontSize14"
                    : "d-none"
                }
                onClick={props.removePollOption}
              >
                Remove
              </span>
            </label>
            <input
              type="text"
              id="option4"
              placeholder="E.g., No"
              className={"bg-color-white rounded-pill"}
              required={props.optionCount == 4 ? true : false}
              value={props.optionFour}
              maxLength={30}
              onChange={(e) => props.setOptionFour(e.target.value)}
            />
            <label className={"w-100 text-right fontSize14"}>
              {props.optionFour ? props.optionFour.length : 0}/30
            </label>
          </div>
        </Stack>
        <div className={"timeline_hr_line mt-4"} />
      </Col>
      <Col lg={12} className={"mt-3"}>
        <h5>
          <span className="text-bold">Poll duration</span>
        </h5>
      </Col>
      <Col lg={4} xs={4} sm={4} className={"mt-1"}>
        <h5>Days</h5>
        <select
          required={props.openPoll == true ? true : false}
          className={"form-control outlineNone rounded-pill no-shadow"}
          onChange={props.settingDays}
          value={props.days}
        >
          <option value={0}>0</option>
          <option value={1}>1</option>
          <option value={2}>2</option>
          <option value={3}>3</option>
          <option value={4}>4</option>
          <option value={5}>5</option>
          <option value={6}>6</option>
          <option value={7}>7</option>
        </select>
      </Col>
      <Col lg={4} xs={4} sm={4} className={"mt-1"}>
        <h5>Hours</h5>
        <select
          required={props.openPoll == true ? true : false}
          className={"form-control outlineNone rounded-pill no-shadow"}
          onChange={props.settingHours}
          value={props.hours}
        >
          <option value={0}>0</option>
          <option value={1}>1</option>
          <option value={2}>2</option>
          <option value={3}>3</option>
          <option value={4}>4</option>
          <option value={5}>5</option>
          <option value={6}>6</option>
          <option value={7}>7</option>
          <option value={8}>8</option>
          <option value={9}>9</option>
          <option value={10}>10</option>
          <option value={11}>11</option>
          <option value={12}>12</option>
          <option value={13}>13</option>
          <option value={14}>14</option>
          <option value={15}>15</option>
          <option value={16}>16</option>
          <option value={17}>17</option>
          <option value={18}>18</option>
          <option value={19}>19</option>
          <option value={20}>20</option>
          <option value={21}>21</option>
          <option value={22}>22</option>
          <option value={23}>23</option>
        </select>
      </Col>
      <Col lg={4} xs={4} sm={4} className={"mt-1"}>
        <h5>Minutes</h5>
        <select
          required={props.openPoll == true ? true : false}
          className={"form-control outlineNone rounded-pill no-shadow"}
          onChange={props.settingMinutes}
          value={props.minutes}
        >
          <option value={0} className={props.opMinHide}>
            0
          </option>
          <option value={1} className={props.opMinHide}>
            1
          </option>
          <option value={2} className={props.opMinHide}>
            2
          </option>
          <option value={3} className={props.opMinHide}>
            3
          </option>
          <option value={4} className={props.opMinHide}>
            4
          </option>
          <option value={5} className={props.opMinHide}>
            5
          </option>
          <option value={6} className={props.opMinHide}>
            6
          </option>
          <option value={7} className={props.opMinHide}>
            7
          </option>
          <option value={8} className={props.opMinHide}>
            8
          </option>
          <option value={9} className={props.opMinHide}>
            9
          </option>
          <option value={10}>10</option>
          <option value={11}>11</option>
          <option value={12}>12</option>
          <option value={13}>13</option>
          <option value={14}>14</option>
          <option value={15}>15</option>
          <option value={16}>16</option>
          <option value={17}>17</option>
          <option value={18}>18</option>
          <option value={19}>19</option>
          <option value={20}>20</option>
          <option value={21}>21</option>
          <option value={22}>22</option>
          <option value={23}>23</option>
          <option value={24}>24</option>
          <option value={25}>25</option>
          <option value={26}>26</option>
          <option value={27}>27</option>
          <option value={28}>28</option>
          <option value={29}>29</option>
          <option value={30}>30</option>
          <option value={31}>31</option>
          <option value={32}>32</option>
          <option value={33}>33</option>
          <option value={34}>34</option>
          <option value={35}>35</option>
          <option value={36}>36</option>
          <option value={37}>37</option>
          <option value={38}>38</option>
          <option value={39}>39</option>
          <option value={40}>40</option>
          <option value={41}>41</option>
          <option value={42}>42</option>
          <option value={43}>43</option>
          <option value={44}>44</option>
          <option value={45}>45</option>
          <option value={46}>46</option>
          <option value={47}>47</option>
          <option value={48}>48</option>
          <option value={49}>49</option>
          <option value={50}>50</option>
          <option value={51}>51</option>
          <option value={52}>52</option>
          <option value={53}>53</option>
          <option value={54}>54</option>
          <option value={55}>55</option>
          <option value={56}>56</option>
          <option value={57}>57</option>
          <option value={58}>58</option>
          <option value={59}>59</option>
        </select>
      </Col>
      <Col lg={6} xs={6} sm={6} className={"mt-3"}>
        <div>
          <Button
            type="button"
            className={"login-button  rounded-pill no-shadow"}
            onClick={props.addOption}
            disabled={props.optionCount == 4 ? true : false}
          >
            <IoAddSharp size={20} /> Add option
          </Button>
        </div>
      </Col>
      <Col lg={6} xs={6} sm={6} className={"mt-3"}>
        <Button
          type="button"
          className={"join-now-button text-center rounded-pill color-black"}
          onClick={props.closePoll}
        >
          Close Poll
        </Button>
      </Col>
    </Row>
  );
}
