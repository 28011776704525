import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { IoWarningOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
export default function PageNotFound(props) {
  let tag = "";
  if (props.tag) {
    tag = props.tag;
  }
  return (
    <>
      {tag == "account_not_available" ? (
        <Container className="mt-4">
          <Row className="align-items-center justify-content-center __height__60">
            <Col lg={12} className="text-center">
              <IoWarningOutline size={100} />
              <br />
              <br />
              <h4>
                <span className="">[@{props.username}]</span>
                <br />
                <br />
                <strong>This account doesn’t exist</strong>
                <br />
                <span className="fontSize-18 color-grey">
                  Please check your URL or try searching for another.
                </span>
                <br />
                <br />
                <Link
                  to={"/home"}
                  className="text-normal textUnderlineNone underlineHover color-blue fontSize15"
                >
                  Go to your feed
                </Link>
              </h4>
            </Col>
          </Row>
        </Container>
      ) : (
        <>
          <h3>Oops! This page doesn't exist.</h3>
          <label className="footnote mb-3">
            Try searching for something else.
          </label>
        </>
      )}
    </>
  );
}
