import React, { useState, useEffect, useRef } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  Col,
  Container,
  Row,
  Stack,
  Modal,
  Button,
  Alert,
} from "react-bootstrap";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Link, NavLink, useParams } from "react-router-dom";
import { BiCheckDouble, BiCheck } from "react-icons/bi";
import { IoIosLink, IoIosArrowRoundBack } from "react-icons/io";
import { BsPen, BsBell, BsChatRightDots } from "react-icons/bs";
import PageNotFound from "../features/PageNotFound";

import {
  IoPersonCircleOutline,
  IoLocationOutline,
  IoCalendarOutline,
  IoAdd,
} from "react-icons/io5";
import axios from "axios";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import BeatLoader from "react-spinners/BeatLoader";
import TopBarProgress from "react-topbar-progress-indicator";
import { isError } from "lodash";
import { ClipLoader } from "react-spinners";
import HeaderToken from "../HeaderToken";

const headers = {
  Authorization: `${HeaderToken.Authorization}`,
  Accept: `${HeaderToken.Accept}`,
};

export default function TopNav() {
  const queryClient = useQueryClient();
  const user_uuid = localStorage.getItem("user_id");
  const currentUsername = localStorage.getItem("username");
  const baseURL = process.env.REACT_APP_URL;
  const url = baseURL;
  const { username } = useParams();

  const profileDetailQuery = useQuery({
    queryKey: ["profileDetails", username],
    queryFn: async () => {
      const response = await axios.get(
        `${baseURL}/profile/details/${username}`,
        { headers }
      );
      const data = response.data;
      return data;
    },
  });

  // let history = useHistory();
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [dateJoined, setDateJoined] = useState(null);
  const [fullname, setFullname] = useState("");
  const [fname, setFname] = useState(null);
  const [lname, setLname] = useState(null);
  const [website, setWebsite] = useState(null);
  const [bio, setBio] = useState(null);
  const [location, setLocation] = useState(null);
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [show, setShow] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [hideEmoji, setHideEmoji] = useState(false);
  const [post, setPost] = useState("");
  const [postButton, setPostbutton] = useState("login-button");
  const [disableBtn, setDisableBtn] = useState("");
  const [textearTextSize, setTextearTextSize] = useState(
    "textarea-share-post-text-size-22"
  );
  const [loadPostbtn, setLoadPostbtn] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [progressBar, setProgressBar] = useState(false);
  const [updateCount, setUpdateCount] = useState(0);
  const [following, setFollowing] = useState(0);
  const [followers, setFollowers] = useState(0);
  const [previewProfilePic, setPreviewProfilePic] = useState(null);
  const [previewCover, setPreviewCover] = useState(null);
  const [selectedPic, setSelectedPic] = useState(false);
  const [selectedCover, setSelectedCover] = useState(false);
  const [successfulCoverUpload, setSuccessfulCoverUpload] = useState(false);
  const [successfulPicUpload, setSuccessfulPicUpload] = useState(false);
  const [profile_pic_value, setProfile_pic_value] = useState(null);
  const [profile_cover_value, setProfile_cover_value] = useState(null);
  const [imgURLpic, setIMGURLPIC] = useState();
  const [imgURLcover, setIMGURLCOVER] = useState();
  const [profile_user_id, setProfileUserId] = useState(null);
  const [followText, setFollowText] = useState("btn-blocked-users");

  let { uploadPreviewPic, uploadPreviewCover } = "";

  const profilePic = (e) => {
    // console.log(e.target.files);
    setPreviewProfilePic(URL.createObjectURL(e.target.files[0]));
    setProfile_pic_value(e.target.files[0]);
    setSelectedPic(true);
    setIMGURLPIC("");
  };

  const profileCover = (e) => {
    setPreviewCover(URL.createObjectURL(e.target.files[0]));
    setProfile_cover_value(e.target.files[0]);
    setSelectedCover(true);
    setIMGURLCOVER("");
  };

  // console.log(
  //   baseURL.replace("api", "") +
  //     "public/profile/picture/@" +
  //     profileDetailQuery.data.user.username +
  //     "/" +
  //     profileDetailQuery.data.user.profile_pic
  // );

  // const profileDetails = async () => {
  //   const res = await fetch(baseURL + "/profile/details/" + username, {
  //     headers,
  //   });
  //   return res.json();
  // };

  // const checkFollowing = async () => {
  //   const res = await fetch(
  //     url + "/following/check/" + username + "/" + user_uuid,
  //     {
  //       headers,
  //     }
  //   );
  //   return res.json();
  // };

  // const AmIBlocked = async () => {
  //   const res = await fetch(`${baseURL}/${username}/${user_uuid}/amiblocked`, {
  //     headers,
  //   });
  //   return res.json();
  // };

  // const profdetails = useQuery({
  //   queryKey: ["profileDetails", username],
  //   queryFn: async () => {
  //     const response = await axios.get(
  //       `${baseURL}/profile/details/${username}`,
  //       {
  //         headers,
  //       }
  //     );
  //     const data = response.data;
  //     return data;
  //   },
  // });
  const checkFollow = useQuery({
    queryKey: ["checkFollowing", username],
    queryFn: async () => {
      const response = await axios.get(
        `${url}/following/check/${username}/${user_uuid}`,
        { headers }
      );
      const data = response.data;
      return data;
    },
  });
  const ImBlocked = useQuery({
    queryKey: ["ImBlock", username],
    queryFn: async () => {
      const response = await axios.get(
        `${baseURL}/${username}/${user_uuid}/amiblocked`,
        {
          headers,
        }
      );
      const data = response.data;
      return data;
    },
  });

  const {
    data: ImBlockedData,
    status: ImBlockedStatus,
    refetch: ImBlockedRefetch,
  } = ImBlocked;
  const { data, refetch, status } = checkFollow;
  // const {
  //   data: details,
  //   refetch: dRefetch,
  //   status: statusDetails,
  //   isError,
  // } = profdetails;

  useEffect(() => {
    let url = "";
    url = baseURL + "/profile/details/" + username;
    axios.get(url, { headers }).then((response) => {
      if (response.data.user.length == 0) {
        return;
      }
      setFullname(
        response.data.user.first_name + " " + response.data.user.last_name
      );
      setIMGURLCOVER(
        process.env.REACT_APP_URL.replace("api", "") +
          "public/profile/cover/@" +
          username +
          "/"
      );

      setIMGURLPIC(
        process.env.REACT_APP_URL.replace("api", "") +
          "public/profile/picture/@" +
          username +
          "/"
      );

      setEmail(response.data.user.email);
      setBio(response.data.bio.bio);
      setWebsite(response.data.profile.website);
      setLocation(response.data.profile.location);
      setPhone(response.data.user.cell_phone);
      setPreviewProfilePic(
        baseURL.replace("api", "") +
          "public/profile/picture/@" +
          username +
          "/" +
          response.data.user.profile_pic
      );
      setPreviewCover(
        baseURL.replace("api", "") +
          "public/profile/cover/@" +
          username +
          "/" +
          response.data.user.profile_cover
      );
      setFname(response.data.user.first_name);
      setLname(response.data.user.last_name);
      // setDateJoined(response.data.user.created_at);
      // setProfileUserId(response.data.user.id);
      // setFollowers(response.data.followers);
      // setFollowing(response.data.following);
    });

    // setPreviewCover(
    //   baseURL.replace("api", "") +
    //     "public/profile/cover/@" +
    //     username +
    //     "/" +
    //     previewCover
    // );

    // setPreviewProfilePic(
    //   baseURL.replace("api", "") +
    //     "public/profile/picture/@" +
    //     username +
    //     "/" +
    //     previewProfilePic
    // );

    //   console.log(
    //     baseURL.replace("api", "") +
    //       "public/profile/picture/@" +
    //       profileDetailQuery.data.user.username +
    //       "/" +
    //       profileDetailQuery.data.user.profile_pic
    //   );
    //   console.log("testing...");
    // }

    queryClient.invalidateQueries(
      { queryKey: ["profileDetails"] },
      { exact: true }
    );
  }, [window.location.hrefg]);

  function editProfile() {
    setShow(true);
    setSuccessfulPicUpload(false);
    setSuccessfulCoverUpload(false);
    setProfile_pic_value(null);
    setProfile_cover_value(null);
  }

  function editPassword() {
    setShowPassword(true);
  }

  function handleClose() {
    setShow(false);
    setHideEmoji(false);
    setShowPassword(false);
  }
  function updateProfile(event) {
    event.preventDefault();

    const formData = new FormData();
    formData.append("first_name", fname);
    formData.append("last_name", lname);
    formData.append("bio", bio);
    formData.append("website", website);
    formData.append("location", location);
    formData.append("phone", phone);
    formData.append(
      "email",
      profileDetailQuery.isLoading ? "" : profileDetailQuery.data.user.email
    );
    formData.append("user_id", user_uuid);
    formData.append("profile_pic", profile_pic_value);
    formData.append("profile_cover", profile_cover_value);
    // formData.append("query", "UPDATEPROFILE");

    setProgressBar(true);
    setSpinner(true);
    setLoadPostbtn(false);

    const url = baseURL;
    axios({
      method: "POST",
      url: baseURL + "/update/user/profile",
      data: formData,
      headers: {
        Authorization: HeaderToken.Authorization,
        Accept: HeaderToken.Accept,
        "Content-Type": "multipart/form-data",
      },
    }).then((response) => {
      queryClient.invalidateQueries(
        { queryKey: ["profileDetails"] },
        { exact: true }
      );

      setSpinner(false);
      setLoadPostbtn(true);
      setProgressBar(false);
      setShow(false);
      setSuccessfulCoverUpload(true);
      setSuccessfulPicUpload(true);
      setSelectedPic(false);
      setSelectedCover(false);

      // alert(response.data.data.user.first_name);
      localStorage.setItem("first_name", fname);
      localStorage.setItem("last_name", lname);
      localStorage.setItem("profile_pic", response.data.data.user.profile_pic);
      const fullname =
        response.data.data.user.first_name +
        " " +
        response.data.data.user.last_name;
      localStorage.setItem("fullname", fullname);
    });
    // refetch();
  }

  function updatePassword(e) {
    e.preventDefault();
    const data = new FormData();
    if (newPassword.trim() !== confirmPassword.trim()) {
      setError("The new password field confirmation does not match.");
      setSuccess(null);
      return;
    }
    setLoader(true);
    data.append("password", currentPassword);
    data.append("new_password", newPassword);
    data.append("password_confirmation", confirmPassword);
    data.append("user_id", user_uuid);
    data.append(
      "email",
      profileDetailQuery.isLoading ? "" : profileDetailQuery.data.user.email
    );

    axios({
      method: "POST",
      url: `${baseURL}/update/password`,
      data: data,
      headers: {
        Authorization: HeaderToken.Authorization,
        Accept: HeaderToken.Accept,
      },
    })
      .then((response) => {
        queryClient.invalidateQueries(
          { queryKey: ["profileDetails"] },
          { exact: true }
        );
        setSuccess(response.data.message);
        setError(null);
        setLoader(false);
      })
      .catch((error) => {
        setError(error.response.data.message);
        setSuccess(null);
        setLoader(false);
      });
  }

  function addBio(e) {
    setBio(e.target.value);

    if (e.target.value.trim().length > 190) {
      setTextearTextSize("textarea-share-post-text-size-15");
    } else {
      setTextearTextSize("textarea-share-post-text-size-22");
    }
  }

  function handleFname(e) {
    setFname(e.target.value);
    setFullname(e.target.value + " " + lname);

    if (
      e.target.value.trim().length > 0 &&
      lname.trim().length > 0 &&
      email.trim().length > 0
    ) {
      setPostbutton("login-button");
      setDisableBtn("");
    } else {
      setPostbutton("disabled-button");
      setDisableBtn("disabled");
    }
  }

  function handleLname(e) {
    setLname(e.target.value);
    setFullname(fname + " " + e.target.value);

    if (
      e.target.value.trim().length > 0 &&
      lname.trim().length > 0 &&
      email.trim().length > 0
    ) {
      setPostbutton("login-button");
      setDisableBtn("");
    } else {
      setPostbutton("disabled-button");
      setDisableBtn("disabled");
    }
  }

  function handleEmail(e) {
    setEmail(e.target.value);

    if (
      e.target.value.trim().length > 0 &&
      lname.trim().length > 0 &&
      fname.trim().length > 0
    ) {
      setPostbutton("login-button");
      setDisableBtn("");
    } else {
      setPostbutton("disabled-button");
      setDisableBtn("disabled");
    }
  }

  function follow(user_id) {
    var following_user_id = user_uuid;
    var followed_user_id = user_id;

    const formData = new FormData();
    formData.append("following_user_id", following_user_id);
    formData.append("followed_user_id", followed_user_id);
    const url = baseURL;
    axios({
      method: "POST",
      url: url + "/following/create",
      data: formData,
      headers: {
        Authorization: HeaderToken.Authorization,
        Accept: HeaderToken.Accept,
      },
    }).then((response) => {
      queryClient.invalidateQueries(
        { queryKey: ["checkFollowing"] },
        { exact: true }
      );

      // refetch();
    });
  }
  let site = "";

  function block(user_id) {
    var blocking_user_id = user_uuid;
    var blocked_user_id = user_id;

    axios
      .get(url + "/block/user/" + blocking_user_id + "/" + blocked_user_id, {
        headers,
      })
      .then((res) => {
        queryClient.invalidateQueries(
          { queryKey: ["checkFollowing"] },
          { exact: true }
        );
        // refetch();
      });
  }
  return (
    <Container className="p-0">
      {progressBar && <TopBarProgress />}
      <Modal
        show={showPassword}
        onHide={handleClose}
        backdropClassName="backdrop-login"
        centered
        className={"modal_bg_color_white"}
      >
        <Modal.Body>
          <Container className="p-1">
            <form onSubmit={updatePassword}>
              <Row className="align-items-center">
                <Col
                  lg={12}
                  className={
                    !loader || success ? "d-none" : "text-center fontSize14"
                  }
                >
                  <ClipLoader size={25} color={"#333333"} />
                </Col>
                <Col lg={12} className={loader ? "d-none" : ""}>
                  <Alert variant={"danger"} className={!error ? "d-none" : ""}>
                    {error}
                  </Alert>
                  <Alert
                    variant={"secondary"}
                    className={!success ? "d-none" : ""}
                  >
                    {success}
                  </Alert>
                </Col>
                <Col lg={12} className={loader || success ? "d-none" : "mb-3"}>
                  <input
                    type="password"
                    value={currentPassword}
                    placeholder={"Current password"}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                  />
                </Col>
                <Col lg={12} className={loader || success ? "d-none" : "mb-3"}>
                  <input
                    type="password"
                    value={newPassword}
                    placeholder={"New password"}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </Col>

                <Col lg={12} className={loader || success ? "d-none" : "mb-3"}>
                  <input
                    type="password"
                    value={confirmPassword}
                    placeholder={"Confirm password"}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </Col>

                <Col
                  lg={12}
                  xs={12}
                  className={loader || success ? "d-none" : "mb-0"}
                >
                  {loadPostbtn && (
                    <Button
                      className={
                        postButton + " mb-3 text-center rounded-pill no-shadow"
                      }
                      type="submit"
                      disabled={disableBtn}
                    >
                      Save
                    </Button>
                  )}

                  {/* {spinner && (
                    <Button
                      type={"button"}
                      className="login-button text-center mb-3 rounded-pill"
                      disabled={true}
                    >
                      <BeatLoader color={"#fff"} />
                    </Button>
                  )} */}
                </Col>
                <Col lg={12} className={loader ? "d-none" : ""}>
                  <label
                    className="join-now-button text-center rounded-pill"
                    onClick={handleClose}
                  >
                    Close
                  </label>
                </Col>
              </Row>
            </form>
          </Container>
        </Modal.Body>
      </Modal>
      <Modal
        show={show}
        onHide={handleClose}
        backdropClassName="backdrop-login"
        centered
        className={"modal_bg_color_white"}
      >
        <Modal.Body>
          <Container className="p-1">
            <form onSubmit={updateProfile}>
              <Row className="align-items-center edit__profile__modal">
                <Col lg={12} className="pb-2 mb-5">
                  <h6 className="color-black line-height-20 text-bold">
                    Upload media
                    <br />
                    <label
                      htmlFor="cover"
                      className="cover__container text-center row justify-content-center align-items-center cursorPointer"
                      style={{
                        backgroundImage: `url(${previewCover})`,
                      }}
                    >
                      {/* <label>
                        <IoAdd size={25} />
                      </label> */}
                      <label
                        htmlFor="profile_pic"
                        className="profile__pic__container text-center row justify-content-center align-items-center cursorPointer"
                        style={{
                          backgroundImage: `url(${previewProfilePic})`,
                        }}
                      >
                        {/* <label>
                          <IoAdd size={25} />
                        </label> */}
                      </label>
                    </label>
                    {/* <label
                      className="color-blue cursorPointer fontSize14 mt-3"
                      // htmlFor="profile_pic"
                    >
                      <IoPersonCircleOutline size={20} /> Profile Picture{" "}
                      <BiCheck
                        size={22}
                        className={selectedPic == true ? "" : "d-none"}
                      />{" "}
                      <BiCheckDouble
                        size={22}
                        className={successfulPicUpload == true ? "" : "d-none"}
                      />
                    </label> */}
                    <input
                      type="file"
                      name="profile_pic"
                      accept="image/png, image/jpeg, image/WebP, image/webp, image/gif"
                      id="profile_pic"
                      className="d-none"
                      onChange={profilePic}
                    />
                    {/* <br />
                    <label
                      className="color-blue cursorPointer fontSize14"
                      // htmlFor="cover"
                    >
                      {" "}
                      <IoPersonCircleOutline size={20} /> Profile Cover
                      <BiCheck
                        size={22}
                        className={selectedCover == true ? "" : "d-none"}
                      />{" "}
                      <BiCheckDouble
                        size={22}
                        className={
                          successfulCoverUpload == true ? "" : "d-none"
                        }
                      />
                    </label> */}
                    <input
                      type="file"
                      name="cover"
                      accept="image/png, image/jpeg, image/jpg, image/WebP, image/webp, image/gif"
                      id="cover"
                      className="d-none"
                      onChange={profileCover}
                    />
                  </h6>
                </Col>
                <Col>
                  <div className="timeline_hr_line" />
                </Col>
                <Col lg={12} className="mb-0 pb-2 pt-2 mt-2">
                  <h6 className="color-black line-height-20 text-bold">
                    This can't be changed
                    <br />
                    <label className="footnote">
                      <span className="text-bold">Username: </span>
                      {localStorage.getItem("username").replace("@", "")}
                    </label>
                  </h6>
                </Col>
                <Col lg={12} xs={12} sm={12} className={"mb-3"}>
                  <div className="timeline_hr_line" />
                </Col>
                <Col lg={6} className="mb-3">
                  <h6 className="color-black line-height-20 text-bold">
                    First name
                    <label className="float-right footnote color-red">*</label>
                  </h6>
                  <input
                    type="text"
                    value={fname}
                    placeholder={"First name"}
                    onChange={handleFname}
                  />
                </Col>
                <Col lg={6} className="mb-3">
                  <h6 className="color-black line-height-20 text-bold">
                    Last name
                    <label className="float-right footnote color-red">*</label>
                  </h6>
                  <input
                    type="text"
                    value={lname}
                    placeholder={"Last name"}
                    onChange={handleLname}
                  />
                </Col>
                <Col lg={12} className={"mt-3"}>
                  <h6 className="color-black line-height-20 text-bold">
                    Bio
                    <label className={"float-right color-black"}>
                      {bio ? bio.length : 0}/250
                    </label>
                  </h6>
                  <textarea
                    className={textearTextSize + " textarea-share-post"}
                    placeholder={"Write a bio"}
                    value={bio}
                    onChange={addBio}
                    maxLength={250}
                  />
                </Col>

                <Col lg={12} className="mb-3">
                  <h6 className="color-black line-height-20 text-bold">
                    Email
                    <label className="float-right footnote color-red">*</label>
                  </h6>
                  <input
                    type="text"
                    value={email}
                    placeholder="Email address"
                    onChange={handleEmail}
                  />
                </Col>

                <Col lg={12} className="mb-3">
                  <h6 className="color-black line-height-20 text-bold">
                    Website
                  </h6>
                  <input
                    type="text"
                    value={
                      website == "undefined" ||
                      website == "null" ||
                      website == ""
                        ? ""
                        : website
                    }
                    placeholder="https://www.example.com"
                    onChange={(e) => setWebsite(e.target.value)}
                  />
                </Col>

                <Col lg={12} className="mb-3">
                  <h6 className="color-black line-height-20 text-bold">
                    Location
                    {location == "undefined" ||
                    location == "" ||
                    location == null ? (
                      ""
                    ) : (
                      <label className="footnote w-100">
                        <span className="text-bold">Current: </span> {location}
                      </label>
                    )}
                  </h6>
                  <GooglePlacesAutocomplete
                    apiKey="AIzaSyDNn7uMQVzoH91NYn7vzX437q5fp8g92FU"
                    styles={{
                      textInputContainer: {
                        backgroundColor: "grey",
                      },
                      textInput: {
                        height: 38,
                        color: "#5d5d5d",
                        fontSize: 16,
                      },
                    }}
                    textInputProps={{
                      onChange: (text) => {
                        setLocation(text);
                      },
                    }}
                    selectProps={{
                      onChange: (data, details = null) =>
                        setLocation(data["value"]["description"]),
                      fetchDetails: true,
                      placeholder: "Enter your address",
                      // defaultInputValue: this.state.venue,
                    }}
                  />
                </Col>

                <Col lg={12} className="mb-3">
                  <h6 className="color-black line-height-20 text-bold">
                    Phone number
                  </h6>

                  <PhoneInput
                    country={"za"}
                    enableSearch={true}
                    value={
                      phone == "undefined" || phone == "null" || phone == ""
                        ? ""
                        : phone
                    }
                    onChange={(phone) => setPhone(phone)}
                    placeholder="Strengthen your account"
                    searchPlaceholder="Search country"
                    inputClass={"countriesInputContainer"}
                    searchClass={"searchCountriesInput"}
                    dropdownClass={"searchDropDownInput"}
                    disableSearchIcon={true}
                    autocompleteSearch={true}
                  />
                  {/* <input
                    type="text"
                    value={
                      phone == "undefined" || phone == "null" || phone == ""
                        ? ""
                        : phone
                    }
                    placeholder="Strengthen your account"
                    onChange={(e) => setPhone(e.target.value)}
                  /> */}
                </Col>

                <Col lg={12} xs={12} className="mb-3">
                  {loadPostbtn && (
                    <Button
                      className={postButton + " mb-3 text-center rounded-pill"}
                      type="submit"
                      disabled={disableBtn}
                    >
                      Save
                    </Button>
                  )}

                  {spinner && (
                    <Button
                      type={"button"}
                      className="login-button text-center mb-3 rounded-pill"
                      disabled={true}
                    >
                      <BeatLoader color={"#fff"} />
                    </Button>
                  )}

                  <label
                    className="join-now-button text-center rounded-pill"
                    onClick={handleClose}
                  >
                    Cancel
                  </label>
                </Col>
              </Row>
            </form>
          </Container>
        </Modal.Body>
      </Modal>
      {profileDetailQuery.isError ? (
        <></>
      ) : (
        <>
          {profileDetailQuery.isLoading ? (
            <div className="text-center mt-5 marginBottom-200">
              <ClipLoader size={25} color={"#333333"} />
            </div>
          ) : profileDetailQuery.data.user.length == 0 ? (
            <PageNotFound tag={"account_not_available"} username={username} />
          ) : (
            <div
              className={
                profileDetailQuery.isError
                  ? "d-none"
                  : "login-elements-container border-hidde mb-3 pt-0 padding-12"
              }
            >
              <Row
                className={
                  profileDetailQuery.isSuccess ? "align-items-center" : "d-none"
                }
              >
                <Col lg={12} className={"pb-0"}>
                  <div className="p-0 pb-0 mb-0 border-botto">
                    {profileDetailQuery.isLoading ? (
                      <>
                        <ClipLoader size={15} color={"#333333"} />
                      </>
                    ) : (
                      <Row className={"align-items-center"}>
                        <Col lg={6} sm={6} xs={12} className={"p-2"}>
                          <h6 className={"color-black"}>
                            {/* {profileDetailQuery.data.user.first_name} */}

                            <span className="text-bold">
                              {profileDetailQuery.isLoading ? (
                                <ClipLoader size={17} color={"#333333"} />
                              ) : (
                                <>
                                  {profileDetailQuery.data.user.first_name +
                                    " " +
                                    profileDetailQuery.data.user.last_name}

                                  <br />
                                  <label className="footnote">
                                    @{profileDetailQuery.data.user.username}
                                  </label>
                                </>
                              )}
                            </span>
                          </h6>
                        </Col>
                        <Col lg={6} sm={6} xs={12} className={"pt-0 p-2"}>
                          {ImBlockedStatus == "loading" ? (
                            ""
                          ) : (
                            <>
                              <Row
                                className={
                                  ImBlockedData.am_i_blocked
                                    ? "justify-content-end mt-0 text-right"
                                    : "d-none"
                                }
                              >
                                <Col lg={12}>
                                  <label className={"color-red fontSize14"}>
                                    Oops! You are blocked.
                                  </label>
                                </Col>
                              </Row>

                              <Row
                                className={
                                  ImBlockedData.am_i_blocked
                                    ? "d-none"
                                    : "justify-content-end mt-0"
                                }
                              >
                                <Col
                                  lg={12}
                                  className={
                                    currentUsername != "@" + username
                                      ? "text-right"
                                      : "d-none"
                                  }
                                >
                                  {status == "success" ? (
                                    <>
                                      {data.is_blocked == 1 ? (
                                        <label
                                          className={
                                            "btn-blocked-users native-button-red fontBold text-center float-right btn-sm float-mobile-left"
                                          }
                                          onClick={() =>
                                            block(
                                              profileDetailQuery.isLoading
                                                ? 0
                                                : profileDetailQuery.data.user
                                                    .id
                                            )
                                          }
                                        ></label>
                                      ) : (
                                        <>
                                          {data.is_following == 1 ? (
                                            <>
                                              {" "}
                                              <Link
                                                to={`/messages/${username}`}
                                              >
                                                <label
                                                  className={
                                                    "native-button-dark-grey fontBold text-center btn-sm float-mobile-left marginRightMobile-5"
                                                  }
                                                >
                                                  <BsChatRightDots size={13} />
                                                </label>
                                              </Link>{" "}
                                              <label
                                                className={
                                                  "native-button-dark-grey fontBold text-center btn-sm float-mobile-left marginRightMobile-5"
                                                }
                                                onClick={() =>
                                                  block(
                                                    profileDetailQuery.isLoading
                                                      ? 0
                                                      : profileDetailQuery.data
                                                          .user.id
                                                  )
                                                }
                                              >
                                                Block
                                              </label>
                                              &nbsp;
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                          <label
                                            className={
                                              data.is_following == 1
                                                ? "btn-follow-topics native-button-blue fontBold text-center float-right btn-sm float-mobile-left"
                                                : "btn-follow-user native-button-dark-grey fontBold text-center float-right btn-sm float-mobile-left"
                                            }
                                            onClick={() =>
                                              follow(
                                                profileDetailQuery.isLoading
                                                  ? 0
                                                  : profileDetailQuery.data.user
                                                      .id
                                              )
                                            }
                                          ></label>
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </Col>

                                <Col
                                  lg={12}
                                  className={
                                    currentUsername == "@" + username
                                      ? ""
                                      : "d-none"
                                  }
                                >
                                  <label
                                    className="native-button-blue fontBold text-center float-right marginLeft-5 marginLeftMobile-0 marginRightMobile-5 btn-sm float-mobile-left"
                                    onClick={editPassword}
                                  >
                                    Password
                                  </label>

                                  <label
                                    className="native-button-dark-grey fontBold text-center float-right btn-sm float-mobile-left"
                                    onClick={editProfile}
                                  >
                                    Edit
                                  </label>
                                </Col>
                              </Row>
                            </>
                          )}
                        </Col>
                      </Row>
                    )}
                  </div>
                </Col>
              </Row>
              <Row className="align-items-center marginBottomMobileProfile">
                {profileDetailQuery.isLoading ? (
                  ""
                ) : (
                  <Col lg={12} className="p-0">
                    <div
                      className="w-100 profile-cover-container"
                      style={{
                        backgroundImage:
                          "url(" +
                          process.env.REACT_APP_URL.replace("api", "") +
                          "public/profile/cover/@" +
                          profileDetailQuery.data.user.username +
                          "/" +
                          profileDetailQuery.data.user.profile_cover +
                          ")",
                      }}
                    >
                      <Row className="justify-content-start row-profile-picture">
                        <div
                          className="profile-screen-picture"
                          style={{
                            backgroundImage:
                              "url(" +
                              process.env.REACT_APP_URL.replace("api", "") +
                              "public/profile/picture/@" +
                              profileDetailQuery.data.user.username +
                              "/" +
                              profileDetailQuery.data.user.profile_pic +
                              ")",
                          }}
                        ></div>
                      </Row>
                    </div>
                  </Col>
                )}
              </Row>
              <br />

              {ImBlockedStatus == "loading" ? (
                ""
              ) : (
                <>
                  <div
                    className={
                      ImBlockedData.am_i_blocked ? "w-100 mt-0" : "d-none"
                    }
                  >
                    <h3>
                      <span className="text-bold">
                        @{username} blocked you.
                      </span>
                    </h3>
                    <label className="footnote">
                      You are blocked from viewing @{username}'s posts.
                    </label>
                  </div>
                  <div
                    className={ImBlockedData.am_i_blocked ? "d-none" : "w-100"}
                  >
                    {profileDetailQuery.isSuccess ? (
                      <>
                        {profileDetailQuery.data.bio.bio == null ||
                        profileDetailQuery.data.bio.bio == "undefined" ? (
                          ""
                        ) : (
                          <Row className="mb-0">
                            <Col lg={12}>
                              <label className="color-black">
                                {profileDetailQuery.data.bio.bio}
                              </label>
                            </Col>
                          </Row>
                        )}
                      </>
                    ) : (
                      bio
                    )}

                    <Row className="mb-2">
                      <Col lg={12} className="footnote">
                        {profileDetailQuery.isSuccess ? (
                          <>
                            {profileDetailQuery.data.profile.location == null ||
                            profileDetailQuery.data.profile.location ==
                              "null" ||
                            profileDetailQuery.data.profile.location ==
                              "undefined" ? (
                              ""
                            ) : (
                              <label className="">
                                <IoLocationOutline size={17} />{" "}
                                {profileDetailQuery.data.profile.location}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </label>
                            )}
                          </>
                        ) : (
                          location
                        )}

                        {profileDetailQuery.isSuccess ? (
                          <>
                            {profileDetailQuery.data.profile.website == null ||
                            profileDetailQuery.data.profile.website ==
                              "undefined" ||
                            profileDetailQuery.data.profile.website ==
                              "null" ? (
                              ""
                            ) : (
                              <label>
                                <Link
                                  to={{
                                    pathname: "http://" + site,
                                  }}
                                  target="_blank"
                                  className="color-blue anchor-link"
                                >
                                  <IoIosLink size={17} />{" "}
                                  {profileDetailQuery.data.profile.website}
                                </Link>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </label>
                            )}
                          </>
                        ) : (
                          <>
                            {website}
                            <br />
                          </>
                        )}
                        <label className="mt-2">
                          <IoCalendarOutline size={17} /> Joined{" "}
                          {profileDetailQuery.isSuccess
                            ? profileDetailQuery.data.user.created_at
                            : dateJoined}
                        </label>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <label className="color-green cursorPointer fontBold ">
                          <Link
                            to={"/following/" + username}
                            className={"color-grey"}
                          >
                            <label className="anchor-link fontBold">
                              {profileDetailQuery.isSuccess
                                ? profileDetailQuery.data.following
                                : ""}{" "}
                              Following
                            </label>
                          </Link>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <Link
                            to={"/followers/" + username}
                            className={"color-grey"}
                          >
                            <label className="anchor-link fontBold">
                              {profileDetailQuery.isSuccess
                                ? profileDetailQuery.data.followers
                                : ""}{" "}
                              Followers
                            </label>
                          </Link>
                          {/* &nbsp;&nbsp;&nbsp;&nbsp;
              <Link to={"/followers/" + username} className={"color-green"}>
                <label className="anchor-link">
                  {profileDetailQuery.isSuccess ? details.followers : followers}{" "}
                  Subjects
                </label>
              </Link>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Link to={"/followers/" + username} className={"color-green"}>
                <label className="anchor-link">
                  {profileDetailQuery.isSuccess ? details.followers : followers}{" "}
                  Topics
                </label>
              </Link> */}
                        </label>
                      </Col>
                    </Row>

                    <Row className="mb-1 mt-4 justify-content-center align-items-center">
                      <Col className="text-center p-0">
                        <NavLink
                          to={"/profile/" + username}
                          className={(navData) =>
                            navData.isActive
                              ? "mobile-tabs-icons-active text-center mobile-tabs-icons textUnderlineNone profile-tabs"
                              : "text-center mobile-tabs-icons textUnderlineNone profile-tabs"
                          }
                        >
                          <label className="w-75 cursorPointer">
                            <span
                              className={
                                "text-bold cursorPointer mobile-header-sizes"
                              }
                            >
                              Posts
                            </span>
                          </label>
                        </NavLink>
                      </Col>
                      <Col className="text-center p-0">
                        <NavLink
                          to={"/documents/" + username}
                          className={(navData) =>
                            navData.isActive
                              ? "mobile-tabs-icons-active text-center mobile-tabs-icons textUnderlineNone profile-tabs"
                              : "text-center mobile-tabs-icons textUnderlineNone profile-tabs"
                          }
                        >
                          <label className="w-75 cursorPointer">
                            <span
                              className={
                                "text-bold cursorPointer mobile-header-sizes"
                              }
                            >
                              Documents
                            </span>
                          </label>
                        </NavLink>
                      </Col>
                      <Col className="text-center p-0">
                        <NavLink
                          to={"/media/" + username}
                          className={(navData) =>
                            navData.isActive
                              ? "mobile-tabs-icons-active text-center mobile-tabs-icons textUnderlineNone profile-tabs"
                              : "text-center mobile-tabs-icons textUnderlineNone profile-tabs"
                          }
                        >
                          <label className="w-75 cursorPointer">
                            <span
                              className={
                                "text-bold cursorPointer mobile-header-sizes"
                              }
                            >
                              Media
                            </span>
                          </label>
                        </NavLink>
                      </Col>
                      <Col className="text-center p-0">
                        <NavLink
                          to={"/likes/" + username}
                          className={(navData) =>
                            navData.isActive
                              ? "mobile-tabs-icons-active text-center mobile-tabs-icons textUnderlineNone profile-tabs"
                              : "text-center mobile-tabs-icons textUnderlineNone profile-tabs"
                          }
                        >
                          <label className="w-75 cursorPointer">
                            <span className="text-bold cursorPointer mobile-header-sizes">
                              Likes
                            </span>
                          </label>
                        </NavLink>
                      </Col>
                    </Row>
                  </div>
                </>
              )}
            </div>
          )}
        </>
      )}
    </Container>
  );
}

TopBarProgress.config({
  barColors: {
    0: "rgb(29, 155, 240)",
    "1.0": "rgb(29, 155, 240)",
  },
  shadowBlur: 0,
});
