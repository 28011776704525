import React, { useState, useEffect } from "react";
import { Col, Container, Row, Stack } from "react-bootstrap";
import { NavLink, Link, useHistory } from "react-router-dom";
import axios from "axios";
import {
  BsBookmarks,
  BsChatDots,
  BsPeople,
  BsBack,
  BsMegaphone,
  BsChatRightDots,
} from "react-icons/bs";
import { FaRunning } from "react-icons/fa";
import {
  IoPeopleCircleOutline,
  IoSettingsOutline,
  IoInformationCircleOutline,
  IoAnalytics,
  IoEnterOutline,
  IoBanOutline,
  IoPeopleOutline,
  IoAtCircle,
  IoAtCircleOutline,
  IoAtSharp,
  IoWalkOutline,
  IoWalkSharp,
} from "react-icons/io5";

import { IoIosHeart, IoIosHeartEmpty } from "react-icons/io";
import { useQuery } from "@tanstack/react-query";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import HeaderToken from "../HeaderToken";

const headers = {
  Authorization: `${HeaderToken.Authorization}`,
  Accept: `${HeaderToken.Accept}`,
};

export default function Nav() {
  // let history = useHistory();
  // if (!localStorage.getItem("user_id")) {
  //   history.push({ pathname: "/login" });
  // }

  const fetchFollowCount = async () => {
    const res = await fetch(`${baseURL}/following/count/${user_id}`, {
      headers,
    });
    return res.json();
  };

  const {
    data: dataCount,
    status: statusCount,
    refetch: refecthCount,
  } = useQuery("count", fetchFollowCount);

  const baseURL = process.env.REACT_APP_URL;
  const username = localStorage.getItem("username");
  const user_id = localStorage.getItem("user_id");
  let url = "";

  const fetchMentionsCount = async () => {
    const res = await fetch(
      `${baseURL}/notification/mentions/count/${user_id}`,
      {
        headers,
      }
    );
    return res.json();
  };

  const {
    data: dataMentionsCount,
    status: statusMentionsCount,
    refetch: refetchMentionsCount,
  } = useQuery("MentionsCount", fetchMentionsCount);

  useEffect(() => {
    document.title = "Home / Social";
  }, []);

  const [fullname, setFullname] = useState("");

  return (
    <Col lg={3} className={"pt-0"}>
      <div
        className={
          statusCount == "success" && dataCount.count < 5
            ? "d-none"
            : "width-fixed-container-left-nav"
        }
      >
        <div className={"border-hidden mb-0 bg-color-transparent"}>
          <Row className="justify-content-center align-items-center hover">
            <Col col={12} className="">
              <NavLink
                to={"/" + username.replace("@", "")}
                className={(navData) =>
                  navData.isActive
                    ? "side-nav-profile-active-2 textUnderlineNone d-flex side-nav-profile mb-3 p-2"
                    : "textUnderlineNone d-flex side-nav-profile mb-3 p-2"
                }
              >
                <div
                  className={"profile-pic-logged float-left"}
                  style={{
                    backgroundImage:
                      "url(" +
                      process.env.REACT_APP_URL.replace("api", "") +
                      "public/profile/picture/" +
                      localStorage.getItem("username") +
                      "/" +
                      localStorage.getItem("profile_pic") +
                      ")",
                  }}
                />{" "}
                &nbsp;&nbsp;
                <h6 className="mb-0 hover fontSize-16">
                  <span className="text-bold">
                    {localStorage.getItem("fullname").length > 18
                      ? localStorage.getItem("fullname").slice(0, 18) + "..."
                      : localStorage.getItem("fullname")}
                  </span>
                  <br />
                  <span className="text-normal footnote">
                    {localStorage.getItem("username")}
                  </span>
                </h6>
              </NavLink>
            </Col>
          </Row>
          <Row className="justify-content-center align-items-center mb-4">
            <Col lg={12}>
              <NavLink
                to={"/following/" + username.replace("@", "")}
                className={(navData) =>
                  navData.isActive
                    ? "side-nav-profile-active textUnderlineNone side-nav-profile color-black mb-0 fontSize-20 hover padding-15"
                    : "textUnderlineNone side-nav-profile color-black mb-0 fontSize-20 hover padding-15"
                }
              >
                <IoWalkOutline size={27} /> &nbsp;&nbsp;Following
              </NavLink>
            </Col>
          </Row>

          <Row className="justify-content-center align-items-center mb-4">
            <Col lg={12}>
              <NavLink
                to={"/followers/" + username.replace("@", "")}
                className={(navData) =>
                  navData.isActive
                    ? "side-nav-profile-active textUnderlineNone side-nav-profile color-black mb-0 fontSize-20 hover padding-15"
                    : "textUnderlineNone side-nav-profile color-black mb-0 fontSize-20 hover padding-15"
                }
              >
                <IoWalkSharp size={27} /> &nbsp;&nbsp;Followers
              </NavLink>
            </Col>
          </Row>

          <Row className="justify-content-center align-items-center mb-4">
            <Col lg={12}>
              <NavLink
                to="/mentions"
                className={(navData) =>
                  navData.isActive
                    ? "side-nav-profile-active textUnderlineNone side-nav-profile color-black mb-0 fontSize-20 hover padding-15"
                    : "textUnderlineNone side-nav-profile color-black mb-0 fontSize-20 hover padding-15"
                }
              >
                <IoAtSharp size={27} /> &nbsp;&nbsp;Mentions&nbsp;
                {statusMentionsCount == "loading" ? (
                  ""
                ) : (
                  <>
                    {dataMentionsCount.count == 0 ? (
                      ""
                    ) : (
                      <label className="mention-notifications">
                        {dataMentionsCount.count}
                      </label>
                    )}
                  </>
                )}
              </NavLink>
            </Col>
          </Row>

          <Row className="justify-content-center align-items-center mb-4">
            <Col lg={12}>
              <NavLink
                to="/bookmarks"
                className={(navData) =>
                  navData.isActive
                    ? "side-nav-profile-active textUnderlineNone side-nav-profile color-black mb-0 fontSize-20 hover padding-15"
                    : "textUnderlineNone side-nav-profile color-black mb-0 fontSize-20 hover padding-15"
                }
              >
                <BsBookmarks size={27} /> &nbsp;&nbsp;Bookmarks
              </NavLink>
            </Col>
          </Row>

          <Row className="justify-content-center align-items-center mb-4">
            <Col lg={12}>
              <NavLink
                to="/likes"
                className={(navData) =>
                  navData.isActive
                    ? "side-nav-profile-active textUnderlineNone side-nav-profile color-black mb-0 fontSize-20 hover padding-15"
                    : "textUnderlineNone side-nav-profile color-black mb-0 fontSize-20 hover padding-15"
                }
              >
                <IoIosHeartEmpty size={27} /> &nbsp;&nbsp;Likes
              </NavLink>
            </Col>
          </Row>

          <Row className="justify-content-center align-items-center mb-4">
            <Col lg={12}>
              <NavLink
                to="/topics"
                className={(navData) =>
                  navData.isActive
                    ? "side-nav-profile-active textUnderlineNone side-nav-profile color-black mb-0 fontSize-20 hover padding-15"
                    : "textUnderlineNone side-nav-profile color-black mb-0 fontSize-20 hover padding-15"
                }
              >
                <BsChatDots size={27} /> &nbsp;&nbsp;Topics
              </NavLink>
            </Col>
          </Row>
          <Row className="justify-content-center align-items-center">
            <Col lg={12}>
              <NavLink
                to="/blocked"
                className={(navData) =>
                  navData.isActive
                    ? "side-nav-profile-active textUnderlineNone side-nav-profile color-black mb-0 fontSize-20 hover padding-15"
                    : "textUnderlineNone side-nav-profile color-black mb-0 fontSize-20 hover padding-15"
                }
              >
                <IoBanOutline size={27} />
                &nbsp;&nbsp; Blocked
              </NavLink>
            </Col>
          </Row>

          {/* <div className="h-divider" />
          <Row className="justify-content-center align-items-center mt-2">
            <Col lg={12}>
              <Stack>
                <NavLink
                  to="/settings"
                  className="textUnderlineNone side-nav-profile color-black"
                  exact
                  activeClassName="side-nav-profile-active"
                >
                  <label className="mb-0 fontSize-20 hover padding-15">
                    <IoSettingsOutline size={27} />
                    &nbsp;&nbsp;Settings and privacy
                  </label>
                </NavLink>
              </Stack>
            </Col>
          </Row>
          <Row className="justify-content-center align-items-center mb-0">
            <Col lg={12}>
              <Stack>
                <NavLink
                  to="/help"
                  className="textUnderlineNone side-nav-profile color-black"
                  exact
                  activeClassName="side-nav-profile-active"
                >
                  <label className="mb-0 fontSize-20 hover padding-15">
                    <IoInformationCircleOutline size={27} />
                    &nbsp;&nbsp;Help center
                  </label>
                </NavLink>
              </Stack>
            </Col>
          </Row> */}
        </div>
      </div>
    </Col>
  );
}
