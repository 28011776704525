import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Container, Row, Col, Button, Stack } from "react-bootstrap";
import BeatLoader from "react-spinners/BeatLoader";
import axios from "axios";
import { Link } from "react-router-dom";
import HeaderToken from "../HeaderToken";
import { ClipLoader } from "react-spinners";

const headers = {
  Authorization: `${HeaderToken.Authorization}`,
  Accept: `${HeaderToken.Accept}`,
};

const Blocked = (props) => {
  const queryClient = useQueryClient();
  const [hidden, setHidden] = useState({});
  const [count, setCount] = useState(0);

  const url = process.env.REACT_APP_URL;
  const imgURL = process.env.REACT_APP_URL.replace("api", "");

  const blockedUserQuery = useQuery({
    queryKey: ["blockedUsers"],
    queryFn: async () => {
      const response = await axios.get(`${url}/blocked/users/${props.userID}`, {
        headers,
      });
      const data = response.data;
      return data;
    },
  });

  const { data, status, refetch } = blockedUserQuery;

  function unBlock(blocked_user_id) {
    setCount(count + 1);

    setHidden({ ...hidden, [blocked_user_id]: !hidden[blocked_user_id] });
    axios
      .get(url + "/block/user/" + props.userID + "/" + blocked_user_id, {
        headers,
      })
      .then((res) => {
        setHidden({ ...hidden, [blocked_user_id]: hidden[blocked_user_id] });
        queryClient.invalidateQueries(
          { queryKey: ["blockedUsers"] },
          { exact: true }
        );
        // refetch();
      });
  }
  return (
    <div className="p-3 pt-0">
      {blockedUserQuery.isLoading ? (
        <div className="col-12 text-center p-5 color-blue">
          <ClipLoader size={25} color={"#333333"} />
        </div>
      ) : (
        <>
          {blockedUserQuery.data.length == 0 ? (
            <Row>
              <Col lg={12} className={"mb-5"}>
                <Row className="justify-content-center">
                  <Col lg={8}>
                    <h3 className="mobile-header-sizes">
                      Blocked users will appear here.
                    </h3>
                    <label className="footnote mb-3">
                      Control who see your Posts by means of selective Blocking.
                      <br />
                      You can always unblock someone from this page.
                    </label>
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
            <Row>
              {blockedUserQuery.data.map((item, index) => (
                <Col lg={4} sm={4} xs={6} className="mb-4 p-1">
                  <div className="topic-container p-0">
                    <Link
                      to={"/" + item.username}
                      className={"color-blue textUnderlineNone"}
                    >
                      <div
                        className={"blocked-users-cover"}
                        style={{
                          backgroundImage:
                            "url(" +
                            imgURL +
                            "public/profile/cover/@" +
                            item.username +
                            "/" +
                            item.profile_cover +
                            ")",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                        }}
                      />
                    </Link>

                    <div className="p-2 fontSize14 relative">
                      <Link
                        to={"/" + item.username}
                        className={"color-blue textUnderlineNone"}
                      >
                        <div
                          className={"profile-pic blocked-user-profile-pic"}
                          style={{
                            backgroundImage:
                              "url(" +
                              imgURL +
                              "public/profile/picture/@" +
                              item.username +
                              "/" +
                              item.profile_pic +
                              ")",
                          }}
                        />
                      </Link>
                      <Link
                        to={"/" + item.username}
                        className={"color-black textUnderlineNone"}
                      >
                        <div className={"mt-3 line-height-20"}>
                          <span className="mobile-hide text-bold">
                            {item.fullname.length > 19
                              ? item.fullname.slice(0, 19) + "..."
                              : item.fullname}
                          </span>

                          <span className="screen-hide text-bold">
                            {item.fullname.length > 14
                              ? item.fullname.slice(0, 14) + "..."
                              : item.fullname}
                          </span>
                        </div>
                        <label className={"footnote cursorPointer w-100"}>
                          <span className="mobile-hide text-normal">
                            @
                            {item.username.length > 19
                              ? item.username.slice(0, 19) + "..."
                              : item.username}
                          </span>

                          <span className="screen-hide text-normal">
                            @
                            {item.username.length > 14
                              ? item.username.slice(0, 14) + "..."
                              : item.username}
                          </span>
                        </label>
                      </Link>{" "}
                      <br />
                      {!hidden[item.id] && (
                        <Button
                          className={
                            "btn-blocked-users native-button-red w-100 fontBold mt-3 no-shadow btn-sm"
                          }
                          onClick={() => unBlock(item.id)}
                        ></Button>
                      )}
                      {!!hidden[item.id] && (
                        <label
                          className={
                            "disabled-bg-color fontBold no-shadow btn-sm text-center w-100 mt-3 p-1"
                          }
                        >
                          <ClipLoader size={15} color="#000" />
                        </label>
                      )}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          )}
        </>
      )}
    </div>
  );
};

export default Blocked;
