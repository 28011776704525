import React, { useState, useEffect } from "react";
import { Col, Container, Row, Stack, Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import Header from "./navigation/HeaderPassword";
export default function Signup() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    document.title = "Sign Up";
  });

  function handleClose() {
    setShow(false);
  }
  function handleSignUp() {
    setShow(true);
  }

  return (
    <div className="body-login">
      <Header />
      <Container className="login password-container">
        <Row className="h-100 align-items-center justify-content-center">
          <Col x={12} lg={6}>
            <Row className={"justify-content-center align-items-center"}>
              <Col xs={12} lg={10}>
                <Stack gap={4}>
                  <div className="login-elements-container border-hidden">
                    <Stack gap={3}>
                      <h4 className="text-left">Sign up</h4>
                      <Row>
                        <Col lg={6} xs={12}>
                          <input
                            type="text"
                            placeholder="First name"
                            className="bg-color-white w-100 mb-3"
                          />
                        </Col>
                        <Col lg={6} xs={12}>
                          <input
                            type="text"
                            placeholder="Last name"
                            className="bg-color-white w-100 mb-3"
                          />
                        </Col>
                        <Col lg={12} xs={12}>
                          <input
                            type="email"
                            placeholder="Email address"
                            className="bg-color-white w-100 mb-3"
                          />
                        </Col>

                        <Col lg={6} xs={12}>
                          <input
                            type="text"
                            placeholder="@username"
                            className="bg-color-white w-100 mb-3"
                          />
                        </Col>
                        <Col lg={6} xs={12}>
                          <input
                            type="password"
                            placeholder="New password"
                            className="bg-color-white w-100 mb-3"
                          />
                        </Col>
                        <Col lg={12} xs={12} className="mb-3 footnote">
                          By clicking Agree & Join, you agree to the Social User
                          Agreement, Privacy Policy, and Cookie Policy.
                        </Col>

                        <Col lg={12} xs={12} className="mb-3">
                          <Button className="register-button">Sign Up</Button>
                        </Col>

                        <Col lg={12} xs={12} className="footnote text-center">
                          We can't wait to welcome you.
                        </Col>
                      </Row>
                    </Stack>
                  </div>
                  {/* <div className="text-center">
                    Discover, share and empower.
                  </div> */}
                </Stack>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
