import React, { useState, useEffect, useRef, useCallback } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Tagify } from "react-tagify";
import Linkify from "react-linkify";
import { SecureLink } from "react-secure-link";
// import { MentionsInput, Mention } from "react-mentions";
import _ from "lodash";
import {
  Col,
  Container,
  Row,
  Stack,
  Modal,
  Button,
  Dropdown,
} from "react-bootstrap";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Header from "./navigation/HeaderHome";
import profile from "../assets/profileplaceholder.jpg";
import Emojies from "../components/features/Emojies";
import Polls from "../components/features/Polls";
import Elements from "../components/features/Elements";
import PreviewMedia from "./previews/PreviewMedia";
import {
  IoMdImages,
  IoIosMic,
  IoIosHeartEmpty,
  IoIosHeart,
} from "react-icons/io";
import {
  BsEmojiSmile,
  BsChatRightDots,
  BsFillBookmarkFill,
  BsBookmark,
  BsXLg,
  BsChevronDoubleUp,
  BsChevronDoubleDown,
  BsChevronDoubleLeft,
  BsChevronDoubleRight,
  BsPencil,
} from "react-icons/bs";
import {
  FaPoll,
  FaRegFileExcel,
  FaRegFilePowerpoint,
  FaRegFilePdf,
  FaRegFileWord,
  FaFileCsv,
  FaFilePdf,
} from "react-icons/fa";
import {
  BiDotsHorizontalRounded,
  BiUserMinus,
  BiFlag,
  BiSad,
} from "react-icons/bi";
import {
  HiOutlineDocumentText,
  HiOutlineTrash,
  HiCheck,
  HiOutlineRefresh,
} from "react-icons/hi";
import ReactTooltip from "react-tooltip";
import { BeatLoader, ClipLoader, PuffLoader } from "react-spinners";
import axios from "axios";
import TopBarProgress from "react-topbar-progress-indicator";
import { BsBookmarkPlus } from "react-icons/bs";
import {
  IoChatboxOutline,
  IoShareOutline,
  IoChatbox,
  IoShare,
  IoEllipse,
  IoBookOutline,
  IoPencil,
  IoBanOutline,
  IoCheckmarkCircleOutline,
  IoArrowBack,
  IoArrowForward,
  IoReloadSharp,
} from "react-icons/io5";

import SideNav from "./navigation/SideNavHome";
import RightNav from "./navigation/SidNavHomeRight";
import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";
import PreviewDocuments from "./previews/PreviewDocuments";
import AttemptUploadErrorMessage from "./previews/AttemptUploadErrorMessage";
import Posts from "./Posts";
import Replies from "./features/Replies";
import mentionStyle from "./css/mentionStyle";
import mentionsInputStyle from "./css/mentionsInputStyle";
import tagStyle from "./css/tagStyle";
import HeaderToken from "./HeaderToken";
import Footer from "./navigation/Footer";
import { Helmet } from "react-helmet";
import { InputTextarea } from "primereact/inputtextarea";
import { Mention } from "primereact/mention";

const headers = {
  Authorization: `${HeaderToken.Authorization}`,
  Accept: `${HeaderToken.Accept}`,
};

function Compose(props) {
  const location = useLocation();
  const info = location.state;
  const inputElement = useRef();
  let navigate = useNavigate();
  const queryClient = useQueryClient();
  const { status_id } = useParams();
  const { photo_id } = useParams();
  const { user_name } = useParams();
  const { index } = useParams();
  const { media_index } = useParams();
  const { PostsOnTopicsYouFollow } = useParams();

  const [values, setValues] = useState("");
  const [voteSuccessful, setVoteSuccessful] = useState(false);
  const [postSent, setPostSent] = useState(false);
  const [postSentFeedback, setPostSentFeedback] = useState("");
  const [pagination, setPagination] = useState(20);
  const [opMinHide, setOpMinHide] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [subjectSelected, setSubjectSelected] = useState(false);

  const [selectedTopic, setSelectedTopic] = useState("");
  const [topicSelected, setTopicSelected] = useState(false);
  const [resetFunc, setResetFunc] = useState(false);
  const [topicId, setTopicId] = useState(null);
  const [subjectId, setSubjectId] = useState(null);

  const [subjects, setSubjects] = useState([]);
  const [topics, setTopics] = useState([]);
  const [people, setPeople] = useState([]);
  const [posts, setPosts] = useState([]);
  const [media, setMedia] = useState([]);
  const [postCount, setPostCount] = useState(0);
  const [replyCount, setReplyCount] = useState(0);
  const [likeCount, setLikeCount] = useState(0);
  const [bookmarkCount, setBookmarkCount] = useState(0);
  const [followCount, setFollowCount] = useState(0);
  const [voteCount, setVoteCount] = useState(0);
  const [blockCount, setBlockCount] = useState(0);
  const [promiseLoading, setPromiseLoading] = useState(true);
  const [bookMarkStatus, setBookMarkStatus] = useState(false);
  const [deleteCount, setDeleteCount] = useState(0);
  const [muteCount, setMuteCount] = useState(0);
  const [complaintCount, setComplaintCount] = useState(0);
  const [concernText, setConcernText] = useState(
    "Tell us why you don't want to see this post."
  );

  const [concernClickedOne, setConcernClickedOne] = useState(false);
  const [concernClickedTwo, setConcernClickedTwo] = useState(false);
  const [concernClickedText, setConcernClickedText] = useState("");
  const [photos, setPhotos] = useState(true);
  const [photoCount, setPhotoCount] = useState("");
  const [photoPost, setPhotoPost] = useState("");
  const [photoPostId, setPhotoPostId] = useState("");
  const [photoId, setPhotoId] = useState("");
  const [photoUsername, setPhotoUsername] = useState("");
  const [photoPostDate, setPhotoPostDate] = useState("");
  const [selectedPhoto, setSelectedPhoto] = useState("");
  const [photoPath, setPhotoPath] = useState("");
  const [photoRepostsCount, setPhotoRepostsCount] = useState(0);
  const [photoLikesCount, setPhotoLikesCount] = useState(0);
  const [photoBookmarksCount, setPhotoBookmarksCount] = useState(0);
  const [photoRepliesCount, setPhotoRepliesCount] = useState(0);

  const [photoReposted, setPhotoReposted] = useState(false);
  const [photoLiked, setPhotoLiked] = useState(false);
  const [photoBookmarked, setPhotoBookmarked] = useState(false);
  const [photoReplied, setPhotoReplied] = useState(false);

  const [replySection, setReplySection] = useState(false);
  const [photoReplies, setPhotoReplies] = useState([]);
  const [photoFullname, setPhotoFullname] = useState("");
  const [profilePicPath, setProfilePicPath] = useState("");
  const [photoProfilePic, setPhotoProfilePic] = useState("");
  const [currentPage, setCurrentPage] = useState("");
  const [photosTimelinePreview, setPhotosTimelinePreview] = useState(false);
  const [successfulPhotoReplyShow, setSuccessfulPhotoReplyShow] =
    useState(false);
  const [successfulPhotoReply, setSuccessfulPhotoReply] = useState("");
  const [showMorePost, setShowMorePost] = useState(false);
  const [showMorePostId, setShowMorePostId] = useState(0);
  const [showMorePostCount, setShowMorePostCount] = useState(0);
  const [showMoreQuotedPostText, setShowMoreQuotedPostText] = useState(false);
  const [mediaReposting, setMediaReposting] = useState([]);
  const [singleTopic, setSingleTopic] = useState(null);
  const [singleSubject, setSingleSubject] = useState(null);
  const [photoCollection, setPhotoCollection] = useState([]);

  const [photoPolls, setPhotoPolls] = useState();
  const [photoDidIVote, setPhotoDidIVote] = useState();
  const [photoDuration, setPhotoDuration] = useState();
  const [photoPollResults, setPhotoPollResults] = useState();
  const [photoDocuments, setPhotoDocuments] = useState();
  const [photoSubjects, setPhotoSubjects] = useState();
  const [photoTopics, setPhotoTopics] = useState();
  const [photoCurrentPostPolls, setPhotoCurrentPostPolls] = useState();
  const [photoCurrentPostPollsClose, setPhotoCurrentPostPollsClose] =
    useState();
  const [photoVotes, setPhotoVotes] = useState();

  const [documentsReposting, setDocumentsReposting] = useState([]);
  const [nextCount, setNextCount] = useState(0);
  const [docTitle, setDocTitle] = useState({});
  const [polls, setPolls] = useState([]);
  const [pollResults, setPollResults] = useState([]);
  const [didIvote, setDidIVote] = useState(0);
  const [currentPostPolls, setCurrentPostPolls] = useState(0);
  const [pollDuration, setPollDuration] = useState([]);
  const [pollVotes, setPollVotes] = useState(0);
  const [filterMentions, setFilterMentions] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [customers, setCustomers] = useState([]);
  let user_id = localStorage.getItem("user_id");
  const [hidden, setHidden] = useState({});

  const baseURL = process.env.REACT_APP_URL;

  useEffect(() => {
    // let userID = "";
    // if (query.trim().length > 0) {
    //   userID = "";
    // } else {
    //   userID = user_id;
    // }

    // axios
    //   .get(url + "/filter/mentions/" + user_id, {
    //     headers,
    //   })
    //   .then((res) => {
    //     res.data.users.forEach(
    //       (d) =>
    //         (d["nickname"] = `${d.username.replace(/\s+/g, "").toLowerCase()}`)
    //     );
    //     setCustomers(res.data.users);

    //     // suggestions = res.data.users;
    //     // console.log(suggestions);
    //     // return res.data.users;

    //     // setFilterMentions(res.data.users);
    //     // console.log(res.data.users);
    //   })
    //   .catch((error) => {
    //     setFilterMentions([]);
    //     // console.log(error.response.data.message);
    //   });

    // setCustomers(data);

    // console.log(info.params);
    if (inputElement.current) {
      inputElement.current.focus();
    }

    // const user_id = localStorage.getItem("user_id");
    // getPhotos();

    axios
      .get(url + "/subjects/get/" + user_id, { headers })
      .then((response) => {
        if (response.data.subjects.length == 0) {
          setSubjects([]);
        } else {
          setSubjects(response.data.subjects);
        }
      })
      .catch((error) => {
        setSubjects([]);
      });
  }, [nextCount]);

  const [show, setShow] = useState(true);
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [hideEmoji, setHideEmoji] = useState(false);
  const [post, setPost] = useState("");
  const [newPost, setNewPost] = useState("");
  const [replies, setReply] = useState("");
  const [postButton, setPostbutton] = useState("login-button");
  const [disableBtn, setDisableBtn] = useState("disabled");
  const [textearTextSize, setTextearTextSize] = useState(
    "textarea-share-post-text-size-22"
  );
  const [loadPostbtn, setLoadPostbtn] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [progressBar, setProgressBar] = useState(false);
  const [fullname, setFullname] = useState("");
  const [replyUsername, setReplyUsername] = useState("");
  const [replyPic, setReplyPic] = useState(null);
  const [showReply, setshowReply] = useState(false);

  const [replyName, setReplyName] = useState("");
  const [replyPost, setReplyPost] = useState("");
  const [ReplyPostID, setReplyPost_uuid] = useState(false);
  const [action, setAction] = useState("");
  const [pollClose, setPollClose] = useState(false);
  const [followText, setFollowText] = useState("Following...");
  const [followBtn, setFollowBtn] = useState("btn btn-success btn-sm");
  const [warningText, setWarningText] = useState(null);
  const [preview, setPreview] = useState([]);
  const [previewDoc, setPreviewDoc] = useState([]);
  const [previewURLImage, setPreviewURLImage] = useState();
  const [imgWidth, setImgWidth] = useState();
  const [imgHeight, setImgHeight] = useState(250);
  const [images, setImages] = useState(null);
  const [documents, setDocuments] = useState(null);
  const [previewDocURL, setPreviewDocURL] = useState(null);
  const [selectedEmoji, setSelectedEmoji] = useState("");
  const [focusTextarea, setFocusTextarea] = useState(true);
  const [showSend, setShowSend] = useState(false);
  const [photoRepliesLoader, setPhotoRepliesLoader] = useState(true);
  const [tagValues, setTagValues] = useState([]);
  const [repostsMedia, setRepostsMedia] = useState([]);
  const [optionCount, setOptionCount] = useState(2);
  const [openPoll, setOpenPoll] = useState(false);
  const [optionOne, setOptionOne] = useState(null);
  const [optionTwo, setOptionTwo] = useState(null);
  const [optionThree, setOptionThree] = useState(null);
  const [optionFour, setOptionFour] = useState(null);
  const [days, setDays] = useState(1);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [mentions, setMentions] = useState("");

  const [pollQuestionPlaceholderReply, setPollQuestionPlaceholderReply] =
    useState("Post your reply...");
  const [pollQuestionPlaceholder, setPollQuestionPlaceholder] = useState(
    "What's on your mind today?"
  );

  let url = baseURL;

  function statusTextarea() {
    setHideEmoji(false);
    setFilterMentions([]);
  }

  function handleClose() {
    navigate(-1);
    // navigate({
    //   pathname: `${info.page}`,
    //   search: `${info.params != null ? info.params : "cancelled=true"}`,
    // });
    // history.push({
    //   pathname: `${info.page}`,
    //   search: `${info.params != null ? info.params : "cancelled=true"}`,
    // });
  }
  const onEmojiClick = (emojiData) => {
    if (inputElement.current) {
      inputElement.current.focus();
    }

    setHideEmoji(false);

    setNewPost((prevInput) => prevInput + emojiData.emoji);

    setFocusTextarea(true);
    setDisableBtn("");
    setPostbutton("login-button");
  };

  function emojies() {
    setOpenPoll(false);
    if (hideEmoji == true) {
      setHideEmoji(false);
    } else {
      setHideEmoji(true);
    }
  }

  function shareTextarea(e) {
    const value = e.target.value;
    setNewPost(e.target.value);
    if (e.target.value.trim().length > 0) {
      setPostbutton("login-button");
      setDisableBtn("");
      setWarningText(null);

      if (e.target.value.trim().length > 190) {
        setTextearTextSize("textarea-share-post-text-size-15");
      } else {
        setTextearTextSize("textarea-share-post-text-size-22");
      }
    } else {
      setPostbutton("login-button");
      setDisableBtn("disabled");
    }

    // let newValue = value.split(" ").pop();
    // if (newValue.includes("@")) {
    //   let mention = value.split(" ").pop();

    //   axios
    //     .get(url + "/filter/mentions/" + mention, { headers })
    //     .then((res) => {
    //       setFilterMentions(res.data.users);
    //       // console.log(res.data.users);
    //     })
    //     .catch((error) => {
    //       setFilterMentions([]);
    //       // console.log(error.response.data.message);
    //     });
    // } else {
    //   setFilterMentions([]);
    // }
  }

  function addMention(username) {
    if (inputElement.current) {
      inputElement.current.focus();
    }

    var val = newPost;
    val = val.substring(0, val.lastIndexOf("@"));
    setNewPost(`${val}@${username} `);
    setFilterMentions([]);
  }

  function updateStatus(event) {
    event.preventDefault();

    // console.log(props.location.state.page + "hello");
    const user_id = localStorage.getItem("user_id");
    const formData = new FormData();

    if (docTitle.length >= 0) {
      for (let i = 0; i < docTitle.length; i++) {
        formData.append("docTile", docTitle);
      }
    }
    if (images != null) {
      for (let i = 0; i < images.length; i++) {
        formData.append("images[]", images[i]);
      }
    }

    if (documents != null) {
      for (let i = 0; i < documents.length; i++) {
        formData.append("documents[]", documents[i]);
      }
    }

    if (openPoll == true) {
      formData.append("optionOne", optionOne);
      formData.append("optionTwo", optionTwo);
      formData.append("days", days);
      formData.append("hours", hours);
      formData.append("minutes", minutes);

      if (optionCount >= 3) {
        formData.append("optionThree", optionThree);
      }
      if (optionCount == 4) {
        formData.append("optionFour", optionFour);
      }
    }

    formData.append("post", newPost);
    formData.append("user_id", user_id);
    formData.append("subject_id", subjectId);
    formData.append("topic_id", topicId);
    formData.append("query", "POSTSTATUS");

    if (newPost.trim().length <= 0) {
      setWarningText(
        <label className="p-1 alert alert-danger">
          Type something for this post...
        </label>
      );
      return;
    }
    setProgressBar(true);
    setSpinner(true);
    setLoadPostbtn(false);

    const url = baseURL;
    // const { mutate, isLoading, error } = useMutation();
    axios({
      method: "POST",
      url: url + "/post/create",
      data: formData,
      headers: {
        Authorization: HeaderToken.Authorization,
        Accept: HeaderToken.Accept,
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        // queryClient.invalidateQueries({ queryKey: ["posts"] }, { exact: true });

        setWarningText(null);
        setPostSentFeedback("Great! Your post was sent.");
        setPostSent(true);
        setDocTitle({});
        setOpenPoll(false);
        setPollQuestionPlaceholder("What's on your mind today?");

        setSpinner(false);
        setLoadPostbtn(true);
        setProgressBar(false);
        setShow(false);
        setImages(null);
        setPreview([]);
        setPreviewDoc([]);
        setDocuments(null);
        setNewPost("");

        setTopicId(null);
        setSubjectId(null);
        setSelectedTopic("");
        setSelectedSubject("");
        setTopicSelected(false);
        setSubjectSelected(false);
        setResetFunc(false);
        setTopics([]);
        setDays(1);
        setHours(0);
        setMinutes(0);
        setOptionOne(null);
        setOptionTwo(null);
        setOptionThree(null);
        setOptionFour(null);

        // const info = {
        //   message: "Great! Your post was sent.",
        //   success: true,
        //   type: "compose",
        // };

        // navigate(-1);
        // console.log("dfdfd");
        if (info.params) {
          navigate(
            {
              pathname: `${info.page}`,
              search: `${info.params}`,
            },
            {
              state: {
                message: "Great! Your post was sent.",
                success: true,
                type: "compose",
              },
            }
          );
        } else {
          navigate(
            {
              pathname: `${info.page}`,
            },
            {
              state: {
                message: "Great! Your post was sent.",
                success: true,
                type: "compose",
              },
            }
          );
        }

        // history.push({
        //   pathname: `${info.page}`,
        //   search: `${info.params != null ? info.params : "success=true"}`,
        //   state: {
        //     message: "Great! Your post was sent.",
        //     success: true,
        //     type: "compose",
        //   },
        // });
        // refetch();
      })
      .catch((error) => {
        setPostSentFeedback("Oops! Something went wrong.");
        setPostSent(true);
      });

    setTimeout(() => {
      setPostSent(false);
    }, 6000);
  }

  url = baseURL;

  function documentUpload(e) {
    if (e.target.files.length > 4) {
      setDisableBtn("disabled");
      setWarningText(
        <label className="p-1 alert alert-danger">
          <span className="text-bold">Warning!</span> <br />
          You can only upload upto 4 documents per post.
        </label>
      );
    } else {
      setOpenPoll(false);
      setHideEmoji(false);
      setPostCount(postCount + 1);
      setPreview([]);
      setImages(null);
      setDocuments(e.target.files);
      let documents = [];
      let documentName = [];
      for (let i = 0; i < e.target.files.length; i++) {
        const fsize = e.target.files[i].size;
        const file = Math.round(fsize / 1024);

        if (file > 5096) {
          setDisableBtn("disabled");
          setWarningText(
            <label className="p-1 alert alert-danger">
              <span className="text-bold">
                Warning!
                <br />
                <span className="textUnderline">{e.target.files[i].name}</span>
              </span>{" "}
              is too big to upload. Please select a file that is 5MB or less,
              otherwise continue typing and post without document.
            </label>
          );
          setDocuments([]);
          return;
        } else {
          documents.push(URL.createObjectURL(e.target.files[i]));
          documentName.push(e.target.files[i].name.replace(/\.[^.$]+$/, ""));
        }
      }
      setPreviewDoc(documentName);
      setPreviewDocURL(documents);
      setDocTitle(documentName);
      setDisableBtn("");
      setPostbutton("login-button");
      setWarningText(null);
    }
  }

  function imageUpload(e) {
    if (e.target.files.length > 4) {
      setDisableBtn("disabled");
      setWarningText(
        <label className="p-1 alert alert-danger">
          <strong>NB:</strong> You can only upload upto 4 images per post.
        </label>
      );
    } else {
      setOpenPoll(false);
      setHideEmoji(false);
      setPostCount(postCount + 1);
      setPreviewDoc([]);
      setDocuments(null);
      setImages(e.target.files);
      let images = [];
      let uploadImages = [];
      for (let i = 0; i < e.target.files.length; i++) {
        images.push(URL.createObjectURL(e.target.files[i]));
      }

      if (e.target.files.length == 1) {
        setImgWidth("col-lg-12");
        setImgHeight(250);
      } else if (
        e.target.files.length == 2 ||
        e.target.files.length == 4 ||
        e.target.files.length == 3
      ) {
        setImgWidth("col-lg-6");
        setImgHeight(200);
      }

      setPreview(images);
      setDisableBtn("");
      setPostbutton("login-button");
      setWarningText(null);

      setPreviewURLImage("");
    }
  }

  function subject(subject_id, subject) {
    setSubjectId(subject_id);
    setSelectedSubject(subject);
    setSubjectSelected(true);
    setResetFunc(true);

    axios
      .get(url + "/topics/get/" + subject_id, { headers })
      .then((response) => {
        if (response.data.topics.length == 0) {
          setTopics([]);
        } else {
          setTopics(response.data.topics);
        }
      })
      .catch((error) => {});
  }

  function showSubjects() {
    return (
      <>
        {subjects.length == 0 ? (
          <div className={"paddingRight-13 paddingLeft-13 footnote fontSize14"}>
            Subjects you follow will appear here.
            <br />
            <Link to={"/subjects"}>
              <label className="native-button-dark-grey btn-sm rounded-pill mt-2 fontBold fontSize14 w-100 text-center">
                See subjects
              </label>
            </Link>
          </div>
        ) : (
          ""
        )}
        {/* <div
          className={subjects.length > 0 ? "d-none" : "w-100 text-center mt-1"}
        >
          <ClipLoader size={20} />
        </div> */}

        {subjects.map((item, index) => (
          <Dropdown.Item
            onClick={() => subject(item.subject_id, item.subject)}
            key={index}
          >
            {item.subject}
          </Dropdown.Item>
        ))}
      </>
    );
  }

  function showTopics() {
    return (
      <>
        <div
          className={topics.length > 0 ? "d-none" : "w-100 text-center mt-1"}
        >
          <ClipLoader size={20} />
        </div>
        {topics.map((item, index) => (
          <Dropdown.Item onClick={() => topic(item.id, item.topic)} key={index}>
            {item.topic}
          </Dropdown.Item>
        ))}
      </>
    );
  }

  function topic(topic_id, topic) {
    setTopicId(topic_id);
    setSelectedTopic(topic);
    setTopicSelected(true);
  }

  function resetSubject() {
    setTopicId(null);
    setSubjectId(null);
    setSelectedTopic("");
    setSelectedSubject("");
    setTopicSelected(false);
    setSubjectSelected(false);
    setResetFunc(false);
    setTopics([]);
  }

  function tags(value, index) {
    // const { id, value } = e.target;
    setDocTitle((docTitle) => Object.assign([], docTitle, { [index]: value }));
    // setPreviewDoc((docTitle) =>
    //   Object.assign([], docTitle, { [index]: value })
    // );

    // console.log(docTitle);
  }

  function clearAttachment() {
    setPostCount(postCount + 1);
    setPreview([]);
    setImages(null);
    setPreviewDoc([]);
    setDocuments(null);
  }

  function removePollOption() {
    setOptionCount(optionCount - 1);
    if (optionCount == 3) {
      setOptionThree(null);
    }
    if (optionCount == 4) {
      setOptionFour(null);
    }
  }

  function addOption() {
    setOptionCount(optionCount + 1);
  }

  function closePoll() {
    setHideEmoji(false);
    if (openPoll == true) {
      setOpenPoll(false);
      setPollQuestionPlaceholder("What's on your mind today?");
      setPollQuestionPlaceholderReply("Post your reply...");
    } else {
      setOpenPoll(true);
      setPollQuestionPlaceholder("Ask a question...");
      setPollQuestionPlaceholderReply("Ask a question...");
    }
  }

  function settingDays(e) {
    setDays(e.target.value);
    if (e.target.value == 0 && minutes < 10 && hours == 0) {
      setHours(1);
    } else if (e.target.value == 0 && minutes >= 10 && hours == 0) {
      setOpMinHide("d-none");
    } else {
      setOpMinHide("");
    }
  }

  function settingHours(e) {
    setHours(e.target.value);
    if (e.target.value == 0 && days == 0 && minutes < 10) {
      setOpMinHide("d-none");
      setMinutes(10);
    } else if (e.target.value == 0 && days == 0 && minutes >= 10) {
      setOpMinHide("d-none");
    } else {
      setOpMinHide("");
    }
  }

  function settingMinutes(e) {
    setMinutes(e.target.value);
    if (e.target.value == 0 && days == 0 && hours == 0) {
      setHours(1);
    }
  }

  function hashtag(value, type, post_id) {
    let hashtagValue;
    if (type == "tag") {
      hashtagValue = value.replace("#", "");
      navigate(`/hashtag/${post_id}/${hashtagValue}`);
      // history.push({ pathname: `/hashtag/${post_id}/${hashtagValue}` });
    } else {
      hashtagValue = value.replace("@", "");
      navigate(`/profile/${hashtagValue}`);
      // history.push({ pathname: `/profile/${hashtagValue}` });
    }

    return;
  }

  function modalFunc() {
    setFilterMentions([]);
    if (hideEmoji == true) {
      setHideEmoji(false);
    }
  }

  const onSearch = (event) => {
    let query = event.query;
    let suggestions;
    let userID = "";
    if (query.trim().length > 0) {
      userID = "";
    } else {
      userID = user_id;
    }

    axios
      .get(url + "/filter/mentions/" + query + "" + userID + "/" + user_id, {
        headers,
      })
      .then((res) => {
        res.data.users.forEach(
          (d) =>
            (d["nickname"] = `${d.username.replace(/\s+/g, "").toLowerCase()}`)
        );
        setCustomers(res.data.users);

        // suggestions = res.data.users;
        // console.log(suggestions);
        // return res.data.users;

        setFilterMentions(res.data.users);
        // console.log(res.data.users);
      })
      .catch((error) => {
        setFilterMentions([]);
        // console.log(error.response.data.message);
      });

    // setTimeout(() => {
    // const query = event.query;
    // let suggestions;

    if (!query.trim().length) {
      suggestions = [...customers];
    } else {
      suggestions = customers.filter((customer) => {
        return customer.username.toLowerCase();
        // .startsWith(query.toLowerCase());
      });
    }

    setSuggestions(suggestions);
    // }, 250);
  };

  const itemTemplate = (suggestions) => {
    return (
      <Row className="align-items-center">
        <Col lg={2} xs={2}>
          <div
            className={"profile-pic"}
            style={{
              backgroundImage:
                "url(" +
                process.env.REACT_APP_URL.replace("api", "") +
                "public/profile/picture/@" +
                suggestions.username +
                "/" +
                suggestions.profile_pic +
                ")",
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          />
        </Col>

        <Col lg={10} xs={10}>
          {/* <img alt={filterMentions.fullname} src={src} width="32" /> */}
          <label className=" mentions__">
            {suggestions.fullname.length > 25
              ? suggestions.fullname.slice(0, 25) + "..."
              : suggestions.fullname}
            <br />
            <small className="small___text">@{suggestions.username}</small>
          </label>
        </Col>
      </Row>
    );
  };

  return (
    <div className="body-login compose-body">
      <Helmet>
        <title>
          Compose new post | {process.env.REACT_APP_YOUR_VARIABLE_NAME_HERE}
        </title>
      </Helmet>
      {progressBar && <TopBarProgress />}
      <Container>
        <Modal
          show={show}
          onHide={handleClose}
          backdropClassName="backdrop-login"
          centered
          className={"compose__modal_backdrop"}
        >
          <Modal.Body>
            <Container className="p-1">
              <form onSubmit={updateStatus}>
                <Row className="align-items-center">
                  <Col lg={12}>
                    {filterMentions.length == 0 ? (
                      <></>
                    ) : (
                      <>
                        {/* <div className={"mentionContainer"}>
                        {filterMentions.map((item, index) => (
                          <Row
                            className={
                              "align-items-center justify-content-center  mention-container-inner"
                            }
                            key={index}
                            onClick={() => addMention(item.username)}
                          >
                            <Col lg={3} xs={3}>
                              <div
                                className={"profile-pic"}
                                style={{
                                  backgroundImage:
                                    "url(" +
                                    process.env.REACT_APP_URL.replace(
                                      "api",
                                      ""
                                    ) +
                                    "public/profile/picture/@" +
                                    item.username +
                                    "/" +
                                    item.profile_pic +
                                    ")",
                                  backgroundPosition: "center",
                                  backgroundSize: "cover",
                                }}
                              />
                            </Col>
                            <Col lg={9} xs={9} className="p-0">
                              <div
                                className={
                                  "fontSize14 line-height-15 color-black"
                                }
                              >
                                <span className="text-bold">
                                  {item.fullname.length > 23
                                    ? item.fullname.slice(0, 23) + "..."
                                    : item.fullname}
                                </span>
                                <br />
                                <label
                                  className={
                                    "footnote fontSize14 cursorPointer"
                                  }
                                >
                                  @{item.username}
                                </label>
                              </div>
                            </Col>
                          </Row>
                        ))}
                      </div> */}
                      </>
                    )}

                    <Row>
                      <Col xs={10} sm={10} lg={10} className="mb-0">
                        <NavLink
                          to={{
                            pathname:
                              "/profile/" +
                              localStorage.getItem("username").replace("@", ""),
                          }}
                          data-tip={`${localStorage.getItem(
                            "fullname"
                          )} ${localStorage.getItem("username")}`}
                          data-place={"bottom"}
                          data-effect={"solid"}
                          className="text-center textUnderlineNone color-grey line-height-20"
                          exact
                          activeClassName="mobile-tabs-icons-active"
                        >
                          <div
                            className={"profile-pic float-left line-height-20"}
                            style={{
                              backgroundImage:
                                "url(" +
                                process.env.REACT_APP_URL.replace("api", "") +
                                "public/profile/picture/" +
                                localStorage.getItem("username") +
                                "/" +
                                localStorage.getItem("profile_pic") +
                                ")",
                            }}
                          />
                          <label className="line-height-20 text-left mt-0 marginLeft-5">
                            <span className="text-bold fontSize-18 text-left">
                              {localStorage.getItem("fullname")}
                            </span>
                            <br />
                            <span className={"fontSize-18"}>
                              @
                              {localStorage
                                .getItem("username")
                                .replace("@", "")}
                            </span>
                          </label>
                        </NavLink>
                      </Col>
                    </Row>
                    {/* <MentionsInput
                      value={newPost}
                      onChange={(e) => setNewPost(e.target.value)}
                      className={
                        textearTextSize +
                        " textarea-share-post mt-2 mention__textarea__container"
                      }
                    >
                      <Mention data={usersDa} style={mentionStyle} className="mention__style" />
                    </MentionsInput> */}

                    <Mention
                      value={newPost}
                      onChange={shareTextarea}
                      suggestions={suggestions}
                      onSearch={onSearch}
                      field="nickname"
                      placeholder={pollQuestionPlaceholder}
                      rows={2}
                      itemTemplate={itemTemplate}
                      autoResize
                      onClick={statusTextarea}
                      className={textearTextSize + " textarea-share-post"}
                      trigger={["@"]}
                    />

                    {/* <InputTextarea
                      autoResize
                      value={newPost}
                      onChange={shareTextarea}
                      onClick={statusTextarea}
                      rows={2}
                      cols={30}
                      placeholder={pollQuestionPlaceholder}
                      className={textearTextSize + " textarea-share-post"}
                    /> */}

                    {/* <textarea
                      className={textearTextSize + " textarea-share-post"}
                      placeholder={pollQuestionPlaceholder}
                      value={newPost}
                      ref={inputElement}
                      onChange={shareTextarea}
                      onClick={statusTextarea}
                    /> */}
                    <AttemptUploadErrorMessage
                      warningText={warningText}
                      className={warningText == null ? "d-none" : "col-12"}
                    />
                    <PreviewMedia
                      preview={preview}
                      clearAttachment={clearAttachment}
                      className={preview.length > 0 ? "" : "d-none"}
                    />
                    <PreviewDocuments
                      previewDoc={previewDoc}
                      clearAttachment={clearAttachment}
                      docTitle={docTitle}
                      tags={tags}
                      previewDocURL={previewDocURL}
                      className={previewDoc.length > 0 ? "" : "d-none"}
                    />
                    <div className={openPoll == false ? "d-none" : "mb-3"}>
                      <Polls
                        openPoll={openPoll}
                        optionOne={optionOne}
                        optionTwo={optionTwo}
                        optionThree={optionThree}
                        optionFour={optionFour}
                        optionCount={optionCount}
                        removePollOption={removePollOption}
                        setOptionOne={setOptionOne}
                        setOptionTwo={setOptionTwo}
                        setOptionThree={setOptionThree}
                        setOptionFour={setOptionFour}
                        settingHours={settingHours}
                        settingDays={settingDays}
                        hours={hours}
                        days={days}
                        settingMinutes={settingMinutes}
                        minutes={minutes}
                        addOption={addOption}
                        closePoll={closePoll}
                        opMinHide={opMinHide}
                      />
                    </div>
                    {hideEmoji && (
                      <div className="mb-3 mt-0 col-12 relative">
                        <Emojies handleClick={onEmojiClick} action={"post"} />
                      </div>
                    )}
                  </Col>
                  <Col lg={12} className="mb-3">
                    <Elements
                      imageUpload={imageUpload}
                      documentUpload={documentUpload}
                      emojies={emojies}
                      chosenEmoji={chosenEmoji}
                      closePoll={closePoll}
                      subjectSelected={subjectSelected}
                      selectedSubject={selectedSubject}
                      selectedTopic={selectedTopic}
                      topicSelected={topicSelected}
                      showTopics={showTopics}
                      showSubjects={showSubjects}
                      resetFunc={resetFunc}
                      resetSubject={resetSubject}
                      currentPostPolls={currentPostPolls}
                      action={action}
                    />
                  </Col>

                  <Col lg={12} xs={12} className="mb-0">
                    {loadPostbtn && (
                      <Button
                        className={postButton + " mb-3 text-center fontBold"}
                        type="submit"
                        disabled={
                          preview.length == 0 &&
                          previewDoc.length == 0 &&
                          newPost == ""
                            ? "disabled"
                            : disableBtn
                        }
                      >
                        Post
                      </Button>
                    )}

                    {spinner && (
                      <Button
                        type="button"
                        disabled={true}
                        className="login-button text-center mb-3"
                      >
                        <ClipLoader color={"#fff"} size={17} />
                      </Button>
                    )}

                    <label
                      className="join-now-button text-center"
                      onClick={handleClose}
                    >
                      Cancel
                    </label>
                  </Col>
                </Row>
              </form>
            </Container>
          </Modal.Body>
        </Modal>
      </Container>
    </div>
  );
}

TopBarProgress.config({
  barColors: {
    0: "rgb(29, 155, 240)",
    "1.0": "rgb(29, 155, 240)",
  },
  shadowBlur: 0,
});

export default Compose;
