import React from "react";

const AttemptUploadErrorMessage = (props) => {
  return (
    <div>
      <label className="color-red fontSize14">{props.warningText}</label>
    </div>
  );
};

export default AttemptUploadErrorMessage;
