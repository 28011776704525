import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Container, Row, Col, Button, Stack, NavLink } from "react-bootstrap";
import SideNav from "./navigation/SideNavHome";
import RightNav from "./navigation/SidNavHomeRight";
import Header from "./navigation/HeaderHome";
import BlockedUser from "./features/Blocked";

import { IoIosArrowRoundBack } from "react-icons/io";
import AcademicsToFollow from "./features/AcademicsToFollow";
import TopicisToFollow from "./features/TopicsToFollow";
import Footer from "./navigation/Footer";
import Posts from "./Posts";
import { SecureLink } from "react-secure-link";
import { Linkify } from "react-easy-linkify";
import mentionStyle from "./css/mentionStyle";
import tagStyle from "./css/tagStyle";
import { hashtag } from "react-easy-linkify/dist/plugins/Hashtag";
import { Tagify } from "react-tagify";
import Documents from "./features/Documents";
import Docs from "./Docs";
import axios from "axios";
import HeaderToken from "./HeaderToken";
import { IoAttach, IoClose, IoCloseCircleOutline } from "react-icons/io5";
import {
  FaFileCsv,
  FaRegFileExcel,
  FaRegFilePdf,
  FaRegFilePowerpoint,
  FaRegFileWord,
} from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
function Search() {
  useEffect(() => {}, []);
  let navigate = useNavigate();
  const user_id = localStorage.getItem("user_id");
  const baseURL = process.env.REACT_APP_URL;
  const imgURL = baseURL.replace("api", "");
  const [value, setValue] = useState("");
  const [results, setResults] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [topics, setTopics] = useState([]);
  const [posts, setPosts] = useState([]);
  const [is_search_front_active, set_is_search_front_active] = useState(false);

  const headers = {
    Authorization: `${HeaderToken.Authorization}`,
    Accept: `${HeaderToken.Accept}`,
  };

  function clearSearch() {
    setValue("");
    setResults([]);
    setDocuments([]);
    setTopics([]);
    setPosts([]);
  }

  function keywordSearch(e) {
    setValue(e.target.value);
    let keyword = e.target.value;

    let endpoint = `${baseURL}/${user_id}/search/site?keyword=${keyword}`;

    axios
      .get(`${endpoint}`, { headers })
      .then((res) => {
        if (res.data.results.users.length > 0) {
          setResults(res.data.results.users);
          console.log(res.data.results.users);
        } else {
          setResults([]);
        }

        if (res.data.results.documents.length > 0) {
          setDocuments(res.data.results.documents);
        } else {
          setDocuments([]);
        }

        if (res.data.results.topics.length > 0) {
          setTopics(res.data.results.topics);
        } else {
          setTopics([]);
        }

        if (res.data.results.posts.length > 0) {
          setPosts(res.data.results.posts);
        } else {
          setPosts([]);
        }
      })
      .catch((error) => {
        setResults([]);
        setDocuments([]);
        setTopics([]);
        setPosts([]);
        // console.log(error);
      });
  }

  function replyPage(post, name, username, post_uuid) {
    var url = window.location.pathname;
    if (url.includes("hashtag")) {
      return;
    } else if (url.includes("profile")) {
      return;
    } else {
      navigate("/status/" + username + "/" + post_uuid);
      // history.push({
      //   pathname: "/status/" + username + "/" + post_uuid,
      // });
    }
  }

  return (
    <div className="body-login">
      {/* <Header /> */}
      <Container>
        <Row className="forced-margi">
          {/* <SideNav /> */}
          {/* <RightNav /> */}

          <Col lg={6} className={"p-0"}>
            <div className={"login-elements-container border-hidde mb-5 p-0"}>
              <Row className={"align-items-center pb-0"}>
                <Col lg={12} className={"pb-0"}>
                  <div className="p-3 mb-0 border-botto">
                    <Row className={"align-items-center"}>
                      <Col lg={1} sm={1} xs={1}>
                        <label
                          className="back-arrow-post mb-0"
                          onClick={() => navigate(-1)}
                        >
                          <IoIosArrowRoundBack size={30} />
                        </label>
                      </Col>
                      <Col
                        lg={11}
                        sm={11}
                        xs={10}
                        className={"paddingLeft17 paddingRight17"}
                      >
                        <input
                          type="text"
                          name="search"
                          value={value}
                          className={"rounded-pill"}
                          placeholder={`Search ${process.env.REACT_APP_YOUR_VARIABLE_NAME_HERE}`}
                          onChange={keywordSearch}
                        />
                      </Col>
                      <Col
                        lg={1}
                        sm={1}
                        xs={1}
                        className={"text-left p-0 marginLeft-15"}
                      >
                        <label
                          className="back-arrow-post mb-0"
                          onClick={clearSearch}
                        >
                          <IoCloseCircleOutline size={30} color={"gray"} />
                        </label>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>

              <Row
                className={
                  value.length > 0 &&
                  results.length <= 0 &&
                  documents.length <= 0 &&
                  topics.length <= 0 &&
                  posts.length <= 0
                    ? "justify-content-center"
                    : "d-none"
                }
              >
                <Col lg={12} xs={11} sm={12} className="text-center mt-2">
                  <h4 className="color-grey">No results.</h4>
                </Col>
              </Row>

              <Row
                className={
                  value.length > 0 ? "d-none" : "justify-content-center"
                }
              >
                <Col lg={12} xs={11} sm={12}>
                  <h4 className="text-color-brown">
                    Docs that might interest you
                  </h4>
                  <Docs />
                  <h4 className="text-color-brown">People you might follow</h4>
                  <AcademicsToFollow search={true} />
                  <br />
                  <h4 className="text-color-brown">Topics you might like</h4>
                  <TopicisToFollow search={true} />
                </Col>
              </Row>
              <Row
                className={
                  value.length > 0 ? "justify-content-center" : "d-none"
                }
              >
                <Col xs={11} sm={11}>
                  <div
                    className={"mt-0"}
                    // onClick={deactivateSearchFront}
                  >
                    <div className="p-2 pr-0">
                      {posts.length > 0 ? (
                        <h4 className="text-color-brown">Posts</h4>
                      ) : (
                        ""
                      )}
                      {posts.map((item, index) => (
                        <Row
                          key={index}
                          className="justify-content-center align-items-center side-nav-topics mt-0 mb-0"
                          onClick={() =>
                            replyPage(
                              item.post,
                              item.first_name + " " + item.last_name,
                              item.username,
                              item.id
                            )
                          }
                        >
                          <Col lg={12}>
                            <Row className={"align-items-center"}>
                              <Col lg={12}>
                                <label className="line-height-20 cursorPointer">
                                  <Tagify
                                    onClick={(text, type) =>
                                      hashtag(text, type, item.id)
                                    }
                                    detectHashtags={true}
                                    detectMentions={true}
                                    tagStyle={tagStyle}
                                    mentionStyle={mentionStyle}
                                    detectLinks={true}
                                  >
                                    <Linkify
                                      componentDecorator={(
                                        decoratedHref,
                                        decoratedText,
                                        key
                                      ) => (
                                        <SecureLink
                                          target="_blank"
                                          href={decoratedHref}
                                          key={key}
                                          className={
                                            "color-blue textUnderlineNone"
                                          }
                                        >
                                          {decoratedText}
                                        </SecureLink>
                                      )}
                                    >
                                      {!item.post ? (
                                        <label className="color-blue">
                                          <IoAttach size={20} /> With
                                          attachments
                                        </label>
                                      ) : item.post.length > 60 ? (
                                        item.post.slice(0, 60) + "..."
                                      ) : (
                                        item.post
                                      )}
                                    </Linkify>
                                  </Tagify>
                                </label>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      ))}
                    </div>
                    <div className="p-2 pr-0">
                      {topics.length > 0 ? (
                        <h4 className="text-color-brown">Topics</h4>
                      ) : (
                        ""
                      )}
                      {topics.map((item, index) => (
                        <Row
                          key={index}
                          className="justify-content-center align-items-center side-nav-topics mt-0 mb-0"
                        >
                          <Col lg={12}>
                            <Row className={"align-items-center"}>
                              <Link
                                to={"/topicPosts?clicked=" + item.topic}
                                className={"color-black textUnderlineNone"}
                              >
                                <Col lg={12}>
                                  <label className="line-height-20">
                                    <h5 className="cursorPointer">
                                      {item.topic.length > 27
                                        ? item.topic.slice(0, 27) + "..."
                                        : item.topic}
                                      <br />
                                      <label
                                        className={
                                          "footnote cursorPointer fontSize14"
                                        }
                                      >
                                        {item.subject}
                                      </label>
                                    </h5>
                                  </label>
                                </Col>
                              </Link>
                              {/* <Col lg={5}>
                                        <Button
                                          type="button"
                                          className={
                                            user_id == item.user_id &&
                                            item.is_followed == 1
                                              ? "btn-follow-topics btn-sm native-button-dark-grey float-right fontBold no-shadow"
                                              : "btn-follow-user btn-sm native-button-dark-grey float-right fontBold no-shadow"
                                          }
                                          onClick={() =>
                                            clickedFollow(item.id, item.topic)
                                          }
                                        >
                                        </Button>
                                      </Col> */}
                            </Row>
                          </Col>
                        </Row>
                      ))}
                    </div>
                    <Row>
                      <div className={"padding-20 mt-0"}>
                        {documents.length > 0 ? (
                          <h4 className="text-color-brown">Documents</h4>
                        ) : (
                          ""
                        )}
                        {documents.map((doc, index) => (
                          <a
                            href={
                              process.env.REACT_APP_URL.replace("api", "") +
                              "public/timeline/documents/" +
                              doc.username +
                              "/" +
                              doc.file
                            }
                            target={"_blank"}
                            className={"textUnderlineNone"}
                          >
                            <Row
                              className={
                                "align-items-center footnote docsSearchResults"
                              }
                            >
                              <Col lg={12} key={index} className={"p-0"}>
                                <div className={"fontSize-18"}>
                                  {doc.format == "pdf" ? (
                                    <label className={"p-1"}>
                                      <FaRegFilePdf
                                        size={20}
                                        className={"color-red"}
                                      />
                                      &nbsp;
                                    </label>
                                  ) : doc.format == "docx" ? (
                                    <label className={"p-1"}>
                                      <FaRegFileWord
                                        size={20}
                                        className={"color-blue"}
                                      />
                                      &nbsp;
                                    </label>
                                  ) : doc.format == "csv" ? (
                                    <label className={"p-1"}>
                                      <FaFileCsv
                                        size={20}
                                        className={"color-green"}
                                      />
                                      &nbsp;
                                    </label>
                                  ) : doc.format == "xlsx" ? (
                                    <label className={"p-1"}>
                                      <FaRegFileExcel
                                        size={20}
                                        className={"color-green"}
                                      />
                                      &nbsp;
                                    </label>
                                  ) : doc.format == "pptx" ? (
                                    <label className={"p-1"}>
                                      <FaRegFilePowerpoint
                                        size={20}
                                        className={"color-orange"}
                                      />
                                      &nbsp;
                                    </label>
                                  ) : (
                                    ""
                                  )}

                                  {doc.title == ""
                                    ? doc.file.length > 30
                                      ? doc.file.slice(0, 30) + "..."
                                      : doc.file
                                    : (doc.title.length > 30
                                        ? doc.title.slice(0, 30) + "..."
                                        : doc.title) +
                                      "." +
                                      doc.format}
                                </div>
                              </Col>
                            </Row>
                          </a>
                        ))}
                      </div>

                      <div className={"mt-3"}>
                        {results.length > 0 ? (
                          <h4 className="text-color-brown">People</h4>
                        ) : (
                          ""
                        )}
                        {results.map((item, index) => (
                          <Col lg={12} className="mb-0 mt-0" key={index}>
                            <div className="p-0 mb-0 mt-0">
                              <Row className="paddingLeftRight mb-0 mt-0">
                                <Link
                                  to={`/profile/${item.username}`}
                                  className={
                                    "color-black textUnderlineNone w-100 d-flex mb-0 p-2"
                                  }
                                >
                                  <Col lg={12} className={"cursorPointer "}>
                                    <div
                                      className={
                                        "profile-pic cursorPointer float-left"
                                      }
                                      style={{
                                        backgroundImage:
                                          "url(" +
                                          imgURL +
                                          "public/profile/picture/@" +
                                          item.username +
                                          "/" +
                                          item.profile_pic +
                                          ")",
                                      }}
                                    />
                                    &nbsp;&nbsp;{" "}
                                    <label className="line-height-20">
                                      <span className="text-bold cursorPointer">
                                        {item.fullname.length > 21
                                          ? item.fullname.slice(0, 21) + "..."
                                          : item.fullname}
                                        <br />
                                        <label className="color-grey text-normal cursorPointer">
                                          @{item.username}
                                        </label>
                                      </span>
                                      <br />
                                    </label>
                                  </Col>
                                </Link>
                              </Row>
                            </div>
                          </Col>
                        ))}
                      </div>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          {/* <RightNav /> */}
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Search;
