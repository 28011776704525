import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Col, Row, Stack, Button, Container } from "react-bootstrap";
import { useParams, useHistory, Link, NavLink } from "react-router-dom";
import axios from "axios";
import TopicisToFollow from "../features/TopicsToFollow";
import AcademicsToFollow from "../features/AcademicsToFollow";
import HeaderToken from "../HeaderToken";
import { BsBookmarks } from "react-icons/bs";

const headers = {
  Authorization: `${HeaderToken.Authorization}`,
  Accept: `${HeaderToken.Accept}`,
};

export default function RightNav() {
  const queryClient = useQueryClient();
  const baseURL = process.env.REACT_APP_URL;
  let url = "";
  const [followText, setFollowText] = useState("Following...");
  const [followBtn, setFollowBtn] = useState("btn btn-primary btn-sm");
  const [followCount, setFollowCount] = useState(0);
  let user_id = localStorage.getItem("user_id");
  let username = localStorage.getItem("username");

  // const fetchFollowCount = async () => {
  //   const res = await fetch(`${baseURL}/following/followers/count/${user_id}`, {
  //     headers,
  //   });
  //   return res.json();
  // };

  const followingFollowersQuery = useQuery({
    queryKey: ["followingFollowersQuery"],
    queryFn: async () => {
      const response = await axios.get(
        `${baseURL}/following/followers/count/${user_id}`,
        { headers }
      );
      const data = response.data;
      return data;
    },
    staleTime: 1000 * 60 * 60 * 4,
    cacheTime: 1000 * 60 * 60 * 24,
    refetchOnWindowFocus: false,
  });

  const {
    data: dataCount,
    status: statusCount,
    refetch: refecthCount,
  } = followingFollowersQuery;

  // const getPeople = async () => {
  //   const baseURL = process.env.REACT_APP_URL;
  //   url = baseURL + "/get/people/" + user_id;
  //   const res = await fetch(url, {
  //     headers,
  //   }); //await axios.get(url);
  //   return res.json();
  // };

  // const {
  //   data,
  //   error,
  //   failureCount,
  //   isError,
  //   isFetchedAfterMount,
  //   isFetching,
  //   isIdle,
  //   isLoading,
  //   isPreviousData,
  //   isStale,
  //   isSuccess,
  //   refetch,
  //   remove,
  //   status,
  // } = useQuery("people", getPeople);

  useEffect(() => {}, followCount);

  function follow(user_id) {
    var following_user_id = localStorage.getItem("user_id");
    var followed_user_id = user_id;

    const formData = new FormData();
    formData.append("following_user_id", following_user_id);
    formData.append("followed_user_id", followed_user_id);
    const url = baseURL;
    axios({
      method: "POST",
      url: url + "/following/create",
      data: formData,
      headers: {
        Authorization: HeaderToken.Authorization,
        Accept: HeaderToken.Accept,
      },
    }).then((response) => {
      queryClient.invalidateQueries({ queryKey: ["people"] }, { exact: true });
      // setFollowCount(followCount + 1);
    });
    // refetch();
  }

  function unfollowText() {
    setFollowBtn("btn-sm btn btn-outline-danger rounded-pill");
    setFollowText("Unfollow");
  }

  function followingText() {
    setFollowBtn("btn-sm rounded-pill");
    setFollowText("Following...");
  }

  return (
    <div
      className={
        statusCount == "success" && dataCount.count < 5
          ? "d-none"
          : "width-fixed-container mb-4 d-none d-sm-none d-md-block"
      }
    >
      <div className={"border-hidden mb-0 bg-color-transparen pt-0 "}>
        <Container>
          <Row className="justify-content-center align-items-center mb-2">
            <Col
              lg={12}
              className="p-2 login-elements-containe mb-0 mobile-hide"
            >
              <div lg={12} className="mt-3 mb-3">
                {/* <div className="padding-15 mb-3">
                    <h5 className="mb-2 mt-0">
                      <label className="text-bold">Network connections</label>
                    </h5>
                  </div> */}
                <div className="w-100 mb-3">
                  <NavLink
                    to={`/following/${username.replace("@", "")}`}
                    className={(navData) =>
                      navData.isActive
                        ? "side-nav-topics-active textUnderlineNone side-nav-topics color-black mb-0 fontSize16 hover padding-15 cursorPointer text-bold"
                        : "textUnderlineNone side-nav-topics color-black mb-0 fontSize16 hover padding-15 cursorPointer text-bold"
                    }
                  >
                    Following
                  </NavLink>
                  <div className="w-100 padding-15">
                    <label className="footnote fontSize14">
                      <span className="fontSize-20 text-color-brown">
                        {followingFollowersQuery.isLoading
                          ? 0
                          : followingFollowersQuery.data.followingCount}
                      </span>{" "}
                      People
                    </label>
                  </div>
                </div>

                <div className="w-100 mb-3">
                  <NavLink
                    to={`/followers/${username.replace("@", "")}`}
                    className={(navData) =>
                      navData.isActive
                        ? "side-nav-topics-active textUnderlineNone side-nav-topics color-black mb-0 fontSize16 hover padding-15 cursorPointer text-bold"
                        : "textUnderlineNone side-nav-topics color-black mb-0 fontSize16 hover padding-15 cursorPointer text-bold"
                    }
                  >
                    Followers
                  </NavLink>
                  <div className="w-100 padding-15">
                    <label className="footnote fontSize14">
                      <span className="fontSize-20 text-color-brown">
                        {followingFollowersQuery.isLoading
                          ? 0
                          : followingFollowersQuery.data.followersCount}
                      </span>{" "}
                      Followers
                    </label>
                  </div>
                </div>
                <div className="w-100 mb-3">
                  <NavLink
                    to={`/subjects`}
                    className={(navData) =>
                      navData.isActive
                        ? "side-nav-topics-active textUnderlineNone side-nav-topics color-black mb-0 fontSize16 hover padding-15 cursorPointer text-bold"
                        : "textUnderlineNone side-nav-topics color-black mb-0 fontSize16 hover padding-15 cursorPointer text-bold"
                    }
                  >
                    Subjects
                  </NavLink>
                  <div className="w-100 padding-15">
                    <label className="footnote fontSize14">
                      <span className="fontSize-20 text-color-brown">
                        {followingFollowersQuery.isLoading
                          ? 0
                          : followingFollowersQuery.data.followSubjectsCount}
                      </span>{" "}
                      Subjects following
                    </label>
                  </div>
                </div>
                <div className="w-100">
                  <NavLink
                    to={`/topics`}
                    className={(navData) =>
                      navData.isActive
                        ? "side-nav-topics-active textUnderlineNone side-nav-topics color-black mb-0 fontSize16 hover padding-15 cursorPointer text-bold"
                        : "textUnderlineNone side-nav-topics color-black mb-0 fontSize16 hover padding-15 cursorPointer text-bold"
                    }
                  >
                    Topics
                  </NavLink>
                  <div className="w-100 padding-15">
                    <label className="footnote fontSize14">
                      <span className="fontSize-20 text-color-brown">
                        {followingFollowersQuery.isLoading
                          ? 0
                          : followingFollowersQuery.data.followTopicsCount}
                      </span>{" "}
                      Topics following
                    </label>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              lg={12}
              className="p-4 login-elements-containe mb-0 mobile-hide"
            >
              <Stack className="p-0 negative-margin-top-20">
                {/* <h5 className="mb-2 mt-3">
                    <label className="text-bold">Topics you might like</label>
                  </h5> */}
                <h5 className="mb-0 mt-3 text-color-brow">
                  Topics you might like
                </h5>

                <TopicisToFollow userID={user_id} />
              </Stack>
              <div lg={12} className="mt-1">
                <label>
                  <NavLink
                    to={"/topics"}
                    className={(navData) =>
                      navData.isActive
                        ? "show-more-link-active color-grey textUnderlineNone show-more-link"
                        : "color-grey textUnderlineNone show-more-link"
                    }
                  >
                    <label className="text-left fontBold cursorPointer">
                      Show more
                    </label>
                  </NavLink>
                </label>
              </div>
            </Col>
            {/* <Col lg={12} className={"p-3"}>
                <div className="timeline_hr_line" />
              </Col> */}
            <Col lg={12} className="p-4 login-elements-containe mobile-hide">
              <Stack className="pt-0">
                {/* <h5 className="mb-2">
                    <span className="text-bold">People you might follow</span>
                  </h5> */}
                <h5 className="mb-2 text-color-brow">Add to your feed</h5>

                <AcademicsToFollow userID={user_id} />
              </Stack>
              <div lg={12} className="mt-1">
                <label>
                  <NavLink
                    to={"/academics"}
                    className={(navData) =>
                      navData.isActive
                        ? "show-more-link-active color-grey textUnderlineNone show-more-link"
                        : "color-grey textUnderlineNone show-more-link"
                    }
                  >
                    <label className="text-left fontBold cursorPointer">
                      Show more
                    </label>
                  </NavLink>
                </label>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
