import React, { useEffect, useRef, useState } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import ReactTooltip from "react-tooltip";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Col,
  Row,
  Container,
  Modal,
  Button,
  Dropdown,
  Stack,
} from "react-bootstrap";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { Tagify } from "react-tagify";
import Linkify from "react-linkify";
import { SecureLink } from "react-secure-link";
import PageNotFound from "./PageNotFound";
import {
  IoMdImages,
  IoIosMic,
  IoIosHeartEmpty,
  IoIosHeart,
  IoIosArrowRoundBack,
} from "react-icons/io";
import {
  BsEmojiSmile,
  BsChatRightDots,
  BsBookmarkPlus,
  BsFillBookmarkFill,
  BsBookmark,
  BsPencil,
  BsXLg,
  BsHandThumbsUpFill,
  BsHandThumbsUp,
} from "react-icons/bs";
import {
  BiUserMinus,
  BiSad,
  BiFlag,
  BiUserPlus,
  BiShare,
} from "react-icons/bi";
import { HiOutlineRefresh, HiOutlineTrash, HiCheck } from "react-icons/hi";
import {
  FaPoll,
  FaRegFileExcel,
  FaRegFilePowerpoint,
  FaRegFilePdf,
  FaRegFileWord,
  FaFileCsv,
  FaFilePdf,
} from "react-icons/fa";

import {
  IoChatboxOutline,
  IoChatbox,
  IoShareOutline,
  IoShare,
  IoBookOutline,
  IoPencil,
  IoCheckmarkCircleOutline,
  IoEllipse,
  IoBanOutline,
  IoGridOutline,
} from "react-icons/io5";
import axios from "axios";
import PreviewMedia from "../previews/PreviewMedia";
import PreviewDocuments from "../previews/PreviewDocuments";
import Polls from "./Polls";
import Emojies from "./Emojies";
import Elements from "./Elements";
import tagStyle from "../css/tagStyle";
import mentionStyle from "../css/mentionStyle";
import HeaderToken from "../HeaderToken";

const headers = {
  Authorization: `${HeaderToken.Authorization}`,
  Accept: `${HeaderToken.Accept}`,
};

const Replies = (props) => {
  const queryClient = useQueryClient();
  let navigate = useNavigate();
  const inputElement = useRef();
  let photoPreview = false;
  if (props.photoPreview) {
    photoPreview = true;
  }
  const [postCount, setPostCount] = useState(0);
  const [replyCount, setReplyCount] = useState(1);
  const [likeCount, setLikeCount] = useState(1);
  const [showMoreQuotedPostText, setShowMoreQuotedPostText] = useState(false);
  const [preview, setPreview] = useState([]);
  const [images, setImages] = useState(null);
  const [imgWidth, setImgWidth] = useState();
  const [warningText, setWarningText] = useState(null);
  const [previewURLImage, setPreviewURLImage] = useState();
  const [imgHeight, setImgHeight] = useState(250);
  const [docTitle, setDocTitle] = useState({});
  const [didIvote, setDidIvote] = useState(0);
  const [concernText, setConcernText] = useState(
    "Tell us why you don't want to see this post."
  );
  const [showSend, setShowSend] = useState(false);
  const [concernClickedText, setConcernClickedText] = useState("");
  const [concernClickedOne, setConcernClickedOne] = useState(false);
  const [concernClickedTwo, setConcernClickedTwo] = useState(false);
  const [showMorePost, setShowMorePost] = useState(false);
  const [showMorePostId, setShowMorePostId] = useState(0);
  const [showMorePostCount, setShowMorePostCount] = useState(0);
  const [repostsMedia, setRepostsMedia] = useState([]);
  const [media, setMedia] = useState([]);
  const [files, setFiles] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [votes, setVotes] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [hours, setHours] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [days, setDays] = useState(0);
  const [mins, setMins] = useState(0);
  const [hrs, setHrs] = useState(0);
  const [dys, setDys] = useState(1);

  const [show, setShow] = useState(false);
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [hideEmoji, setHideEmoji] = useState(false);
  const [post, setPost] = useState("");
  const [replies, setReply] = useState("");
  const [postButton, setPostbutton] = useState("disabled-button");
  const [disableBtn, setDisableBtn] = useState("disabled");
  const [textearTextSize, setTextearTextSize] = useState(
    "textarea-share-post-text-size-22"
  );
  const [loadPostbtn, setLoadPostbtn] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [postSpinner, setPostSpinner] = useState(true);
  const [likebySpinner, setLikebySpinner] = useState(true);
  const [progressBar, setProgressBar] = useState(false);
  const [replyUsername, setReplyUsername] = useState("");
  const [showReply, setshowReply] = useState(false);
  const [showView, setShowView] = useState(false);

  const [replyName, setReplyName] = useState("");
  const [replyPost, setReplyPost] = useState("");
  const [ReplyPostID, setReplyPost_uuid] = useState(false);
  const [action, setAction] = useState("");
  const [mediaCount, setMediaCount] = useState(0);
  const [photosTimelinePreview, setPhotosTimelinePreview] = useState(false);
  const [replySection, setReplySection] = useState(false);
  const [previewDoc, setPreviewDoc] = useState([]);
  const [previewDocURL, setPreviewDocURL] = useState(null);
  const [topicId, setTopicId] = useState(null);
  const [subjectId, setSubjectId] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState("");
  const [topicSelected, setTopicSelected] = useState(false);

  const [subjects, setSubjects] = useState([]);
  const [topics, setTopics] = useState([]);

  const [selectedSubject, setSelectedSubject] = useState("");
  const [subjectSelected, setSubjectSelected] = useState(false);

  const [resetFunc, setResetFunc] = useState(false);
  const [pollQuestionPlaceholderReply, setPollQuestionPlaceholderReply] =
    useState("Post your reply...");
  const [pollQuestionPlaceholder, setPollQuestionPlaceholder] =
    useState("Ask a question...");

  const [bookmarkCount, setBookmarkCount] = useState(0);
  const [bookMarkStatus, setBookMarkStatus] = useState(false);
  const [bookmarkMessage, setBookmarkMessage] = useState("");

  const [optionCount, setOptionCount] = useState(2);
  const [openPoll, setOpenPoll] = useState(false);
  const [optionOne, setOptionOne] = useState(null);
  const [optionTwo, setOptionTwo] = useState(null);
  const [optionThree, setOptionThree] = useState(null);
  const [optionFour, setOptionFour] = useState(null);
  const [replyPic, setReplyPic] = useState(null);
  const [currentPage, setCurrentPage] = useState("");
  const [currentPostPolls, setCurrentPostPolls] = useState(0);
  const [polls, setPolls] = useState([]);
  const [pollResults, setPollResults] = useState([]);
  const [pollDuration, setPollDuration] = useState([]);
  const [pollVotes, setPollVotes] = useState(0);
  const [documentsReposting, setDocumentsReposting] = useState([]);
  const [mediaReposting, setMediaReposting] = useState([]);
  const [singleTopic, setSingleTopic] = useState(null);
  const [singleSubject, setSingleSubject] = useState(null);
  const [replyTextarea_size, setReplyTextarea_size] = useState("replyTextarea");
  const [replyElements, setReplyElements] = useState(false);
  const [borderLine, setBorderLine] = useState("");
  const [opMinHide, setOpMinHide] = useState("");

  const baseURL = process.env.REACT_APP_URL;
  const user_uuid = localStorage.getItem("user_id");
  const userName = localStorage.getItem("username");

  let {
    photoPost,
    photoFullname,
    user_name,
    status_id,
    photoProfilePic,
    photoPolls,
    photoDidIVote,
    photoDuration,
    photoPollResults,
    photoVotes,
    photoCollection,
    photoDocuments,
    photoSubjects,
    photoTopics,
    photoCurrentPostPolls,
    photoPollClosed,
  } = "";
  if (props.status_id) {
    photoPost = props.photoPost;
    photoFullname = props.photoFullname;
    user_name = props.user_name;
    status_id = props.status_id;
    photoProfilePic = props.photoProfilePic;
    photoPolls = props.photoPolls;
    photoDidIVote = props.photoDidIVote;
    photoDuration = props.photoDuration;
    photoPollResults = props.photoPollResults;
    photoVotes = props.photoVotes;
    photoCollection = props.photoCollection;
    photoDocuments = props.photoDocuments;
    photoSubjects = props.photoSubjects;
    photoTopics = props.photoTopics;
    photoCurrentPostPolls = props.photoCurrentPostPolls;
    photoPollClosed = props.photoPollClosed;
  }

  const replyQuery = useQuery({
    queryKey: ["replies", window.location.href],
    queryFn: async () => {
      const response = await axios.get(
        `${baseURL}/post/replies/${user_uuid}/${props.postID}`,
        { headers }
      );
      const data = response.data;
      return data;
    },
  });
  const {
    data: RepliesData,
    refetch: RepliesRefetch,
    status: RepliesStatus,
  } = replyQuery;

  //   console.log(RepliesData);

  function fetchSubjects() {
    axios
      .get(baseURL + "/subjects/get/" + user_uuid, { headers })
      .then((response) => {
        if (response.data.subjects.length == 0) {
          setSubjects([]);
        } else {
          setSubjects(response.data.subjects);
        }
      })
      .catch((error) => {
        setSubjects([]);
      });
  }

  useEffect(() => {
    fetchSubjects();
    // RepliesRefetch();
  }, []);

  function removePollOption() {
    setOptionCount(optionCount - 1);
    if (optionCount == 3) {
      setOptionThree(null);
    }
    if (optionCount == 4) {
      setOptionFour(null);
    }
  }

  function addOption() {
    setOptionCount(optionCount + 1);
  }

  function closePoll() {
    if (openPoll == true) {
      setOpenPoll(false);
      setPollQuestionPlaceholder("What's on your mind today?");
      setPollQuestionPlaceholderReply("Post your reply...");
    } else {
      setOpenPoll(true);
      setPollQuestionPlaceholder("Ask a question...");
      setPollQuestionPlaceholderReply("Ask a question...");
    }
  }

  function addLike(post_uuid, currentPage) {
    const user_uuid = localStorage.getItem("user_id");
    setCurrentPage(currentPage);
    setLikeCount(likeCount + 1);
    // setPostCount(postCount + likeCount);

    const formData = new FormData();
    formData.append("post_id", post_uuid);
    formData.append("user_id", user_uuid);
    formData.append("query", "ADDLIKE");

    const url = baseURL;
    axios({
      method: "POST",
      url: url + "/like/add",
      data: formData,
      headers: {
        Authorization: HeaderToken.Authorization,
        Accept: HeaderToken.Accept,
      },
    })
      .then((response) => {
        queryClient.invalidateQueries(
          { queryKey: ["replies"] },
          { exact: true }
        );
        // RepliesRefetch();
        setBookmarkMessage(
          <label
            className={
              response.data.is_liked == 0 ? "color-red" : "color-green"
            }
          >
            {response.data.message}
          </label>
        );
        setBookMarkStatus(true);
      })
      .catch((error) => {
        setBookmarkMessage(
          <span className="color-red">Oops! Something went wrong...</span>
        );
        setBookMarkStatus(true);
      });

    setTimeout(() => {
      setBookMarkStatus(false);
    }, 4000);
  }

  // function getPhotos(username, post_id, photo_id) {
  //   setPhotosTimelinePreview(false);
  //   setReplySection(false);
  //   history.push({
  //     pathname:
  //       "/home/" + username + "/status/" + post_id + "/photo/" + photo_id,
  //   });

  //   axios
  //     .get(
  //       baseURL +
  //         "/status/photo/get/" +
  //         photo_id +
  //         "/" +
  //         post_id +
  //         "/" +
  //         username +
  //         "/" +
  //         user_uuid
  //     )
  //     .then((response) => {
  //       setPhotosTimelinePreview(true);
  //     })
  //     .catch((error) => {});
  // }

  function showMore(post_id) {
    setShowMorePostCount(showMorePostCount + 1);
    if (showMorePost == true && showMorePostId == post_id) {
      setShowMorePost(false);
      setShowMorePostId(0);
    } else {
      setShowMorePost(true);
      setShowMorePostId(post_id);
    }
  }

  function vote(poll_id, post_id) {
    const data = new FormData();
    data.append("poll_id", poll_id);
    data.append("user_id", user_uuid);
    data.append("post_id", post_id);
    axios({
      method: "POST",
      url: baseURL + "/vote",
      data: data,
      headers: {
        Authorization: HeaderToken.Authorization,
        Accept: HeaderToken.Accept,
      },
    })
      .then((response) => {
        queryClient.invalidateQueries(
          { queryKey: ["replies"] },
          { exact: true }
        );
        // refetch();
        // setPostCount(postCount + 1);
      })
      .catch((error) => {});
  }

  function showMoreQuotedPost() {
    if (showMoreQuotedPostText == true) {
      setShowMoreQuotedPostText(false);
    } else {
      setShowMoreQuotedPostText(true);
    }
  }

  function follow(user_id) {
    var following_user_id = user_uuid;
    var followed_user_id = user_id;

    const formData = new FormData();
    formData.append("following_user_id", following_user_id);
    formData.append("followed_user_id", followed_user_id);
    const url = baseURL;
    axios({
      method: "POST",
      url: url + "/following/create",
      data: formData,
      headers: {
        Authorization: HeaderToken.Authorization,
        Accept: HeaderToken.Accept,
      },
    }).then((response) => {
      queryClient.invalidateQueries({ queryKey: ["replies"] }, { exact: true });
    });
    // refetch();
  }

  function documentUpload(e) {
    setPostCount(postCount + 1);
    setPreview([]);
    setImages(null);
    setDocuments(e.target.files);
    if (e.target.files.length > 4) {
      setDisableBtn("disabled");
      setWarningText("You can only upload upto 4 documents per post.");
    } else {
      let documents = [];
      let documentName = [];
      for (let i = 0; i < e.target.files.length; i++) {
        documents.push(URL.createObjectURL(e.target.files[i]));
        documentName.push(e.target.files[i].name);
      }

      setPreviewDoc(documentName);
      setPreviewDocURL(documents);
      setDisableBtn("");
      setPostbutton("login-button");
      setWarningText(null);
    }
  }

  function imageUpload(e) {
    setPostCount(postCount + 1);
    setPreviewDoc([]);
    setDocuments(null);
    setImages(e.target.files);
    if (e.target.files.length > 4) {
      setDisableBtn("disabled");
      setWarningText("You can only upload upto 4 images per post.");
    } else {
      let images = [];
      let uploadImages = [];
      for (let i = 0; i < e.target.files.length; i++) {
        images.push(URL.createObjectURL(e.target.files[i]));
      }

      if (e.target.files.length == 1) {
        setImgWidth("col-lg-12");
        setImgHeight(250);
      } else if (
        e.target.files.length == 2 ||
        e.target.files.length == 4 ||
        e.target.files.length == 3
      ) {
        setImgWidth("col-lg-6");
        setImgHeight(200);
      }

      setPreview(images);
      setDisableBtn("");
      setPostbutton("login-button");
      setWarningText(null);

      setPreviewURLImage("");
    }
  }

  function block(user_id) {
    var blocking_user_id = user_uuid;
    var blocked_user_id = user_id;

    axios
      .get(
        baseURL + "/block/user/" + blocking_user_id + "/" + blocked_user_id,
        { headers }
      )
      .then((res) => {
        queryClient.invalidateQueries(
          { queryKey: ["replies"] },
          { exact: true }
        );
        // refetch();
      });
  }

  function deleteReply(post_id) {
    axios({
      method: "POST",
      url: baseURL + "/reply/delete/" + post_id,
      headers: {
        Authorization: HeaderToken.Authorization,
        Accept: HeaderToken.Accept,
      },
    })
      .then((response) => {
        queryClient.invalidateQueries(
          { queryKey: ["replies"] },
          { exact: true }
        );
        // refetch();
      })
      .catch((error) => {});
  }

  function muteReply(reply_id) {
    // setUndoMute()
    const data = new FormData();
    data.append("post_id", reply_id);
    data.append("user_id", user_uuid);
    axios({
      method: "POST",
      url: baseURL + "/reply/mute",
      data: data,
      headers: {
        Authorization: HeaderToken.Authorization,
        Accept: HeaderToken.Accept,
      },
    })
      .then((response) => {
        queryClient.invalidateQueries(
          { queryKey: ["replies"] },
          { exact: true }
        );
      })
      .catch((error) => {});
    // refetch();
  }

  function hashtag(value, type, post_id) {
    let hashtagValue;
    if (type == "tag") {
      hashtagValue = value.replace("#", "");
      navigate(`/hashtag/${post_id}/${hashtagValue}`);
      // history.push({ pathname: `/hashtag/${post_id}/${hashtagValue}` });
    } else {
      hashtagValue = value.replace("@", "");
      navigate(`/${hashtagValue}`);
      // history.push({ pathname: `/profile/${hashtagValue}` });
    }

    return;
  }

  function addMention(username) {
    alert(username);
  }

  function resetSubject() {
    setTopicId(null);
    setSubjectId(null);
    setSelectedTopic("");
    setSelectedSubject("");
    setTopicSelected(false);
    setSubjectSelected(false);
    setResetFunc(false);
    setTopics([]);
  }

  function tags(value, index) {
    // const { id, value } = e.target;
    setDocTitle((docTitle) => Object.assign([], docTitle, { [index]: value }));

    // console.log(docTitle);
  }

  function subjectSelectedFunc(subject_id, subject) {
    setSubjectId(subject_id);
    setSelectedSubject(subject);
    setSubjectSelected(true);
    setResetFunc(true);

    axios
      .get(baseURL + "/topics/get/" + subject_id, { headers })
      .then((response) => {
        if (response.data.topics.length == 0) {
          setTopics([]);
        } else {
          setTopics(response.data.topics);
        }
      })
      .catch((error) => {
        setTopics([]);
      });
  }

  function showSubjects() {
    return (
      <>
        {subjects.length == 0 ? (
          <div className={"paddingRight-13 paddingLeft-13 footnote fontSize14"}>
            Subjects you follow will appear here.
            <br />
            <Link to={"/topics"}>
              <label className="native-button-dark-grey btn-sm rounded-pill mt-2 fontBold fontSize14">
                Follow subjects
              </label>
            </Link>
          </div>
        ) : (
          ""
        )}
        {subjects.map((item, index) => (
          <Dropdown.Item
            onClick={() => subjectSelectedFunc(item.subject_id, item.subject)}
            key={index}
          >
            {item.subject}
          </Dropdown.Item>
        ))}
      </>
    );
  }

  function showTopics() {
    return (
      <>
        {topics.map((item, index) => (
          <Dropdown.Item
            onClick={() => topicFunc(item.id, item.topic)}
            key={index}
          >
            {item.topic}
          </Dropdown.Item>
        ))}
      </>
    );
  }

  function topicFunc(topic_id, topic) {
    setTopicId(topic_id);
    setSelectedTopic(topic);
    setTopicSelected(true);
  }

  function fewerFeeds(post_id, complaint, option) {
    setConcernClickedText(complaint);

    setConcernText("You'll see fewer posts like this");
    setShowSend(true);

    if (option == 1) {
      if (concernClickedOne == true) {
        setConcernClickedOne(false);
        setShowSend(false);
        setConcernText("Tell us why you don't want to see this post.");
      } else {
        setConcernClickedOne(true);
      }

      setConcernClickedTwo(false);
    } else {
      if (concernClickedTwo == true) {
        setConcernClickedTwo(false);
        setShowSend(false);
        setConcernText("Tell us why you don't want to see this post.");
      } else {
        setConcernClickedTwo(true);
      }

      setConcernClickedOne(false);
    }
  }

  function sendComplaint(post_id) {
    const data = new FormData();
    data.append("post_id", post_id);
    data.append("user_id", user_uuid);
    data.append("complaint", concernClickedText);
    axios({
      method: "POST",
      url: baseURL + "/post/send/complaint",
      data: data,
      headers: {
        Authorization: HeaderToken.Authorization,
        Accept: HeaderToken.Accept,
      },
    })
      .then((response) => {
        setPostCount(postCount + 1);
      })
      .catch((error) => {});
  }

  function replyPage(post, name, username, post_uuid) {
    setPostCount(postCount + 1);
    navigate("/status/" + username + "/" + post_uuid);
    // history.push({
    //   pathname: "/status/" + username + "/" + post_uuid,
    // });
  }

  function Bookmark(post_id) {
    const user_id = localStorage.getItem("user_id");
    const url = baseURL;

    const data = new FormData();
    data.append("post_id", post_id);
    data.append("user_id", user_id);
    axios({
      method: "POST",
      url: url + "/bookmarks/create",
      data: data,
      headers: {
        Authorization: HeaderToken.Authorization,
        Accept: HeaderToken.Accept,
      },
    })
      .then((response) => {
        queryClient.invalidateQueries(
          { queryKey: ["replies"] },
          { exact: true }
        );
        // RepliesRefetch();
        setBookMarkStatus(true);
        setBookmarkMessage(
          <label
            className={
              response.data.is_bookmarked == 1 ? "color-green" : "color-red"
            }
          >
            {response.data.message}.{" "}
            <Link
              to="/bookmarks"
              className={
                response.data.is_bookmarked == 0 ? "d-none" : "color-blue"
              }
            >
              <span className="text-bold">View</span>
            </Link>
          </label>
        );
      })
      .catch((error) => {
        setBookmarkMessage("Oops! Something went wrong...");
      });
    setTimeout(() => {
      setBookMarkStatus(false);
    }, 4000);
  }

  function reply(
    post,
    name,
    username,
    post_uuid,
    pic,
    polls,
    did_i_vote,
    poll_duration,
    poll_results,
    poll_votes,
    media,
    documents,
    subject,
    topic,
    currentPostPolls,
    actionTaken,
    currentPage
  ) {
    // alert(documents);
    // return;
    setOpenPoll(false);
    setOptionOne(null);
    setOptionTwo(null);
    setOptionThree(null);
    setOptionFour(null);
    setOptionCount(2);
    setCurrentPostPolls(currentPostPolls);
    setAction(actionTaken);
    setReplyUsername(username);
    setReplyPic(pic);
    setReplyName(name);
    setReplyPost(post);
    setReplyPost_uuid(post_uuid);
    setshowReply(true);
    setCurrentPage(currentPage);

    setPolls(polls);
    setPollResults(poll_results);
    setDidIvote(did_i_vote);
    setPollDuration(poll_duration);
    setPollVotes(poll_votes);
    setMediaReposting(media);
    setDocumentsReposting(documents);
    setSingleSubject(subject);
    setSingleTopic(topic);

    setDisableBtn("disabled");
    setPostbutton("disabled-button");
  }

  function addReply(event) {
    setDisableBtn("disabled");
    event.preventDefault();
    setReplyCount(replyCount + 1);
    const user_uuid = localStorage.getItem("user_id");
    const formData = new FormData();

    if (docTitle.length >= 0) {
      for (let i = 0; i < docTitle.length; i++) {
        formData.append("docTile", docTitle);
      }
    }
    if (images != null) {
      for (let i = 0; i < images.length; i++) {
        formData.append("images[]", images[i]);
      }
    }

    if (documents != null) {
      for (let i = 0; i < documents.length; i++) {
        formData.append("documents[]", documents[i]);
      }
    }

    if (openPoll == true) {
      formData.append("optionOne", optionOne);
      formData.append("optionTwo", optionTwo);
      formData.append("days", dys);
      formData.append("hours", hrs);
      formData.append("minutes", mins);

      if (optionCount >= 3) {
        formData.append("optionThree", optionThree);
      }
      if (optionCount == 4) {
        formData.append("optionFour", optionFour);
      }
    }

    formData.append(
      "reply",
      action == "reply" || action == "quote"
        ? replies
        : action == "replyCurrentStatus"
        ? post
        : replyPost
    );

    formData.append("subject_id", subjectId);
    formData.append("topic_id", topicId);
    formData.append("post_id", ReplyPostID);
    formData.append("user_id", user_uuid);
    formData.append("action", action);
    formData.append("query", "ADDREPLY");

    setProgressBar(true);
    setSpinner(true);
    setLoadPostbtn(false);

    const url = baseURL;
    axios({
      method: "POST",
      url: url + "/reply/create",
      data: formData,
      headers: {
        Authorization: HeaderToken.Authorization,
        Accept: HeaderToken.Accept,
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        queryClient.invalidateQueries(
          { queryKey: ["replies"] },
          { exact: true }
        );
        // RepliesRefetch();
        setOpenPoll(false);
        setDocTitle({});
        setSpinner(false);
        setLoadPostbtn(true);
        setProgressBar(false);
        setshowReply(false);
        setReply("");
        setImages(null);
        setPreview([]);
        setPreviewDoc([]);
        setDocuments(null);
        setTopicId(null);
        setSubjectId(null);
        setSelectedTopic("");
        setSelectedSubject("");
        setTopicSelected(false);
        setSubjectSelected(false);
        setResetFunc(false);
        setTopics([]);
        setDys(1);
        setHrs(0);
        setMins(0);
        setOptionOne("");
        setOptionTwo("");
        setOptionThree("");
        setOptionFour("");

        setPollQuestionPlaceholderReply("Post your reply...");
        setSpinner(false);
        setLoadPostbtn(true);
        setProgressBar(false);
        setshowReply(false);
        setBookmarkMessage(
          <label className="color-green">
            {action == "repost"
              ? "Repost "
              : action == "quote"
              ? "Quote "
              : "Reply "}{" "}
            sent.
          </label>
        );
        setBookMarkStatus(true);

        if (action == "replyCurrentStatus") {
          setPost("");
          setReplyTextarea_size("replyTextarea");
        }
        setTextearTextSize("textarea-share-post-text-size-22");
        setReplyElements(false);
        setBorderLine("");
      })
      .catch((error) => {
        setBookmarkMessage(
          <label className="color-red">Oops! Something went wrong...</label>
        );
        setBookMarkStatus(true);
      });
    setTimeout(() => {
      setBookMarkStatus(false);
    }, 5000);
  }

  function replyTextarea(e) {
    setReply(e.target.value);
    if (e.target.value.trim().length > 0) {
      setPostbutton("login-button");
      setDisableBtn("");

      if (e.target.value.trim().length > 190) {
        setTextearTextSize("textarea-share-post-text-size-15");
      } else {
        setTextearTextSize("textarea-share-post-text-size-22");
      }
    } else {
      setPostbutton("disabled-button");
      setDisableBtn("disabled");
    }
  }

  function handleClose() {
    setShow(false);
    setHideEmoji(false);
    setshowReply(false);
    if (post == "") {
      setDisableBtn("disabled");
    } else {
      setDisableBtn("");
    }
  }

  const onEmojiClick = (emojiData) => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
    setHideEmoji(false);
    setReply((prevInput) => prevInput + emojiData.emoji);

    // setFocusTextarea(true);
    setDisableBtn("");
    setPostbutton("login-button");
  };

  function emojies() {
    if (hideEmoji == true) {
      setHideEmoji(false);
    } else {
      setHideEmoji(true);
    }
  }

  function settingDays(e) {
    setDys(e.target.value);
    if (e.target.value == 0 && mins < 10 && hrs == 0) {
      setHrs(1);
    } else if (e.target.value == 0 && mins >= 10 && hrs == 0) {
      setOpMinHide("d-none");
    } else {
      setOpMinHide("");
    }
  }

  function settingHours(e) {
    setHrs(e.target.value);
    if (e.target.value == 0 && dys == 0 && mins < 10) {
      setOpMinHide("d-none");
      setMins(10);
    } else if (e.target.value == 0 && dys == 0 && mins >= 10) {
      setOpMinHide("d-none");
    } else {
      setOpMinHide("");
    }
  }

  function settingMinutes(e) {
    setMins(e.target.value);
    if (e.target.value == 0 && dys == 0 && hrs == 0) {
      setHrs(1);
    }
  }

  function clearAttachment() {
    setPostCount(postCount + 1);
    setPreview([]);
    setImages(null);
    setPreviewDoc([]);
    setDocuments(null);
  }

  function dm(username, post) {
    navigate({
      pathname: `/messages/${username}`,
      search: `?message=${post}`,
    });
    // history.push({
    //   pathname: `/messages/${username}`,
    //   search: `?message=${post}`,
    // });
  }

  function modalFunc() {
    if (hideEmoji == true) {
      setHideEmoji(false);
    }
  }

  return (
    <>
      <Col lg={12} className="mt-1 d-non">
        {RepliesStatus == "loading" ? (
          ""
        ) : (
          <>
            {RepliesData.length == 0 ? (
              <div
                className={
                  photoPreview
                    ? "footnote text-center w-100 mt-3"
                    : "footnote text-left w-100 mb-3 p-3 fontSize-20"
                }
              >
                No replies at the moment.
                <br />
                <label
                  onClick={() =>
                    props.reply(
                      photoPost,
                      photoFullname,
                      user_name,
                      status_id,
                      photoProfilePic,
                      photoPolls,
                      photoDidIVote,
                      photoDuration,
                      photoPollResults,
                      photoVotes,
                      photoCollection,
                      photoDocuments,
                      photoSubjects,
                      photoTopics,
                      photoCurrentPostPolls,
                      photoPollClosed,
                      "reply",
                      "status_photo_preview"
                    )
                  }
                  className={
                    !photoPreview
                      ? "d-none"
                      : "native-button-dark-grey fontBold mt-3"
                  }
                >
                  Add your say
                </label>
              </div>
            ) : (
              <>
                {RepliesData.map((item, index) => (
                  <div
                    className={
                      item.is_sent == 1
                        ? "d-none"
                        : photoPreview
                        ? "w-100 p-3 timeline-content-photo pb-0"
                        : "w-100 p-3 timeline-content pb-0"
                    }
                    key={index}
                  >
                    <Row className={item.mute == 0 ? "d-none" : ""}>
                      <Col lg={12} className={"fontSize14 mb-1"}>
                        <label className="pt-1">Post by</label>{" "}
                        <Link
                          to={"/" + item.user.username}
                          className="color-black textUnderlineNone"
                        >
                          <span className="text-bold">
                            {item.user.first_name + " " + item.user.last_name}
                          </span>{" "}
                          <label className="footnote">
                            {" "}
                            @{item.user.username}
                          </label>
                        </Link>
                        <span
                          className="cursorPointer float-right unfollow-btn"
                          onClick={() => follow(item.user.id)}
                        >
                          Unfollow
                        </span>
                      </Col>
                      <Col lg={12} className="pb-3">
                        <div
                          className={
                            "paddingRight10 paddingLeft10 greyBG paddingTopDown w-100 fontSize14"
                          }
                        >
                          <label className="w-100 color-light-grey">
                            <label className="pt-1">
                              Let's improve your feed. {concernText}
                            </label>{" "}
                            <span
                              className="cursorPointer float-right undo-btn"
                              onClick={() => muteReply(item.posts.id)}
                            >
                              Undo
                            </span>
                            <span
                              className={
                                showSend == false
                                  ? "d-none"
                                  : "cursorPointer float-right send-btn"
                              }
                              onClick={() => sendComplaint(item.posts.id)}
                            >
                              Send
                            </span>
                          </label>
                        </div>

                        <div
                          className={
                            "fontSize14 paddingRight10 paddingLeft10 paddingTopDown options-not-relevant w-100 options-not-relevant-border color-light-grey"
                          }
                          onClick={() =>
                            fewerFeeds(
                              item.posts.id,
                              "This post isn't relevant",
                              1
                            )
                          }
                        >
                          <span className="text-bold">
                            This post isn't relevant
                          </span>
                          <label
                            className={
                              concernClickedOne == false
                                ? "d-none"
                                : "float-right"
                            }
                          >
                            <HiCheck size={20} className="check-green-color" />
                          </label>
                        </div>
                        <div
                          className={
                            "fontSize14 paddingRight10 paddingLeft10 paddingTopDown options-not-relevant w-100 color-light-grey"
                          }
                          onClick={() =>
                            fewerFeeds(
                              item.posts.id,
                              "This post contains nudity",
                              2
                            )
                          }
                        >
                          <span className="text-bold">
                            This post contains nudity
                          </span>
                          <label
                            className={
                              concernClickedTwo == false
                                ? "d-none"
                                : "float-right"
                            }
                          >
                            <HiCheck size={20} className="check-green-color" />
                          </label>
                        </div>
                      </Col>
                    </Row>
                    <Row
                      className={
                        item.mute == 1 ? "d-none" : "justify-content-center"
                      }
                    >
                      <Row>
                        <Row className="align-items-center">
                          <Col
                            lg={12}
                            className={
                              item.posts.author_id != null
                                ? "mb-3 marginTop-15"
                                : "d-none"
                            }
                          >
                            <label className="footnote fontSize14 mt-2">
                              <HiOutlineRefresh size={16} /> Original Author:{" "}
                              <Link
                                to={
                                  "../" + item.authorUsername.replace("@", "")
                                }
                                className={"textUnderlineNone color-blue"}
                              >
                                {item.authorUsername ==
                                localStorage.getItem("username")
                                  ? "You"
                                  : item.authorName.length > 15
                                  ? item.authorName.slice(0, 15) + "..."
                                  : item.authorName}
                              </Link>
                            </label>
                          </Col>
                        </Row>
                        <Row className={"align-items-center"}>
                          <Col lg={1} xs={1} sm={1} className="p-0">
                            <NavLink
                              to={"/" + item.user.username.replace("@", "")}
                              className="textUnderlineNone color-black"
                            >
                              <div
                                className={
                                  photoPreview
                                    ? "profile-pic-logged"
                                    : "profile-pic"
                                }
                                style={{
                                  backgroundImage:
                                    "url(" +
                                    process.env.REACT_APP_URL.replace(
                                      "api",
                                      ""
                                    ) +
                                    "public/profile/picture/@" +
                                    item.user.username +
                                    "/" +
                                    item.user.profile_pic +
                                    ")",
                                }}
                              />
                            </NavLink>
                          </Col>
                          <Col
                            lg={11}
                            xs={11}
                            sm={11}
                            className={"mobileTimelinePaddingLeft"}
                          >
                            <NavLink
                              to={"/" + item.user.username.replace("@", "")}
                              className="textUnderlineNone color-black"
                            >
                              <label className={"line-height-20"}>
                                <span className={"text-bold"}>
                                  {item.user.first_name} {item.user.last_name}
                                </span>
                                <br />
                                <span className="footnote cursorPointer">
                                  @{item.user.username}
                                </span>
                              </label>
                            </NavLink>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            lg={1}
                            xs={1}
                            sm={1}
                            className="d-none d-md-block d-lg-block d-xl-block d-xxl-block"
                          ></Col>
                          <Col
                            lg={11}
                            xs={12}
                            sm={11}
                            className={"mobilePaddingTimeline-0"}
                          >
                            <Row className="mb-4">
                              <Col
                                lg={12}
                                className={
                                  item.postSubject == null
                                    ? "d-none"
                                    : "p-0 mt-1"
                                }
                              >
                                <Link
                                  to={
                                    item.postSubject == null
                                      ? ""
                                      : "/subject/" +
                                        item.postSubject.replace(" ", "")
                                  }
                                >
                                  <label
                                    className={
                                      "subject fontSize14 cursorPointer"
                                    }
                                  >
                                    <span className="text-bold">
                                      {item.postSubject == null ? (
                                        ""
                                      ) : (
                                        <label className={"cursorPointer"}>
                                          <IoBookOutline size={15} />{" "}
                                          {item.postSubject}
                                        </label>
                                      )}
                                    </span>
                                  </label>
                                </Link>
                                <Link
                                  to={
                                    item.postTopic == null
                                      ? ""
                                      : "/topicPosts?clicked=" +
                                        item.postTopic +
                                        "&from_post_id=" +
                                        item.posts.id
                                  }
                                >
                                  <label
                                    className={
                                      "topics fontSize14 cursorPointer"
                                    }
                                  >
                                    {item.postTopic == null ? (
                                      ""
                                    ) : (
                                      <label className={"cursorPointer"}>
                                        <IoPencil size={15} /> {item.postTopic}
                                      </label>
                                    )}
                                  </label>
                                </Link>
                              </Col>
                              <Row>
                                <Col
                                  lg={12}
                                  className={
                                    item.topics == null ? "d-none" : ""
                                  }
                                >
                                  <Link
                                    to={
                                      item.topics == null
                                        ? ""
                                        : "/topicPosts?clicked=" +
                                          item.topics.topic +
                                          "&from_post_id=" +
                                          item.posts.id
                                    }
                                  >
                                    <label
                                      className={
                                        "topics fontSize14 cursorPointer textUnderlineNone underlineHover text-normal"
                                      }
                                    >
                                      {item.topics == null ? (
                                        ""
                                      ) : (
                                        <>
                                          <IoBookOutline size={15} />{" "}
                                          {item.topics.topic}
                                        </>
                                      )}
                                    </label>
                                  </Link>
                                </Col>
                              </Row>
                              {item.posts.post == null ? (
                                ""
                              ) : (
                                <label
                                  className={
                                    item.posts.post == null
                                      ? "d-none"
                                      : "whiteSpace cursorPointer marginBottom-15"
                                  }
                                >
                                  {item.posts.post.length > 150 ? (
                                    <>
                                      <span
                                        onClick={() =>
                                          replyPage(
                                            item.posts.post,
                                            item.user.first_name +
                                              " " +
                                              item.user.last_name,
                                            item.user.username,
                                            item.posts.id
                                          )
                                        }
                                        className="text-normal cursorPointer"
                                      >
                                        <span
                                          className={
                                            showMorePost == true
                                              ? "d-none"
                                              : "text-normal"
                                          }
                                        >
                                          <Tagify
                                            onClick={(text, type) =>
                                              hashtag(text, type, item.posts.id)
                                            }
                                            detectHashtags={true}
                                            detectMentions={true}
                                            tagStyle={tagStyle}
                                            mentionStyle={mentionStyle}
                                            detectLinks={true}
                                          >
                                            <Linkify
                                              componentDecorator={(
                                                decoratedHref,
                                                decoratedText,
                                                key
                                              ) => (
                                                <SecureLink
                                                  target="_blank"
                                                  href={decoratedHref}
                                                  key={key}
                                                  className={
                                                    "color-blue textUnderlineNone"
                                                  }
                                                >
                                                  {decoratedText}
                                                </SecureLink>
                                              )}
                                            >
                                              {item.posts.post.slice(0, 150)}
                                            </Linkify>
                                          </Tagify>
                                        </span>
                                        <span
                                          className={
                                            showMorePost == true
                                              ? "text-normal"
                                              : "d-none"
                                          }
                                        >
                                          {item.posts.id == showMorePostId ? (
                                            <Tagify
                                              onClick={(text, type) =>
                                                hashtag(
                                                  text,
                                                  type,
                                                  item.posts.id
                                                )
                                              }
                                              detectHashtags={true}
                                              detectMentions={true}
                                              tagStyle={tagStyle}
                                              mentionStyle={mentionStyle}
                                              detectLinks={true}
                                            >
                                              <Linkify
                                                componentDecorator={(
                                                  decoratedHref,
                                                  decoratedText,
                                                  key
                                                ) => (
                                                  <SecureLink
                                                    target="_blank"
                                                    href={decoratedHref}
                                                    key={key}
                                                    className={
                                                      "color-blue textUnderlineNone"
                                                    }
                                                  >
                                                    {decoratedText}
                                                  </SecureLink>
                                                )}
                                              >
                                                {item.posts.post}
                                              </Linkify>
                                            </Tagify>
                                          ) : (
                                            <Tagify
                                              onClick={(text, type) =>
                                                hashtag(
                                                  text,
                                                  type,
                                                  item.posts.id
                                                )
                                              }
                                              detectHashtags={true}
                                              detectMentions={true}
                                              tagStyle={tagStyle}
                                              mentionStyle={mentionStyle}
                                              detectLinks={true}
                                            >
                                              <Linkify
                                                componentDecorator={(
                                                  decoratedHref,
                                                  decoratedText,
                                                  key
                                                ) => (
                                                  <SecureLink
                                                    target="_blank"
                                                    href={decoratedHref}
                                                    key={key}
                                                    className={
                                                      "color-blue textUnderlineNone"
                                                    }
                                                  >
                                                    {decoratedText}
                                                  </SecureLink>
                                                )}
                                              >
                                                {item.posts.post.slice(0, 150)}
                                              </Linkify>
                                            </Tagify>
                                          )}
                                        </span>
                                      </span>
                                      {showMorePost == true ? "" : ""}
                                      <span
                                        className="color-blue cursorPointer text-normal"
                                        onClick={() => showMore(item.posts.id)}
                                      >
                                        {showMorePost == true &&
                                        item.posts.id == showMorePostId ? (
                                          " Show less..."
                                        ) : (
                                          <span className="text-normal">
                                            <span
                                              className={
                                                "color-black text-normal"
                                              }
                                            >
                                              ...
                                            </span>{" "}
                                            Show more
                                          </span>
                                        )}
                                      </span>
                                    </>
                                  ) : (
                                    <label
                                      onClick={() =>
                                        replyPage(
                                          item.posts.post,
                                          item.user.first_name +
                                            " " +
                                            item.user.last_name,
                                          item.user.username,
                                          item.posts.id
                                        )
                                      }
                                      className="cursorPointer"
                                    >
                                      <Tagify
                                        onClick={(text, type) =>
                                          hashtag(text, type, item.posts.id)
                                        }
                                        detectHashtags={true}
                                        detectMentions={true}
                                        tagStyle={tagStyle}
                                        mentionStyle={mentionStyle}
                                        detectLinks={true}
                                      >
                                        <Linkify
                                          componentDecorator={(
                                            decoratedHref,
                                            decoratedText,
                                            key
                                          ) => (
                                            <SecureLink
                                              target="_blank"
                                              href={decoratedHref}
                                              key={key}
                                              className={
                                                "color-blue textUnderlineNone"
                                              }
                                            >
                                              {decoratedText}
                                            </SecureLink>
                                          )}
                                        >
                                          {item.posts.post}
                                        </Linkify>
                                      </Tagify>
                                    </label>
                                  )}
                                  {repostsMedia.length == 0 ? (
                                    ""
                                  ) : (
                                    <div className="col-12 mt-2">
                                      {repostsMedia.map(
                                        (mediaRepost, index) => (
                                          <>{index}</>
                                        )
                                      )}
                                    </div>
                                  )}
                                </label>
                              )}
                            </Row>
                            <Row
                              className={
                                item.media.length > 0 ? "mt-1" : "d-none"
                              }
                            >
                              {item.media.map((img, index) => (
                                <Col
                                  className={
                                    item.media.length == 1
                                      ? "col-12"
                                      : item.media.length == 2
                                      ? "col-6 paddingSides" + index
                                      : item.media.length == 3
                                      ? "col-4 threePaddingSides" + index
                                      : item.media.length == 4
                                      ? "col-6 fourPaddingSides" + index
                                      : "col-6"
                                  }
                                  key={index}
                                >
                                  <div
                                    // onClick={() =>
                                    //   getPhotos(
                                    //     item.user.username,
                                    //     item.posts.id,
                                    //     img.id,
                                    //     item.media,
                                    //     index
                                    //   )
                                    // }
                                    onClick={() =>
                                      replyPage(
                                        item.posts.post,
                                        item.user.first_name +
                                          " " +
                                          item.user.last_name,
                                        item.user.username,
                                        item.posts.id
                                      )
                                    }
                                    className={
                                      item.media.length == 2
                                        ? "mb-0 border-radius" + index
                                        : item.media.length == 3
                                        ? "borderRadiusImg" + index
                                        : item.media.length == 4
                                        ? "borderRadiusImgFour" + index
                                        : "mb-0 border-radius-5"
                                    }
                                    style={{
                                      backgroundImage:
                                        "url(" +
                                        process.env.REACT_APP_URL.replace(
                                          "api",
                                          ""
                                        ) +
                                        "public/timeline/media/" +
                                        item.user.username +
                                        "/" +
                                        img.file +
                                        ")",
                                      backgroundSize: "cover",
                                      backgroundRepeat: "no-repeat",
                                      height:
                                        item.media.length == 1
                                          ? photoPreview
                                            ? 200
                                            : 300
                                          : item.media.length == 2
                                          ? photoPreview
                                            ? 150
                                            : 300
                                          : item.media.length == 4
                                          ? photoPreview
                                            ? 130
                                            : 250
                                          : photoPreview
                                          ? 100
                                          : 200,
                                      width: "100%",
                                      border: "thin solid #ececec",

                                      // backgroundRepeat: "no-repeat",
                                      backgroundPosition: "center",
                                    }}
                                  />
                                </Col>
                              ))}
                            </Row>
                            <Row
                              className={
                                item.documents.length > 0 &&
                                item.posts.pos == null
                                  ? "mt-0"
                                  : item.documents.length > 0
                                  ? "mt-1"
                                  : "d-none"
                              }
                            >
                              {item.posts.post == null ? (
                                <span
                                  className="send-btn fontBold mb-0 text-center fontSize14"
                                  style={{ width: 140 }}
                                  onClick={() =>
                                    replyPage(
                                      item.posts.post,
                                      item.user.first_name +
                                        " " +
                                        item.user.last_name,
                                      item.user.username,
                                      item.posts.id
                                    )
                                  }
                                >
                                  Open post <IoBookOutline size={17} />
                                </span>
                              ) : (
                                ""
                              )}
                              <div className={"padding-20 mt-0"}>
                                {item.documents.map((doc, index) => (
                                  <a
                                    href={
                                      process.env.REACT_APP_URL.replace(
                                        "api",
                                        ""
                                      ) +
                                      "public/timeline/documents/" +
                                      item.user.username +
                                      "/" +
                                      doc.file
                                    }
                                    target={"_blank"}
                                    className={"textUnderlineNone"}
                                  >
                                    <Row
                                      className={
                                        "align-items-center document-link-timeline"
                                      }
                                    >
                                      <Col
                                        lg={12}
                                        key={index}
                                        className={"p-0"}
                                      >
                                        <div className={""}>
                                          {doc.format == "pdf" ? (
                                            <label className={"p-1"}>
                                              <FaRegFilePdf
                                                size={20}
                                                className={"color-red"}
                                              />
                                              &nbsp;
                                            </label>
                                          ) : doc.format == "docx" ? (
                                            <label className={"p-1"}>
                                              <FaRegFileWord
                                                size={20}
                                                className={"color-blue"}
                                              />
                                              &nbsp;
                                            </label>
                                          ) : doc.format == "csv" ? (
                                            <label className={"p-1"}>
                                              <FaFileCsv
                                                size={20}
                                                className={"color-green"}
                                              />
                                              &nbsp;
                                            </label>
                                          ) : doc.format == "xlsx" ? (
                                            <label className={"p-1"}>
                                              <FaRegFileExcel
                                                size={20}
                                                className={"color-green"}
                                              />
                                              &nbsp;
                                            </label>
                                          ) : doc.format == "pptx" ? (
                                            <label className={"p-1"}>
                                              <FaRegFilePowerpoint
                                                size={20}
                                                className={"color-orange"}
                                              />
                                              &nbsp;
                                            </label>
                                          ) : (
                                            ""
                                          )}
                                          {doc.title == ""
                                            ? doc.file
                                            : doc.title + "." + doc.format}
                                        </div>
                                      </Col>
                                    </Row>
                                  </a>
                                ))}
                              </div>
                            </Row>
                            <Row
                              className={
                                item.repostDocuments.length > 0
                                  ? "mt-0"
                                  : "d-none"
                              }
                            >
                              {item.posts.post == null ? (
                                <span
                                  className="send-btn fontBold mb-0 text-center"
                                  style={{ width: 140 }}
                                  onClick={() =>
                                    replyPage(
                                      item.posts.post,
                                      item.user.first_name +
                                        " " +
                                        item.user.last_name,
                                      item.user.username,
                                      item.posts.id
                                    )
                                  }
                                >
                                  Open post <IoBookOutline size={17} />
                                </span>
                              ) : (
                                ""
                              )}
                              <div className={"padding-20"}>
                                {item.repostDocuments.map((doc, index) => (
                                  <a
                                    href={
                                      process.env.REACT_APP_URL.replace(
                                        "api",
                                        ""
                                      ) +
                                      "public/timeline/documents/" +
                                      item.authorUsername.replace("@", "") +
                                      "/" +
                                      doc.file
                                    }
                                    target={"_blank"}
                                    className={"textUnderlineNone"}
                                  >
                                    <Row
                                      className={
                                        "align-items-center document-link-timeline"
                                      }
                                    >
                                      <Col
                                        lg={12}
                                        key={index}
                                        className={"p-0"}
                                      >
                                        <div className={""}>
                                          {doc.format == "pdf" ? (
                                            <label className="p-1">
                                              <FaRegFilePdf
                                                size={20}
                                                className={"color-red"}
                                              />
                                            </label>
                                          ) : doc.format == "docx" ? (
                                            <label className="p-1">
                                              <FaRegFileWord
                                                size={20}
                                                className={"color-blue"}
                                              />
                                            </label>
                                          ) : doc.format == "csv" ? (
                                            <label className="p-1">
                                              <FaFileCsv
                                                size={20}
                                                className={"color-green"}
                                              />
                                            </label>
                                          ) : doc.format == "xlsx" ? (
                                            <label className="p-1">
                                              <FaRegFileExcel
                                                size={20}
                                                className={"color-green"}
                                              />
                                            </label>
                                          ) : doc.format == "pptx" ? (
                                            <label className="p-1">
                                              <img
                                                src={
                                                  process.env.REACT_APP_URL.replace(
                                                    "api",
                                                    ""
                                                  ) + "/pptx_icons.png"
                                                }
                                                style={{ width: 40 }}
                                              />
                                            </label>
                                          ) : (
                                            ""
                                          )}
                                          {doc.title == ""
                                            ? doc.file
                                            : doc.title + "." + doc.format}
                                        </div>
                                      </Col>
                                    </Row>
                                  </a>
                                ))}
                              </div>
                            </Row>
                            <Row
                              className={
                                item.polls.length > 0 &&
                                item.quotedData.linked_quote_post_id !=
                                  item.posts.id
                                  ? "mt-1"
                                  : "d-none"
                              }
                            >
                              <div
                                className={
                                  item.did_i_vote == 1 ||
                                  (item.pollDuration.minutes <= 0 &&
                                    item.pollDuration.days <= 0 &&
                                    item.pollDuration.hours <= 0 &&
                                    item.pollDuration.seconds <= 0)
                                    ? "col-12"
                                    : "d-none"
                                }
                              >
                                {item.pollResults.map((results, index) => (
                                  <div
                                    className={
                                      results.percentage == 0
                                        ? "w-100 resulst-polls-container-no-votes"
                                        : "w-100 resulst-polls-container"
                                    }
                                    key={index}
                                  >
                                    <label
                                      className={
                                        results.percentage == 0
                                          ? ""
                                          : "polls-voted color-black"
                                      }
                                      style={{
                                        width: results.percentage + "%",
                                      }}
                                    />
                                    <label className={"color-black"}>
                                      {results.options}{" "}
                                      {results.who_i_voted_for == 1 ? (
                                        <IoCheckmarkCircleOutline
                                          size={20}
                                          color={"#66b266"}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </label>
                                    <label
                                      className={"float-right color-black"}
                                    >
                                      {results.percentage}%
                                    </label>
                                  </div>
                                ))}
                              </div>

                              <div
                                className={
                                  item.did_i_vote == 1 ||
                                  (item.pollDuration.minutes <= 0 &&
                                    item.pollDuration.days <= 0 &&
                                    item.pollDuration.hours <= 0 &&
                                    item.pollDuration.seconds <= 0)
                                    ? "d-none"
                                    : "col-12"
                                }
                              >
                                {item.polls.map((polls, index) => (
                                  <Col lg={12} key={index}>
                                    <div
                                      className={"col-12 polls fontBold"}
                                      onClick={() =>
                                        vote(polls.id, polls.post_id)
                                      }
                                    >
                                      {polls.option}
                                    </div>
                                  </Col>
                                ))}
                              </div>
                              <Col lg={12}>
                                <label className="footnote">
                                  {item.votes}{" "}
                                  {item.votes == 1 ? "vote" : "votes"} -{" "}
                                  {item.pollDuration.minutes <= 0 &&
                                  item.pollDuration.days <= 0 &&
                                  item.pollDuration.hours <= 0 &&
                                  item.pollDuration.seconds <= 0
                                    ? "Poll closed."
                                    : item.pollDuration.days >= 1
                                    ? item.pollDuration.days + "d left"
                                    : item.pollDuration.hours >= 1 &&
                                      item.pollDuration.minutes >= 1
                                    ? item.pollDuration.hours +
                                      "h and " +
                                      item.pollDuration.minutes +
                                      "m left"
                                    : item.pollDuration.hours >= 1 &&
                                      item.pollDuration.minutes == 0
                                    ? item.pollDuration.hours + "h left"
                                    : item.pollDuration.minutes >= 1
                                    ? item.pollDuration.minutes + "m left"
                                    : "Poll closed."}
                                </label>
                              </Col>
                            </Row>
                            <Row
                              className={
                                item.reposts_media.length > 0
                                  ? "mt-1"
                                  : "d-none"
                              }
                            >
                              {item.reposts_media.map((img, index) => (
                                <Col
                                  className={
                                    item.reposts_media.length == 1
                                      ? "col-12"
                                      : item.reposts_media.length == 2
                                      ? "col-6 paddingSides" + index
                                      : item.reposts_media.length == 3
                                      ? "col-4 threePaddingSides" + index
                                      : item.reposts_media.length == 4
                                      ? "col-6 fourPaddingSides" + index
                                      : "col-6"
                                  }
                                  key={index}
                                >
                                  <div
                                    // onClick={() =>
                                    //   getPhotos(
                                    //     item.authorUsername.replace("@", ""),
                                    //     img.original_post_id,
                                    //     img.id,
                                    //     item.reposts_media,
                                    //     index
                                    //   )
                                    // }
                                    className={
                                      item.reposts_media.length == 2
                                        ? "mb-0 border-radius" + index
                                        : item.reposts_media.length == 3
                                        ? "borderRadiusImg" + index
                                        : item.reposts_media.length == 4
                                        ? "borderRadiusImgFour" + index
                                        : "mb-0 border-radius-5"
                                    }
                                    style={{
                                      backgroundImage:
                                        "url(" +
                                        process.env.REACT_APP_URL.replace(
                                          "api",
                                          ""
                                        ) +
                                        "public/timeline/media/" +
                                        item.authorUsername.replace("@", "") +
                                        "/" +
                                        img.file +
                                        ")",
                                      backgroundSize: "cover",
                                      height:
                                        item.reposts_media.length == 1
                                          ? 300
                                          : item.reposts_media.length == 2
                                          ? 300
                                          : item.reposts_media.length == 4
                                          ? 150
                                          : 200,
                                      width: "100%",
                                      border: "thin solid #ececec",

                                      // backgroundRepeat: "no-repeat",
                                      backgroundPosition: "center",
                                    }}
                                  />
                                </Col>
                              ))}
                            </Row>
                            {item.quotedData.length == 0 ? (
                              ""
                            ) : (
                              <Row
                                className={
                                  item.quotedData.linked_quote_post_id ==
                                  item.posts.id
                                    ? ""
                                    : "d-none"
                                }
                              >
                                <Col lg={12}>
                                  <div className="quote-post mt-1 whiteSpace">
                                    <Row>
                                      <Col lg={2}>
                                        <Link
                                          to={
                                            "/" +
                                            item.quotedData.quoted_username.replace(
                                              "@",
                                              ""
                                            )
                                          }
                                          className={
                                            "color-black textUnderlineNone cursorPointer"
                                          }
                                        >
                                          {item.quotedData.quoted_profile_pic ==
                                          null ? (
                                            <div
                                              className={"profile-pic"}
                                              style={{
                                                backgroundImage:
                                                  "url(" +
                                                  process.env.REACT_APP_URL.replace(
                                                    "api",
                                                    ""
                                                  ) +
                                                  "placeholder.jpeg",
                                              }}
                                            />
                                          ) : (
                                            <div
                                              className={"profile-pic"}
                                              style={{
                                                backgroundImage:
                                                  "url(" +
                                                  process.env.REACT_APP_URL.replace(
                                                    "api",
                                                    ""
                                                  ) +
                                                  "public/profile/picture/" +
                                                  item.quotedData
                                                    .quoted_username +
                                                  "/" +
                                                  item.quotedData
                                                    .quoted_profile_pic +
                                                  ")",
                                              }}
                                            />
                                          )}
                                        </Link>
                                      </Col>
                                      <Col lg={10} className={"marginLeft-30"}>
                                        <Link
                                          to={
                                            "/" +
                                            item.quotedData.quoted_username.replace(
                                              "@",
                                              ""
                                            )
                                          }
                                          className={
                                            "color-black textUnderlineNone cursorPointer"
                                          }
                                        >
                                          <span className="fontSize14">
                                            {item.quotedData.quoted_fullname}
                                          </span>
                                          <br />
                                          <label className="footnote fontSize14">
                                            {item.quotedData.quoted_username}
                                          </label>
                                        </Link>
                                      </Col>
                                      <Col lg={12} className="mt-2">
                                        <div
                                          className={
                                            item.quotedData.quotedSubject ==
                                            null
                                              ? "d-none"
                                              : ""
                                          }
                                        >
                                          <Link
                                            to={
                                              item.quotedData.quotedTopic ==
                                              null
                                                ? "d-none"
                                                : "/topicPosts?clicked=" +
                                                  item.quotedData.quotedTopic +
                                                  "&from_post_id=" +
                                                  item.quotedData.quoted_post_id
                                            }
                                          >
                                            <label
                                              className={
                                                "topics fontSize14 cursorPointer textUnderlineNone underlineHover text-normal"
                                              }
                                            >
                                              {item.quotedData.quotedTopic ==
                                              null ? (
                                                ""
                                              ) : (
                                                <>
                                                  <IoBookOutline size={15} />{" "}
                                                  {item.quotedData.quotedTopic}
                                                </>
                                              )}
                                            </label>
                                          </Link>
                                        </div>

                                        <div className={"cursorPointer w-100"}>
                                          {item.quotedData.quoted_post ==
                                          null ? (
                                            ""
                                          ) : item.quotedData.quoted_post
                                              .length > 150 ? (
                                            <>
                                              {showMoreQuotedPostText == true
                                                ? item.quotedData.quoted_post
                                                : item.quotedData.quoted_post.slice(
                                                    0,
                                                    150
                                                  )}

                                              <span className={"text-normal"}>
                                                ...{" "}
                                                <label
                                                  className={
                                                    "color-blue cursorPointer"
                                                  }
                                                  onClick={showMoreQuotedPost}
                                                >
                                                  {showMoreQuotedPostText ==
                                                  true
                                                    ? " Show less"
                                                    : " Show more"}
                                                </label>
                                              </span>
                                            </>
                                          ) : (
                                            <label
                                              onClick={() =>
                                                replyPage(
                                                  item.quotedData.quoted_post,
                                                  item.quotedData
                                                    .quoted_fullname,
                                                  item.quotedData
                                                    .quoted_username,
                                                  item.quotedData.quoted_post_id
                                                )
                                              }
                                              className="cursorPointer"
                                            >
                                              {item.quotedData.quoted_post}
                                            </label>
                                          )}
                                        </div>
                                        <Row
                                          className={
                                            item.polls.length > 0
                                              ? "mt-1"
                                              : "d-none"
                                          }
                                        >
                                          <div
                                            className={
                                              item.did_i_vote == 1 ||
                                              item.pollDuration.seconds <= 0
                                                ? "col-12"
                                                : "d-none"
                                            }
                                          >
                                            {item.pollResults.map(
                                              (results, index) => (
                                                <div
                                                  className={
                                                    results.percentage == 0
                                                      ? "w-100 resulst-polls-container-no-votes"
                                                      : "w-100 resulst-polls-container"
                                                  }
                                                  key={index}
                                                >
                                                  <label
                                                    className={
                                                      results.percentage == 0
                                                        ? ""
                                                        : "polls-voted color-black"
                                                    }
                                                    style={{
                                                      width:
                                                        results.percentage +
                                                        "%",
                                                    }}
                                                  />
                                                  <label
                                                    className={"color-black"}
                                                  >
                                                    {results.options}{" "}
                                                    {results.who_i_voted_for ==
                                                    1 ? (
                                                      <IoCheckmarkCircleOutline
                                                        size={20}
                                                        color={"#66b266"}
                                                      />
                                                    ) : (
                                                      ""
                                                    )}
                                                  </label>
                                                  <label
                                                    className={
                                                      "float-right color-black"
                                                    }
                                                  >
                                                    {results.percentage}%
                                                  </label>
                                                </div>
                                              )
                                            )}
                                          </div>

                                          <div
                                            className={
                                              item.did_i_vote == 1 ||
                                              item.pollDuration.seconds <= 0
                                                ? "d-none"
                                                : "col-12"
                                            }
                                          >
                                            {item.polls.map((polls, index) => (
                                              <Col lg={12} key={index}>
                                                <div
                                                  className={
                                                    "col-12 polls fontBold"
                                                  }
                                                  onClick={() =>
                                                    vote(
                                                      polls.id,
                                                      polls.post_id
                                                    )
                                                  }
                                                >
                                                  {polls.option}
                                                </div>
                                              </Col>
                                            ))}
                                          </div>
                                          <Col lg={12}>
                                            <label className="footnote">
                                              <span className="text-bold">
                                                {item.votes}
                                              </span>{" "}
                                              {item.votes == 1
                                                ? "vote"
                                                : "votes"}{" "}
                                              -{" "}
                                              {item.pollDuration.minutes <= 0 &&
                                              item.pollDuration.days <= 0 &&
                                              item.pollDuration.hours <= 0 &&
                                              item.pollDuration.seconds <= 0
                                                ? "Poll closed."
                                                : item.pollDuration.days >= 1
                                                ? item.pollDuration.days +
                                                  "d left"
                                                : item.pollDuration.hours >=
                                                    1 &&
                                                  item.pollDuration.minutes >= 1
                                                ? item.pollDuration.hours +
                                                  "h and " +
                                                  item.pollDuration.minutes +
                                                  "m left"
                                                : item.pollDuration.hours >=
                                                    1 &&
                                                  item.pollDuration.minutes == 0
                                                ? item.pollDuration.hours +
                                                  "h left"
                                                : item.pollDuration.minutes >= 1
                                                ? item.pollDuration.minutes +
                                                  "m left"
                                                : "Poll closed."}
                                            </label>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                    <Row
                                      className={
                                        item.quotedData.quoted_documents
                                          .length > 0
                                          ? "mt-2 marginTop-15 paddingLeft-13 paddingRight-13"
                                          : "d-none"
                                      }
                                    >
                                      {item.quotedData.quoted_documents.map(
                                        (doc, index) => (
                                          <Col lg={12} key={index} className="">
                                            <a
                                              href={
                                                process.env.REACT_APP_URL.replace(
                                                  "api",
                                                  ""
                                                ) +
                                                "public/timeline/documents/" +
                                                item.quotedData
                                                  .quoted_username +
                                                "/" +
                                                doc.file
                                              }
                                              target={"_blank"}
                                              className={"textUnderlineNone"}
                                            >
                                              <Row
                                                className={
                                                  "align-items-center document-link-timeline"
                                                }
                                              >
                                                <Col
                                                  lg={12}
                                                  key={index}
                                                  className={"p-0"}
                                                >
                                                  <div className={""}>
                                                    {doc.format == "pdf" ? (
                                                      <img
                                                        src={
                                                          process.env.REACT_APP_URL.replace(
                                                            "api",
                                                            ""
                                                          ) + "/pdf_icons.png"
                                                        }
                                                        style={{
                                                          width: 60,
                                                        }}
                                                      />
                                                    ) : doc.format == "docx" ? (
                                                      <label className="p-1">
                                                        <img
                                                          src={
                                                            process.env.REACT_APP_URL.replace(
                                                              "api",
                                                              ""
                                                            ) +
                                                            "/word_icons.png"
                                                          }
                                                          style={{
                                                            width: 40,
                                                          }}
                                                        />
                                                      </label>
                                                    ) : doc.format == "csv" ? (
                                                      <label className="p-1">
                                                        <img
                                                          src={
                                                            process.env.REACT_APP_URL.replace(
                                                              "api",
                                                              ""
                                                            ) + "/csv_icons.png"
                                                          }
                                                          style={{
                                                            width: 40,
                                                          }}
                                                        />
                                                      </label>
                                                    ) : doc.format == "xlsx" ? (
                                                      <label className="p-1">
                                                        <img
                                                          src={
                                                            process.env.REACT_APP_URL.replace(
                                                              "api",
                                                              ""
                                                            ) +
                                                            "/xlsx_icons.png"
                                                          }
                                                          style={{
                                                            width: 40,
                                                          }}
                                                        />
                                                      </label>
                                                    ) : doc.format == "pptx" ? (
                                                      <label className="p-1">
                                                        <img
                                                          src={
                                                            process.env.REACT_APP_URL.replace(
                                                              "api",
                                                              ""
                                                            ) +
                                                            "/pptx_icons.png"
                                                          }
                                                          style={{
                                                            width: 40,
                                                          }}
                                                        />
                                                      </label>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {doc.title == ""
                                                      ? doc.file
                                                      : doc.title +
                                                        "." +
                                                        doc.format}
                                                  </div>
                                                </Col>
                                              </Row>
                                            </a>
                                          </Col>
                                        )
                                      )}
                                    </Row>
                                    <Row
                                      className={
                                        item.quotedData.quoted_media.length > 0
                                          ? "mt-1"
                                          : "d-none"
                                      }
                                    >
                                      {item.quotedData.quoted_media.map(
                                        (img, index) => (
                                          <Col
                                            className={
                                              item.quotedData.quoted_media
                                                .length == 1
                                                ? "col-12"
                                                : item.quotedData.quoted_media
                                                    .length == 2
                                                ? "col-6 paddingSides" + index
                                                : item.quotedData.quoted_media
                                                    .length == 3
                                                ? "col-4 threePaddingSides" +
                                                  index
                                                : item.quotedData.quoted_media
                                                    .length == 4
                                                ? "col-6 fourPaddingSides" +
                                                  index
                                                : "col-6"
                                            }
                                          >
                                            <div
                                              // onClick={() =>
                                              //   getPhotos(
                                              //     item.quotedData.quoted_username.replace(
                                              //       "@",
                                              //       ""
                                              //     ),
                                              //     item.quotedData.quoted_post_id,
                                              //     img.id,
                                              //     item.quotedData.quoted_media,
                                              //     index
                                              //   )
                                              // }
                                              className={
                                                item.quotedData.quoted_media
                                                  .length == 2
                                                  ? "mb-0 border-radius" + index
                                                  : item.quotedData.quoted_media
                                                      .length == 3
                                                  ? "borderRadiusImg" + index
                                                  : item.quotedData.quoted_media
                                                      .length == 4
                                                  ? "borderRadiusImgFour" +
                                                    index
                                                  : "mb-0 border-radius-5"
                                              }
                                              style={{
                                                backgroundImage:
                                                  "url(" +
                                                  process.env.REACT_APP_URL.replace(
                                                    "api",
                                                    ""
                                                  ) +
                                                  "public/timeline/media/" +
                                                  item.quotedData.quoted_username.replace(
                                                    "@",
                                                    ""
                                                  ) +
                                                  "/" +
                                                  img.file +
                                                  ")",
                                                backgroundSize: "cover",
                                                height:
                                                  item.quotedData.quoted_media
                                                    .length == 1
                                                    ? 300
                                                    : item.quotedData
                                                        .quoted_media.length ==
                                                      2
                                                    ? 300
                                                    : item.quotedData
                                                        .quoted_media.length ==
                                                      4
                                                    ? 150
                                                    : 200,
                                                width: "100%",
                                                border: "thin solid #ececec",

                                                // backgroundRepeat: "no-repeat",
                                                backgroundPosition: "center",
                                              }}
                                            />
                                          </Col>
                                        )
                                      )}
                                    </Row>
                                  </div>
                                </Col>
                              </Row>
                            )}

                            <Col lg={12} className="mt-0 p-0">
                              <Row className="mt-2">
                                <Col>
                                  <label className={"footnote fontSize14"}>
                                    {item.posts.created_at}
                                  </label>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={3} className={"p-0"}>
                                  <label
                                    className="action-likes-timeline text-center mb-1 mt-1"
                                    onClick={() =>
                                      addLike(item.posts.id, "home_page")
                                    }
                                    data-tip={"Like"}
                                    data-place={"bottom"}
                                    data-effect={"solid"}
                                  >
                                    {item.liked_by_me == true ? (
                                      <label className="fontSize14 action-likes-true cursorPointer">
                                        <BsHandThumbsUpFill size={20} />{" "}
                                        {item.like_count}
                                      </label>
                                    ) : (
                                      <label className="fontSize14 cursorPointer">
                                        <BsHandThumbsUp size={20} />{" "}
                                        {item.like_count}
                                      </label>
                                    )}
                                    &nbsp;
                                    <label
                                      className={
                                        photoPreview
                                          ? "d-none"
                                          : "light-bold hover fontSize14"
                                      }
                                    >
                                      <span
                                        className={
                                          item.liked_by_me == true
                                            ? "action-likes-true light-bold"
                                            : "light-bold"
                                        }
                                      >
                                        Like
                                      </span>
                                    </label>
                                    <ReactTooltip
                                      arrowColor={"transparent"}
                                      className="p-1 fontSize-12"
                                    />
                                  </label>
                                </Col>
                                <Col lg={3} className={"p-0"}>
                                  <label
                                    className="action-replies-timeline text-center mb-1 mt-1"
                                    onClick={() =>
                                      reply(
                                        item.posts.post,
                                        item.user.first_name +
                                          " " +
                                          item.user.last_name,
                                        item.user.username,
                                        item.posts.id,
                                        item.user.profile_pic,
                                        item.polls,
                                        item.did_i_vote,
                                        {
                                          days: item.pollDuration.days,
                                          hours: item.pollDuration.hours,
                                          minutes: item.pollDuration.minutes,
                                          seconds: item.pollDuration.seconds,
                                        },
                                        item.pollResults,
                                        item.votes,
                                        item.media,
                                        item.documents,
                                        item.subjects != null
                                          ? item.subjects.subject
                                          : null,
                                        item.topics != null
                                          ? item.topics.topic
                                          : null,
                                        item.current_post_polls,
                                        "reply",
                                        "home_page"
                                      )
                                    }
                                    data-tip={"Reply"}
                                    data-place={"bottom"}
                                    data-effect={"solid"}
                                  >
                                    {item.did_reply_to_post == true ? (
                                      <label
                                        className={
                                          item.did_reply_to_post == true
                                            ? "action-replies-true cursorPointer fontSize14"
                                            : "cursorPointer fontSize14"
                                        }
                                      >
                                        <IoChatbox size={20} />{" "}
                                        {item.reply_count}
                                      </label>
                                    ) : (
                                      <label
                                        className={"cursorPointer fontSize14"}
                                      >
                                        <IoChatboxOutline size={20} />{" "}
                                        {item.reply_count}
                                      </label>
                                    )}
                                    &nbsp;
                                    {photoPreview ? (
                                      ""
                                    ) : (
                                      <label
                                        className={
                                          item.did_reply_to_post == true
                                            ? "action-replies-true light-bold hover fontSize14"
                                            : "light-bold hover fontSize14"
                                        }
                                      >
                                        Reply
                                      </label>
                                    )}
                                    <ReactTooltip
                                      arrowColor={"transparent"}
                                      className="p-1 fontSize-12"
                                    />
                                  </label>
                                </Col>

                                <Col lg={3} className={"p-0"}>
                                  <label
                                    className="action-bookmarks-timeline text-center mb-1 mt-1"
                                    onClick={() =>
                                      Bookmark(item.posts.id, "home_page")
                                    }
                                    data-tip={"Bookmark"}
                                    data-place={"bottom"}
                                    data-effect={"solid"}
                                  >
                                    {item.bookmarked_by_me == true ? (
                                      <span
                                        className={
                                          "fontSize14 cursorPointer action-bookmarks-true"
                                        }
                                      >
                                        <BsFillBookmarkFill size={18} />{" "}
                                        {item.bookmark_count}
                                      </span>
                                    ) : (
                                      <span className="cursorPointer fontSize14">
                                        <BsBookmarkPlus size={18} />{" "}
                                        {item.bookmark_count}
                                      </span>
                                    )}
                                    &nbsp;
                                    {photoPreview ? (
                                      ""
                                    ) : (
                                      <span
                                        className={
                                          item.bookmarked_by_me == true
                                            ? "action-bookmarks-true light-bold hover fontSize14"
                                            : "light-bold hover fontSize14"
                                        }
                                      >
                                        Bookmark
                                      </span>
                                    )}
                                    <ReactTooltip
                                      arrowColor={"transparent"}
                                      className="p-1 fontSize-12"
                                    />
                                  </label>
                                </Col>

                                <Col lg={3} className={"p-0"}>
                                  <label className=" w-100 text-center mb-1 mt-0 color-light-grey pt-2 pb-2">
                                    {/* <label className="light-bold">Repost</label> */}
                                    <Dropdown
                                      style={{
                                        marginTop: -5,
                                      }}
                                      data-tip={"More"}
                                      data-place={"bottom"}
                                      data-effect={"solid"}
                                    >
                                      <Dropdown.Toggle
                                        className={
                                          item.did_repost_to_post == true
                                            ? "icons-header-true icons-header"
                                            : "action-menu-timeline" +
                                              " light-bold color-light-grey fontSize14"
                                        }
                                        style={{
                                          borderRadius: 10,
                                          border: "hidden",
                                          background: "#fff",
                                          paddingBottom: 8,
                                          boxShadow: "none",
                                          backgroundColor: "transparent",
                                        }}
                                      >
                                        {/* <IoShareOutline size={19} /> */}
                                        <IoGridOutline size={20} />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          onClick={() =>
                                            reply(
                                              item.posts.post,
                                              item.user.first_name +
                                                " " +
                                                item.user.last_name,
                                              item.user.username,
                                              item.posts.id,
                                              item.user.profile_pic,
                                              item.polls,
                                              item.did_i_vote,
                                              {
                                                days: item.pollDuration.days,
                                                hours: item.pollDuration.hours,
                                                minutes:
                                                  item.pollDuration.minutes,
                                                seconds:
                                                  item.pollDuration.seconds,
                                              },
                                              item.pollResults,
                                              item.votes,
                                              item.media,
                                              item.documents,
                                              item.subjects != null
                                                ? item.subjects.subject
                                                : null,
                                              item.topics != null
                                                ? item.topics.topic
                                                : null,
                                              item.current_post_polls,
                                              "repost",
                                              "home_page"
                                            )
                                          }
                                          className={
                                            item.post_ownership == true
                                              ? "d-none"
                                              : "hover drop-toggle-padding fontMedium"
                                          }
                                        >
                                          <BiShare size={16} />
                                          &nbsp;&nbsp;&nbsp;Repost
                                          {item.did_repost_to_post == true ? (
                                            <span className="float-right">
                                              <IoEllipse
                                                size={10}
                                                className="color-blue"
                                              />
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          onClick={() =>
                                            reply(
                                              item.posts.post,
                                              item.user.first_name +
                                                " " +
                                                item.user.last_name,
                                              item.user.username,
                                              item.posts.id,
                                              item.user.profile_pic,
                                              item.polls,
                                              item.did_i_vote,
                                              {
                                                days: item.pollDuration.days,
                                                hours: item.pollDuration.hours,
                                                minutes:
                                                  item.pollDuration.minutes,
                                                seconds:
                                                  item.pollDuration.seconds,
                                              },
                                              item.pollResults,
                                              item.votes,
                                              item.media,
                                              item.documents,
                                              item.subjects != null
                                                ? item.subjects.subject
                                                : null,
                                              item.topics != null
                                                ? item.topics.topic
                                                : null,
                                              item.current_post_polls,
                                              "quote",
                                              "home_page"
                                            )
                                          }
                                          className="drop-toggle-padding fontMedium"
                                        >
                                          <BsPencil size={16} />
                                          &nbsp;&nbsp;&nbsp;Quote post
                                          {item.did_quote_post == true ? (
                                            <span className="float-right">
                                              <IoEllipse
                                                size={10}
                                                className="color-blue"
                                              />
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          onClick={() =>
                                            dm(
                                              item.user.username,

                                              item.posts.post
                                            )
                                          }
                                          className={
                                            item.user.username ==
                                            userName.replace("@", "")
                                              ? "d-none"
                                              : "drop-toggle-padding fontMedium"
                                          }
                                        >
                                          <BsChatRightDots size={16} />
                                          &nbsp;&nbsp;&nbsp;Send direct message
                                        </Dropdown.Item>

                                        <Dropdown.Item
                                          onClick={() => follow(item.user.id)}
                                          className={
                                            item.post_ownership == true
                                              ? "d-none"
                                              : "drop-toggle-padding fontMedium"
                                          }
                                        >
                                          {item.do_i_follow_this_user ? (
                                            <>
                                              <BiUserMinus size={16} />
                                              &nbsp;&nbsp;&nbsp;Unfollow @
                                              {item.user.username}
                                            </>
                                          ) : (
                                            <>
                                              <BiUserPlus size={16} />
                                              &nbsp;&nbsp;&nbsp;Follow @
                                              {item.user.username}
                                            </>
                                          )}
                                        </Dropdown.Item>

                                        <Dropdown.Item
                                          onClick={() => block(item.user.id)}
                                          className={
                                            item.post_ownership == true
                                              ? "d-none"
                                              : item.did_i_block_this_user ==
                                                true
                                              ? "color-red drop-toggle-padding fontMedium"
                                              : "drop-toggle-padding fontMedium"
                                          }
                                        >
                                          <IoBanOutline size={16} />
                                          &nbsp;&nbsp;&nbsp;
                                          {item.did_i_block_this_user == true
                                            ? "Unblock"
                                            : "Block"}{" "}
                                          @{item.user.username}
                                        </Dropdown.Item>

                                        <Dropdown.Item
                                          onClick={() =>
                                            muteReply(item.posts.id)
                                          }
                                          className={
                                            item.post_ownership == true
                                              ? "d-none"
                                              : "drop-toggle-padding fontMedium"
                                          }
                                        >
                                          <BiSad size={16} />
                                          &nbsp;&nbsp;&nbsp;I don't want to see
                                          this post
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          onClick={() => follow(item.user.id)}
                                          className={
                                            item.post_ownership == true
                                              ? "d-none"
                                              : "drop-toggle-padding d-none fontMedium"
                                          }
                                        >
                                          <BiFlag size={16} />
                                          &nbsp;&nbsp;&nbsp;Report post
                                        </Dropdown.Item>

                                        <Dropdown.Item
                                          onClick={() =>
                                            deleteReply(item.posts.id)
                                          }
                                          className={
                                            item.post_ownership == true
                                              ? "drop-toggle-padding fontMedium"
                                              : "d-none"
                                          }
                                        >
                                          <HiOutlineTrash size={16} />
                                          &nbsp;&nbsp;&nbsp;Delete post
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                      <ReactTooltip
                                        arrowColor={"transparent"}
                                        className="p-1 fontSize-12"
                                      />
                                    </Dropdown>
                                  </label>
                                </Col>
                              </Row>
                            </Col>
                          </Col>
                        </Row>
                      </Row>
                    </Row>
                  </div>
                ))}
              </>
            )}
          </>
        )}
      </Col>
      <Modal
        show={showReply}
        onHide={handleClose}
        backdropClassName="backdrop-login"
        centered
        className={"modal_bg_color_white"}
      >
        <Modal.Body>
          <Container className="p-1 pt-0">
            <form onSubmit={addReply}>
              <Row className="align-items-center">
                <Col lg={12} className="whiteSpace mb-2">
                  <textarea
                    className={
                      action == "repost"
                        ? "d-none"
                        : textearTextSize + " textarea-share-post"
                    }
                    placeholder={
                      action == "quote"
                        ? "Say something..."
                        : pollQuestionPlaceholderReply
                    }
                    value={replies}
                    onChange={replyTextarea}
                    onClick={modalFunc}
                    ref={inputElement}
                  />
                  <PreviewMedia
                    preview={preview}
                    clearAttachment={clearAttachment}
                    className={preview.length > 0 ? "" : "d-none"}
                  />
                  <PreviewDocuments
                    previewDoc={previewDoc}
                    clearAttachment={clearAttachment}
                    docTitle={docTitle}
                    tags={tags}
                    previewDocURL={previewDocURL}
                    className={previewDoc.length > 0 ? "" : "d-none"}
                  />
                  <div className={openPoll == false ? "d-none" : "mb-3"}>
                    <Polls
                      openPoll={openPoll}
                      optionOne={optionOne}
                      optionTwo={optionTwo}
                      optionThree={optionThree}
                      optionFour={optionFour}
                      optionCount={optionCount}
                      removePollOption={removePollOption}
                      setOptionOne={setOptionOne}
                      setOptionTwo={setOptionTwo}
                      setOptionThree={setOptionThree}
                      setOptionFour={setOptionFour}
                      settingHours={settingHours}
                      settingDays={settingDays}
                      hours={hrs}
                      days={dys}
                      settingMinutes={settingMinutes}
                      minutes={mins}
                      addOption={addOption}
                      closePoll={closePoll}
                      opMinHide={opMinHide}
                    />
                  </div>
                  {hideEmoji && (
                    <div className="mb-3 mt-0 col-12">
                      {/* <span
                        className="text-normal float-right share-icons"
                        data-tip={"Close"}
                        data-place={"top"}
                        data-effect={"solid"}
                        onClick={emojies}
                      >
                        <BsXLg size={13} />
                        <ReactTooltip arrowColor={"transparent"} />
                      </span> */}
                      <Emojies handleClick={onEmojiClick} action={"post"} />
                    </div>
                  )}
                  <Col
                    lg={12}
                    className={action == "repost" ? "d-none" : "mb-3"}
                  >
                    <Elements
                      imageUpload={imageUpload}
                      documentUpload={documentUpload}
                      emojies={emojies}
                      chosenEmoji={chosenEmoji}
                      closePoll={closePoll}
                      subjectSelected={subjectSelected}
                      selectedSubject={selectedSubject}
                      selectedTopic={selectedTopic}
                      topicSelected={topicSelected}
                      showTopics={showTopics}
                      showSubjects={showSubjects}
                      resetFunc={resetFunc}
                      resetSubject={resetSubject}
                      currentPostPolls={currentPostPolls}
                      action={action}
                    />
                  </Col>
                  <div className="w-100">
                    <Row className={"align-items-center"}>
                      <Col lg={2}>
                        {replyPic == null ? (
                          <div
                            className={"profile-pic"}
                            style={{
                              backgroundImage:
                                "url(" +
                                process.env.REACT_APP_URL.replace("api", "") +
                                "placeholder.jpeg" +
                                ")",
                            }}
                          />
                        ) : (
                          <div
                            className={"profile-pic"}
                            style={{
                              backgroundImage:
                                "url(" +
                                process.env.REACT_APP_URL.replace("api", "") +
                                "public/profile/picture/@" +
                                replyUsername +
                                "/" +
                                replyPic +
                                ")",
                            }}
                          />
                        )}
                      </Col>
                      <Col lg={10} className={"p-0 marginLeft-13"}>
                        <span className="text-bold">{replyName}</span>
                        <br />
                        <label className="footnote">@{replyUsername}</label>
                      </Col>
                    </Row>

                    <div className={"mb-0 mt-2"}>
                      <Row className={singleTopic == null ? "d-none" : ""}>
                        <Col lg={12} className="pt-0">
                          <Link
                            to={
                              singleTopic == null
                                ? ""
                                : "/topicPosts?clicked=" +
                                  singleTopic +
                                  "&from_post_id=" +
                                  ReplyPostID
                            }
                          >
                            <label
                              className={
                                "topics fontSize14 cursorPointer textUnderlineNone underlineHover text-normal"
                              }
                            >
                              {singleTopic == null ? (
                                ""
                              ) : (
                                <>
                                  <IoBookOutline size={15} /> {singleTopic}
                                </>
                              )}
                            </label>
                          </Link>
                        </Col>
                      </Row>
                      {replyPost == null ? (
                        ""
                      ) : replyPost.length > 150 ? (
                        <>
                          {showMoreQuotedPostText == true ? (
                            <Tagify
                              onClick={(text, type) => hashtag(text, type)}
                              detectHashtags={true}
                              detectMentions={true}
                              tagStyle={tagStyle}
                              mentionStyle={mentionStyle}
                              detectLinks={true}
                            >
                              <Linkify
                                componentDecorator={(
                                  decoratedHref,
                                  decoratedText,
                                  key
                                ) => (
                                  <SecureLink
                                    target="_blank"
                                    href={decoratedHref}
                                    key={key}
                                    className={"color-blue textUnderlineNone"}
                                  >
                                    {decoratedText}
                                  </SecureLink>
                                )}
                              >
                                {replyPost}
                              </Linkify>
                            </Tagify>
                          ) : (
                            <Tagify
                              onClick={(text, type) => hashtag(text, type)}
                              detectHashtags={true}
                              detectMentions={true}
                              tagStyle={tagStyle}
                              mentionStyle={mentionStyle}
                              detectLinks={true}
                            >
                              <Linkify
                                componentDecorator={(
                                  decoratedHref,
                                  decoratedText,
                                  key
                                ) => (
                                  <SecureLink
                                    target="_blank"
                                    href={decoratedHref}
                                    key={key}
                                    className={"color-blue textUnderlineNone"}
                                  >
                                    {decoratedText}
                                  </SecureLink>
                                )}
                              >
                                {replyPost.slice(0, 150)}
                              </Linkify>
                            </Tagify>
                          )}
                          <span
                            className="color-blue cursorPointer text-normal"
                            onClick={showMoreQuotedPost}
                          >
                            {showMoreQuotedPostText == true ? (
                              <span className="text-normal"> Show less...</span>
                            ) : (
                              <span className="text-normal">
                                <span className="color-black text-normal">
                                  ...
                                </span>{" "}
                                Show more
                              </span>
                            )}
                          </span>
                        </>
                      ) : (
                        <div className={"w-100"}>
                          {" "}
                          <Tagify
                            onClick={(text, type) => hashtag(text, type)}
                            detectHashtags={true}
                            detectMentions={true}
                            tagStyle={tagStyle}
                            mentionStyle={mentionStyle}
                            detectLinks={true}
                          >
                            <Linkify
                              componentDecorator={(
                                decoratedHref,
                                decoratedText,
                                key
                              ) => (
                                <SecureLink
                                  target="_blank"
                                  href={decoratedHref}
                                  key={key}
                                  className={"color-blue textUnderlineNone"}
                                >
                                  {decoratedText}
                                </SecureLink>
                              )}
                            >
                              {replyPost}
                            </Linkify>
                          </Tagify>
                        </div>
                      )}
                      <div>
                        <div
                          className={currentPostPolls == false ? "d-none" : ""}
                        >
                          <Row className={polls.length == 0 ? "d-none" : ""}>
                            <div
                              className={
                                didIvote == 1 || pollDuration.seconds <= 0
                                  ? "col-12 mt-1"
                                  : "d-none"
                              }
                            >
                              {pollResults.map((results, index) => (
                                <div
                                  className={
                                    results.percentage == 0
                                      ? "w-100 resulst-polls-container-no-votes"
                                      : "w-100 resulst-polls-container"
                                  }
                                  key={index}
                                >
                                  <label
                                    className={
                                      results.percentage == 0
                                        ? ""
                                        : "polls-voted color-black"
                                    }
                                    style={{
                                      width: results.percentage + "%",
                                    }}
                                  />
                                  <label className={"color-black"}>
                                    {results.options}{" "}
                                    {results.who_i_voted_for == 1 ? (
                                      <IoCheckmarkCircleOutline
                                        size={20}
                                        color={"#66b266"}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </label>
                                  <label className={"float-right color-black"}>
                                    {results.percentage}%
                                  </label>
                                </div>
                              ))}
                            </div>
                            <div
                              className={
                                polls.length > 0 &&
                                didIvote == 0 &&
                                pollDuration.seconds > 0
                                  ? "mt-1"
                                  : "d-none"
                              }
                            >
                              {polls.map((polls, index) => (
                                <Col lg={12} key={index}>
                                  <div
                                    className={"col-12 polls-repost fontBold"}
                                  >
                                    {polls.option}
                                  </div>
                                </Col>
                              ))}
                            </div>
                            <label className={"footnote"}>
                              {pollVotes} {pollVotes == 1 ? "vote" : "votes"} -{" "}
                              {pollDuration.minutes <= 0 &&
                              pollDuration.days <= 0 &&
                              pollDuration.hours <= 0 &&
                              pollDuration.seconds <= 0
                                ? "Poll closed."
                                : pollDuration.days >= 1
                                ? pollDuration.days + "d left"
                                : pollDuration.hours >= 1 &&
                                  pollDuration.minutes >= 1
                                ? pollDuration.hours +
                                  "h and " +
                                  pollDuration.minutes +
                                  "m left"
                                : pollDuration.hours >= 1 &&
                                  pollDuration.minutes == 0
                                ? pollDuration.hours + "h left"
                                : pollDuration.minutes >= 1
                                ? pollDuration.minutes + "m left"
                                : "Poll closed."}
                            </label>
                          </Row>
                        </div>
                        <Row
                          className={
                            documentsReposting.length > 0
                              ? "mt-0 paddingLeft10 paddingRight10"
                              : "d-none"
                          }
                        >
                          {documentsReposting.map((doc, index) => (
                            <Col lg={12} key={index}>
                              <a
                                href={
                                  process.env.REACT_APP_URL.replace("api", "") +
                                  "public/timeline/documents/" +
                                  replyUsername +
                                  "/" +
                                  doc.file
                                }
                                target={"_blank"}
                                className={"textUnderlineNone"}
                              >
                                <Row
                                  className={
                                    "align-items-center document-link-timeline"
                                  }
                                >
                                  <Col lg={12} key={index} className={"p-0"}>
                                    <div className={""}>
                                      {doc.format == "pdf" ? (
                                        <label className="p-1">
                                          <FaRegFilePdf
                                            size={20}
                                            className={"color-red"}
                                          />
                                        </label>
                                      ) : doc.format == "docx" ? (
                                        <label className="p-1">
                                          <FaRegFileWord
                                            size={20}
                                            className={"color-blue"}
                                          />
                                        </label>
                                      ) : doc.format == "csv" ? (
                                        <label className="p-1">
                                          <FaFileCsv
                                            size={20}
                                            className={"color-green"}
                                          />
                                        </label>
                                      ) : doc.format == "xlsx" ? (
                                        <label className="p-1">
                                          <FaRegFileExcel
                                            size={20}
                                            className={"color-green"}
                                          />
                                        </label>
                                      ) : doc.format == "pptx" ? (
                                        <label className="p-1">
                                          <FaRegFilePowerpoint
                                            size={20}
                                            className={"color-orange"}
                                          />
                                        </label>
                                      ) : (
                                        ""
                                      )}
                                      {doc.title == ""
                                        ? doc.file
                                        : doc.title + "." + doc.format}
                                    </div>
                                  </Col>
                                </Row>
                              </a>
                            </Col>
                          ))}
                        </Row>
                        <Row
                          className={
                            mediaReposting.length > 0 ? "mt-1" : "d-none"
                          }
                        >
                          {mediaReposting.map((img, index) => (
                            <Col
                              className={
                                mediaReposting.length == 1
                                  ? "col-12"
                                  : mediaReposting.length == 2
                                  ? "col-6 paddingSides" + index
                                  : mediaReposting.length == 3
                                  ? "col-4 threePaddingSides" + index
                                  : mediaReposting.length == 4
                                  ? "col-6 fourPaddingSides" + index
                                  : "col-6"
                              }
                              key={index}
                            >
                              <div
                                // onClick={() =>
                                //   getPhotos(
                                //     replyUsername,
                                //     mediaReposting.post_id,
                                //     img.id,
                                //     mediaReposting,
                                //     index
                                //   )
                                // }
                                className={
                                  mediaReposting.length == 2
                                    ? "mb-3 border-radius" + index
                                    : mediaReposting.length == 3
                                    ? "borderRadiusImg" + index
                                    : mediaReposting.length == 4
                                    ? "borderRadiusImgFour" + index
                                    : "mb-3 border-radius-5"
                                }
                                style={{
                                  backgroundImage:
                                    "url(" +
                                    process.env.REACT_APP_URL.replace(
                                      "api",
                                      ""
                                    ) +
                                    "public/timeline/media/" +
                                    replyUsername +
                                    "/" +
                                    img.file +
                                    ")",
                                  backgroundSize: "cover",
                                  height:
                                    mediaReposting.length == 1
                                      ? 300
                                      : mediaReposting.length == 2
                                      ? 300
                                      : mediaReposting.length == 4
                                      ? 150
                                      : 200,
                                  width: "100%",
                                  border: "thin solid #ececec",

                                  // backgroundRepeat: "no-repeat",
                                  backgroundPosition: "center",
                                }}
                              />
                            </Col>
                          ))}
                        </Row>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col lg={12} xs={12} className="mb-0">
                  {action == "repost" ? (
                    <label className="footnote mb-2">
                      <span className="text-bold">NB:</span> Your followers will
                      see this.
                    </label>
                  ) : action == "quote" ? (
                    ""
                  ) : (
                    ""
                  )}

                  {loadPostbtn && (
                    <Button
                      className={
                        action == "repost" || action == "quote"
                          ? "login-button mb-3 rounded-pill"
                          : postButton + " mb-3 text-center rounded-pill"
                      }
                      type="submit"
                      disabled={
                        action == "repost" || action == "quote"
                          ? ""
                          : disableBtn
                      }
                    >
                      {action == "reply"
                        ? "Reply"
                        : action == "quote"
                        ? "Quote"
                        : "Repost"}
                    </Button>
                  )}

                  {spinner && (
                    <Button
                      type="button"
                      disabled={true}
                      className="login-button text-center mb-3 rounded-pill"
                    >
                      <BeatLoader color={"#fff"} />
                    </Button>
                  )}

                  <label
                    className="join-now-button text-center rounded-pill"
                    onClick={handleClose}
                  >
                    Cancel
                  </label>
                </Col>
              </Row>
            </form>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Replies;
