import React, { useState, useEffect, useRef } from "react";
import { Col, Container, Row, Stack, Modal, Button } from "react-bootstrap";
import {
  Link,
  NavLink,
  useParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import Header from "./navigation/HeaderHome";
import SideNavProfile from "./navigation/SideNavProfile";
import SideNav from "./navigation/SideNavHome";
import TopNav from "./navigation/TopNavProfile";
import RightNav from "./navigation/SidNavHomeRight";

import profile from "../assets/profileplaceholder.jpg";
import { IoMdImages, IoIosMic, IoIosLink } from "react-icons/io";
import { BsEmojiSmile, BsPerson } from "react-icons/bs";
import { FaPoll } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import Picker from "emoji-picker-react";
import BeatLoader from "react-spinners/BeatLoader";
import axios from "axios";
import TopBarProgress from "react-topbar-progress-indicator";
import { BsHouseDoor, BsBookmarks, BsChatDots, BsPeople } from "react-icons/bs";
import {
  IoPeopleCircleOutline,
  IoSettingsOutline,
  IoInformationCircleOutline,
  IoAnalytics,
  IoEnterOutline,
  IoPersonCircleOutline,
  IoLocationOutline,
  IoCalendarOutline,
  IoManOutline,
} from "react-icons/io5";

import { BiRun } from "react-icons/bi";

import Posts from "../components/profileContent/Posts";
import PageNotFound from "./features/PageNotFound";
import HeaderToken from "./HeaderToken";
import { ClipLoader } from "react-spinners";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Footer from "./navigation/Footer";

const headers = {
  Authorization: `${HeaderToken.Authorization}`,
  Accept: `${HeaderToken.Accept}`,
};
export default function Profile(props) {
  const locate = useLocation();
  const info = locate.state;
  // const history = useHistory();
  const initialValue = ["hellow", "fdfdfd", "dfdffd"];
  const [getpost, setGetposts] = useState(initialValue);
  const [dateJoined, setDateJoined] = useState(null);
  const [fullname, setFullname] = useState(
    localStorage.getItem("first_name") + " " + localStorage.getItem("last_name")
  );
  const [fname, setFname] = useState(null);
  const [lname, setLname] = useState(null);
  const [website, setWebsite] = useState("");
  const [bio, setBio] = useState("");
  const [location, setLocation] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [userID, setUserID] = useState(0);
  const [error, setError] = useState(0);
  const baseURL = process.env.REACT_APP_URL;
  const { username } = useParams();

  // console.log(userID);
  useEffect(() => {
    // axios
    //   .get(baseURL + "/userid/get/" + username, { headers })
    //   .then((res) => {
    //     setUserID(res.data.userID);
    //     setError(0);
    //   })
    //   .catch((error) => {
    //     setUserID(0);
    //     setError(1);
    //   });
  }, [window.location.href]);
  // document.title =
  //   process.env.REACT_APP_YOUR_VARIABLE_NAME_HERE + " | " + fullname;

  const [progressBar, setProgressBar] = useState(false);

  const userIDQuery = useQuery({
    queryKey: ["userID", window.location.href],
    queryFn: async () => {
      const response = await axios.get(`${baseURL}/userid/get/${username}`, {
        headers,
      });
      const data = response.data;
      return data;
    },
  });

  return (
    <div className="body-login">
      <Header />
      {progressBar && <TopBarProgress />}
      <Container className="mb-4">
        <Row className="forced-margin justify-content-center">
          {/* <SideNav /> */}
          {!userIDQuery.isLoading ? (
            userIDQuery.data.userID == 0 ? (
              ""
            ) : (
              <RightNav />
            )
          ) : (
            ""
          )}
          <Col lg={6} className="p-0">
            {error == true ? (
              <div className="login-elements-container border-hidde text-center">
                <PageNotFound />
              </div>
            ) : (
              <>
                <TopNav />
                {!userIDQuery.isLoading ? (
                  <div className="login-elements-containe border-hidde p-0 border-top-hidden">
                    {userIDQuery.data.userID == 0 ? (
                      ""
                    ) : (
                      <Posts
                        userID={userIDQuery.data.userID}
                        username={username}
                        tag={"profile"}
                      />
                    )}
                  </div>
                ) : (
                  <div className="text-center mb-5 mt-5">
                    <ClipLoader size={25} color={"#333333"} />
                  </div>
                )}
              </>
            )}
          </Col>

          {/* <SideNavProfile /> */}
          {/* <RightNav /> */}
        </Row>
      </Container>
      <Footer
        postSentFeedback={info ? info.message : null}
        postSent={info ? info.success : null}
        postType={"compose"}
      />
    </div>
  );
}

TopBarProgress.config({
  barColors: {
    0: "rgb(29, 155, 240)",
    "1.0": "rgb(29, 155, 240)",
  },
  shadowBlur: 0,
});
