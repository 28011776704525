import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  Col,
  Container,
  Row,
  Stack,
  Modal,
  Button,
  Dropdown,
} from "react-bootstrap";
import { Tagify } from "react-tagify";
import Linkify from "react-linkify";
import { SecureLink } from "react-secure-link";
import PageNotFound from "./features/PageNotFound";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Header from "./navigation/HeaderHome";
import profile from "../assets/profileplaceholder.jpg";
import Emojies from "../components/features/Emojies";
import Polls from "../components/features/Polls";
import Elements from "../components/features/Elements";
import PreviewMedia from "./previews/PreviewMedia";
import {
  IoMdImages,
  IoIosMic,
  IoIosHeartEmpty,
  IoIosHeart,
  IoIosArrowRoundBack,
} from "react-icons/io";
import {
  BsEmojiSmile,
  BsChatRightDots,
  BsBookmarkPlus,
  BsFillBookmarkFill,
  BsBookmark,
  BsPencil,
  BsXLg,
  BsHandThumbsUpFill,
  BsHandThumbsUp,
} from "react-icons/bs";
import {
  BiUserMinus,
  BiSad,
  BiFlag,
  BiUserPlus,
  BiSolidShare,
  BiShare,
} from "react-icons/bi";
import { HiOutlineRefresh, HiOutlineTrash, HiCheck } from "react-icons/hi";
import {
  FaPoll,
  FaRegFileExcel,
  FaRegFilePowerpoint,
  FaRegFilePdf,
  FaRegFileWord,
  FaFileCsv,
  FaFilePdf,
} from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import Picker from "emoji-picker-react";
import BeatLoader from "react-spinners/BeatLoader";
import axios from "axios";
import TopBarProgress from "react-topbar-progress-indicator";
import {
  IoChatboxOutline,
  IoChatbox,
  IoShareOutline,
  IoShare,
  IoBookOutline,
  IoPencil,
  IoCheckmarkCircleOutline,
  IoEllipse,
  IoBanOutline,
  IoSendOutline,
} from "react-icons/io5";

import SideNav from "./navigation/SideNavHome";
import RightNav from "./navigation/SidNavHomeRight";
import PreviewDocuments from "./previews/PreviewDocuments";
import Replies from "./features/Replies";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import tagStyle from "./css/tagStyle";
import mentionStyle from "./css/mentionStyle";
import HeaderToken from "./HeaderToken";
import { ClipLoader } from "react-spinners";
import Footer from "./navigation/Footer";
import { Helmet } from "react-helmet";

const headers = {
  Authorization: `${HeaderToken.Authorization}`,
  Accept: `${HeaderToken.Accept}`,
};

export default function Status(props) {
  const location = useLocation();
  const info = location.state;
  const queryClient = useQueryClient();
  // let history = useHistory();
  let navigate = useNavigate();
  const inputElement = useRef();
  const subInputElement = useRef();
  const { post_uuid } = useParams();
  const [getReplies, setReplies] = useState([]);
  const [voteSuccessful, setVoteSuccessful] = useState(false);
  const [polls, setPolls] = useState([]);
  const [postCount, setPostCount] = useState(0);
  const [replyCount, setReplyCount] = useState(1);
  const [likeCount, setLikeCount] = useState(1);
  const [currentStatus, setCurrentStatus] = useState("");
  const [fullname, setFullname] = useState("");
  const [username, setUsername] = useState("");
  const [authorId, setAuthorId] = useState(null);
  const [postDate, setPostDate] = useState(null);
  const [like_count, setLike_count] = useState(0);
  const [shares_count, setShares_count] = useState(0);
  const [reply_count, setReply_count] = useState(0);
  const [bookmark_count, setBookmark_count] = useState(0);
  const [repost_count, setRepost_count] = useState(0);
  const [bookmarkCount, setBookmarkCount] = useState(0);
  const [promiseLoading, serPromiseLoading] = useState(true);
  const [bookMarkStatus, setBookMarkStatus] = useState(false);
  const [replyTextarea_size, setReplyTextarea_size] = useState("replyTextarea");
  const [replyElements, setReplyElements] = useState(false);
  const [borderLine, setBorderLine] = useState("");
  const [oops, setOops] = useState(false);
  const [bookmarkMessage, setBookmarkMessage] = useState("");
  const [viewType, setViewType] = useState("likes");
  const [likedBy, setLikedBy] = useState([]);
  const [repostedBy, setRepostedBy] = useState([]);
  const [likeByNoPosts, setLikeByNoPosts] = useState(false);
  const [repostedByNoPosts, setRepostedByNoPosts] = useState(false);
  const [author, setAuthor] = useState("");
  const [subject, setSubject] = useState(null);
  const [topic, setTopic] = useState("");
  const [authorUsername, setAuthorUsername] = useState("");
  const [profilePic, setprofilePic] = useState();
  const [media, setMedia] = useState([]);
  const [files, setFiles] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [votes, setVotes] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [hours, setHours] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [days, setDays] = useState(0);

  const [mins, setMins] = useState(0);
  const [hrs, setHrs] = useState(0);
  const [snds, setSnds] = useState(0);
  const [dys, setDys] = useState(1);

  const [didIVote, setDidIVote] = useState(0);
  const [did_i_block_user, setDid_i_block_user] = useState(0);
  const [linkedQuotePostId, setLinkedQuotePostId] = useState("");
  const [pollResults, setPollResults] = useState([]);
  const [quotedData, setQuotedData] = useState([]);
  const [pollDuration, setPollDuration] = useState([]);
  const [showMoreQuotedPostText, setShowMoreQuotedPostText] = useState(false);
  const [repostDocuments, setRepostDocuments] = useState([]);

  const [didQuotePost, setDidQuotePost] = useState(false);
  const [bookmarkedByMe, setBookmarkedByMe] = useState(false);
  const [likedByMe, setLikedByMe] = useState(false);
  const [didRepostToPost, setDidRepostToPost] = useState(false);
  const [didReplyToPost, setDidReplyToPost] = useState(false);
  const [postOwnership, setPostOwnership] = useState(false);
  const [mediaReposting, setMediaReposting] = useState([]);
  const [singleTopic, setSingleTopic] = useState(null);
  const [singleSubject, setSingleSubject] = useState(null);
  const [previewDoc, setPreviewDoc] = useState([]);
  const [previewDocURL, setPreviewDocURL] = useState(null);
  const [currentPostPolls, setCurrentPostPolls] = useState(0);
  const [resetFunc, setResetFunc] = useState(false);
  const [pollQuestionPlaceholderReply, setPollQuestionPlaceholderReply] =
    useState("Post your reply...");
  const [pollQuestionPlaceholder, setPollQuestionPlaceholder] =
    useState("Ask a question...");

  const [optionCount, setOptionCount] = useState(2);
  const [openPoll, setOpenPoll] = useState(false);
  const [optionOne, setOptionOne] = useState(null);
  const [optionTwo, setOptionTwo] = useState(null);
  const [optionThree, setOptionThree] = useState(null);
  const [optionFour, setOptionFour] = useState(null);
  const [replyPic, setReplyPic] = useState(null);
  const [currentPage, setCurrentPage] = useState("");
  const [pollVotes, setPollVotes] = useState(0);
  const [documentsReposting, setDocumentsReposting] = useState([]);
  const [topicId, setTopicId] = useState(null);
  const [subjectId, setSubjectId] = useState(null);

  const [pagination, setPagination] = useState(20);
  const [opMinHide, setOpMinHide] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [subjectSelected, setSubjectSelected] = useState(false);

  const [selectedTopic, setSelectedTopic] = useState("");
  const [topicSelected, setTopicSelected] = useState(false);

  const [subjects, setSubjects] = useState([]);
  const [topics, setTopics] = useState([]);
  const [people, setPeople] = useState([]);

  const [preview, setPreview] = useState([]);

  const [images, setImages] = useState(null);
  const [imgWidth, setImgWidth] = useState();

  const [warningText, setWarningText] = useState(null);

  const [previewURLImage, setPreviewURLImage] = useState();
  const [imgHeight, setImgHeight] = useState(250);

  const [docTitle, setDocTitle] = useState({});

  const [didIvote, setDidIvote] = useState(0);

  const [concernText, setConcernText] = useState(
    "Tell us why you don't want to see this post."
  );

  const [showSend, setShowSend] = useState(false);
  const [concernClickedText, setConcernClickedText] = useState("");

  const [concernClickedOne, setConcernClickedOne] = useState(false);
  const [concernClickedTwo, setConcernClickedTwo] = useState(false);

  const [showMorePost, setShowMorePost] = useState(false);
  const [showMorePostId, setShowMorePostId] = useState(0);
  const [showMorePostCount, setShowMorePostCount] = useState(0);

  const [repostsMedia, setRepostsMedia] = useState([]);

  const baseURL = process.env.REACT_APP_URL;

  let url = "";
  // const query = new URLSearchParams(props.location.search);
  // const post_uuid = query.get("status_");
  // console.log(post_uuid);
  const user_uuid = localStorage.getItem("user_id");

  function usersThatReposted() {
    url = baseURL + "/get/reposted/users/" + post_uuid + "/" + user_uuid;
    axios
      .get(url, { headers })
      .then((response) => {
        setLikebySpinner(false);
        if (response.data.length == 0) {
          setRepostedByNoPosts(true);
          setRepostedBy([]);
        } else {
          setRepostedBy(response.data.data);
          setRepostedByNoPosts(true);
        }
      })
      .catch((error) => {
        setRepostedBy([]);
        setLikebySpinner(false);
        setRepostedByNoPosts(true);
      });
  }

  function settingDays(e) {
    setDys(e.target.value);
    if (e.target.value == 0 && mins < 10 && hrs == 0) {
      setHrs(1);
    } else if (e.target.value == 0 && mins >= 10 && hrs == 0) {
      setOpMinHide("d-none");
    } else {
      setOpMinHide("");
    }
  }

  function settingHours(e) {
    setHrs(e.target.value);
    if (e.target.value == 0 && dys == 0 && mins < 10) {
      setOpMinHide("d-none");
      setMins(10);
    } else if (e.target.value == 0 && dys == 0 && mins >= 10) {
      setOpMinHide("d-none");
    } else {
      setOpMinHide("");
    }
  }

  function settingMinutes(e) {
    setMins(e.target.value);
    if (e.target.value == 0 && dys == 0 && hrs == 0) {
      setHrs(1);
    }
  }

  function clearAttachment() {
    setPostCount(postCount + 1);
    setPreview([]);
    setImages(null);
    setPreviewDoc([]);
    setDocuments(null);
  }

  function removePollOption() {
    setOptionCount(optionCount - 1);
    if (optionCount == 3) {
      setOptionThree(null);
    }
    if (optionCount == 4) {
      setOptionFour(null);
    }
  }

  function addOption() {
    setOptionCount(optionCount + 1);
  }

  function usersThatLikedPost() {
    if (likebySpinner == false) {
    } else {
      setLikebySpinner(true);
    }

    url = baseURL + "/get/liked/users/" + post_uuid + "/" + user_uuid;
    axios
      .get(url, { headers })
      .then((response) => {
        setLikebySpinner(false);
        if (response.data.length == 0) {
          setLikeByNoPosts(true);
          setLikedBy([]);
        } else {
          setLikedBy(response.data.data);
          setLikeByNoPosts(true);
        }
      })
      .catch((error) => {
        setLikedBy([]);
        setLikebySpinner(false);
        setLikeByNoPosts(true);
      });
  }

  const currentPostQuery = useQuery({
    queryKey: ["currentPost", window.location.href],
    queryFn: async () => {
      const response = await axios.get(
        `${baseURL}/get/current/status/${user_uuid}/${post_uuid}`,
        { headers }
      );
      const data = response.data;
      return data;
    },
  });

  const {
    data: getCurrentPost,
    status: currentPostStatus,
    refetch: CurrentPostRefetch,
  } = currentPostQuery;

  function fetchSubjects() {
    axios
      .get(baseURL + "/subjects/get/" + user_uuid, { headers })
      .then((response) => {
        if (response.data.subjects.length == 0) {
          setSubjects([]);
        } else {
          setSubjects(response.data.subjects);
        }
      })
      .catch((error) => {
        setSubjects([]);
      });
  }

  useEffect(() => {
    fetchSubjects();
    CurrentPostRefetch();
    // RepliesRefetch();
  }, [window.location.href]);

  const [show, setShow] = useState(false);
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [hideEmoji, setHideEmoji] = useState(false);
  const [hideEmojiModal, setHideEmojiModal] = useState(false);
  const [post, setPost] = useState("");
  const [replies, setReply] = useState("");
  const [postButton, setPostbutton] = useState("disabled-button");
  const [disableBtn, setDisableBtn] = useState("disabled");
  const [textearTextSize, setTextearTextSize] = useState(
    "textarea-share-post-text-size-22"
  );
  const [loadPostbtn, setLoadPostbtn] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [postSpinner, setPostSpinner] = useState(true);
  const [likebySpinner, setLikebySpinner] = useState(true);
  const [progressBar, setProgressBar] = useState(false);
  const [replyUsername, setReplyUsername] = useState("");
  const [showReply, setshowReply] = useState(false);
  const [showView, setShowView] = useState(false);

  const [replyName, setReplyName] = useState("");
  const [replyPost, setReplyPost] = useState("");
  const [ReplyPostID, setReplyPost_uuid] = useState(false);
  const [action, setAction] = useState("");
  const [mediaCount, setMediaCount] = useState(0);
  const [photosTimelinePreview, setPhotosTimelinePreview] = useState(false);
  const [replySection, setReplySection] = useState(false);

  function handleClose() {
    setShow(false);
    setHideEmoji(false);
    setshowReply(false);
    if (post == "") {
      setDisableBtn("disabled");
    } else {
      setDisableBtn("");
    }
  }
  // const onEmojiClick = (event, emojiObject) => {
  //   setChosenEmoji(emojiObject);
  // };

  const onEmojiClick = (emojiData) => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
    setHideEmojiModal(false);
    setReply((prevInput) => prevInput + emojiData.emoji);

    // setFocusTextarea(true);
    setDisableBtn("");
    setPostbutton("login-button");
  };

  const onEmojiClickSub = (emojiData) => {
    if (subInputElement.current) {
      subInputElement.current.focus();
    }
    setHideEmoji(false);
    setPost((prevInput) => prevInput + emojiData.emoji);

    // setFocusTextarea(true);
    setDisableBtn("");
    setPostbutton("login-button");
  };

  function emojies() {
    if (hideEmoji == true) {
      setHideEmoji(false);
    } else {
      setHideEmoji(true);
    }
  }

  function emojiesModal() {
    if (hideEmojiModal == true) {
      setHideEmojiModal(false);
    } else {
      setHideEmojiModal(true);
    }
  }

  function replyTextareaSize() {}
  function shareTextarea(e) {
    setPost(e.target.value);
    if (e.target.value.trim().length > 0) {
      setPostbutton("login-button");
      setDisableBtn("");

      if (e.target.value.trim().length > 190) {
        setTextearTextSize("textarea-share-post-text-size-15");
      } else {
        setTextearTextSize("textarea-share-post-text-size-22");
      }
    } else {
      setPostbutton("disabled-button");
      setDisableBtn("disabled");
    }
  }

  function updateStatus(event) {
    setPostCount(postCount + 1);
    event.preventDefault();
    const user_uuid = localStorage.getItem("user_id");
    const formData = new FormData();
    formData.append("post", post);
    formData.append("user_uuid", user_uuid);
    formData.append("query", "POSTSTATUS");

    setProgressBar(true);
    setSpinner(true);
    setLoadPostbtn(false);

    const url = baseURL;
    axios({
      method: "POST",
      url: url,
      data: formData,
      headers: {
        Authorization: HeaderToken.Authorization,
        Accept: HeaderToken.Accept,
        "content-type": "multipart/form-data",
      },
    }).then((response) => {
      setSpinner(false);
      setLoadPostbtn(true);
      setProgressBar(false);
      setShow(false);

      navigate("/home");
      // history.push({ pathname: "/home" });

      // console.log(response.data[0]);
    });
  }

  function addLike(post_uuid, currentPage) {
    const user_uuid = localStorage.getItem("user_id");
    setCurrentPage(currentPage);
    setLikeCount(likeCount + 1);
    // setPostCount(postCount + likeCount);

    const formData = new FormData();
    formData.append("post_id", post_uuid);
    formData.append("user_id", user_uuid);
    formData.append("query", "ADDLIKE");

    const url = baseURL;
    axios({
      method: "POST",
      url: url + "/like/add",
      data: formData,
      headers: {
        Authorization: HeaderToken.Authorization,
        Accept: HeaderToken.Accept,
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        setBookmarkMessage(response.data.message);
        setBookMarkStatus(true);
        CurrentPostRefetch();
      })
      .catch((error) => {
        setBookmarkMessage("Oops! Something went wrong...");
        setBookMarkStatus(true);
      });

    setTimeout(() => {
      setBookMarkStatus(false);
    }, 4000);
  }

  function replyPage(post, name, username, post_uuid) {
    var url = window.location.pathname;
    if (url.includes("hashtag")) {
      return;
    } else if (url.includes("profile")) {
      return;
    } else {
      navigate("../../status/" + username + "/" + post_uuid);
      // history.push({
      //   pathname: "../../status/" + username + "/" + post_uuid,
      // });
    }
  }

  function Bookmark(post_id) {
    const user_id = localStorage.getItem("user_id");
    const url = baseURL;

    const data = new FormData();
    data.append("post_id", post_id);
    data.append("user_id", user_id);
    axios({
      method: "POST",
      url: url + "/bookmarks/create",
      data: data,
      headers: {
        Authorization: HeaderToken.Authorization,
        Accept: HeaderToken.Accept,
      },
    })
      .then((response) => {
        setBookMarkStatus(true);
        setBookmarkMessage(response.data.message);
        CurrentPostRefetch();
      })
      .catch((error) => {
        setBookmarkMessage("Oops! Something went wrong...");
      });
    setTimeout(() => {
      setBookMarkStatus(false);
    }, 4000);
  }

  function reply(
    post,
    name,
    username,
    post_uuid,
    pic,
    polls,
    did_i_vote,
    poll_duration,
    poll_results,
    poll_votes,
    media,
    documents,
    subject,
    topic,
    currentPostPolls,
    actionTaken,
    currentPage
  ) {
    // alert(documents);
    // return;
    setOpenPoll(false);
    setOptionOne(null);
    setOptionTwo(null);
    setOptionThree(null);
    setOptionFour(null);
    setOptionCount(2);
    setCurrentPostPolls(currentPostPolls);
    setAction(actionTaken);
    setReplyUsername(username);
    setReplyPic(pic);
    setReplyName(name);
    setReplyPost(post);
    setReplyPost_uuid(post_uuid);
    setshowReply(true);
    setCurrentPage(currentPage);

    setPolls(polls);
    setPollResults(poll_results);
    setDidIvote(did_i_vote);
    setPollDuration(poll_duration);
    setPollVotes(poll_votes);
    setMediaReposting(media);
    setDocumentsReposting(documents);
    setSingleSubject(subject);
    setSingleTopic(topic);

    setDisableBtn("disabled");
    setPostbutton("disabled-button");
  }

  function closePoll() {
    if (openPoll == true) {
      setOpenPoll(false);
      setPollQuestionPlaceholder("What's on your mind today?");
      setPollQuestionPlaceholderReply("Post your reply...");
    } else {
      setOpenPoll(true);
      setPollQuestionPlaceholder("Ask a question...");
      setPollQuestionPlaceholderReply("Ask a question...");
    }
  }

  function addReply(event) {
    setDisableBtn("disabled");
    event.preventDefault();
    const user_uuid = localStorage.getItem("user_id");
    const formData = new FormData();

    if (docTitle.length >= 0) {
      for (let i = 0; i < docTitle.length; i++) {
        formData.append("docTile", docTitle);
      }
    }
    if (images != null) {
      for (let i = 0; i < images.length; i++) {
        formData.append("images[]", images[i]);
      }
    }

    if (documents != null) {
      for (let i = 0; i < documents.length; i++) {
        formData.append("documents[]", documents[i]);
      }
    }

    if (openPoll == true) {
      formData.append("optionOne", optionOne);
      formData.append("optionTwo", optionTwo);
      formData.append("days", dys);
      formData.append("hours", hrs);
      formData.append("minutes", mins);

      if (optionCount >= 3) {
        formData.append("optionThree", optionThree);
      }
      if (optionCount == 4) {
        formData.append("optionFour", optionFour);
      }
    }

    formData.append(
      "reply",
      action == "reply" || action == "quote"
        ? replies
        : action == "replyCurrentStatus"
        ? post
        : replyPost
    );

    formData.append("subject_id", subjectId);
    formData.append("topic_id", topicId);
    formData.append("post_id", ReplyPostID);
    formData.append("user_id", user_uuid);
    formData.append("action", action);
    formData.append("query", "ADDREPLY");

    setProgressBar(true);
    setSpinner(true);
    setLoadPostbtn(false);

    const url = baseURL;
    axios({
      method: "POST",
      url: url + "/reply/create",
      data: formData,
      headers: {
        Authorization: HeaderToken.Authorization,
        Accept: HeaderToken.Accept,
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        queryClient.invalidateQueries(
          { queryKey: ["currentPost"] },
          { exact: true }
        );

        queryClient.invalidateQueries(
          { queryKey: ["replies"] },
          { exact: true }
        );
        // RepliesRefetch();
        setOpenPoll(false);
        setDocTitle({});
        setSpinner(false);
        setLoadPostbtn(true);
        setProgressBar(false);
        setshowReply(false);
        setReply("");
        setImages(null);
        setPreview([]);
        setPreviewDoc([]);
        setDocuments(null);
        setTopicId(null);
        setSubjectId(null);
        setSelectedTopic("");
        setSelectedSubject("");
        setTopicSelected(false);
        setSubjectSelected(false);
        setResetFunc(false);
        setTopics([]);
        setDys(1);
        setHrs(0);
        setMins(0);
        setOptionOne("");
        setOptionTwo("");
        setOptionThree("");
        setOptionFour("");

        setPollQuestionPlaceholderReply("Post your reply...");
        setSpinner(false);
        setLoadPostbtn(true);
        setProgressBar(false);
        setshowReply(false);
        setBookmarkMessage(
          <label className="color-white">
            Great!{" "}
            {action == "repost"
              ? "Repost "
              : action == "quote"
              ? "Quote "
              : "Reply "}{" "}
            sent.
          </label>
        );
        setBookMarkStatus(true);

        if (action == "replyCurrentStatus") {
          setPost("");
          setReplyTextarea_size("replyTextarea");
        }
        setTextearTextSize("textarea-share-post-text-size-22");
        setReplyElements(false);
        setBorderLine("");
      })
      .catch((error) => {
        setBookmarkMessage("Oops! Something went wrong...");
        setBookMarkStatus(true);
      });
    setTimeout(() => {
      setBookMarkStatus(false);
    }, 5000);
  }

  function replyTextarea(e) {
    setReply(e.target.value);
    if (e.target.value.trim().length > 0) {
      setPostbutton("login-button");
      setDisableBtn("");

      if (e.target.value.trim().length > 190) {
        setTextearTextSize("textarea-share-post-text-size-15");
      } else {
        setTextearTextSize("textarea-share-post-text-size-22");
      }
    } else {
      setPostbutton("disabled-button");
      setDisableBtn("disabled");
    }
  }

  function showMore(post_id) {
    setShowMorePostCount(showMorePostCount + 1);
    if (showMorePost == true && showMorePostId == post_id) {
      setShowMorePost(false);
      setShowMorePostId(0);
    } else {
      setShowMorePost(true);
      setShowMorePostId(post_id);
    }
  }
  function replyTextareaFocus(post_id) {
    setHideEmoji(false);
    setReplyTextarea_size("replyTextareaFocus");
    setReplyElements(true);
    setBorderLine("reply_text_area");
    setDisableBtn("disabled");
    setAction("replyCurrentStatus");
    setReplyPost_uuid(post_id);
  }

  function goBack() {
    navigate(-1);
    // history.goBack();
    setPostCount(postCount + 1);
  }

  function likedByList() {
    return (
      <>
        {likedBy.length == 0 ? (
          <div className={likeByNoPosts == true ? "" : "d-none"}>
            <h5>This post has no likes.</h5>
          </div>
        ) : (
          ""
        )}
        {likedBy.map((item, key) => (
          <Link
            to={"/" + item.username}
            className="color-black textUnderlineNone cursorPointer"
            key={key}
          >
            <Row className="mb-0 side-nav-topics">
              <Col lg={1} xs={2} sm={1} className="">
                <div
                  className="profile-pic"
                  style={{
                    backgroundImage:
                      "url(" +
                      process.env.REACT_APP_URL.replace("api", "") +
                      "public/profile/picture/@" +
                      item.username +
                      "/" +
                      item.profile_pic +
                      ")",
                  }}
                />
              </Col>
              <Col
                lg={11}
                sm={11}
                xs={10}
                className="mb-0 paddingLeft30 margin__mobile_neg__30"
              >
                <label className="line-height-20">
                  <span className=" paddingLeft5 fontBold">
                    {item.fullname.length > 15
                      ? item.fullname.slice(0, 15) + "..."
                      : item.fullname}
                  </span>
                  <br />

                  <label className="footnote cursorPointer paddingLeft5">
                    @{item.username}
                  </label>
                </label>
              </Col>
            </Row>
          </Link>
        ))}
      </>
    );
  }

  function RepostedByList() {
    return (
      <>
        {repostedBy.length == 0 ? (
          <div className={repostedByNoPosts == true ? "" : "d-none"}>
            <h5>This post has no reposts.</h5>
          </div>
        ) : (
          ""
        )}
        {repostedBy.map((item, key) => (
          <Link
            to={"/" + item.username}
            className="color-black textUnderlineNone cursorPointer"
            key={key}
          >
            <Row className="side-nav-topics mb-0">
              <Col lg={1} xs={2} sm={1} className="">
                <div
                  className="profile-pic"
                  style={{
                    backgroundImage:
                      "url(" +
                      process.env.REACT_APP_URL.replace("api", "") +
                      "public/profile/picture/@" +
                      item.username +
                      "/" +
                      item.profile_pic +
                      ")",
                  }}
                />
              </Col>
              <Col
                lg={11}
                sm={11}
                xs={10}
                className="mb-0 paddingLeft30 margin__mobile_neg__30"
              >
                <label className="line-height-20">
                  <span className=" paddingLeft5 fontBold">
                    {item.fullname.length > 15
                      ? item.fullname.slice(0, 15) + "..."
                      : item.fullname}
                  </span>
                  <br />

                  <label className="footnote cursorPointer paddingLeft5">
                    @{item.username}
                  </label>
                </label>
              </Col>
            </Row>
          </Link>
        ))}
      </>
    );
  }

  function viewLiked() {
    setShowView(true);
    setViewType("likes");
    usersThatLikedPost();
  }

  function viewPosted() {
    setShowView(true);
    setViewType("reposts");
    usersThatReposted();
  }

  function closeViewType() {
    setShowView(false);
    setViewType("likes");
  }

  async function getPhotos(username, post_id, photo_id) {
    setPhotosTimelinePreview(false);
    setReplySection(false);
    navigate("/home/" + username + "/status/" + post_id + "/photo/" + photo_id);
    // history.push({
    //   pathname:
    //     "/home/" + username + "/status/" + post_id + "/photo/" + photo_id,
    // });

    await axios
      .get(
        url +
          "/status/photo/get/" +
          photo_id +
          "/" +
          post_id +
          "/" +
          username +
          "/" +
          user_uuid
      )
      .then((response) => {
        // setPhotoReposted(response.data.reposted);
        // setPhotoLiked(response.data.liked);
        // setPhotoBookmarked(response.data.bookmarked);
        // setPhotoReplied(response.data.replied);

        // setPhotoPost(response.data.post.post);
        // setPhotoPostId(response.data.post_id);
        // setPhotoId(response.data.photo_id);
        // setPhotoPostDate(response.data.post.created_at);
        // setPhotoUsername(response.data.username);
        // setPhotoFullname(response.data.fullname);
        // setProfilePicPath(response.data.profile_pic_path);
        // setPhotoProfilePic(response.data.profile_pic);
        // setPhotoPath(response.data.photo_path);
        // setSelectedPhoto(response.data.selected_photo);
        // setPhotoCount(response.data.photo_count);
        // setPhotoRepostsCount(response.data.repost_count);
        // setPhotoLikesCount(response.data.like_count);
        // setPhotoRepliesCount(response.data.reply_count);
        // setPhotoBookmarksCount(response.data.bookmark_count);
        setPhotosTimelinePreview(true);
      })
      .catch((error) => {});
  }

  function vote(poll_id, post_id) {
    setVoteSuccessful(true);
    const data = new FormData();
    data.append("poll_id", poll_id);
    data.append("user_id", user_uuid);
    data.append("post_id", post_id);
    axios({
      method: "POST",
      url: baseURL + "/vote",
      data: data,
      headers: {
        Authorization: HeaderToken.Authorization,
        Accept: HeaderToken.Accept,
      },
    })
      .then((response) => {
        CurrentPostRefetch();
        setPostCount(postCount + 1);
      })
      .catch((error) => {});
  }

  function showMoreQuotedPost() {
    if (showMoreQuotedPostText == true) {
      setShowMoreQuotedPostText(false);
    } else {
      setShowMoreQuotedPostText(true);
    }
  }

  function follow(user_id) {
    var following_user_id = user_uuid;
    var followed_user_id = user_id;

    const formData = new FormData();
    formData.append("following_user_id", following_user_id);
    formData.append("followed_user_id", followed_user_id);
    const url = baseURL;
    axios({
      method: "POST",
      url: url + "/following/create",
      data: formData,
      headers: {
        Authorization: HeaderToken.Authorization,
        Accept: HeaderToken.Accept,
        "content-type": "multipart/form-data",
      },
    }).then((response) => {
      setPostCount(postCount + 1);
    });
    // refetch();
  }

  function documentUpload(e) {
    setPostCount(postCount + 1);
    setPreview([]);
    setImages(null);
    setDocuments(e.target.files);
    if (e.target.files.length > 4) {
      setDisableBtn("disabled");
      setWarningText("You can only upload upto 4 documents per post.");
    } else {
      let documents = [];
      let documentName = [];
      for (let i = 0; i < e.target.files.length; i++) {
        documents.push(URL.createObjectURL(e.target.files[i]));
        documentName.push(e.target.files[i].name);
      }

      setPreviewDoc(documentName);
      setPreviewDocURL(documents);
      setDisableBtn("");
      setPostbutton("login-button");
      setWarningText(null);
    }
  }

  function imageUpload(e) {
    setPostCount(postCount + 1);
    setPreviewDoc([]);
    setDocuments(null);
    setImages(e.target.files);
    if (e.target.files.length > 4) {
      setDisableBtn("disabled");
      setWarningText("You can only upload upto 4 images per post.");
    } else {
      let images = [];
      let uploadImages = [];
      for (let i = 0; i < e.target.files.length; i++) {
        images.push(URL.createObjectURL(e.target.files[i]));
      }

      if (e.target.files.length == 1) {
        setImgWidth("col-lg-12");
        setImgHeight(250);
      } else if (
        e.target.files.length == 2 ||
        e.target.files.length == 4 ||
        e.target.files.length == 3
      ) {
        setImgWidth("col-lg-6");
        setImgHeight(200);
      }

      setPreview(images);
      setDisableBtn("");
      setPostbutton("login-button");
      setWarningText(null);

      setPreviewURLImage("");
    }
  }

  function block(user_id) {
    var blocking_user_id = user_uuid;
    var blocked_user_id = user_id;

    axios
      .get(
        baseURL + "/block/user/" + blocking_user_id + "/" + blocked_user_id,
        { headers }
      )
      .then((res) => {
        setPostCount(postCount + 1);
        // refetch();
      });
  }

  function deleteReply(post_id) {
    axios({
      method: "POST",
      url: baseURL + "/reply/delete/" + post_id,
      headers: {
        Authorization: HeaderToken.Authorization,
        Accept: HeaderToken.Accept,
      },
    })
      .then((response) => {
        setPostCount(postCount + 1);
        // refetch();
      })
      .catch((error) => {});
  }

  function muteReply(reply_id) {
    // setUndoMute()
    const data = new FormData();
    data.append("post_id", reply_id);
    data.append("user_id", user_uuid);
    axios({
      method: "POST",
      url: baseURL + "/reply/mute",
      data: data,
      headers: {
        Authorization: HeaderToken.Authorization,
        Accept: HeaderToken.Accept,
      },
    })
      .then((response) => {
        setPostCount(postCount + 1);
      })
      .catch((error) => {});
    // refetch();
  }

  function hashtag(value, type, post_id) {
    let hashtagValue;
    if (type == "tag") {
      hashtagValue = value.replace("#", "");
      navigate(`/hashtag/${post_id}/${hashtagValue}`);
      // history.push({ pathname: `/hashtag/${post_id}/${hashtagValue}` });
    } else {
      hashtagValue = value.replace("@", "");
      navigate(`/${hashtagValue}`);
      // history.push({ pathname: `/profile/${hashtagValue}` });
    }

    return;
  }

  function addMention(username) {
    alert(username);
  }

  function resetSubject() {
    setTopicId(null);
    setSubjectId(null);
    setSelectedTopic("");
    setSelectedSubject("");
    setTopicSelected(false);
    setSubjectSelected(false);
    setResetFunc(false);
    setTopics([]);
  }

  function tags(value, index) {
    // const { id, value } = e.target;
    setDocTitle((docTitle) => Object.assign([], docTitle, { [index]: value }));

    // console.log(docTitle);
  }

  function subjectSelectedFunc(subject_id, subject) {
    setSubjectId(subject_id);
    setSelectedSubject(subject);
    setSubjectSelected(true);
    setResetFunc(true);

    axios
      .get(baseURL + "/topics/get/" + subject_id, { headers })
      .then((response) => {
        if (response.data.topics.length == 0) {
          setTopics([]);
        } else {
          setTopics(response.data.topics);
        }
      })
      .catch((error) => {
        setTopics([]);
      });
  }

  function showSubjects() {
    return (
      <>
        {subjects.length == 0 ? (
          <div className={"paddingRight-13 paddingLeft-13 footnote fontSize14"}>
            Subjects you follow will appear here.
            <br />
            <Link to={"/topics"}>
              <label className="native-button-dark-grey btn-sm rounded-pill mt-2 fontBold fontSize14">
                Follow subjects
              </label>
            </Link>
          </div>
        ) : (
          ""
        )}
        {subjects.map((item, index) => (
          <Dropdown.Item
            onClick={() => subjectSelectedFunc(item.subject_id, item.subject)}
            key={index}
          >
            {item.subject}
          </Dropdown.Item>
        ))}
      </>
    );
  }

  function showTopics() {
    return (
      <>
        {topics.map((item, index) => (
          <Dropdown.Item
            onClick={() => topicFunc(item.id, item.topic)}
            key={index}
          >
            {item.topic}
          </Dropdown.Item>
        ))}
      </>
    );
  }

  function topicFunc(topic_id, topic) {
    setTopicId(topic_id);
    setSelectedTopic(topic);
    setTopicSelected(true);
  }

  function fewerFeeds(post_id, complaint, option) {
    setConcernClickedText(complaint);

    setConcernText("You'll see fewer posts like this");
    setShowSend(true);

    if (option == 1) {
      if (concernClickedOne == true) {
        setConcernClickedOne(false);
        setShowSend(false);
        setConcernText("Tell us why you don't want to see this post.");
      } else {
        setConcernClickedOne(true);
      }

      setConcernClickedTwo(false);
    } else {
      if (concernClickedTwo == true) {
        setConcernClickedTwo(false);
        setShowSend(false);
        setConcernText("Tell us why you don't want to see this post.");
      } else {
        setConcernClickedTwo(true);
      }

      setConcernClickedOne(false);
    }
  }

  function modalFunc() {
    // setFilterMentions([]);
    if (hideEmoji == true) {
      setHideEmoji(false);
    }

    if (hideEmojiModal == true) {
      setHideEmojiModal(false);
    }
  }

  return (
    <div className="body-login">
      <Helmet>
        <title>
          {currentPostStatus == "loading"
            ? ""
            : getCurrentPost.data.current_status.post
            ? "@" +
              getCurrentPost.data.user.username +
              ": " +
              getCurrentPost.data.current_status.post +
              " | " +
              process.env.REACT_APP_YOUR_VARIABLE_NAME_HERE
            : "Post | " + process.env.REACT_APP_YOUR_VARIABLE_NAME_HERE}
        </title>
      </Helmet>
      <Header />
      {progressBar && <TopBarProgress />}
      <Container>
        <Modal
          show={showView}
          onHide={closeViewType}
          backdropClassName="backdrop-login"
          centered
          className={"modal_bg_color_white"}
        >
          <Modal.Header closeButton>
            <Modal.Title className={"text-bold fontSize-20"}>
              {viewType == "likes"
                ? "Liked by"
                : viewType == "reposts"
                ? "Reposted by"
                : "View not available"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={likebySpinner == true ? "text-center" : "d-none"}>
              <BeatLoader color={"#3399cc"} />
            </div>

            <div className="modalScroll">
              {viewType == "likes" ? likedByList() : RepostedByList()}
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={showReply}
          onHide={handleClose}
          backdropClassName="backdrop-login"
          centered
          className={"modal_bg_color_white"}
        >
          <Modal.Body>
            <Container className="p-1 pt-0">
              <form onSubmit={addReply}>
                <Row className="align-items-center">
                  <Col lg={12} className="whiteSpace mb-2">
                    <textarea
                      className={
                        action == "repost"
                          ? "d-none"
                          : textearTextSize + " textarea-share-post"
                      }
                      placeholder={
                        action == "quote"
                          ? "Say something..."
                          : pollQuestionPlaceholderReply
                      }
                      value={replies}
                      onChange={replyTextarea}
                      onClick={modalFunc}
                      ref={inputElement}
                    />
                    <PreviewMedia
                      preview={preview}
                      clearAttachment={clearAttachment}
                      className={preview.length > 0 ? "" : "d-none"}
                    />
                    <PreviewDocuments
                      previewDoc={previewDoc}
                      clearAttachment={clearAttachment}
                      docTitle={docTitle}
                      tags={tags}
                      previewDocURL={previewDocURL}
                      className={previewDoc.length > 0 ? "" : "d-none"}
                    />
                    <div className={openPoll == false ? "d-none" : "mb-3"}>
                      <Polls
                        openPoll={openPoll}
                        optionOne={optionOne}
                        optionTwo={optionTwo}
                        optionThree={optionThree}
                        optionFour={optionFour}
                        optionCount={optionCount}
                        removePollOption={removePollOption}
                        setOptionOne={setOptionOne}
                        setOptionTwo={setOptionTwo}
                        setOptionThree={setOptionThree}
                        setOptionFour={setOptionFour}
                        settingHours={settingHours}
                        settingDays={settingDays}
                        hours={hrs}
                        days={dys}
                        settingMinutes={settingMinutes}
                        minutes={mins}
                        addOption={addOption}
                        closePoll={closePoll}
                        opMinHide={opMinHide}
                      />
                    </div>

                    {hideEmojiModal && (
                      <div className="mb-3 mt-0 col-12">
                        {/* <span
                          className="text-normal float-right share-icons"
                          data-tip={"Close"}
                          data-place={"top"}
                          data-effect={"solid"}
                          onClick={emojies}
                        >
                          <BsXLg size={13} />
                          <ReactTooltip arrowColor={"transparent"} />
                        </span> */}

                        <Emojies
                          handleClick={onEmojiClick}
                          action={"post"}
                          homeReply={true}
                        />
                      </div>
                    )}
                    <Col
                      lg={12}
                      className={action == "repost" ? "d-none" : "mb-3"}
                    >
                      <Elements
                        imageUpload={imageUpload}
                        documentUpload={documentUpload}
                        emojies={emojiesModal}
                        chosenEmoji={chosenEmoji}
                        closePoll={closePoll}
                        subjectSelected={subjectSelected}
                        selectedSubject={selectedSubject}
                        selectedTopic={selectedTopic}
                        topicSelected={topicSelected}
                        showTopics={showTopics}
                        showSubjects={showSubjects}
                        resetFunc={resetFunc}
                        resetSubject={resetSubject}
                        currentPostPolls={currentPostPolls}
                        action={action}
                      />
                    </Col>

                    <div className="w-100">
                      <Row className={singleTopic == null ? "d-none" : ""}>
                        <Col lg={12} className="pt-0">
                          <Link
                            to={
                              singleTopic == null
                                ? ""
                                : "/topicPosts?clicked=" +
                                  singleTopic +
                                  "&from_post_id=" +
                                  ReplyPostID
                            }
                          >
                            <label
                              className={
                                "topics fontSize14 cursorPointer textUnderlineNone underlineHover text-normal"
                              }
                            >
                              {singleTopic == null ? (
                                ""
                              ) : (
                                <>
                                  <IoBookOutline size={15} /> {singleTopic}
                                </>
                              )}
                            </label>
                          </Link>
                        </Col>
                      </Row>
                      <Row className={"align-items-center"}>
                        <Col lg={2} sm={2} xs={2}>
                          <div
                            className={"profile-pic"}
                            style={{
                              backgroundImage:
                                "url(" +
                                process.env.REACT_APP_URL.replace("api", "") +
                                "public/profile/picture/@" +
                                replyUsername +
                                "/" +
                                replyPic +
                                ")",
                            }}
                          />
                        </Col>
                        <Col
                          lg={10}
                          sm={10}
                          xs={10}
                          className={
                            "p-0 marginLeft-13 mobileTimelineMarginLeft line-height-20"
                          }
                        >
                          <span className="text-bold">{replyName}</span>
                          <br />
                          <span className="footnote">@{replyUsername}</span>
                        </Col>
                      </Row>

                      <div className={"mb-0 mt-2"}>
                        {replyPost == null ? (
                          ""
                        ) : replyPost.length > 150 ? (
                          <>
                            {showMoreQuotedPostText == true ? (
                              <Tagify
                                onClick={(text, type) => hashtag(text, type)}
                                detectHashtags={true}
                                detectMentions={true}
                                tagStyle={tagStyle}
                                mentionStyle={mentionStyle}
                                detectLinks={true}
                              >
                                <Linkify
                                  componentDecorator={(
                                    decoratedHref,
                                    decoratedText,
                                    key
                                  ) => (
                                    <SecureLink
                                      target="_blank"
                                      href={decoratedHref}
                                      key={key}
                                      className={"color-blue textUnderlineNone"}
                                    >
                                      {decoratedText}
                                    </SecureLink>
                                  )}
                                >
                                  {replyPost}
                                </Linkify>
                              </Tagify>
                            ) : (
                              <Tagify
                                onClick={(text, type) => hashtag(text, type)}
                                detectHashtags={true}
                                detectMentions={true}
                                tagStyle={tagStyle}
                                mentionStyle={mentionStyle}
                                detectLinks={true}
                              >
                                <Linkify
                                  componentDecorator={(
                                    decoratedHref,
                                    decoratedText,
                                    key
                                  ) => (
                                    <SecureLink
                                      target="_blank"
                                      href={decoratedHref}
                                      key={key}
                                      className={"color-blue textUnderlineNone"}
                                    >
                                      {decoratedText}
                                    </SecureLink>
                                  )}
                                >
                                  {replyPost.slice(0, 150)}
                                </Linkify>
                              </Tagify>
                            )}
                            <span
                              className="color-blue cursorPointer text-normal"
                              onClick={showMoreQuotedPost}
                            >
                              {showMoreQuotedPostText == true ? (
                                <span className="text-normal">
                                  {" "}
                                  Show less...
                                </span>
                              ) : (
                                <span className="text-normal">
                                  <span className="color-black text-normal">
                                    ...
                                  </span>{" "}
                                  Show more
                                </span>
                              )}
                            </span>
                          </>
                        ) : (
                          <div className={"w-100"}>
                            {" "}
                            <Tagify
                              onClick={(text, type) => hashtag(text, type)}
                              detectHashtags={true}
                              detectMentions={true}
                              tagStyle={tagStyle}
                              mentionStyle={mentionStyle}
                              detectLinks={true}
                            >
                              <Linkify
                                componentDecorator={(
                                  decoratedHref,
                                  decoratedText,
                                  key
                                ) => (
                                  <SecureLink
                                    target="_blank"
                                    href={decoratedHref}
                                    key={key}
                                    className={"color-blue textUnderlineNone"}
                                  >
                                    {decoratedText}
                                  </SecureLink>
                                )}
                              >
                                {replyPost}
                              </Linkify>
                            </Tagify>
                          </div>
                        )}
                        <div>
                          <Row
                            className={
                              documentsReposting.length > 0
                                ? "mt-0 paddingLeft10 paddingRight10"
                                : "d-none"
                            }
                          >
                            {documentsReposting.map((doc, index) => (
                              <Col lg={12} key={index}>
                                <a
                                  href={
                                    process.env.REACT_APP_URL.replace(
                                      "api",
                                      ""
                                    ) +
                                    "public/timeline/documents/" +
                                    replyUsername.replace("@", "") +
                                    "/" +
                                    doc.file
                                  }
                                  target={"_blank"}
                                  className={"textUnderlineNone"}
                                >
                                  <Row
                                    className={
                                      "align-items-center document-link-timeline"
                                    }
                                  >
                                    <Col lg={12} key={index} className={"p-0"}>
                                      <div className={""}>
                                        {doc.format == "pdf" ? (
                                          <label className="p-1">
                                            <FaRegFilePdf
                                              size={20}
                                              className={"color-red"}
                                            />
                                          </label>
                                        ) : doc.format == "docx" ? (
                                          <label className="p-1">
                                            <FaRegFileWord
                                              size={20}
                                              className={"color-blue"}
                                            />
                                          </label>
                                        ) : doc.format == "csv" ? (
                                          <label className="p-1">
                                            <FaFileCsv
                                              size={20}
                                              className={"color-green"}
                                            />
                                          </label>
                                        ) : doc.format == "xlsx" ? (
                                          <label className="p-1">
                                            <FaRegFileExcel
                                              size={20}
                                              className={"color-green"}
                                            />
                                          </label>
                                        ) : doc.format == "pptx" ? (
                                          <label className="p-1">
                                            <FaRegFilePowerpoint
                                              size={20}
                                              className={"color-orange"}
                                            />
                                          </label>
                                        ) : (
                                          ""
                                        )}
                                        {doc.title == ""
                                          ? doc.file
                                          : doc.title + "." + doc.format}
                                      </div>
                                    </Col>
                                  </Row>
                                </a>
                              </Col>
                            ))}
                          </Row>
                          <Row
                            className={
                              mediaReposting.length > 0 ? "mt-1" : "d-none"
                            }
                          >
                            {mediaReposting.map((img, index) => (
                              <Col
                                className={
                                  mediaReposting.length == 1
                                    ? "col-12"
                                    : mediaReposting.length == 2
                                    ? "col-6 paddingSides" + index
                                    : mediaReposting.length == 3
                                    ? "col-4 threePaddingSides" + index
                                    : mediaReposting.length == 4
                                    ? "col-6 fourPaddingSides" + index
                                    : "col-6"
                                }
                                key={index}
                              >
                                <div
                                  onClick={() =>
                                    getPhotos(
                                      replyUsername,
                                      mediaReposting.post_id,
                                      img.id,
                                      mediaReposting,
                                      index
                                    )
                                  }
                                  className={
                                    mediaReposting.length == 2
                                      ? "mb-3 border-radius" + index
                                      : mediaReposting.length == 3
                                      ? "borderRadiusImg" + index
                                      : mediaReposting.length == 4
                                      ? "borderRadiusImgFour" + index
                                      : "mb-3 border-radius-5"
                                  }
                                  style={{
                                    backgroundImage:
                                      "url(" +
                                      process.env.REACT_APP_URL.replace(
                                        "api",
                                        ""
                                      ) +
                                      "public/timeline/media/" +
                                      replyUsername.replace("@", "") +
                                      "/" +
                                      img.file +
                                      ")",
                                    backgroundSize: "cover",
                                    height:
                                      mediaReposting.length == 1
                                        ? 300
                                        : mediaReposting.length == 2
                                        ? 300
                                        : mediaReposting.length == 4
                                        ? 150
                                        : 200,
                                    width: "100%",
                                    border: "thin solid #ececec",

                                    // backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center",
                                  }}
                                />
                              </Col>
                            ))}
                          </Row>

                          <div
                            className={
                              currentPostPolls == false ? "d-none" : ""
                            }
                          >
                            <Row className={polls.length == 0 ? "d-none" : ""}>
                              <div
                                className={
                                  didIvote == 1 ||
                                  (pollDuration.minutes <= 0 &&
                                    pollDuration.days <= 0 &&
                                    pollDuration.hours <= 0 &&
                                    pollDuration.seconds <= 0)
                                    ? "col-12 mt-1"
                                    : "d-none"
                                }
                              >
                                {pollResults.map((results, index) => (
                                  <div
                                    className={
                                      results.percentage == 0
                                        ? "w-100 resulst-polls-container-no-votes"
                                        : "w-100 resulst-polls-container"
                                    }
                                    key={index}
                                  >
                                    <label
                                      className={
                                        results.percentage == 0
                                          ? ""
                                          : "polls-voted color-black"
                                      }
                                      style={{
                                        width: results.percentage + "%",
                                      }}
                                    />
                                    <label className={"color-black"}>
                                      {results.options}{" "}
                                      {results.who_i_voted_for == 1 ? (
                                        <IoCheckmarkCircleOutline
                                          size={20}
                                          color={"#66b266"}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </label>
                                    <label
                                      className={"float-right color-black"}
                                    >
                                      {results.percentage}%
                                    </label>
                                  </div>
                                ))}
                              </div>

                              <label
                                className={
                                  !voteSuccessful
                                    ? "d-none"
                                    : "w-100 color-green text-left mb-1"
                                }
                              >
                                Vote successful!
                              </label>
                              <div className={voteSuccessful ? "d-none" : ""}>
                                <div
                                  className={
                                    polls.length > 0 &&
                                    didIvote == 0 &&
                                    (pollDuration.minutes >= 0 ||
                                      pollDuration.days >= 0 ||
                                      pollDuration.hours >= 0 ||
                                      pollDuration.seconds >= 0)
                                      ? "mt-1"
                                      : "d-none"
                                  }
                                >
                                  {polls.map((polls, index) => (
                                    <Col lg={12} key={index}>
                                      <div
                                        className={"col-12 polls fontBold"}
                                        onClick={() =>
                                          vote(polls.id, polls.post_id)
                                        }
                                      >
                                        {polls.option}
                                      </div>
                                    </Col>
                                  ))}
                                </div>
                              </div>
                              <label className={"footnote"}>
                                fdf
                                {pollVotes} {pollVotes == 1 ? "vote" : "votes"}{" "}
                                -{" "}
                                {pollDuration.minutes <= 0 &&
                                pollDuration.days <= 0 &&
                                pollDuration.hours <= 0 &&
                                pollDuration.seconds <= 0
                                  ? "Poll closed."
                                  : pollDuration.days >= 1
                                  ? pollDuration.days + "d left"
                                  : pollDuration.hours >= 1 &&
                                    pollDuration.minutes >= 1
                                  ? pollDuration.hours +
                                    "h and " +
                                    pollDuration.minutes +
                                    "m left"
                                  : pollDuration.hours >= 1 &&
                                    pollDuration.minutes == 0
                                  ? pollDuration.hours + "h left"
                                  : pollDuration.minutes >= 1
                                  ? pollDuration.minutes + "m left"
                                  : "Poll closed."}
                              </label>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col lg={12} xs={12} className="mb-0">
                    {action == "repost" ? (
                      <label className="footnote mb-2">
                        <span className="text-bold">NB:</span> Your followers
                        will see this.
                      </label>
                    ) : action == "quote" ? (
                      ""
                    ) : (
                      ""
                    )}

                    {loadPostbtn && (
                      <Button
                        className={
                          action == "repost" || action == "quote"
                            ? "login-button mb-3 rounded-pill"
                            : postButton + " mb-3 text-center rounded-pill"
                        }
                        type="submit"
                        disabled={
                          action == "repost" || action == "quote"
                            ? ""
                            : disableBtn
                        }
                      >
                        {action == "reply"
                          ? "Reply"
                          : action == "quote"
                          ? "Quote"
                          : "Repost"}
                      </Button>
                    )}

                    {spinner && (
                      <Button
                        type="button"
                        disabled={true}
                        className="login-button text-center mb-3 rounded-pill"
                      >
                        <BeatLoader color={"#fff"} />
                      </Button>
                    )}

                    <label
                      className="join-now-button text-center rounded-pill"
                      onClick={handleClose}
                    >
                      Cancel
                    </label>
                  </Col>
                </Row>
              </form>
            </Container>
          </Modal.Body>
        </Modal>

        <Row className="forced-margin justify-content-center">
          {/* <SideNav /> */}
          <RightNav />
          <Col lg={6} className={"p-0"}>
            <Stack>
              <div className={"login-elements-container border-hidde mb-5 p-0"}>
                <Row className="align-items-center">
                  <Row className={"align-items-center pb-0"}>
                    <Col lg={12} className={"pb-0"}>
                      <div className="p-3 pb-0 mb-0 border-botto">
                        <Row className={"align-items-center"}>
                          <Col lg={1} sm={1} xs={2}>
                            <label
                              className="back-arrow-post mb-0"
                              onClick={() => navigate(-1)}
                            >
                              <IoIosArrowRoundBack size={30} />
                            </label>
                          </Col>
                          <Col lg={11} sm={11} xs={10} className={""}>
                            <span className="text-bold fontSize-20">Post</span>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>

                  {currentPostStatus == "loading" ? (
                    <div className={"w-100 text-center mb-3 mt-3"}>
                      <ClipLoader size={25} color={"#333333"} />
                    </div>
                  ) : (
                    <>
                      <Row>
                        <Col
                          className={
                            !getCurrentPost ? "text-center mt-4 mb-3" : "d-none"
                          }
                        >
                          <PageNotFound />
                          <br />
                          <Link
                            to={"/explore"}
                            className="native-button-blue rounded-pill fontBold textUnderlineNone"
                          >
                            Search
                          </Link>
                        </Col>
                      </Row>
                      <Row
                        className={
                          !getCurrentPost ? "d-none" : "justify-content-center"
                        }
                        style={{
                          padding: 27,
                          paddingBottom: 0,
                          paddingRight: 5,
                        }}
                      >
                        <Col
                          lg={12}
                          className={
                            authorId != null ? "marginTop-15 mb-4" : "d-none"
                          }
                        >
                          <label className="fontSize14 footnote">
                            <HiOutlineRefresh size={16} /> Original Author:{" "}
                            <Link
                              to={"../../" + authorUsername}
                              className={"textUnderlineNone color-blue"}
                            >
                              {author.length > 15
                                ? author.slice(0, 15) + "..."
                                : author}
                            </Link>
                          </label>
                        </Col>

                        <Col
                          lg={1}
                          xs={1}
                          className={
                            !getCurrentPost ? "d-none" : "marginTop-15"
                          }
                        >
                          <Link
                            to={"../../" + getCurrentPost.data.user.username}
                            className="textUnderlineNone"
                          >
                            <div
                              className={"profile-pic"}
                              style={{
                                backgroundImage:
                                  "url(" +
                                  process.env.REACT_APP_URL.replace("api", "") +
                                  "public/profile/picture/@" +
                                  getCurrentPost.data.user.username +
                                  "/" +
                                  getCurrentPost.data.user.profile_pic +
                                  ")",
                              }}
                            />
                          </Link>
                        </Col>
                        <Col
                          lg={11}
                          xs={11}
                          className={"marginTop-15 mobileTimelinePaddingLeft30"}
                          style={{ paddingLeft: 20 }}
                        >
                          <Link
                            to={"../../" + getCurrentPost.data.user.username}
                            className="textUnderlineNone"
                          >
                            <label className="color-black fontBold line-height-20">
                              {`${getCurrentPost.data.user.first_name} ${getCurrentPost.data.user.last_name}`}
                              <br />

                              <label className="footnote cursorPointer">
                                @{getCurrentPost.data.user.username}
                              </label>
                            </label>{" "}
                          </Link>
                        </Col>
                        <Col lg={12} className="mt-2">
                          <Row
                            className={
                              getCurrentPost.data.topic == null
                                ? "d-none"
                                : "mb-0"
                            }
                          >
                            <Col lg={12}>
                              <Link
                                to={
                                  getCurrentPost.data.topic == null
                                    ? ""
                                    : "/topicPosts?clicked=" +
                                      getCurrentPost.data.topic +
                                      "&from_post_id=" +
                                      post_uuid
                                }
                              >
                                <label
                                  className={
                                    "topics fontSize14 cursorPointer textUnderlineNone underlineHover text-normal"
                                  }
                                >
                                  {getCurrentPost.data.topic == null ? (
                                    ""
                                  ) : (
                                    <>
                                      <IoBookOutline size={15} />{" "}
                                      {getCurrentPost.data.topic}
                                    </>
                                  )}
                                </label>
                              </Link>
                            </Col>
                          </Row>
                          <Stack className="stack">
                            <label className="color-black content-holder-timeline whiteSpace currentStatus mb-1">
                              {currentPostStatus == "success" ? (
                                <Tagify
                                  onClick={(text, type) =>
                                    hashtag(text, type, post_uuid)
                                  }
                                  detectHashtags={true}
                                  detectMentions={true}
                                  tagStyle={tagStyle}
                                  mentionStyle={mentionStyle}
                                  detectLinks={true}
                                >
                                  <Linkify
                                    componentDecorator={(
                                      decoratedHref,
                                      decoratedText,
                                      key
                                    ) => (
                                      <SecureLink
                                        target="_blank"
                                        href={decoratedHref}
                                        key={key}
                                        className={
                                          "color-blue textUnderlineNone"
                                        }
                                      >
                                        {decoratedText}
                                      </SecureLink>
                                    )}
                                  >
                                    {getCurrentPost.data.current_status.post}
                                  </Linkify>
                                </Tagify>
                              ) : (
                                ""
                              )}
                            </label>
                            {repostDocuments.length == 0 ? (
                              ""
                            ) : (
                              <Row className={"mt-0"}>
                                <div className={"padding-20"}>
                                  {repostDocuments.map((doc, index) => (
                                    <a
                                      href={
                                        process.env.REACT_APP_URL.replace(
                                          "api",
                                          ""
                                        ) +
                                        "public/timeline/documents/" +
                                        authorUsername.replace("@", "") +
                                        "/" +
                                        doc.file
                                      }
                                      target={"_blank"}
                                      className={"textUnderlineNone"}
                                    >
                                      <Row
                                        className={
                                          "align-items-center document-link-timeline"
                                        }
                                      >
                                        <Col
                                          lg={12}
                                          key={index}
                                          className={"p-0"}
                                        >
                                          <div className={""}>
                                            {doc.format == "pdf" ? (
                                              <label className="p-1">
                                                <FaRegFilePdf
                                                  size={20}
                                                  className={"color-red"}
                                                />
                                              </label>
                                            ) : doc.format == "docx" ? (
                                              <label className="p-1">
                                                <FaRegFileWord
                                                  size={20}
                                                  className={"color-blue"}
                                                />
                                              </label>
                                            ) : doc.format == "csv" ? (
                                              <label className="p-1">
                                                <FaFileCsv
                                                  size={20}
                                                  className={"color-green"}
                                                />
                                              </label>
                                            ) : doc.format == "xlsx" ? (
                                              <label className="p-1">
                                                <FaRegFileExcel
                                                  size={20}
                                                  className={"color-green"}
                                                />
                                              </label>
                                            ) : doc.format == "pptx" ? (
                                              <label className="p-1">
                                                <img
                                                  src={
                                                    process.env.REACT_APP_URL.replace(
                                                      "api",
                                                      ""
                                                    ) + "/pptx_icons.png"
                                                  }
                                                  style={{ width: 40 }}
                                                />
                                              </label>
                                            ) : (
                                              ""
                                            )}
                                            {doc.title == ""
                                              ? doc.file
                                              : doc.title + "." + doc.format}
                                          </div>
                                        </Col>
                                      </Row>
                                    </a>
                                  ))}
                                </div>
                              </Row>
                            )}

                            {currentPostStatus == "loading" ? (
                              <div className="text-center mb-5">
                                <ClipLoader size={25} color={"#333333"} />
                              </div>
                            ) : (
                              ""
                            )}
                          </Stack>
                        </Col>

                        {getCurrentPost.data.documents.length == 0 ? (
                          ""
                        ) : (
                          <div className={"paddingLeftIRight mb-2 mt-0"}>
                            {getCurrentPost.data.documents.map((doc, index) => (
                              <a
                                href={
                                  process.env.REACT_APP_URL.replace("api", "") +
                                  "public/timeline/documents/" +
                                  getCurrentPost.data.user.username.replace(
                                    "@",
                                    ""
                                  ) +
                                  "/" +
                                  doc.file
                                }
                                target={"_blank"}
                                className={"textUnderlineNone"}
                              >
                                <Row
                                  className={
                                    "align-items-center document-link-timeline"
                                  }
                                >
                                  <Col lg={12} key={index} className={"p-0"}>
                                    <div className={""}>
                                      {doc.format == "pdf" ? (
                                        <label className="p-1">
                                          <FaRegFilePdf
                                            size={20}
                                            className={"color-red"}
                                          />
                                        </label>
                                      ) : doc.format == "docx" ? (
                                        <label className="p-1">
                                          <FaRegFileWord
                                            size={20}
                                            className={"color-blue"}
                                          />
                                        </label>
                                      ) : doc.format == "csv" ? (
                                        <label className="p-1">
                                          <FaFileCsv
                                            size={20}
                                            className={"color-green"}
                                          />
                                        </label>
                                      ) : doc.format == "xlsx" ? (
                                        <label className="p-1">
                                          <FaRegFileExcel
                                            size={20}
                                            className={"color-green"}
                                          />
                                        </label>
                                      ) : doc.format == "pptx" ? (
                                        <label className="p-1">
                                          <FaRegFilePowerpoint
                                            size={20}
                                            className={"color-orange"}
                                          />
                                        </label>
                                      ) : (
                                        ""
                                      )}
                                      {doc.title == ""
                                        ? doc.file
                                        : doc.title + "." + doc.format}
                                    </div>
                                  </Col>
                                </Row>
                              </a>
                            ))}
                          </div>
                        )}
                        {getCurrentPost.data.media.length == 0 ? (
                          ""
                        ) : (
                          <Row className="mb-3 p-0">
                            {getCurrentPost.data.media.map((item, index) => (
                              <Col
                                className={
                                  getCurrentPost.data.media.length == 1
                                    ? "col-12"
                                    : getCurrentPost.data.media.length == 2
                                    ? "col-6 paddingSides" + index
                                    : getCurrentPost.data.media.length == 3
                                    ? "col-4 threePaddingSides" + index
                                    : getCurrentPost.data.media.length == 4
                                    ? "col-6 fourPaddingSides" + index
                                    : "col-6"
                                }
                                key={index}
                              >
                                <div
                                  // onClick={() =>
                                  //   getPhotos(username, post_uuid, item.id)
                                  // }
                                  className={
                                    getCurrentPost.data.media.length == 2
                                      ? "mb-3 border-radius" + index
                                      : getCurrentPost.data.media.length == 3
                                      ? "borderRadiusImg" + index
                                      : getCurrentPost.data.media.length == 4
                                      ? "borderRadiusImgFour" + index
                                      : "mb-3 border-radius-5"
                                  }
                                  style={{
                                    backgroundImage:
                                      "url(" +
                                      process.env.REACT_APP_URL.replace(
                                        "api",
                                        ""
                                      ) +
                                      "public/timeline/media/" +
                                      getCurrentPost.data.user.username.replace(
                                        "@",
                                        ""
                                      ) +
                                      "/" +
                                      item.file +
                                      ")",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    height:
                                      getCurrentPost.data.media.length == 1
                                        ? 320
                                        : getCurrentPost.data.media.length == 2
                                        ? 230
                                        : getCurrentPost.data.media.length == 4
                                        ? 200
                                        : 230,
                                    width: "100%",
                                    border: "thin solid #ececec",

                                    // backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center",
                                  }}
                                />
                              </Col>
                            ))}
                          </Row>
                        )}

                        <>
                          {getCurrentPost.data.quotedData.length == 0 ? (
                            ""
                          ) : (
                            <Row
                              className={
                                getCurrentPost.data.quotedData
                                  .linked_quote_post_id == post_uuid
                                  ? ""
                                  : "d-none"
                              }
                            >
                              <Col lg={12} className="p-0">
                                <div className="quote-post mt-1 whiteSpace mb-2">
                                  <Row>
                                    <Col lg={2} sm={2} xs={2}>
                                      <Link
                                        to={
                                          "/" +
                                          getCurrentPost.data.quotedData.quoted_username.replace(
                                            "@",
                                            ""
                                          )
                                        }
                                        className={
                                          "color-black textUnderlineNone cursorPointer"
                                        }
                                      >
                                        <div
                                          className={"profile-pic"}
                                          style={{
                                            backgroundImage:
                                              "url(" +
                                              process.env.REACT_APP_URL.replace(
                                                "api",
                                                ""
                                              ) +
                                              "public/profile/picture/" +
                                              getCurrentPost.data.quotedData
                                                .quoted_username +
                                              "/" +
                                              getCurrentPost.data.quotedData
                                                .quoted_profile_pic +
                                              ")",
                                          }}
                                        />
                                      </Link>
                                    </Col>
                                    <Col
                                      lg={10}
                                      sm={10}
                                      xs={10}
                                      className={
                                        "marginLeft-40 mobileTimelineMarginLeft"
                                      }
                                    >
                                      <Link
                                        to={
                                          "/" +
                                          getCurrentPost.data.quotedData.quoted_username.replace(
                                            "@",
                                            ""
                                          )
                                        }
                                        className={
                                          "color-black textUnderlineNone cursorPointer"
                                        }
                                      >
                                        <label className="line-height-20">
                                          <span className="fontBold">
                                            {
                                              getCurrentPost.data.quotedData
                                                .quoted_fullname
                                            }
                                          </span>
                                          <br />
                                          <span className="footnote">
                                            {
                                              getCurrentPost.data.quotedData
                                                .quoted_username
                                            }
                                          </span>
                                        </label>
                                      </Link>
                                    </Col>
                                    <Col lg={12} className="mt-2">
                                      <div
                                        className={
                                          getCurrentPost.data.quotedData
                                            .quotedSubject == null
                                            ? "d-none"
                                            : ""
                                        }
                                      >
                                        <Link
                                          to={
                                            getCurrentPost.data.quotedData
                                              .quotedTopic == null
                                              ? ""
                                              : "/topicPosts?clicked=" +
                                                getCurrentPost.data.quotedData
                                                  .quotedTopic +
                                                "&from_post_id=" +
                                                getCurrentPost.data.quotedData
                                                  .quoted_post_id
                                          }
                                        >
                                          <label
                                            className={
                                              "topics fontSize14 cursorPointer textUnderlineNone underlineHover text-normal"
                                            }
                                          >
                                            {getCurrentPost.data.quotedData
                                              .quotedTopic == null ? (
                                              ""
                                            ) : (
                                              <>
                                                <IoBookOutline size={15} />{" "}
                                                {
                                                  getCurrentPost.data.quotedData
                                                    .quotedTopic
                                                }
                                              </>
                                            )}
                                          </label>
                                        </Link>
                                      </div>
                                      <div
                                        className={"cursorPointer w-100"}
                                        onClick={() =>
                                          replyPage(
                                            getCurrentPost.data.quotedData
                                              .quoted_post,
                                            getCurrentPost.data.quotedData
                                              .quoted_fullname,
                                            getCurrentPost.data.quotedData.quoted_username.replace(
                                              "@",
                                              ""
                                            ),
                                            getCurrentPost.data.quotedData
                                              .quoted_post_id
                                          )
                                        }
                                      >
                                        {getCurrentPost.data.quotedData
                                          .quoted_post == null ? (
                                          ""
                                        ) : getCurrentPost.data.quotedData
                                            .quoted_post.length > 150 ? (
                                          <>
                                            {showMoreQuotedPostText == true ? (
                                              <Tagify
                                                onClick={(text, type) =>
                                                  hashtag(
                                                    text,
                                                    type,
                                                    getCurrentPost.data
                                                      .quotedData.quoted_post_id
                                                  )
                                                }
                                                detectHashtags={true}
                                                detectMentions={true}
                                                tagStyle={tagStyle}
                                                mentionStyle={mentionStyle}
                                                detectLinks={true}
                                              >
                                                <Linkify
                                                  componentDecorator={(
                                                    decoratedHref,
                                                    decoratedText,
                                                    key
                                                  ) => (
                                                    <SecureLink
                                                      target="_blank"
                                                      href={decoratedHref}
                                                      key={key}
                                                      className={
                                                        "color-blue textUnderlineNone"
                                                      }
                                                    >
                                                      {decoratedText}
                                                    </SecureLink>
                                                  )}
                                                >
                                                  {
                                                    getCurrentPost.data
                                                      .quotedData.quoted_post
                                                  }
                                                </Linkify>
                                              </Tagify>
                                            ) : (
                                              <Tagify
                                                onClick={(text, type) =>
                                                  hashtag(text, type)
                                                }
                                                detectHashtags={true}
                                                detectMentions={true}
                                                tagStyle={tagStyle}
                                                mentionStyle={mentionStyle}
                                                detectLinks={true}
                                              >
                                                <Linkify
                                                  componentDecorator={(
                                                    decoratedHref,
                                                    decoratedText,
                                                    key
                                                  ) => (
                                                    <SecureLink
                                                      target="_blank"
                                                      href={decoratedHref}
                                                      key={key}
                                                      className={
                                                        "color-blue textUnderlineNone"
                                                      }
                                                    >
                                                      {decoratedText}
                                                    </SecureLink>
                                                  )}
                                                >
                                                  {getCurrentPost.data.quotedData.quoted_post.slice(
                                                    0,
                                                    150
                                                  )}
                                                </Linkify>
                                              </Tagify>
                                            )}

                                            <span className={"text-normal"}>
                                              <label
                                                className={
                                                  "color-blue cursorPointer"
                                                }
                                                onClick={showMoreQuotedPost}
                                              >
                                                {showMoreQuotedPostText == true
                                                  ? " Show less"
                                                  : "... Show more"}
                                              </label>
                                            </span>
                                          </>
                                        ) : (
                                          <>
                                            <span className="cursorPointer">
                                              <Tagify
                                                onClick={(text, type) =>
                                                  hashtag(
                                                    text,
                                                    type,
                                                    getCurrentPost.data
                                                      .quotedData.quoted_post_id
                                                  )
                                                }
                                                detectHashtags={true}
                                                detectMentions={true}
                                                tagStyle={tagStyle}
                                                mentionStyle={mentionStyle}
                                                detectLinks={true}
                                              >
                                                <Linkify
                                                  componentDecorator={(
                                                    decoratedHref,
                                                    decoratedText,
                                                    key
                                                  ) => (
                                                    <SecureLink
                                                      target="_blank"
                                                      href={decoratedHref}
                                                      key={key}
                                                      className={
                                                        "color-blue textUnderlineNone"
                                                      }
                                                    >
                                                      {decoratedText}
                                                    </SecureLink>
                                                  )}
                                                >
                                                  {
                                                    getCurrentPost.data
                                                      .quotedData.quoted_post
                                                  }
                                                </Linkify>
                                              </Tagify>
                                            </span>
                                          </>
                                        )}
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row
                                    className={
                                      getCurrentPost.data.quotedData
                                        .quoted_documents.length > 0
                                        ? "mt-0 paddingLeft-13 paddingRight-13"
                                        : "d-none"
                                    }
                                  >
                                    {getCurrentPost.data.quotedData.quoted_documents.map(
                                      (doc, index) => (
                                        <Col lg={12} key={index} className="">
                                          <a
                                            href={
                                              process.env.REACT_APP_URL.replace(
                                                "api",
                                                ""
                                              ) +
                                              "public/timeline/documents/" +
                                              getCurrentPost.data.quotedData.quoted_username.replace(
                                                "@",
                                                ""
                                              ) +
                                              "/" +
                                              doc.file
                                            }
                                            target={"_blank"}
                                            className={"textUnderlineNone"}
                                          >
                                            <Row
                                              className={
                                                "align-items-center document-link-timeline"
                                              }
                                            >
                                              <Col
                                                lg={12}
                                                key={index}
                                                className={"p-0"}
                                              >
                                                <div className={""}>
                                                  {doc.format == "pdf" ? (
                                                    <label className="p-1">
                                                      <FaRegFilePdf
                                                        size={20}
                                                        className={"color-red"}
                                                      />
                                                    </label>
                                                  ) : doc.format == "docx" ? (
                                                    <label className="p-1">
                                                      <FaRegFileWord
                                                        size={20}
                                                        className={"color-blue"}
                                                      />
                                                    </label>
                                                  ) : doc.format == "csv" ? (
                                                    <label className="p-1">
                                                      <FaFileCsv
                                                        size={20}
                                                        className={
                                                          "color-green"
                                                        }
                                                      />
                                                    </label>
                                                  ) : doc.format == "xlsx" ? (
                                                    <label className="p-1">
                                                      <FaRegFileExcel
                                                        size={20}
                                                        className={
                                                          "color-green"
                                                        }
                                                      />
                                                    </label>
                                                  ) : doc.format == "pptx" ? (
                                                    <label className="p-1">
                                                      <FaRegFilePowerpoint
                                                        size={20}
                                                        className={
                                                          "color-orange"
                                                        }
                                                      />
                                                    </label>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {doc.title == ""
                                                    ? doc.file
                                                    : doc.title +
                                                      "." +
                                                      doc.format}
                                                </div>
                                              </Col>
                                            </Row>
                                          </a>
                                        </Col>
                                      )
                                    )}
                                  </Row>
                                  <Row
                                    className={
                                      getCurrentPost.data.quotedData
                                        .quoted_media.length > 0
                                        ? "mt-1"
                                        : "d-none"
                                    }
                                  >
                                    {getCurrentPost.data.quotedData.quoted_media.map(
                                      (img, index) => (
                                        <Col
                                          className={
                                            getCurrentPost.data.quotedData
                                              .quoted_media.length == 1
                                              ? "col-12"
                                              : getCurrentPost.data.quotedData
                                                  .quoted_media.length == 2
                                              ? "col-6 paddingSides" + index
                                              : getCurrentPost.data.quotedData
                                                  .quoted_media.length == 3
                                              ? "col-4 threePaddingSides" +
                                                index
                                              : getCurrentPost.data.quotedData
                                                  .quoted_media.length == 4
                                              ? "col-6 fourPaddingSides" + index
                                              : "col-6"
                                          }
                                        >
                                          <div
                                            onClick={() =>
                                              getPhotos(
                                                getCurrentPost.data.quotedData.quoted_username.replace(
                                                  "@",
                                                  ""
                                                ),
                                                getCurrentPost.data.quotedData
                                                  .quoted_post_id,
                                                img.id,
                                                getCurrentPost.data.quotedData
                                                  .quoted_media,
                                                index
                                              )
                                            }
                                            className={
                                              getCurrentPost.data.quotedData
                                                .quoted_media.length == 2
                                                ? "mb-0 border-radius" + index
                                                : getCurrentPost.data.quotedData
                                                    .quoted_media.length == 3
                                                ? "borderRadiusImg" + index
                                                : getCurrentPost.data.quotedData
                                                    .quoted_media.length == 4
                                                ? "borderRadiusImgFour" + index
                                                : "mb-0 border-radius-5"
                                            }
                                            style={{
                                              backgroundImage:
                                                "url(" +
                                                process.env.REACT_APP_URL.replace(
                                                  "api",
                                                  ""
                                                ) +
                                                "public/timeline/media/" +
                                                getCurrentPost.data.quotedData.quoted_username.replace(
                                                  "@",
                                                  ""
                                                ) +
                                                "/" +
                                                img.file +
                                                ")",
                                              backgroundSize: "cover",
                                              height:
                                                getCurrentPost.data.quotedData
                                                  .quoted_media.length == 1
                                                  ? 300
                                                  : getCurrentPost.data
                                                      .quotedData.quoted_media
                                                      .length == 2
                                                  ? 300
                                                  : getCurrentPost.data
                                                      .quotedData.quoted_media
                                                      .length == 4
                                                  ? 150
                                                  : 200,
                                              width: "100%",
                                              border: "thin solid #ececec",

                                              // backgroundRepeat: "no-repeat",
                                              backgroundPosition: "center",
                                            }}
                                          />
                                        </Col>
                                      )
                                    )}
                                  </Row>
                                  <Row
                                    className={
                                      getCurrentPost.data.quotedData
                                        .quoted_polls.length > 0
                                        ? "mt-2"
                                        : "d-none"
                                    }
                                  >
                                    <div
                                      className={
                                        getCurrentPost.data.did_i_vote == 1 ||
                                        (getCurrentPost.data.pollDuration
                                          .days <= 0 &&
                                          getCurrentPost.data.pollDuration
                                            .hours <= 0 &&
                                          getCurrentPost.data.pollDuration
                                            .minutes <= 0 &&
                                          getCurrentPost.data.pollDuration
                                            .seconds <= 0)
                                          ? "col-12"
                                          : "d-none"
                                      }
                                    >
                                      {getCurrentPost.data.pollResults.map(
                                        (results, index) => (
                                          <div
                                            className={
                                              results.percentage == 0
                                                ? "w-100 resulst-polls-container-no-votes"
                                                : "w-100 resulst-polls-container"
                                            }
                                            key={index}
                                          >
                                            <label
                                              className={
                                                results.percentage == 0
                                                  ? ""
                                                  : "polls-voted color-black"
                                              }
                                              style={{
                                                width: results.percentage + "%",
                                              }}
                                            />
                                            <label className={"color-black"}>
                                              {results.options}{" "}
                                              {results.who_i_voted_for == 1 ? (
                                                <IoCheckmarkCircleOutline
                                                  size={20}
                                                  color={"#66b266"}
                                                />
                                              ) : (
                                                ""
                                              )}
                                            </label>
                                            <label
                                              className={
                                                "float-right color-black"
                                              }
                                            >
                                              {results.percentage}%
                                            </label>
                                          </div>
                                        )
                                      )}
                                    </div>
                                    <div
                                      className={
                                        getCurrentPost.data.did_i_vote == 1 ||
                                        (getCurrentPost.data.pollDuration
                                          .days <= 0 &&
                                          getCurrentPost.data.pollDuration
                                            .hours <= 0 &&
                                          getCurrentPost.data.pollDuration
                                            .minutes <= 0 &&
                                          getCurrentPost.data.pollDuration
                                            .seconds <= 0)
                                          ? "d-none"
                                          : "col-12"
                                      }
                                    >
                                      {getCurrentPost.data.polls.map(
                                        (polls, index) => (
                                          <Col lg={12} key={index}>
                                            <div
                                              className={
                                                "col-12 polls fontBold"
                                              }
                                              onClick={() =>
                                                vote(polls.id, polls.post_id)
                                              }
                                            >
                                              {polls.option}
                                            </div>
                                          </Col>
                                        )
                                      )}
                                    </div>
                                    <Col lg={12}>
                                      <label className="footnote">
                                        {getCurrentPost.data.votes}{" "}
                                        {getCurrentPost.data.votes == 1
                                          ? "vote"
                                          : "votes"}{" "}
                                        -{" "}
                                        {getCurrentPost.data.pollDuration
                                          .minutes <= 0 &&
                                        getCurrentPost.data.pollDuration.days <=
                                          0 &&
                                        getCurrentPost.data.pollDuration
                                          .hours <= 0 &&
                                        getCurrentPost.data.pollDuration
                                          .seconds <= 0
                                          ? "Poll closed."
                                          : getCurrentPost.data.pollDuration
                                              .days >= 1
                                          ? getCurrentPost.data.pollDuration
                                              .days + "d left"
                                          : getCurrentPost.data.pollDuration
                                              .hours >= 1 &&
                                            getCurrentPost.data.pollDuration
                                              .minutes >= 1
                                          ? getCurrentPost.data.pollDuration
                                              .hours +
                                            "h and " +
                                            getCurrentPost.data.pollDuration
                                              .minutes +
                                            "m left"
                                          : getCurrentPost.data.pollDuration
                                              .hours >= 1 &&
                                            getCurrentPost.data.pollDuration
                                              .minutes == 0
                                          ? getCurrentPost.data.pollDuration
                                              .hours + "h left"
                                          : getCurrentPost.data.pollDuration
                                              .minutes >= 1
                                          ? getCurrentPost.data.pollDuration
                                              .minutes + "m left"
                                          : "Poll closed."}
                                      </label>
                                    </Col>
                                  </Row>
                                  <Row className={"mt-2"}>
                                    <Col lg={12}>
                                      <label className="footnote">
                                        {
                                          getCurrentPost.data.quotedData
                                            .quoted_post_date
                                        }
                                      </label>
                                    </Col>
                                    <Col lg={12} className="mt-0">
                                      <label className="footnote fontSize14">
                                        <label
                                          onClick={() =>
                                            addLike(
                                              getCurrentPost.data.quotedData
                                                .quoted_post_id,
                                              "status"
                                            )
                                          }
                                          className={
                                            getCurrentPost.data.quotedData
                                              .quoted_liked_by_me
                                              ? "anchor-link hover color-blue"
                                              : "anchor-link hover color-grey"
                                          }
                                          data-tip={"Like"}
                                          data-place={"bottom"}
                                          data-effect={"solid"}
                                        >
                                          {
                                            getCurrentPost.data.quotedData
                                              .quoted_like_count
                                          }
                                          &nbsp;Likes
                                          <ReactTooltip
                                            arrowColor={"transparent"}
                                            className="p-1 fontSize-12"
                                          />
                                        </label>
                                        &nbsp;&nbsp;&nbsp;
                                        <label
                                          onClick={
                                            () =>
                                              reply(
                                                getCurrentPost.data.quotedData
                                                  .quoted_post,
                                                getCurrentPost.data.quotedData
                                                  .quoted_fullname,
                                                getCurrentPost.data.quotedData.quoted_username.replace(
                                                  "@",
                                                  ""
                                                ),
                                                getCurrentPost.data.quotedData
                                                  .quoted_post_id,
                                                getCurrentPost.data.quotedData
                                                  .quoted_profile_pic,
                                                getCurrentPost.data.quotedData
                                                  .quoted_polls,
                                                getCurrentPost.data.quotedData
                                                  .quoted_did_i_vote,
                                                getCurrentPost.data.quotedData
                                                  .quoted_pollDuration,
                                                getCurrentPost.data.quotedData
                                                  .quoted_pollResults,
                                                getCurrentPost.data.quotedData
                                                  .quoted_votes,
                                                getCurrentPost.data.quotedData
                                                  .quoted_media,
                                                getCurrentPost.data.quotedData
                                                  .quoted_documents,
                                                getCurrentPost.data.quotedData
                                                  .quotedSubject != null
                                                  ? getCurrentPost.data
                                                      .quotedData.quotedSubject
                                                  : null,
                                                getCurrentPost.data.quotedData
                                                  .quotedTopic != null
                                                  ? getCurrentPost.data
                                                      .quotedData.quotedTopic
                                                  : null,
                                                getCurrentPost.data.quotedData
                                                  .quoted_polls,
                                                "reply",
                                                "status"
                                              )
                                            // replyPage(
                                            //   getCurrentPost.data.quotedData
                                            //     .quoted_post,
                                            //   getCurrentPost.data.quotedData
                                            //     .quoted_fullname,
                                            //   getCurrentPost.data.quotedData
                                            //     .quoted_username,
                                            //   getCurrentPost.data.quotedData
                                            //     .quoted_post_id
                                            // )
                                          }
                                          className={
                                            getCurrentPost.data.quotedData
                                              .quoted_did_reply_to_post
                                              ? "anchor-link hover color-blue"
                                              : "anchor-link hover color-grey"
                                          }
                                          data-tip={"Reply"}
                                          data-place={"bottom"}
                                          data-effect={"solid"}
                                        >
                                          {
                                            getCurrentPost.data.quotedData
                                              .quoted_reply_count
                                          }
                                          &nbsp;Replies
                                          <ReactTooltip
                                            arrowColor={"transparent"}
                                            className="p-1 fontSize-12"
                                          />
                                        </label>{" "}
                                        &nbsp;&nbsp;&nbsp;
                                        <label
                                          onClick={() =>
                                            Bookmark(
                                              getCurrentPost.data.quotedData
                                                .quoted_post_id,
                                              "status"
                                            )
                                          }
                                          className={
                                            getCurrentPost.data.quotedData
                                              .quoted_bookmarked_by_me
                                              ? "anchor-link hover color-blue"
                                              : "anchor-link hover color-grey"
                                          }
                                          data-tip={"Bookmark"}
                                          data-place={"bottom"}
                                          data-effect={"solid"}
                                        >
                                          {
                                            getCurrentPost.data.quotedData
                                              .quoted_bookmark_count
                                          }
                                          &nbsp;Bookmarks
                                          <ReactTooltip
                                            arrowColor={"transparent"}
                                            className="p-1 fontSize-12"
                                          />
                                        </label>{" "}
                                        &nbsp;&nbsp;&nbsp;
                                        <label
                                          className={
                                            getCurrentPost.data.quotedData
                                              .quoted_did_repost_to_post
                                              ? "color-blue"
                                              : "text-silver"
                                          }
                                          data-tip={"Just reposts"}
                                          data-place={"bottom"}
                                          data-effect={"solid"}
                                        >
                                          {
                                            getCurrentPost.data.quotedData
                                              .quoted_repost_count
                                          }
                                          &nbsp;Reposts
                                          <ReactTooltip
                                            arrowColor={"transparent"}
                                            className="p-1 fontSize-12"
                                          />
                                        </label>
                                      </label>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                          )}
                        </>

                        <Row
                          className={
                            getCurrentPost.data.polls.length > 0 &&
                            linkedQuotePostId != post_uuid &&
                            !getCurrentPost.data.quotedData.quoted_post_id
                              ? "mt-3"
                              : "d-none"
                          }
                        >
                          <div
                            className={
                              getCurrentPost.data.did_i_vote == 1 ||
                              (getCurrentPost.data.pollDuration.minutes <= 0 &&
                                getCurrentPost.data.pollDuration.days <= 0 &&
                                getCurrentPost.data.pollDuration.hours <= 0 &&
                                getCurrentPost.data.pollDuration.seconds <= 0)
                                ? "col-12 p-0"
                                : "d-none"
                            }
                          >
                            {getCurrentPost.data.pollResults.map(
                              (results, index) => (
                                <div
                                  className={
                                    results.percentage == 0
                                      ? "w-100 resulst-polls-container-no-votes"
                                      : "w-100 resulst-polls-container"
                                  }
                                  key={index}
                                >
                                  <label
                                    className={
                                      results.percentage == 0
                                        ? ""
                                        : "polls-voted color-black"
                                    }
                                    style={{
                                      width: results.percentage + "%",
                                    }}
                                  />
                                  <label className={"color-black"}>
                                    {results.options}{" "}
                                    {results.who_i_voted_for == 1 ? (
                                      <IoCheckmarkCircleOutline
                                        size={20}
                                        color={"#66b266"}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </label>
                                  <label className={"float-right color-black"}>
                                    {results.percentage}%
                                  </label>
                                </div>
                              )
                            )}
                          </div>

                          <div
                            className={
                              getCurrentPost.data.did_i_vote == 1 ||
                              (getCurrentPost.data.pollDuration.minutes <= 0 &&
                                getCurrentPost.data.pollDuration.days <= 0 &&
                                getCurrentPost.data.pollDuration.hours <= 0 &&
                                getCurrentPost.data.pollDuration.seconds <= 0)
                                ? "d-none"
                                : "col-12 p-0"
                            }
                          >
                            {getCurrentPost.data.polls.map((polls, index) => (
                              <Col lg={12} key={index}>
                                <div
                                  className={"col-12 polls fontBold"}
                                  onClick={() => vote(polls.id, polls.post_id)}
                                >
                                  {polls.option}
                                </div>
                              </Col>
                            ))}
                          </div>
                          <Col lg={12} className="p-0">
                            <label className="footnote">
                              {getCurrentPost.data.votes}{" "}
                              {getCurrentPost.data.votes == 1
                                ? "vote"
                                : "votes"}{" "}
                              -{" "}
                              {getCurrentPost.data.pollDuration.minutes <= 0 &&
                              getCurrentPost.data.pollDuration.days <= 0 &&
                              getCurrentPost.data.pollDuration.hours <= 0 &&
                              getCurrentPost.data.pollDuration.seconds <= 0
                                ? "Poll closed."
                                : getCurrentPost.data.pollDuration.days >= 1
                                ? getCurrentPost.data.pollDuration.days +
                                  "d left"
                                : getCurrentPost.data.pollDuration.hours >= 1 &&
                                  getCurrentPost.data.pollDuration.minutes >= 1
                                ? getCurrentPost.data.pollDuration.hours +
                                  "h and " +
                                  getCurrentPost.data.pollDuration.minutes +
                                  "m left"
                                : getCurrentPost.data.pollDuration.hours >= 1 &&
                                  getCurrentPost.data.pollDuration.minutes == 0
                                ? getCurrentPost.data.pollDuration.hours +
                                  "h left"
                                : getCurrentPost.data.pollDuration.minutes >= 1
                                ? getCurrentPost.data.pollDuration.minutes +
                                  "m left"
                                : "Poll closed."}
                            </label>
                          </Col>
                        </Row>
                        <Col lg={12}>
                          <label className="footnote">
                            {getCurrentPost.data.current_status.created_at}
                          </label>
                        </Col>
                        <Col lg={12} className="pl-3 pr-3 mt-3">
                          <div className="border-top border-bottom pb-2 pt-2">
                            <Row className={"justify-content-center"}>
                              <Col className="text-center">
                                <label className={"color-light-grey"}>
                                  <span className="text-bold">
                                    {getCurrentPost.data.reply_count}
                                  </span>{" "}
                                  Replies
                                </label>
                              </Col>
                              <Col className="text-center">
                                <label
                                  className={"anchor-link hover color-grey"}
                                  onClick={viewPosted}
                                >
                                  <span className="text-bold">
                                    {getCurrentPost.data.repost_count}
                                  </span>{" "}
                                  Reposts
                                </label>
                              </Col>
                              <Col className="text-center">
                                <label
                                  className={"anchor-link hover color-grey"}
                                  onClick={viewLiked}
                                >
                                  {" "}
                                  <span className="text-bold">
                                    {getCurrentPost.data.like_count}
                                  </span>{" "}
                                  Likes
                                </label>
                              </Col>

                              {/* <Col>
                                <label
                                  className={
                                    "color-light-grey mobile-font-sizes-status"
                                  }
                                >
                                  {" "}
                                  <span className="text-bold">
                                    {getCurrentPost.data.bookmark_count}
                                  </span>{" "}
                                  Bookmarks
                                </label>
                              </Col> */}
                            </Row>
                          </div>
                        </Col>
                        <Col lg={12} className="pl-3 pr-3 mt-0">
                          <div className="border-bottom pb-2 pt-2">
                            <Row>
                              <Col className="text-center">
                                <label
                                  data-tip={"Reply"}
                                  data-place={"bottom"}
                                  data-effect={"solid"}
                                  className={
                                    getCurrentPost.data.did_reply_to_post
                                      ? "share-icons-reply-active share-icons-reply"
                                      : "share-icons-reply" +
                                        " anchor-link hover cursorPointer "
                                  }
                                  onClick={() =>
                                    reply(
                                      getCurrentPost.data.current_status.post,
                                      getCurrentPost.data.user.first_name +
                                        " " +
                                        getCurrentPost.data.user.last_name,
                                      getCurrentPost.data.user.username,
                                      post_uuid,
                                      getCurrentPost.data.user.profile_pic,
                                      getCurrentPost.data.quotedData
                                        .quoted_post_id
                                        ? []
                                        : getCurrentPost.data.polls,
                                      getCurrentPost.data.did_i_vote,
                                      getCurrentPost.data.pollDuration,
                                      getCurrentPost.data.pollResults,
                                      getCurrentPost.data.votes,
                                      getCurrentPost.data.media,
                                      getCurrentPost.data.documents,
                                      getCurrentPost.data.subject != null
                                        ? getCurrentPost.data.subject
                                        : null,
                                      getCurrentPost.data.topic != null
                                        ? getCurrentPost.data.topic
                                        : null,
                                      getCurrentPost.data.polls,
                                      "reply",
                                      "status"
                                    )
                                  }
                                >
                                  <IoChatboxOutline
                                    size={23}
                                    className={
                                      getCurrentPost.data.did_reply_to_post
                                        ? "d-none"
                                        : ""
                                    }
                                  />
                                  <IoChatbox
                                    size={23}
                                    className={
                                      !getCurrentPost.data.did_reply_to_post
                                        ? "d-none"
                                        : ""
                                    }
                                  />
                                  <ReactTooltip
                                    arrowColor={"transparent"}
                                    className="p-1 fontSize-12"
                                  />
                                </label>
                              </Col>
                              <Col className="text-center">
                                <label
                                  data-tip={"Repost"}
                                  data-place={"bottom"}
                                  data-effect={"solid"}
                                  className={
                                    getCurrentPost.data.did_repost_to_post
                                      ? "share-icons-repost-active share-icons-repost"
                                      : "share-icons-repost" +
                                        " anchor-link hover cursorPointer"
                                  }
                                  onClick={() =>
                                    reply(
                                      getCurrentPost.data.current_status.post,
                                      getCurrentPost.data.user.first_name +
                                        " " +
                                        getCurrentPost.data.user.last_name,
                                      getCurrentPost.data.user.username,
                                      post_uuid,
                                      getCurrentPost.data.user.profile_pic,
                                      getCurrentPost.data.polls,
                                      getCurrentPost.data.did_i_vote,
                                      getCurrentPost.data.pollDuration,
                                      getCurrentPost.data.pollResults,
                                      getCurrentPost.data.votes,
                                      getCurrentPost.data.media,
                                      getCurrentPost.data.documents,
                                      getCurrentPost.data.subject != null
                                        ? getCurrentPost.data.subject
                                        : null,
                                      getCurrentPost.data.topic != null
                                        ? getCurrentPost.data.topic
                                        : null,
                                      getCurrentPost.data.polls,
                                      "repost",
                                      "status"
                                    )
                                  }
                                >
                                  <BiShare
                                    size={23}
                                    className={
                                      getCurrentPost.data.did_repost_to_post
                                        ? "d-none"
                                        : ""
                                    }
                                  />
                                  <BiSolidShare
                                    size={23}
                                    className={
                                      !getCurrentPost.data.did_repost_to_post
                                        ? "d-none"
                                        : ""
                                    }
                                  />
                                  <ReactTooltip
                                    arrowColor={"transparent"}
                                    className="p-1 fontSize-12"
                                  />
                                </label>
                              </Col>
                              <Col className="text-center">
                                <label
                                  data-tip={"Like"}
                                  data-place={"bottom"}
                                  data-effect={"solid"}
                                  className={
                                    getCurrentPost.data.liked_by_me
                                      ? "share-icons-like-active share-icons-like"
                                      : "share-icons-like" +
                                        " anchor-link hover cursorPointer"
                                  }
                                  onClick={() =>
                                    addLike(post_uuid, "status_page")
                                  }
                                >
                                  {" "}
                                  <BsHandThumbsUp
                                    size={23}
                                    className={
                                      getCurrentPost.data.liked_by_me
                                        ? "d-none"
                                        : ""
                                    }
                                  />
                                  <BsHandThumbsUpFill
                                    size={23}
                                    className={
                                      !getCurrentPost.data.liked_by_me
                                        ? "d-none"
                                        : ""
                                    }
                                  />
                                  <ReactTooltip
                                    arrowColor={"transparent"}
                                    className="p-1 fontSize-12"
                                  />
                                </label>
                              </Col>

                              <Col className={"text-center"}>
                                <label
                                  data-tip={"Bookmark"}
                                  data-place={"bottom"}
                                  data-effect={"solid"}
                                  className={
                                    getCurrentPost.data.bookmarked_by_me
                                      ? "share-icons-bookmark-active share-icons-bookmark"
                                      : "share-icons-bookmark" +
                                        " anchor-link hover cursorPointer"
                                  }
                                  onClick={() => Bookmark(post_uuid)}
                                >
                                  {" "}
                                  <BsBookmarkPlus
                                    size={23}
                                    className={
                                      getCurrentPost.data.bookmarked_by_me
                                        ? "d-none"
                                        : ""
                                    }
                                  />
                                  <BsFillBookmarkFill
                                    size={23}
                                    className={
                                      !getCurrentPost.data.bookmarked_by_me
                                        ? "d-none"
                                        : ""
                                    }
                                  />
                                  <ReactTooltip
                                    arrowColor={"transparent"}
                                    className="p-1 fontSize-12"
                                  />
                                </label>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col lg={12} className="mt-3">
                          <label className="color-grey mobile-font-sizes14">
                            Reply to
                          </label>{" "}
                          <Link
                            to={"../../" + getCurrentPost.data.user.username}
                            className="footnote"
                          >
                            <label className="color-black cursorPointer mobile-font-sizes14 underlineHover">
                              <b className="color-black">
                                {getCurrentPost.data.user.first_name +
                                  " " +
                                  getCurrentPost.data.user.last_name}
                              </b>{" "}
                              <span className={"footnote"}>
                                @{getCurrentPost.data.user.username}
                              </span>
                            </label>
                          </Link>
                        </Col>
                        <Col lg={12}>
                          <form onSubmit={addReply}>
                            <textarea
                              className={
                                replyTextarea_size +
                                " " +
                                textearTextSize +
                                " textarea-share-post " +
                                borderLine
                              }
                              placeholder={pollQuestionPlaceholderReply}
                              value={post}
                              onChange={shareTextarea}
                              onClick={() => replyTextareaFocus(post_uuid)}
                              ref={subInputElement}
                            />
                            <div
                              className={
                                warningText == null ? "d-none" : "col-12"
                              }
                            >
                              <label className="color-red fontSize14">
                                {warningText}
                              </label>
                            </div>
                            <PreviewMedia
                              preview={preview}
                              clearAttachment={clearAttachment}
                              className={preview.length > 0 ? "" : "d-none"}
                            />
                            <PreviewDocuments
                              previewDoc={previewDoc}
                              clearAttachment={clearAttachment}
                              docTitle={docTitle}
                              tags={tags}
                              previewDocURL={previewDocURL}
                              className={previewDoc.length > 0 ? "" : "d-none"}
                            />
                            <div
                              className={openPoll == false ? "d-none" : "mb-3"}
                            >
                              <Polls
                                openPoll={openPoll}
                                optionOne={optionOne}
                                optionTwo={optionTwo}
                                optionThree={optionThree}
                                optionFour={optionFour}
                                optionCount={optionCount}
                                removePollOption={removePollOption}
                                setOptionOne={setOptionOne}
                                setOptionTwo={setOptionTwo}
                                setOptionThree={setOptionThree}
                                setOptionFour={setOptionFour}
                                settingHours={settingHours}
                                settingDays={settingDays}
                                hours={hrs}
                                days={dys}
                                settingMinutes={settingMinutes}
                                minutes={mins}
                                addOption={addOption}
                                closePoll={closePoll}
                                opMinHide={opMinHide}
                              />
                            </div>
                            {hideEmoji && (
                              <div className="mb-3 mt-0 col-12 relative">
                                {/* <span
                                  className="text-normal float-right share-icons"
                                  data-tip={"Close"}
                                  data-place={"top"}
                                  data-effect={"solid"}
                                  onClick={emojies}
                                >
                                  <BsXLg size={13} />
                                  <ReactTooltip arrowColor={"transparent"} />
                                </span> */}
                                <Emojies
                                  handleClick={onEmojiClickSub}
                                  action={"post"}
                                  statusReply={true}
                                />
                              </div>
                            )}
                            <Row
                              className={
                                replyElements == true
                                  ? "align-items-center"
                                  : "d-none"
                              }
                            >
                              <Col
                                lg={12}
                                sm={12}
                                xs={12}
                                className="text-right"
                              >
                                <Button
                                  type="submit"
                                  className="login-button no-shadow btn__width__auto fontBold float-right rounded-pill"
                                  disabled={disableBtn}
                                >
                                  <IoSendOutline size={18} /> Reply
                                </Button>
                              </Col>
                              <Col lg={12} sm={12} xs={12}>
                                <Elements
                                  imageUpload={imageUpload}
                                  documentUpload={documentUpload}
                                  emojies={emojies}
                                  chosenEmoji={chosenEmoji}
                                  closePoll={closePoll}
                                  subjectSelected={subjectSelected}
                                  selectedSubject={selectedSubject}
                                  selectedTopic={selectedTopic}
                                  topicSelected={topicSelected}
                                  showTopics={showTopics}
                                  showSubjects={showSubjects}
                                  resetFunc={resetFunc}
                                  resetSubject={resetSubject}
                                  currentPostPolls={currentPostPolls}
                                  action={action}
                                />
                              </Col>
                            </Row>
                          </form>
                        </Col>
                      </Row>
                    </>
                  )}

                  <Replies postID={post_uuid} />
                </Row>
              </div>
            </Stack>
          </Col>

          {/* <RightNav /> */}
        </Row>
        {bookMarkStatus && (
          <Row>
            <Col lg={4}>
              <div className="copiedText color-white">{bookmarkMessage}</div>
            </Col>
          </Row>
        )}
      </Container>
      <Footer
        postSentFeedback={info ? info.message : null}
        postSent={info ? info.success : null}
        postType={"compose"}
      />
    </div>
  );
}

TopBarProgress.config({
  barColors: {
    0: "rgb(29, 155, 240)",
    "1.0": "rgb(29, 155, 240)",
  },
  shadowBlur: 0,
});
