import React from "react";
import { useQuery } from "@tanstack/react-query";
import { Container, Button, Stack, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const MyPolls = (props) => {
  return <>{props.userID}</>;
};

export default MyPolls;
