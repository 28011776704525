import axios from "axios";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Logout() {
  let navigate = useNavigate();
  const user_id = localStorage.getItem("user_id");
  const baseURL = process.env.REACT_APP_URL;
  const data = new FormData();
  data.append("user_id", user_id);
  axios({
    method: "POST",
    url: `${baseURL}/delete/token`,
    data: data,
  })
    .then((response) => {
      console.log("Successfully logged out");
    })
    .catch((error) => {
      console.log("Error occured while trying to log out");
    });
  useEffect(() => {
    localStorage.clear();
    navigate("/");
    // history.push({
    //   pathname: "/",
    // });
  });
  return <div></div>;
}
