import axios from "axios";
import React, { useState, useEffect } from "react";
import { Col, Container, Row, Stack, Modal, Alert } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Password } from "primereact/password";
import {
  Link,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import EmailValidator from "email-validator";
import BeatLoader from "react-spinners/BeatLoader";
// import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { IoAt } from "react-icons/io5";
import { Helmet } from "react-helmet";
export default function Login() {
  const location = useLocation;

  const [queryParameters] = useSearchParams();
  let signupClicked = queryParameters.get("q");

  const [disableLogin, setDisableLogin] = useState(false);
  const [disableSignup, setDisableSignup] = useState(false);
  const [show, setShow] = useState(false);
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState();
  const [errorMessageLogin, setErrorMessageLogin] = useState();
  const [alert, setAlert] = useState(false);
  const [alertLogin, setAlertLogin] = useState(false);
  const [signup, setSignup] = useState(true);
  const [spinner, setSpinner] = useState(false);

  useEffect(() => {
    if (signupClicked) {
      if (signupClicked == "signup-clicked") {
        // console.log(signupClicked);
        setShow(true);
      }
    }
  }, []);
  // let history = useHistory();
  let navigate = useNavigate();

  const baseURL = process.env.REACT_APP_URL;
  const url = baseURL;

  function handleClose() {
    // document.title = "Log In";
    setShow(false);
    navigate("/login");
    // history.push({ pathname: "/login" });
  }

  function loginForm(event) {
    event.preventDefault();
    setDisableLogin(true);
    const data = new FormData();
    data.append("email", email);
    data.append("password", password);

    axios({
      method: "POST",
      url: url + "/login",
      data: data,
    })
      .then((response) => {
        setSpinner(false);
        setSignup(true);
        // localStorage.removeItem("unauthorized");
        // localStorage.removeItem("page");

        localStorage.setItem("username", response.data.data.username);
        localStorage.setItem("user_id", response.data.data.user_id);
        localStorage.setItem("first_name", response.data.data.first_name);
        localStorage.setItem("last_name", response.data.data.last_name);
        localStorage.setItem("profile_pic", response.data.data.profile_pic);
        localStorage.setItem("personalized_id", response.data.access_token);

        const fullname =
          response.data.data.first_name + " " + response.data.data.last_name;

        localStorage.setItem("fullname", fullname);

        if (localStorage.getItem("page")) {
          // history.push({ pathname: localStorage.getItem("page") });
          window.location.href = localStorage.getItem("page");
        } else {
          // history.push({ pathname: "/home" });
          // navigate("/home");
          window.location.href = "/home";
        }

        return;
      })
      .catch((error) => {
        // do something
        setErrorMessageLogin(error.response.data.message);
        setAlertLogin(true);
        setDisableLogin(false);
      });
  }

  function handleSignUp() {
    setShow(true);

    // document.title = "Sign Up";
  }

  function handleSignupForm(event) {
    event.preventDefault();
    setDisableSignup(true);
    const formData = new FormData();

    formData.append("first_name", fname);
    formData.append("last_name", lname);
    formData.append("email", email);
    formData.append("username", username);
    formData.append("password", password);
    formData.append("password_confirmation", confirmPassword);

    setSpinner(true);
    setSignup(false);

    const url = baseURL;

    axios({
      method: "POST",
      url: url + "/register",
      data: formData,
      headers: { "content-type": "multipart/form-data" },
    })
      .then((response) => {
        setSpinner(false);
        setSignup(true);

        // localStorage.setItem("username", response.data.data.username);
        // localStorage.setItem("user_id", response.data.data.id);
        // localStorage.setItem("first_name", response.data.data.first_name);
        // localStorage.setItem("last_name", response.data.data.last_name);

        // history.push({ pathname: "/home" });
        navigate("/verify", {
          state: {
            username: username,
            user_id: response.data.data.id,
            first_name: fname,
            last_name: lname,
            email: email,
          },
        });
        // history.push({
        //   pathname: "/verify",
        //   state: {
        //     username: username,
        //     user_id: response.data.data.id,
        //     first_name: fname,
        //     last_name: lname,
        //     email: email,
        //   },
        // });
        return;
      })
      .catch((error) => {
        setDisableSignup(false);
        setSpinner(false);
        setSignup(true);
        setAlert(true);
        setErrorMessage(error.response.data.message);
        // console.log("ERROR: " + error.response.data.message);
      });
  }

  return (
    <div className="body-login">
      <Helmet>
        <title>
          Login or sign up | {process.env.REACT_APP_YOUR_VARIABLE_NAME_HERE}
        </title>
      </Helmet>
      <Modal
        show={show}
        onHide={handleClose}
        backdropClassName="backdrop-login"
        backdrop="static"
        centered
        className={"modal_bg_color"}
      >
        <Modal.Header closeButton>
          <Modal.Title className={"fontBold"}>Sign up</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className="p-1">
            <form onSubmit={handleSignupForm}>
              <Row>
                <Col lg={12} xs={12}>
                  {alert && <Alert variant={"danger"}>{errorMessage}</Alert>}
                </Col>
                <Col xs={6} lg={6} sm={6} className="p-2">
                  <Stack className="mb-1">
                    <input
                      type="text"
                      placeholder="First name"
                      value={fname}
                      onChange={(e) => setFname(e.target.value)}
                    />
                  </Stack>
                </Col>
                <Col xs={6} lg={6} sm={6} className="p-2">
                  <Stack className="mb-1">
                    <input
                      type="text"
                      placeholder="Surname"
                      value={lname}
                      onChange={(e) => setLname(e.target.value)}
                    />
                  </Stack>
                </Col>

                <Col lg={12} sm={12} className="p-2">
                  <Stack className="mb-1">
                    <input
                      type="text"
                      placeholder="Email address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Stack>
                </Col>

                <Col xs={12} lg={12} sm={12} className="p-2">
                  <Stack className="">
                    <input
                      type="text"
                      placeholder="username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </Stack>
                </Col>

                <Col xs={12} lg={16} sm={6} className="p-2">
                  <Stack className="mb-1">
                    {/* <Password
                      value={password}
                      placeholder="New password"
                      onChange={(e) => setPassword(e.target.value)}
                      toggleMask
                      
                    /> */}
                    <input
                      type="password"
                      placeholder="New password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Stack>
                </Col>

                <Col xs={12} lg={6} sm={6} className="p-2">
                  <Stack className="mb-1">
                    <input
                      type="password"
                      placeholder="Confirm password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </Stack>
                </Col>
                <Col lg={12} xs={12} className="mb-3 mt-3 footnote">
                  By clicking Sign up, you agree to the Social User{" "}
                  <Link
                    to={"/agreement"}
                    className="color-blue textUnderlineNone underlineHover"
                  >
                    Agreement
                  </Link>
                  ,{" "}
                  <Link
                    to={"/privacy-policy"}
                    className="color-blue textUnderlineNone underlineHover"
                  >
                    Privacy Policy
                  </Link>
                  , and{" "}
                  <Link
                    to={"/cookie-policy"}
                    className="color-blue textUnderlineNone underlineHover"
                  >
                    Cookie Policy
                  </Link>
                  .
                </Col>
                <Col lg={12} xs={12} className="mb-3">
                  <Button
                    className="register-button no-shadow"
                    type="submit"
                    disabled={disableSignup}
                  >
                    <span className={"fontBold"}>Sign up</span>
                  </Button>
                </Col>

                <Col lg={12} xs={12} className="mb-3 footnote text-center">
                  We can't wait to welcome you.
                </Col>
              </Row>
            </form>
          </Container>
        </Modal.Body>
      </Modal>
      <Container className="login login-container">
        <Row className="height-100 align-items-center">
          <Col xs={12} lg={6}>
            <Row className="header_mobile logo-string-mobile d-lg-none d-md-none d-xl-none d-xxl-none">
              <Col xs={12} sm={12} className="text-center">
                <Link to={"/"} className={"textUnderlineNone"}>
                  <h3 className="text-bold color-blue">
                    {process.env.REACT_APP_YOUR_VARIABLE_NAME_HERE}
                  </h3>
                </Link>
              </Col>
            </Row>

            <Row
              className={
                "justify-content-center align-items-center d-none d-md-block d-lg-block d-xl-block d-xxl-block"
              }
            >
              <Col xs={12} lg={11}>
                <Stack gap={4}>
                  <h1 className="fontBold">
                    <Link
                      to={"/"}
                      className={"color-black textUnderlineNone underlineHover"}
                    >
                      {process.env.REACT_APP_YOUR_VARIABLE_NAME_HERE}
                    </Link>
                    <br />
                    <h4 className={"color-black line-height-25"}>
                      Where scholars, academics, students, professors, and
                      ordinary people meet.
                    </h4>
                  </h1>
                </Stack>
              </Col>
            </Row>
          </Col>
          <Col x={12} lg={6} className="login-margin-bottom">
            <Row className={"justify-content-center align-items-center"}>
              <Col xs={12} lg={9}>
                <div className="text-center w-100 mb-3">
                  {/* <h3 className="fontBold d-none d-md-block d-lg-block d-xl-block d-xxl-block">
                    Log in to {process.env.REACT_APP_YOUR_VARIABLE_NAME_HERE}
                  </h3> */}
                  <h3 className="fontBold d-lg-none d-md-none d-xl-none d-xxl-none">
                    Log in
                  </h3>
                </div>
                <Stack gap={4}>
                  <div className="login-elements-container text-left p-4">
                    <Stack gap={1}>
                      <form onSubmit={loginForm}>
                        <Col lg={12} xs={12}>
                          {alertLogin && (
                            <Alert variant={"danger p-2"}>
                              {errorMessageLogin}
                            </Alert>
                          )}
                        </Col>

                        {localStorage.getItem("unauthorized") ? (
                          <Alert variant={"danger p-2"}>
                            You have to log in to access{" "}
                            <span className={"fontBold"}>
                              {localStorage.getItem("page")}
                            </span>{" "}
                            page{" "}
                          </Alert>
                        ) : (
                          ""
                        )}
                        <label
                          className="fontWeight-700 fontSize20 mb-2"
                          htmlFor="email"
                        >
                          Email or username
                        </label>
                        <input
                          type="text"
                          placeholder="Email address or username"
                          className="mb-3"
                          id="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <label
                          className="fontWeight-700 fontSize20 mb-2"
                          htmlFor="password"
                        >
                          Password
                        </label>
                        <input
                          type="password"
                          placeholder="Password"
                          className="mb-3"
                          id="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <Button
                          className="login-button mb-3 no-shadow"
                          type="submit"
                          disabled={disableLogin}
                        >
                          Log in
                        </Button>
                      </form>
                      <div className="text-center">
                        <Link to="/password" className="login-link anchor">
                          Forgot password
                        </Link>
                      </div>
                      <div className="h-divider mb-2 mt-2" />
                      <label className="footnote w-100 text-center mb-2">
                        Don't have an account?
                      </label>
                      <label
                        className="register-button fontBold text-center"
                        onClick={handleSignUp}
                      >
                        Sign up
                      </label>
                    </Stack>
                  </div>
                  <div
                    className="text-center line-height-10"
                    style={{ marginTop: -25 }}
                  >
                    <h4 className="fontBold">Share, engage and empower.</h4>
                    People on {
                      process.env.REACT_APP_YOUR_VARIABLE_NAME_HERE
                    }{" "}
                    are the first to be{" "}
                    <span className="text-bold">educated</span>.
                    {/* <span className="text-bold">Discover</span>,{" "}
                    <span className="text-bold">share</span> and{" "}
                    <span className="text-bold">empower</span>. */}
                  </div>
                </Stack>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
