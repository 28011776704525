import React, { useState, useEffect, useRef, useCallback } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Tagify } from "react-tagify";
import Linkify from "react-linkify";
import { SecureLink } from "react-secure-link";
import {
  Col,
  Container,
  Row,
  Stack,
  Modal,
  Button,
  Dropdown,
} from "react-bootstrap";
import {
  Link,
  NavLink,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Header from "./navigation/HeaderHome";
import TopicisToFollow from "./features/TopicsToFollow";

import {
  IoIosHeartEmpty,
  IoIosHeart,
  IoIosArrowRoundBack,
} from "react-icons/io";
import {
  BsEmojiSmile,
  BsChatRightDots,
  BsFillBookmarkFill,
  BsBookmark,
  BsXLg,
  BsChevronDoubleUp,
  BsChevronDoubleDown,
  BsChevronDoubleLeft,
  BsChevronDoubleRight,
  BsPencil,
} from "react-icons/bs";
import {
  FaPoll,
  FaRegFileExcel,
  FaRegFilePowerpoint,
  FaRegFilePdf,
  FaRegFileWord,
  FaFileCsv,
  FaFilePdf,
} from "react-icons/fa";
import {
  BiDotsHorizontalRounded,
  BiUserMinus,
  BiFlag,
  BiSad,
} from "react-icons/bi";
import {
  HiOutlineDocumentText,
  HiOutlineTrash,
  HiCheck,
  HiOutlineRefresh,
} from "react-icons/hi";
import ReactTooltip from "react-tooltip";
import BeatLoader from "react-spinners/BeatLoader";
import axios from "axios";
import TopBarProgress from "react-topbar-progress-indicator";
import { BsBookmarkPlus } from "react-icons/bs";
import {
  IoChatboxOutline,
  IoShareOutline,
  IoChatbox,
  IoShare,
  IoEllipse,
  IoBookOutline,
  IoPencil,
  IoBanOutline,
  IoCheckmarkCircleOutline,
  IoArrowBack,
  IoArrowForward,
  IoAddSharp,
} from "react-icons/io5";

import SideNav from "./navigation/SideNavHome";
import RightNav from "./navigation/SidNavHomeRight";
import HeaderToken from "./HeaderToken";
import { ClipLoader } from "react-spinners";
import Footer from "./navigation/Footer";
import { Helmet } from "react-helmet";

const headers = {
  Authorization: `${HeaderToken.Authorization}`,
  Accept: `${HeaderToken.Accept}`,
};

export default function Topics(props) {
  const queryClient = useQueryClient();
  let navigate = useNavigate();
  const location = useLocation();
  const info = location.state;
  const [modalSubject, setModalSubject] = useState(false);
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [subjectID, setSubjectID] = useState(null);
  const [amIFollowing, setAmIFollowing] = useState(0);
  const [spinners, setSpinners] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [hidden, setHidden] = useState({});
  const [count, setCount] = useState(0);

  const [successfulTopicFollowShow, setSuccessfulTopicFollowShow] =
    useState(false);
  const [successfulTopicFollow, setSuccessfulTopicFollow] = useState("");

  const user_id = localStorage.getItem("user_id");
  const url = process.env.REACT_APP_URL;

  const currentTopicQuery = useQuery({
    queryKey: ["currentTopics"],
    queryFn: async () => {
      const response = await axios.get(`${url}/mytopics/get/${user_id}`, {
        headers,
      });
      const data = response.data;
      return data;
    },
    staleTime: 1000 * 60 * 60 * 4,
    cacheTime: 1000 * 60 * 60 * 24,
    refetchOnWindowFocus: false,
  });

  const moreTopicQuery = useQuery({
    queryKey: ["moreTopics"],
    queryFn: async () => {
      const response = await axios.get(`${url}/follow/more/topics/${user_id}`, {
        headers,
      });
      const data = response.data;
      return data;
    },
    staleTime: 1000 * 60 * 60 * 4,
    cacheTime: 1000 * 60 * 60 * 24,
    refetchOnWindowFocus: false,
  });

  const {
    data: dataMyTopics,
    refetch: myTopicsRefetch,
    status: statusMyTopics,
  } = currentTopicQuery;

  const {
    data: dataTopics,
    refetch: topicsRefetch,
    status: statusTopics,
  } = moreTopicQuery;

  useEffect(() => {}, [count]);

  const followTopics = (topic_id, topic) => {
    setCount(count + 1);

    setHidden({ ...hidden, [topic_id]: !hidden[topic_id] });

    setProcessing(true);
    const data = new FormData();
    data.append("user_id", user_id);
    data.append("topic_id", topic_id);
    axios({
      method: "POST",
      url: url + "/follow/topics",
      data: data,
      headers: {
        Authorization: HeaderToken.Authorization,
        Accept: HeaderToken.Accept,
      },
    })
      .then((res) => {
        setHidden({ ...hidden, [topic_id]: hidden[topic_id] });
        setProcessing(false);
        queryClient.invalidateQueries(
          { queryKey: ["currentTopics"] },
          { exact: true }
        );
        queryClient.invalidateQueries(
          { queryKey: ["moreTopics"] },
          { exact: true }
        );
        // myTopicsRefetch();
        // topicsRefetch();
        setSuccessfulTopicFollowShow(true);
        if (res.data.is_followed == true) {
          setSuccessfulTopicFollow(
            <label>
              Your're now following{" "}
              <span className="text-bold">{`${topic}`}</span>
            </label>
          );
        } else {
          setSuccessfulTopicFollow(
            <label>
              Your've unfollowed <span className="text-bold">{`${topic}`}</span>
            </label>
          );
        }
        setTimeout(() => {
          setSuccessfulTopicFollowShow(false);
        }, 4000);
      })
      .catch((error) => {
        // setSuccessfulTopicFollowShow(true);
        // setSuccessfulTopicFollow(<label>{error.res.data.message}</label>);
      });
  };

  const showSubject = (subject, description, id, am_i_following) => {
    setSubject(subject);
    setDescription(description);
    setSubjectID(id);
    setModalSubject(true);
    setAmIFollowing(am_i_following);
  };

  const closeSubject = () => {
    setModalSubject(false);
  };

  const followSubject = (subject_id) => {
    axios
      .get(url + "/follow/subject/" + user_id + "/" + subject_id, { headers })
      .then((res) => {
        queryClient.invalidateQueries(
          { queryKey: ["currentTopics"] },
          { exact: true }
        );
        queryClient.invalidateQueries(
          { queryKey: ["moreTopics"] },
          { exact: true }
        );
        // myTopicsRefetch();
        // topicsRefetch();
      })
      .catch((error) => {});
    setModalSubject(false);
  };

  return (
    <div className="body-login">
      <Helmet>
        <title>Topics | {process.env.REACT_APP_YOUR_VARIABLE_NAME_HERE}</title>
      </Helmet>
      <Header />
      <Container>
        <Row className="forced-margin justify-content-center">
          {/* <SideNav /> */}
          <RightNav />
          <Col lg={6} className="p-0">
            <div className="login-elements-container border-hidde mb-3">
              <Row className={"align-items-center justify-content-center mb-0"}>
                <Col lg={1} xs={2} sm={1} className="text-left ">
                  <label
                    className="back-arrow-post mb-0 float-left"
                    onClick={() => navigate(-1)}
                  >
                    <IoIosArrowRoundBack size={30} />
                  </label>
                </Col>

                <Col lg={5} xs={4} sm={5} className="text-right">
                  <NavLink
                    to={"/topics"}
                    className={(navData) =>
                      navData.isActive
                        ? "side-nav-topics-active side-nav-topics p-2 color-grey fontMedium textUnderlineNone mb-0"
                        : "side-nav-topics p-2 color-grey fontMedium textUnderlineNone mb-0"
                    }
                  >
                    Topics
                  </NavLink>
                </Col>

                <Col lg={5} xs={4} sm={5} className="text-left">
                  <NavLink
                    to={"/subjects"}
                    className={(navData) =>
                      navData.isActive
                        ? "side-nav-topics-active side-nav-topics p-2 color-grey fontMedium textUnderlineNone mb-0"
                        : "side-nav-topics p-2 color-grey fontMedium textUnderlineNone mb-0"
                    }
                  >
                    Subjects
                  </NavLink>
                </Col>
                <Col lg={1} xs={2} sm={5}></Col>
              </Row>
            </div>
            <div className={"login-elements-container border-hidde mb-3 p-0"}>
              <Row className={"align-items-center pb-0 mb mb-3"}>
                <Col lg={12} className={"pb-0"}>
                  <div className="p-3 pb-0 mb-0 border-botto">
                    <Row className={"align-items-center"}>
                      <Col lg={12} className={""}>
                        <span className="text-bold fontSize-20">
                          Topics you follow
                        </span>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>

              <Row className={"align-items-center pb-0"}>
                <Col lg={12} className="paddingLeft30 paddingRight30">
                  {currentTopicQuery.isLoading ? (
                    <div className="col-12 text-center p-5 color-blue">
                      <ClipLoader size={25} color={"#333333"} />
                    </div>
                  ) : currentTopicQuery.isError ? (
                    <Row className="justify-content-center">
                      <Col lg={8}>
                        <div className={"text-center mb-4 mt-0"}>
                          <h4>Oops! It appears there was an error.</h4>
                          <label className={"footnote"}>
                            An error occured while attempting to fetch data.
                            <br />
                            Try refreshing the page.
                          </label>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <>
                      {currentTopicQuery.data.length == 0 ? (
                        <Row>
                          <Col lg={12} className={" mb-5"}>
                            <Row className="justify-content-start">
                              <Col lg={8}>
                                <h4 className="mobile-header-sizes">
                                  Topics you follow will appear here.
                                </h4>
                                <label className={"footnote"}>
                                  Consume what's good for your brain.
                                  <br />
                                  See content on topics you care about.
                                </label>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      ) : (
                        <Row>
                          {currentTopicQuery.data.map((item, index) => (
                            <Col
                              lg={4}
                              sm={4}
                              xs={6}
                              className={"mb-3 p-1"}
                              key={index}
                            >
                              <div className={"topic-container"}>
                                <Stack gap={3}>
                                  <label className={"w-100 text-center"}>
                                    <Link
                                      to={"/topicPosts?clicked=" + item.topic}
                                      className={
                                        "color-black textUnderlineNone"
                                      }
                                    >
                                      <h5 className="mobile-header-sizes">
                                        {item.topic.length > 14
                                          ? item.topic.slice(0, 14) + "..."
                                          : item.topic}
                                      </h5>
                                    </Link>
                                    <label className={"footnote"}>
                                      <label
                                        className={
                                          "footnote mobile-subject-text-font-sizes"
                                        }
                                        data-tip={item.subject}
                                        data-place={"bottom"}
                                        data-effect={"solid"}
                                      >
                                        {item.subject.length > 16
                                          ? item.subject.slice(0, 16) + "..."
                                          : item.subject}
                                        <ReactTooltip
                                          arrowColor={"transparent"}
                                        />
                                      </label>
                                    </label>
                                  </label>

                                  {!hidden[item.topic_id] && (
                                    <Button
                                      className={
                                        "btn-follow-topics fontBold native-button-dark-grey no-shadow"
                                      }
                                      onClick={() =>
                                        followTopics(item.topic_id, item.topic)
                                      }
                                    ></Button>
                                  )}

                                  {!!hidden[item.topic_id] && (
                                    <label
                                      className={
                                        "disabled-bg-color float-right fontBold no-shadow btn-sm btn"
                                      }
                                    >
                                      <ClipLoader size={15} color="#000" />
                                    </label>
                                  )}
                                </Stack>
                              </div>
                            </Col>
                          ))}
                        </Row>
                      )}
                    </>
                  )}
                </Col>
              </Row>
            </div>
            {/* <TopicsToFollow /> */}
            <div className={"login-elements-container border-hidde mb-5 p-0"}>
              <Row className={"align-items-center pb-0"}>
                <Col lg={12} className={"pb-0"}>
                  <div className="pb-2 mb-0 border-botto mb-3 p-3">
                    <span className="text-bold fontSize-20">
                      Topics you might like
                    </span>
                  </div>
                </Col>
                <Col lg={12} className="paddingLeft30 paddingRight30">
                  {moreTopicQuery.isLoading ? (
                    <div className="col-12 text-center p-5 color-blue">
                      <ClipLoader size={25} color={"#333333"} />
                    </div>
                  ) : (
                    <>
                      {moreTopicQuery.data.length == 0 ? (
                        <Row>
                          <Col
                            lg={12}
                            className={
                              spinners == true ? "d-none" : "text-center mb-5"
                            }
                          >
                            <h4>New topics will appear here.</h4>
                            <label className={"footnote"}>
                              We'll continue adding more interesting topics.
                            </label>
                          </Col>
                        </Row>
                      ) : (
                        <Row>
                          {moreTopicQuery.data.map((item, index) => (
                            <Col
                              lg={4}
                              sm={4}
                              xs={6}
                              className={"mb-3 p-1"}
                              key={index}
                            >
                              <div className={"topic-container"}>
                                <Stack gap={3}>
                                  <label className={"w-100 text-center"}>
                                    <Link
                                      to={"/topicPosts?clicked=" + item.topic}
                                      className={
                                        "color-black textUnderlineNone"
                                      }
                                    >
                                      <h5 className="mobile-header-sizes">
                                        {item.topic.length > 14
                                          ? item.topic.slice(0, 14) + "..."
                                          : item.topic}
                                      </h5>
                                    </Link>

                                    <label
                                      className={
                                        "footnote mobile-subject-text-font-sizes"
                                      }
                                      data-tip={item.subject}
                                      data-place={"bottom"}
                                      data-effect={"solid"}
                                    >
                                      {item.subject.length > 16
                                        ? item.subject.slice(0, 16) + "..."
                                        : item.subject}
                                      <ReactTooltip
                                        arrowColor={"transparent"}
                                        className="p-1 fontSize-12"
                                      />
                                    </label>
                                  </label>
                                  {!hidden[item.topic_id] && (
                                    <Button
                                      className={
                                        "fontBold native-button-dark-grey no-shadow"
                                      }
                                      onClick={() =>
                                        followTopics(item.topic_id, item.topic)
                                      }
                                    >
                                      <IoAddSharp size={20} /> Follow{" "}
                                    </Button>
                                  )}
                                  {!!hidden[item.topic_id] && (
                                    <label
                                      className={
                                        "disabled-bg-color float-right fontBold no-shadow btn-sm btn btn-primary"
                                      }
                                    >
                                      <ClipLoader size={15} color="#000" />
                                    </label>
                                  )}
                                </Stack>
                              </div>
                            </Col>
                          ))}
                        </Row>
                      )}
                    </>
                  )}
                </Col>
              </Row>
            </div>
          </Col>

          {/* <RightNav /> */}
        </Row>
      </Container>
      <Modal
        show={modalSubject}
        onHide={closeSubject}
        aria-labelledby="example-custom-modal-styling-title"
      >
        {/* <Modal.Header closeButton></Modal.Header> */}
        <Modal.Body className="">
          <Row>
            <Col lg={12} className="mb-3 mt-3">
              <h5 className="fontBold">
                {subject}{" "}
                <span className={"float-right"}>
                  <Button
                    onClick={() => followSubject(subjectID)}
                    className={
                      amIFollowing == 1
                        ? "btn-follow-topics native-button-dark-grey fontBold no-shadow"
                        : "native-button-dark-grey fontBold no-shadow"
                    }
                    btn-follow-topics
                  >
                    {amIFollowing == 1 ? "" : "Follow"}
                  </Button>
                </span>
              </h5>
              <label className={"mt-2"}>{description}</label>
              {amIFollowing == 1 ? (
                <>
                  <br />
                  <br />
                  <h6 className="footnote fontSize14">
                    You are currently following this subject, however, to
                    unfollow it would mean you won't be able to post nor reply
                    on it.
                  </h6>
                </>
              ) : (
                ""
              )}
              <label
                onClick={closeSubject}
                className="footnote cursorPointer text-left rounded-pill mt-3 textUnderlineNone underlineHover"
              >
                Close
              </label>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {successfulTopicFollowShow && (
        <Row>
          <Col lg={4}>
            <div className="copiedText color-white">
              {successfulTopicFollow}
            </div>
          </Col>
        </Row>
      )}
      <Footer
        postSentFeedback={info ? info.message : null}
        postSent={info ? info.success : null}
        postType={"compose"}
      />
    </div>
  );
}

TopBarProgress.config({
  barColors: {
    0: "rgb(29, 155, 240)",
    "1.0": "rgb(29, 155, 240)",
  },
  shadowBlur: 0,
});
