import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { Fragment, useState } from "react";
import HeaderToken from "../HeaderToken";
import { Helmet } from "react-helmet";
import { Button, Col, Row } from "react-bootstrap";
import { IoAdd, IoAddOutline, IoCheckmarkOutline } from "react-icons/io5";
import { useHistory, useNavigate } from "react-router-dom";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
function InterestList() {
  const headers = {
    Authorization: `${HeaderToken.Authorization}`,
    Accept: `${HeaderToken.Accept}`,
  };

  //   let history = useHistory();
  let navigate = useNavigate();
  const [progress, setProgress] = useState("Queuing...");
  const [disabled, setDisabled] = useState(true);
  const [ids, setIDS] = useState([]);
  const [count, setCount] = useState(0);
  const [mark, setMark] = useState({});
  const user_id = localStorage.getItem("user_id");
  const url = process.env.REACT_APP_URL;

  const moreInterestQuery = useQuery({
    queryKey: ["moreinterests", user_id],
    queryFn: async () => {
      const response = await axios.get(`${url}/moreinterests`, {
        headers,
      });
      const data = response.data;
      return data;
    },
  });

  const interestQuery = useQuery({
    queryKey: ["interests", user_id],
    queryFn: async () => {
      const response = await axios.get(`${url}/interests`, {
        headers,
      });
      const data = response.data;
      return data;
    },
  });

  const { data, status, refetch } = interestQuery;
  console.log(data + "....");

  const follow = (topic_id, topic) => {
    setMark({ ...mark, [topic_id]: !mark[topic_id] });
    if (!mark[topic_id]) {
      setCount(count + 1);
      ids.push([topic_id]);
    } else {
      setCount(count - 1);
      ids.pop([topic_id]);
    }
  };

  const next = () => {
    setDisabled(true);
    setCount(0);
    setProgress("Prosessing...");
    const data = new FormData();
    for (let i = 0; i < ids.length; i++) {
      data.append("topic_id[]", ids[i]);
    }
    data.append("user_id", user_id);

    axios({
      method: "POST",
      url: url + "/follow/interests",
      data: data,
      headers: {
        Authorization: HeaderToken.Authorization,
        Accept: HeaderToken.Accept,
      },
    })
      .then((res) => {
        navigate("/academics");
        // history.push({
        //   pathname: "/academics",
        // });
      })
      .catch((error) => {});
  };

  function skip() {
    navigate("/academics");
  }
  return (
    <Fragment>
      <Helmet>
        <title>Topics | {process.env.REACT_APP_YOUR_VARIABLE_NAME_HERE}</title>
      </Helmet>
      <Row className="mb-3 align-items-center">
        <Col lg={8} sm={8} xs={12} className="mb-2">
          <label className="line-height-15">
            <h5 className="fontMedium">Follow at least 5 topics</h5>
            <label
              className={
                ids.length == 0
                  ? "w-100 text-color-lighter-grey fontSize14"
                  : "w-100 footnote fontSize14"
              }
            >
              {progress} {ids.length}/{ids.length > 5 ? ids.length : 5}
            </label>
          </label>
        </Col>
        <Col lg={4} sm={4} xs={12} className="mb-2">
          <Row>
            <Col className={"order-2 paddingLeft5"}>
              <Button
                onClick={skip}
                type="button"
                className="rounded-pill join-now-button text-bold no-shadow w-100 color-black special__height"
              >
                Skip
              </Button>
            </Col>
            <Col className={"order-1 paddingRight5"}>
              <Button
                type="button"
                className={
                  count < 5 && disabled == true
                    ? "disabled-bg-color no-shadow w-100 text-bold"
                    : "rounded-pill native-button-black text-bold no-shadow w-100"
                }
                disabled={count >= 5 ? false : true}
                onClick={count >= 5 ? next : ""}
              >
                Follow
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="login-elements-containe border-hidde mb-5 p-0">
        <Row>
          <div className="w-100 mb-1">
            <label className="fontMedium fontSize-20">Topics</label>
          </div>
          <Col lg={12} className={"mb-3 mt-1"}>
            {status == "loading"
              ? "Loading..."
              : data.map((item, index) => (
                  <label
                    key={item.topic_id}
                    className={
                      !mark[item.topic_id]
                        ? "interest__list mobile__width_100"
                        : "interest__list__active mobile__width_100"
                    }
                    onClick={() => follow(item.topic_id, item.topic)}
                  >
                    {!mark[item.topic_id] ? (
                      <IoAddOutline size={18} />
                    ) : (
                      <IoCheckmarkOutline size={18} />
                    )}{" "}
                    {item.topic.length > 25
                      ? item.topic.slice(0, 25) + "..."
                      : item.topic}
                  </label>
                ))}
          </Col>
          <div className="w-100 mb-1">
            <label className="fontMedium fontSize-20">More topics</label>
          </div>
          <Col lg={12} className={""}>
            {moreInterestQuery.isLoading
              ? "Loading..."
              : moreInterestQuery.data.map((item, index) => (
                  <label
                    key={item.topic_id}
                    className={
                      !mark[item.topic_id]
                        ? "interest__list mobile__width_100"
                        : "interest__list__active mobile__width_100"
                    }
                    onClick={() => follow(item.topic_id, item.topic)}
                  >
                    {!mark[item.topic_id] ? (
                      <IoAddOutline size={18} />
                    ) : (
                      <IoCheckmarkOutline size={18} />
                    )}{" "}
                    {item.topic.length > 25
                      ? item.topic.slice(0, 25) + "..."
                      : item.topic}
                  </label>
                ))}
          </Col>
        </Row>
      </div>
    </Fragment>
  );
}

export default InterestList;
