import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BiBell, BiHomeCircle } from "react-icons/bi";
import { BsBell, BsChatRightDots } from "react-icons/bs";
import { FaRegEnvelope } from "react-icons/fa";
import {
  IoAdd,
  IoAddCircleOutline,
  IoHome,
  IoPerson,
  IoPersonOutline,
  IoSearchOutline,
} from "react-icons/io5";
import { HiOutlineEnvelope } from "react-icons/hi2";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
function scroll() {
  window.scrollTo(0, 0);
}
function Footer(props) {
  let navigate = useNavigate();
  const [showMessage, setShowMessage] = useState(props.postSent);

  setTimeout(() => {
    setShowMessage(false);
  }, 6000);

  const location = useLocation();
  const [queryParameters] = useSearchParams();
  let topicClicked = queryParameters.get("clicked");
  let from_post_id = queryParameters.get("from_post_id");

  let params = "";
  if (queryParameters.get("clicked") && queryParameters.get("from_post_id")) {
    params = "?clicked=" + topicClicked + "&from_post_id=" + from_post_id;
  } else if (
    queryParameters.get("clicked") &&
    !queryParameters.get("from_post_id")
  ) {
    params = "?clicked=" + topicClicked;
  }

  function sharePost() {
    navigate("/compose/post", {
      state: { page: window.location.pathname },
    });
  }

  return (
    <>
      {props.drawerState == false && (
        <>
          {localStorage.getItem("personalized_id") && (
            <div
              className={
                !localStorage.getItem("personalized_id") ||
                props.drawerState == true
                  ? "d-none"
                  : "footer_mobile"
              }
            >
              <Row className="justify-content-center">
                <Col className="text-center line-height-15 adjust-margin">
                  <NavLink
                    to={"/home"}
                    className={(navData) =>
                      navData.isActive
                        ? "mobile-tabs-icons-active textUnderlineNone mobile-tabs-icons color-black mb-0 fontSize-12"
                        : "textUnderlineNone mobile-tabs-icons color-black mb-0 fontSize-12"
                    }
                    onClick={scroll}
                  >
                    <BiHomeCircle size={22} />
                    <br />
                    Home
                  </NavLink>
                </Col>
                <Col className="text-center line-height-15 adjust-margin">
                  <NavLink
                    to={"/notifications"}
                    className={(navData) =>
                      navData.isActive
                        ? "mobile-tabs-icons-active textUnderlineNone mobile-tabs-icons color-black mb-0 fontSize-12"
                        : "textUnderlineNone mobile-tabs-icons color-black mb-0 fontSize-12"
                    }
                    onClick={scroll}
                  >
                    <BsBell size={22} />
                    <br />
                    Notifications
                  </NavLink>
                </Col>
                <Col className="text-center line-height-15">
                  <label
                    className={(navData) =>
                      navData.isActive
                        ? "mobile-tabs-icons-active textUnderlineNone mobile-tabs-icons color-black mb-0 fontSize-12"
                        : "textUnderlineNone mobile-tabs-icons color-black mb-0 fontSize-12"
                    }
                    onClick={(scroll, sharePost)}
                  >
                    <label className="textUnderlineNone mobile-tabs-icons-active-always mb-0 fontSize-12">
                      <IoAddCircleOutline size={22} />
                      <br />
                      Compose
                    </label>
                  </label>
                </Col>

                {/* <Col className="text-center line-height-10">
                  <NavLink
                    to={"/messages"}
                    className="textUnderlineNone mobile-tabs-icons color-black mb-0 fontSize-10"
                    exact
                    activeClassName="mobile-tabs-icons-active"
                    onClick={scroll}
                  >
                    <HiOutlineEnvelope size={21} />
                    <br />
                    Messages
                  </NavLink>
                </Col> */}
                <Col className="text-center line-height-15 adjust-margin">
                  <NavLink
                    to={"/search"}
                    className={(navData) =>
                      navData.isActive
                        ? "mobile-tabs-icons-active textUnderlineNone mobile-tabs-icons color-black mb-0 fontSize-12"
                        : "textUnderlineNone mobile-tabs-icons color-black mb-0 fontSize-12"
                    }
                    onClick={scroll}
                  >
                    <IoSearchOutline size={23} />
                    <br />
                    Search
                  </NavLink>
                </Col>
                {/* <Col className="text-center line-height-10">
                  <NavLink
                    to={`/profile/${localStorage
                      .getItem("username")
                      .replace("@", "")}`}
                    className="textUnderlineNone mobile-tabs-icons color-black mb-0 fontSize-10"
                    exact
                    activeClassName="mobile-tabs-icons-active"
                    onClick={scroll}
                  >
                    <IoPersonOutline size={21} />
                    <br />
                    Profile
                  </NavLink>
                </Col> */}
              </Row>
            </div>
          )}
        </>
      )}
      <div
        className={
          localStorage.getItem("personalized_id") ? "d-none" : "footer"
        }
      >
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col lg={6} className="p-1">
              <Row className="align-items-center">
                <Col lg={6} xs={6} sm={6}>
                  <h4 className="color-black text-bold fontSize-25 line-height-15">
                    Be in the know.
                    <br />
                    <span className="fontSize15 text-normal footnote">
                      Be among the empowered.
                    </span>
                  </h4>
                </Col>
                <Col lg={6} xs={6} sm={6} className="text-right">
                  <Link
                    to={"/login"}
                    className="textUnderlineNone native-button-blue text-bold fontSize-15 p-2"
                  >
                    Log in
                  </Link>
                  &nbsp; &nbsp;
                  <Link
                    to={"/login?q=signup-clicked"}
                    className="textUnderlineNone join-now-button text-bold color-black fontSize-15 p-2"
                  >
                    Sign up
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      {showMessage && (
        <Row className={props.postType == "entry" ? "d-none" : ""}>
          <Col lg={4}>
            <div className="copiedText color-white">
              {props.postSentFeedback}
            </div>
          </Col>
        </Row>
      )}
    </>
  );
}

export default Footer;
