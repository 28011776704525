import React, { useState } from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import {
  IoBookOutline,
  IoChevronForwardSharp,
  IoChevronBackSharp,
  IoImagesOutline,
  IoDocumentsOutline,
} from "react-icons/io5";

import { FcDocument, FcPicture } from "react-icons/fc";

import { IoMdImages } from "react-icons/io";
import { BsEmojiSmile } from "react-icons/bs";
import { HiOutlineDocumentText } from "react-icons/hi";
import { FaPoll } from "react-icons/fa";

export default function Elements(props) {
  const [openPoll, setOpenPoll] = useState(false);
  const [hideEmoji, setHideEmoji] = useState(false);
  function emojies() {
    setOpenPoll(false);
    if (hideEmoji == true) {
      setHideEmoji(false);
    } else {
      setHideEmoji(true);
    }
  }
  return (
    <>
      <Row className="align-items-center p-2">
        <Col lg={3} sm={3} xs={6} className={"p-1"}>
          <label
            className="share-icons d-flex w-100 text-center fontSize14"
            data-tip={"Add picture"}
            data-place={"bottom"}
            data-effect={"solid"}
            htmlFor="image"
          >
            <span className="text-center w-100">
              <IoImagesOutline size={23} />
              <br />
              Add photo
              <ReactTooltip
                arrowColor={"transparent"}
                className="p-1 fontSize-12"
              />
            </span>
          </label>
          <input
            type="file"
            name="image"
            accept="image/jpg, image/jpeg, image/png, image/WebP, image/webp, image/gif"
            multiple
            id="image"
            max={4}
            className="d-none"
            onChange={props.imageUpload}
          />
        </Col>
        <Col lg={3} sm={3} xs={6} className={"p-1"}>
          <span className="text-bold">
            <label
              className="share-icons d-flex w-100 text-center fontSize14"
              data-tip={"Add document"}
              data-place={"bottom"}
              data-effect={"solid"}
              htmlFor="document"
            >
              <span className={"w-100"}>
                <IoDocumentsOutline size={23} />
                <br />
                Add doc
                <ReactTooltip
                  arrowColor={"transparent"}
                  className="p-1 fontSize-12"
                />
              </span>
            </label>
            <input
              type="file"
              name="document"
              accept="application/pdf, .docx, .doc, .csv, .xlsx, .pptx"
              multiple
              id="document"
              className="d-none"
              onChange={props.documentUpload}
            />
          </span>
        </Col>
        <Col
          lg={3}
          sm={3}
          xs={6}
          className={"p-1  d-none d-md-flex d-lg-flex d-xl-flex d-xxl-flex"}
        >
          <label
            className="share-icons mobile-hide d-flex w-100 text-center fontSize14"
            data-tip={"Add emoji"}
            data-place={"bottom"}
            data-effect={"solid"}
            onClick={props.emojies}
          >
            {props.chosenEmoji ? (
              <span className="text-bold"> {props.chosenEmoji.emoji}</span>
            ) : (
              ""
            )}
            <span className={"w-100"}>
              <BsEmojiSmile size={23} color={"#999900"} />
              <br /> Add emoji
              <ReactTooltip
                arrowColor={"transparent"}
                className="p-1 fontSize-12"
              />
            </span>
          </label>
        </Col>
        <Col lg={3} sm={3} xs={12} className={"p-1"}>
          <label
            className={
              props.currentPostPolls == true || props.action == "quote"
                ? "color-light-very-grey share-icons d-flex w-100 text-center fontSize14"
                : "share-icons d-flex w-100 text-center fontSize14"
            }
            data-tip={
              props.currentPostPolls == true || props.action == "quote"
                ? "Poll disabled"
                : "Create poll"
            }
            data-place={"bottom"}
            data-effect={"solid"}
            onClick={
              props.currentPostPolls == true || props.action == "quote"
                ? ""
                : props.closePoll
            }
          >
            <span className={"w-100"}>
              <FaPoll size={23} color={"#236b8e"} />
              <br /> Create poll
              <ReactTooltip
                arrowColor={"transparent"}
                className="p-1 fontSize-12"
              />
            </span>
          </label>
        </Col>
      </Row>
      <Row className=" mt-2">
        <Col lg={12} sm={12} xs={12} className={""}>
          <div className="w-100 mb-1">
            <label className="color-black text-bold line-height-20">
              Add a topic to this post
              <br />
              <span className="footnote">
                Curate your post to a particular topic.
              </span>
            </label>
          </div>
          <div className="share-icons add-subject-container text-left">
            <label
              className={
                props.subjectSelected == true ? "padding-10 pr-0" : "d-none"
              }
            >
              <label className="screen-hide">
                <span
                  className="footnote fontSize14 text-normal "
                  data-tip={props.selectedSubject}
                  data-place={"bottom"}
                  data-effect={"solid"}
                >
                  {props.selectedSubject.length > 4
                    ? props.selectedSubject.slice(0, 4) + "..."
                    : props.selectedSubject}
                  <ReactTooltip arrowColor={"transparent"} />
                </span>{" "}
                <IoChevronForwardSharp size={14} />{" "}
                <span
                  className="footnote fontSize14 text-normal"
                  data-tip={props.selectedTopic}
                  data-place={"bottom"}
                  data-effect={"solid"}
                >
                  {props.selectedTopic.length > 4
                    ? props.selectedTopic.slice(0, 4) + "..."
                    : props.selectedTopic}
                  <ReactTooltip
                    arrowColor={"transparent"}
                    className="p-1 fontSize-12"
                  />
                </span>
              </label>
              <label className="mobile-hide">
                <span
                  className="footnote fontSize14 text-normal subject-selected"
                  data-tip={props.selectedSubject}
                  data-place={"bottom"}
                  data-effect={"solid"}
                >
                  {props.selectedSubject.length > 14
                    ? props.selectedSubject.slice(0, 14) + "..."
                    : props.selectedSubject}
                  <ReactTooltip arrowColor={"transparent"} />
                </span>{" "}
                <IoChevronForwardSharp size={14} />{" "}
                <span
                  className="footnote fontSize14 text-normal topic-selected"
                  data-tip={props.selectedTopic}
                  data-place={"bottom"}
                  data-effect={"solid"}
                >
                  {props.selectedTopic.length > 14
                    ? props.selectedTopic.slice(0, 14) + "..."
                    : props.selectedTopic}
                  <ReactTooltip
                    arrowColor={"transparent"}
                    className="p-1 fontSize-12"
                  />
                </span>
              </label>
            </label>
            <label className="float-righ">
              <Dropdown>
                <Dropdown.Toggle
                  className="add-subject-toggle light-bold color-light-grey w-100 fontSize14"
                  style={{
                    borderRadius: 10,
                    border: "hidden",
                    background: "#fff",
                    paddingTop: 8,
                    paddingBottom: 8,
                    boxShadow: "none",
                    backgroundColor: "transparent",
                  }}
                  // id="dropdown-basic"
                >
                  {props.subjectSelected == true &&
                  props.topicSelected == false ? (
                    <span className={"text-normal"}>Select topic</span>
                  ) : props.topicSelected == true ? (
                    <span className={"text-normal"}>Change topic</span>
                  ) : (
                    <span className={"text-normal"}>Select subject</span>
                  )}
                </Dropdown.Toggle>

                <Dropdown.Menu classnName="color-red">
                  {props.subjectSelected == true
                    ? props.showTopics()
                    : props.showSubjects()}
                </Dropdown.Menu>
              </Dropdown>
            </label>
            <label
              className={
                props.resetFunc == false
                  ? "d-none"
                  : "new-chev-back float-right mt-2"
              }
              onClick={props.resetSubject}
              data-tip={"Reset"}
              data-place={"bottom"}
              data-effect={"solid"}
            >
              <IoChevronBackSharp size={15} />
              <ReactTooltip
                arrowColor={"transparent"}
                className="p-1 fontSize-12"
              />
            </label>
          </div>
        </Col>
      </Row>
    </>
  );
}
