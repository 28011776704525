import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Container, Row, Col, Button, Stack, Modal } from "react-bootstrap";
import SideNav from "./navigation/SideNavHome";
import RightNav from "./navigation/SidNavHomeRight";
import MessasgePallet from "./navigation/MessasgePallet";
import Chats from "./navigation/Chats";
import Header from "./navigation/HeaderHome";
import Message from "./features/Messages";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import BeatLoader from "react-spinners/BeatLoader";
import { IoAdd, IoCloseOutline } from "react-icons/io5";
import ReactTooltip from "react-tooltip";
import { Link, NavLink } from "react-router-dom";
import { IoAttachSharp } from "react-icons/io5";
import HeaderToken from "./HeaderToken";
import { ClipLoader } from "react-spinners";
import Footer from "./navigation/Footer";
import { Helmet } from "react-helmet";

const headers = {
  Authorization: `${HeaderToken.Authorization}`,
  Accept: `${HeaderToken.Accept}`,
};

function Messages(props) {
  const location = useLocation();
  const info = location.state;
  const { username } = useParams();
  // let history = useHistory();
  const baseURL = process.env.REACT_APP_URL;
  const user_name = localStorage.getItem("username")
    ? localStorage.getItem("username").replace("@", "")
    : null;
  const user_id = localStorage.getItem("user_id");
  const [userID, setUserID] = useState(0);
  const [openNewMessageModal, setOpenNewMessageModal] = useState(false);
  const [searchAcademics, setSearchAcademics] = useState("");
  const [searchData, setSearchData] = useState([]);

  const imgURL = baseURL.replace("api", "");

  const chatMateQuery = useQuery({
    queryKey: ["chatMates"],
    queryFn: async () => {
      const response = await axios.get(
        `${baseURL}/${user_id}/${user_id}/following`,
        {
          headers,
        }
      );
      const data = response.data;
      return data;
    },
  });

  // const fetchAcademia = async () => {
  //   const res = await fetch(
  //     baseURL + "/" + user_id + "/" + user_id + "/following",
  //     {
  //       headers,
  //     }
  //   );
  //   return res.json();
  // };

  // const academia = useQuery("messageAcademics", fetchAcademia);

  // const { data: academiaData, status, refetch: refetchChat } = academia;
  // console.log(academiaData);

  useEffect(() => {
    axios
      .get(baseURL + "/userid/get/" + user_name, { headers })
      .then((res) => {
        setUserID(res.data.userID);
      })
      .catch((error) => {});
  }, [window.location.href]);

  const newMessage = () => {
    setOpenNewMessageModal(true);
  };

  const handleClose = () => {
    setOpenNewMessageModal(false);
  };

  const FindAcademics = (e) => {
    let query = e.target.value;
    setSearchAcademics(query);

    axios
      .get(baseURL + "/" + user_id + "/search/follow?keyword=" + query, {
        headers,
      })
      .then((res) => {
        // console.log(res.data);
        setSearchData(res.data);
      })
      .catch((error) => {
        setSearchData([]);
      });
  };

  return (
    <div className="body-login">
      <Helmet>
        <title>
          Messages | {process.env.REACT_APP_YOUR_VARIABLE_NAME_HERE}
        </title>
      </Helmet>
      <Header />
      <Container>
        <Modal
          show={openNewMessageModal}
          onHide={handleClose}
          backdropClassName="backdrop-login"
          centered
          size={"md"}
          className={"modal_bg_color_white"}
        >
          <Modal.Body>
            <h5>
              <label
                className={"color-grey cursorPointer share-icons-plus"}
                data-tip={"Close"}
                data-place={"bottom"}
                data-effect={"solid"}
                onClick={handleClose}
              >
                <IoCloseOutline size={25} />
                <ReactTooltip
                  arrowColor={"transparent"}
                  className="p-1 fontSize-12"
                />
              </label>
              &nbsp;&nbsp;&nbsp;
              <label className={"fontBold"}>New message</label>
            </h5>
            <div className={"mb-3"}>
              <input
                type="text"
                className="no-shadow border-circle"
                placeholder="Search chatmate"
                // value={searchAcademics}
                onChange={FindAcademics}
              />
            </div>
            <div className={"w-100"}>
              {openNewMessageModal == false ? (
                ""
              ) : (
                <>
                  {searchAcademics.length > 0 ? (
                    <>
                      {searchData.length == 0 ? (
                        <div
                          lg={12}
                          className="text-center mb-3 mt-3 fontSize-20 newMessageListAcademics"
                        >
                          <h4>No chatmate found by that name.</h4>
                          <label className="footnote mb-3">
                            Try a different name.
                          </label>
                        </div>
                      ) : (
                        <div className="newMessageListAcademics">
                          {searchData.map((item, index) => (
                            <Col lg={12} className="mb-0 mt-0" key={index}>
                              <div className="p-0 mb-0 mt-0">
                                <Row className="paddingLeftRight mb-1 mt-0">
                                  <NavLink
                                    to={`/messages/${item.username}`}
                                    className={(navData) =>
                                      navData.isActive
                                        ? "side-nav-topics-active mb-0 side-nav-topics textUnderlineNone w-100 d-flex mb-0"
                                        : "side-nav-topics textUnderlineNone w-100 d-flex mb-0"
                                    }
                                    onClick={handleClose}
                                  >
                                    <Col lg={2} className={"cursorPointer"}>
                                      <div
                                        className={"profile-pic cursorPointer"}
                                        style={{
                                          backgroundImage:
                                            "url(" +
                                            imgURL +
                                            "public/profile/picture/@" +
                                            item.username +
                                            "/" +
                                            item.profile_pic +
                                            ")",
                                        }}
                                      />
                                    </Col>
                                    <Col lg={10} className={"marginLeft-15"}>
                                      <div>
                                        <span className="text-bold cursorPointer">
                                          {item.fullname.length > 19
                                            ? item.fullname.slice(0, 19) + "..."
                                            : item.fullname}
                                          <br />
                                          <label className="color-grey text-normal cursorPointer">
                                            @{item.username}
                                          </label>
                                        </span>
                                        <br />
                                      </div>
                                    </Col>
                                  </NavLink>
                                </Row>
                              </div>
                            </Col>
                          ))}
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {chatMateQuery.data.length == 0 ? (
                        <div
                          lg={12}
                          className="text-center mb-3 mt-3 fontSize-20"
                        >
                          <h3>No academics to text at the moment.</h3>
                          <label className="footnote mb-3">
                            Looks like something went wrong.
                            <br />
                            Come back and try again.
                          </label>
                        </div>
                      ) : (
                        <div className="newMessageListAcademics">
                          {chatMateQuery.data.map((item, index) => (
                            <Col lg={12} className="mb-0 mt-0" key={index}>
                              <div className="p-0 mb-0 mt-0">
                                <Row className="paddingLeftRight mb-1 mt-0">
                                  <NavLink
                                    to={`/messages/${item.username}`}
                                    className={(navData) =>
                                      navData.isActive
                                        ? "side-nav-topics-active mb-0 side-nav-topics textUnderlineNone w-100 d-flex mb-0"
                                        : "side-nav-topics textUnderlineNone w-100 d-flex mb-0"
                                    }
                                    onClick={handleClose}
                                  >
                                    <Col lg={2} className={"cursorPointer"}>
                                      <div
                                        className={"profile-pic cursorPointer"}
                                        style={{
                                          backgroundImage:
                                            "url(" +
                                            imgURL +
                                            "public/profile/picture/@" +
                                            item.username +
                                            "/" +
                                            item.profile_pic +
                                            ")",
                                        }}
                                      />
                                    </Col>
                                    <Col lg={10} className={"marginLeft-15"}>
                                      <div>
                                        <span className="text-bold cursorPointer">
                                          {item.fullname.length > 19
                                            ? item.fullname.slice(0, 19) + "..."
                                            : item.fullname}
                                          <br />
                                          <label className="color-grey text-normal cursorPointer">
                                            @{item.username}
                                          </label>
                                        </span>
                                        <br />
                                      </div>
                                    </Col>
                                  </NavLink>
                                </Row>
                              </div>
                            </Col>
                          ))}
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </Modal.Body>
        </Modal>
        <Row className="forced-margin justify-content-center">
          {/* <SideNav /> */}
          {/* <RightNav /> */}

          <Col lg={4}>
            <Stack>
              <div className={"login-elements-container border-hidde mb-0 p-0"}>
                <Row className={"align-items-center pb-0"}>
                  <Col lg={12} className={"pb-0"}>
                    <div className="p-3 pb-0 mb-0 border-botto">
                      <Row>
                        <Col lg={12} className={""}>
                          <Stack gap={0}>
                            <label className={"w-100"}>
                              <span className="text-bold fontSize-20">
                                Messages
                              </span>
                              <label
                                className={"float-right share-icons-plus"}
                                data-tip={"New message"}
                                data-place={"bottom"}
                                data-effect={"solid"}
                                onClick={newMessage}
                              >
                                <IoAdd
                                  size={23}
                                  className="cursorPointer color-blue"
                                />
                                <ReactTooltip
                                  arrowColor={"transparent"}
                                  className="p-1 fontSize-12"
                                />
                              </label>
                            </label>
                          </Stack>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>

                {userID > 0 ? (
                  <Message userID={userID} username={user_name} />
                ) : (
                  <div className="text-center mb-5 mt-5">
                    <ClipLoader size={25} color={"#333333"} />
                  </div>
                )}
              </div>
            </Stack>
          </Col>

          {username ? (
            <Chats username={username} />
          ) : (
            <MessasgePallet newMessage={newMessage} />
          )}
        </Row>
      </Container>
      <Footer
        postSentFeedback={info ? info.message : null}
        postSent={info ? info.success : null}
        postType={"compose"}
      />
    </div>
  );
}

export default Messages;
