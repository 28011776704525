import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import HeaderToken from "./HeaderToken";
import { Col, Container, Row } from "react-bootstrap";
import {
  FaFileCsv,
  FaRegFileExcel,
  FaRegFilePdf,
  FaRegFilePowerpoint,
  FaRegFileWord,
} from "react-icons/fa";
// import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { ClipLoader } from "react-spinners";

function Docs() {
  const headers = {
    Authorization: `${HeaderToken.Authorization}`,
    Accept: `${HeaderToken.Accept}`,
  };
  const baseURL = process.env.REACT_APP_URL;
  const docsQuery = useQuery({
    queryKey: ["docs", window.location.href],
    queryFn: async () => {
      const response = await axios.get(`${baseURL}/documents`, { headers });
      const data = response.data;
      return data;
    },
  });

  const { data, status, refetch } = docsQuery;

  return (
    <Fragment>
      <Container className="paddingLeft10 paddingRight10 mb-5">
        <Row>
          {docsQuery.isError ? (
            "Error occured"
          ) : docsQuery.isLoading ? (
            <div className="col-12 text-center p-4 color-blue">
              <ClipLoader size={25} color={"#333333"} />
            </div>
          ) : (
            docsQuery.data.map((doc, index) => (
              <a
                href={
                  process.env.REACT_APP_URL.replace("api", "") +
                  "public/timeline/documents/" +
                  doc.username +
                  "/" +
                  doc.file
                }
                target={"_blank"}
                className={"textUnderlineNone"}
              >
                <Row className={"align-items-center document-link-timeline"}>
                  <Col lg={12} key={index} className={"p-0"}>
                    <div className={""}>
                      {doc.format == "pdf" ? (
                        <label className={"p-1"}>
                          <FaRegFilePdf size={20} className={"color-red"} />
                          &nbsp;
                        </label>
                      ) : doc.format == "docx" ? (
                        <label className={"p-1"}>
                          <FaRegFileWord size={20} className={"color-blue"} />
                          &nbsp;
                        </label>
                      ) : doc.format == "csv" ? (
                        <label className={"p-1"}>
                          <FaFileCsv size={20} className={"color-green"} />
                          &nbsp;
                        </label>
                      ) : doc.format == "xlsx" ? (
                        <label className={"p-1"}>
                          <FaRegFileExcel size={20} className={"color-green"} />
                          &nbsp;
                        </label>
                      ) : doc.format == "pptx" ? (
                        <label className={"p-1"}>
                          <FaRegFilePowerpoint
                            size={20}
                            className={"color-orange"}
                          />
                          &nbsp;
                        </label>
                      ) : (
                        ""
                      )}
                      {doc.title == ""
                        ? doc.file.length > 30
                          ? doc.file.slice(0, 30) + "..."
                          : doc.file
                        : (doc.title.length > 30
                            ? doc.title.slice(0, 30) + "..."
                            : doc.title) +
                          "." +
                          doc.format}
                    </div>
                  </Col>
                </Row>
              </a>
            ))
          )}
        </Row>
      </Container>
    </Fragment>
  );
}

export default Docs;
