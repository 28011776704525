import React, { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import HeaderHome from "./navigation/HeaderHome";
import InterestList from "./features/Interests";

function Interests() {
  return (
    <Fragment>
      {/* <div className="relative w-100">
        <HeaderHome />
        <div className="mast__div" />
      </div> */}
      <Container className="mt-3 mb-3">
        <Row className="justify-content-center">
          <Col lg={6}>
            {/* <label className="text-bold">Follow topics you care about.</label> */}
            <InterestList />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default Interests;
