import Button from "@restart/ui/esm/Button";
import React from "react";
import { Col, Container, Row, Stack } from "react-bootstrap";
import { Link } from "react-router-dom";
export default function HeaderPassword() {
  return (
    <div className="p-3 bg-color-white">
      <Container>
        <Row className="align-items-center">
          <Col lg={6}>
            <Stack>
              <Link to="/" className="black-color underline-none width-100">
                <h4>Social</h4>
              </Link>
            </Stack>
          </Col>
          <Col lg={6}>
            <Stack>
              <Row className="justify-content-end">
                <Col lg={5}>
                  <Stack gap={4}>
                    <input
                      type="text"
                      placeholder="@username, email or phone"
                      className="bg-color-white"
                    />
                  </Stack>
                </Col>
                <Col lg={5}>
                  <Stack gap={4}>
                    <input
                      type="password"
                      placeholder="Password"
                      className="bg-color-white"
                    />
                  </Stack>
                </Col>

                <Col lg={2}>
                  <Stack gap={4}>
                    <Button className="register-button">Log In</Button>
                  </Stack>
                </Col>
              </Row>
            </Stack>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
