import React, { Component, useState } from "react";

import Picker, {
  EmojiStyle,
  SkinTones,
  Theme,
  Categories,
  EmojiClickData,
  Emoji,
  SuggestionMode,
  SkinTonePickerLocation,
} from "emoji-picker-react";

export default function Emojies(props) {
  // const data = props.location.state;
  return (
    <div
      className={
        props.statusReply
          ? "emoji-container-status-reply"
          : props.homeReply
          ? "emoji-container-home-reply"
          : "emoji-container"
      }
    >
      <Picker
        onEmojiClick={props.handleClick}
        searchPlaceHolder={"Search emojis"}
        emojiStyle={"native"}
        theme={"auto"}
        width={"100%"}
      />
    </div>
  );
}
