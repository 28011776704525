import React, { useState, useEffect, useRef, useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { Tagify } from "react-tagify";
import Linkify from "react-linkify";
import { SecureLink } from "react-secure-link";
import { Link } from "react-router-dom";

import {
  Col,
  Container,
  Row,
  Stack,
  Modal,
  Button,
  Dropdown,
} from "react-bootstrap";

import { IoAddSharp } from "react-icons/io5";
import axios from "axios";
import HeaderToken from "../HeaderToken";
import { ClipLoader } from "react-spinners";

const headers = {
  Authorization: `${HeaderToken.Authorization}`,
  Accept: `${HeaderToken.Accept}`,
};

export default function Academics(props) {
  const baseURL = process.env.REACT_APP_URL;
  const imgURL = baseURL.replace("api", "");
  const [hidden, setHidden] = useState({});
  const [count, setCount] = useState(0);

  const fetchFollowCount = async () => {
    const res = await fetch(`${baseURL}/following/count/${props.userID}`, {
      headers,
    });
    return res.json();
  };

  const {
    data: dataCount,
    status: statusCount,
    refetch: refecthCount,
  } = useQuery("count", fetchFollowCount);

  // const fetchAcademics = async () => {
  //   const res = await fetch(
  //     baseURL + "/follow/academics/" + props.userID + "/more",
  //     {
  //       headers,
  //     }
  //   );
  //   return res.json();
  // };

  const peopleQuery = useQuery({
    queryKey: ["person"],
    queryFn: async () => {
      const response = await axios.get(
        `${baseURL}/follow/academics/${props.userID}/more`,
        { headers }
      );

      const data = response.data;
      return data;
    },
  });

  // const academics = useQuery("academic", fetchAcademics, { retry: 3 });

  const { data, status, refetch } = peopleQuery;
  // console.log(data);

  useEffect(() => {
    refetch();
    refecthCount();
  }, [count]);

  const follow = (user_id) => {
    var following_user_id = localStorage.getItem("user_id");
    var followed_user_id = user_id;
    setHidden({ ...hidden, [user_id]: !hidden[user_id] });

    const formData = new FormData();
    formData.append("following_user_id", following_user_id);
    formData.append("followed_user_id", followed_user_id);
    const url = baseURL;
    axios({
      method: "POST",
      url: url + "/following/create",
      data: formData,
      headers: {
        Authorization: HeaderToken.Authorization,
        Accept: HeaderToken.Accept,
      },
    }).then((response) => {
      setCount(count + 1);
      setHidden({ ...hidden, [user_id]: hidden[user_id] });
      refecthCount();
    });
    refetch();
  };

  return (
    <div className="p-3">
      {status == "loading" ? (
        <div className="col-12 text-center p-5 color-blue">
          <ClipLoader size={25} color={"#333333"} />
        </div>
      ) : (
        <Row>
          {data.academics.map((item, index) => (
            <Col lg={4} sm={4} xs={6} className="mb-4 p-1">
              <div className="topic-container p-0">
                <Link
                  to={
                    statusCount == "success" && dataCount.count < 5
                      ? "/academics"
                      : "/" + item.username
                  }
                  className={"color-blue textUnderlineNone"}
                >
                  <div
                    className={"blocked-users-cover"}
                    style={{
                      backgroundImage:
                        "url(" +
                        imgURL +
                        "public/profile/cover/@" +
                        item.username +
                        "/" +
                        item.profile_cover +
                        ")",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                </Link>

                <div className="p-2 fontSize14 relative">
                  <Link
                    to={
                      statusCount == "success" && dataCount.count < 5
                        ? "/academics"
                        : "/" + item.username
                    }
                    className={"color-blue textUnderlineNone"}
                  >
                    <div
                      className={"profile-pic blocked-user-profile-pic"}
                      style={{
                        backgroundImage:
                          "url(" +
                          imgURL +
                          "public/profile/picture/@" +
                          item.username +
                          "/" +
                          item.profile_pic +
                          ")",
                      }}
                    />
                  </Link>{" "}
                  <Link
                    to={"/" + item.username}
                    className={"color-black textUnderlineNone"}
                  >
                    <div className={"mt-3 line-height-20"}>
                      <span className="mobile-hide text-bold">
                        {item.fullname.length > 19
                          ? item.fullname.slice(0, 19) + "..."
                          : item.fullname}
                      </span>

                      <span className="screen-hide text-bold">
                        {item.fullname.length > 14
                          ? item.fullname.slice(0, 14) + "..."
                          : item.fullname}
                      </span>
                    </div>
                    <label className={"footnote cursorPointer w-100"}>
                      <span className="mobile-hide text-normal">
                        @
                        {item.username.length > 19
                          ? item.username.slice(0, 19) + "..."
                          : item.username}
                      </span>

                      <span className="screen-hide text-normal">
                        @
                        {item.username.length > 14
                          ? item.username.slice(0, 14) + "..."
                          : item.username}
                      </span>
                    </label>
                  </Link>{" "}
                  <br />
                  {!hidden[item.id] && (
                    <Button
                      type="button"
                      className={
                        "native-button-dark-grey btn-follow-user w-100 fontBold no-shadow mt-2 mb-2 btn-sm"
                      }
                      onClick={() => follow(item.id)}
                    ></Button>
                  )}
                  {!!hidden[item.id] && (
                    <label
                      className={
                        "disabled-bg-color fontBold no-shadow btn-sm btn w-100 mt-2 mb-2"
                      }
                    >
                      <ClipLoader size={15} color="#000" />
                    </label>
                  )}
                </div>
              </div>
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
}
