import React, { useEffect } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Container, Row, Col, Button, Stack } from "react-bootstrap";
import BeatLoader from "react-spinners/BeatLoader";
import axios from "axios";
import { Link } from "react-router-dom";
import HeaderToken from "../HeaderToken";
import { ClipLoader } from "react-spinners";

const headers = {
  Authorization: `${HeaderToken.Authorization}`,
  Accept: `${HeaderToken.Accept}`,
};

const Followers = (props) => {
  const queryClient = useQueryClient();
  const user_id = localStorage.getItem("user_id");
  const baseURL = process.env.REACT_APP_URL;
  useEffect(() => {}, []);
  const url = process.env.REACT_APP_URL;
  const imgURL = process.env.REACT_APP_URL.replace("api", "");

  const followerQuery = useQuery({
    queryKey: ["followers", window.location.href],
    queryFn: async () => {
      const response = await axios.get(
        `${url}/${props.userID}/${user_id}/followers`,
        { headers }
      );
      const data = response.data;
      return data;
    },
  });

  const { data, status, refetch } = followerQuery;

  function unBlock(blocked_user_id) {
    axios
      .get(url + "/block/user/" + props.userID + "/" + blocked_user_id)
      .then((res) => {
        queryClient.invalidateQueries(
          { queryKey: ["followers"] },
          { exact: true }
        );
        // refetch();
      });
  }

  function follow(actioned_user_id) {
    const following_user_id = user_id;
    const followed_user_id = actioned_user_id;

    const formData = new FormData();
    formData.append("following_user_id", following_user_id);
    formData.append("followed_user_id", followed_user_id);
    const url = baseURL;
    axios({
      method: "POST",
      url: url + "/following/create",
      data: formData,
      headers: {
        Authorization: HeaderToken.Authorization,
        Accept: HeaderToken.Accept,
      },
    }).then((response) => {
      queryClient.invalidateQueries(
        { queryKey: ["followers"] },
        { exact: true }
      );
    });
    // refetch();
  }

  return (
    <div className="p-3">
      {status == "loading" ? (
        <div className="col-12 text-center p-5 color-blue">
          <ClipLoader size={25} color={"#333333"} />
        </div>
      ) : (
        <>
          {data.length == 0 ? (
            <div lg={12} className="text-center mb-3 mt-3 fontSize-20">
              <h3>Your followers will appear here.</h3>
              <label className="footnote mb-3">
                Simply follow back or block.
              </label>
            </div>
          ) : (
            <Row>
              {data.map((item, index) => (
                <Col lg={4} sm={4} xs={6} className="mb-4 p-1">
                  <div className="topic-container p-0">
                    <Link
                      to={"/" + item.username}
                      className={"color-blue textUnderlineNone"}
                    >
                      <div
                        className={"blocked-users-cover"}
                        style={{
                          backgroundImage:
                            "url(" +
                            imgURL +
                            "public/profile/cover/@" +
                            item.username +
                            "/" +
                            item.profile_cover +
                            ")",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                        }}
                      />
                    </Link>

                    <div className="p-2 fontSize14 relative">
                      <Link
                        to={"/" + item.username}
                        className={"color-blue textUnderlineNone"}
                      >
                        <div
                          className={"profile-pic blocked-user-profile-pic"}
                          style={{
                            backgroundImage:
                              "url(" +
                              imgURL +
                              "public/profile/picture/@" +
                              item.username +
                              "/" +
                              item.profile_pic +
                              ")",
                          }}
                        />
                      </Link>{" "}
                      <Link
                        to={"/" + item.username}
                        className={"color-black textUnderlineNone"}
                      >
                        <div className={"mt-3 line-height-20"}>
                          <span className="mobile-hide text-bold">
                            {item.fullname.length > 19
                              ? item.fullname.slice(0, 19) + "..."
                              : item.fullname}
                          </span>

                          <span className="screen-hide text-bold">
                            {item.fullname.length > 14
                              ? item.fullname.slice(0, 14) + "..."
                              : item.fullname}
                          </span>
                        </div>
                        <label className={"footnote cursorPointer w-100"}>
                          <span className="mobile-hide text-normal">
                            @
                            {item.username.length > 19
                              ? item.username.slice(0, 19) + "..."
                              : item.username}
                          </span>

                          <span className="screen-hide text-normal">
                            @
                            {item.username.length > 14
                              ? item.username.slice(0, 14) + "..."
                              : item.username}
                          </span>
                        </label>
                      </Link>{" "}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          )}
        </>
      )}
    </div>
  );
};

export default Followers;
