import Button from "@restart/ui/esm/Button";
import React, { useState, useEffect } from "react";
import { Col, Container, Row, Stack, Modal } from "react-bootstrap";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import HeaderToken from "../HeaderToken";
import {
  BsHouseDoor,
  BsPeople,
  BsChatRightDots,
  BsPen,
  BsBell,
  BsEmojiSmile,
  BsMegaphone,
  BsBookmarks,
  BsChatDots,
  BsPencil,
  BsHandThumbsUpFill,
  BsBookmarkCheckFill,
  BsChatTextFill,
} from "react-icons/bs";
import {
  FaPoll,
  FaRegFileExcel,
  FaRegFilePowerpoint,
  FaRegFilePdf,
  FaRegFileWord,
  FaFileCsv,
  FaFilePdf,
} from "react-icons/fa";
import {
  IoIosLogOut,
  IoMdImages,
  IoIosMic,
  IoIosHeartEmpty,
} from "react-icons/io";
import {
  IoArrowBack,
  IoAtSharp,
  IoAttach,
  IoBanOutline,
  IoBanSharp,
  IoGridOutline,
  IoHardwareChipOutline,
  IoLogOutOutline,
  IoMenu,
  IoPencil,
  IoPeopleOutline,
  IoPersonOutline,
  IoScaleOutline,
} from "react-icons/io5";
import ReactTooltip from "react-tooltip";
import TopBarProgress from "react-topbar-progress-indicator";
import axios from "axios";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";

import { useQuery, useQueryClient } from "@tanstack/react-query";
import Posts from "../Posts";
import mentionStyle from "../css/mentionStyle";
import tagStyle from "../css/tagStyle";
import { Tagify } from "react-tagify";
import { Linkify } from "react-easy-linkify";
import { SecureLink } from "react-secure-link";
import Footer from "./Footer";
import { HiOutlineEnvelope } from "react-icons/hi2";
import { BiHomeCircle } from "react-icons/bi";
import { Tooltip } from "primereact/tooltip";

export default function HeaderHome() {
  const headers = {
    Authorization: `${HeaderToken.Authorization}`,
    Accept: `${HeaderToken.Accept}`,
  };

  const queryClient = useQueryClient();
  const { tag } = useParams();
  let navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);

    // if (isOpen) {
    //   document.documentElement.style.overflowY = "hidden";
    //   console.log(isOpen);
    // } else {
    //   document.documentElement.style.overflowY = "auto";
    //   console.log("close");
    // }
  };
  let user_id = localStorage.getItem("user_id");
  let pathname = window.location.pathname;
  if (!localStorage.getItem("user_id") || !localStorage.getItem("username")) {
    pathname = window.location.pathname;
    localStorage.setItem("unauthorized", true);
    localStorage.setItem("page", pathname);
    window.location.href = "/login";
    // history.push({ pathname: "/login" });
  }

  const followCountQuery = useQuery({
    queryKey: ["followCount"],
    queryFn: async () => {
      const response = await axios.get(
        `${baseURL}/following/count/${user_id}`,
        {
          headers,
        }
      );
      const data = await response.data;
      return data;
    },
    staleTime: 1000 * 60 * 60 * 4,
    cacheTime: 1000 * 60 * 60 * 24,
    refetchOnWindowFocus: false,
  });

  const mentionsCountQuery = useQuery({
    queryKey: ["mentionsCount"],
    queryFn: async () => {
      const response = await axios.get(
        `${baseURL}/notification/mentions/count/${user_id}`,
        {
          headers,
        }
      );
      const data = await response.data;
      return data;
    },
  });

  if (
    pathname != "/academics" &&
    followCountQuery.isSuccess &&
    followCountQuery.count < 5
  ) {
    // console.log(pathname);
    window.location.href = "/academics";
  }

  const location = useLocation();
  const [queryParameters] = useSearchParams();
  let topicClicked = queryParameters.get("clicked");
  let from_post_id = queryParameters.get("from_post_id");

  let params = "";
  if (queryParameters.get("clicked") && queryParameters.get("from_post_id")) {
    params = "?clicked=" + topicClicked + "&from_post_id=" + from_post_id;
  } else if (
    queryParameters.get("clicked") &&
    !queryParameters.get("from_post_id")
  ) {
    params = "?clicked=" + topicClicked;
  }

  const [searchString, setSearchString] = useState(
    tag ? `#${tag}` : topicClicked ? `${topicClicked}` : ""
  );
  const [searchFront, setSearchFront] = useState("");
  const [is_search_front_active, set_is_search_front_active] = useState(false);
  const [results, setResults] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [topics, setTopics] = useState([]);
  const [posts, setPosts] = useState([]);
  const [logout, setLogout] = useState(false);
  const [progressBar, setProgressBar] = useState(false);
  const [followCount, setFollowCount] = useState(0);
  const [btnFollowTopic, setBtnFollowTopic] = useState("btn-follow-topics");
  const [successfulTopicFollowShow, setSuccessfulTopicFollowShow] =
    useState(false);
  const [successfulTopicFollow, setSuccessfulTopicFollow] = useState("");

  const baseURL = process.env.REACT_APP_URL;
  const imgURL = baseURL.replace("api", "");

  useEffect(() => {
    const headers = {
      Authorization: `${HeaderToken.Authorization}`,
      Accept: `${HeaderToken.Accept}`,
    };
    queryClient.invalidateQueries(
      { queryKey: ["notificationMessageCount"] },
      { exact: true }
    );

    queryClient.invalidateQueries(
      { queryKey: ["notificationCount"] },
      { exact: true }
    );

    queryClient.invalidateQueries(
      { queryKey: ["followingFollowersQuery"] },
      { exact: true }
    );
  }, [window.location.href]);

  const msgNotificationCountQuery = useQuery({
    queryKey: ["notificationMessageCount"],
    queryFn: async () => {
      const response = await axios.get(
        `${baseURL}/notification/message/count/${user_id}`,
        { headers }
      );
      const data = response.data;
      return data;
    },
  });

  const notificationCountQuery = useQuery({
    queryKey: ["notificationCount"],
    queryFn: async () => {
      const response = await axios.get(
        `${baseURL}/all/notification/count/${user_id}`,
        {
          headers,
        }
      );
      const data = response.data;
      return data;
    },
  });

  const followingFollowersQuery = useQuery({
    queryKey: ["followingFollowersQuery"],
    queryFn: async () => {
      const response = await axios.get(
        `${baseURL}/following/followers/count/${user_id}`,
        { headers }
      );
      const data = response.data;
      return data;
    },
  });

  const {
    data: dataCount,
    status: statusCount,
    refetch: refecthCount,
  } = followingFollowersQuery;

  function handleClose() {
    setLogout(false);
  }

  function handleLogout() {
    setLogout(true);
  }

  let username = localStorage.getItem("username");
  // const username = "@kara";
  let user_name = "";
  if (username.length > 8) {
    user_name = username.substring(0, 8) + "...";
  } else {
    user_name = username;
  }

  function deactivateSearchFront() {
    setSearchFront("");
    set_is_search_front_active(false);
  }

  function searchClicked() {
    setSearchFront("search-front");
    set_is_search_front_active(true);
  }

  function keywordSearch(e) {
    setSearchString(e.target.value);
    let keyword = e.target.value;

    axios
      .get(`${baseURL}/${user_id}/search/site?keyword=${keyword}`, { headers })
      .then((res) => {
        if (res.data.results.users.length > 0) {
          setResults(res.data.results.users);
        } else {
          setResults([]);
        }

        if (res.data.results.documents.length > 0) {
          setDocuments(res.data.results.documents);
        } else {
          setDocuments([]);
        }

        if (res.data.results.topics.length > 0) {
          setTopics(res.data.results.topics);
        } else {
          setTopics([]);
        }

        if (res.data.results.posts.length > 0) {
          setPosts(res.data.results.posts);
        } else {
          setPosts([]);
        }
      })
      .catch((error) => {
        setResults([]);
        setDocuments([]);
        setTopics([]);
        setPosts([]);
        // console.log(error);
      });
  }

  const clickedFollow = (topic_id, topic) => {
    const data = new FormData();
    data.append("user_id", user_id);
    data.append("topic_id", topic_id);
    axios({
      method: "POST",
      url: baseURL + "/follow/topics",
      data: data,
      headers: {
        Authorization: HeaderToken.Authorization,
        Accept: HeaderToken.Accept,
      },
    })
      .then((res) => {
        setSuccessfulTopicFollowShow(true);
        if (res.data.is_followed == true) {
          setSuccessfulTopicFollow(
            <label>
              Your're now following{" "}
              <span className="text-bold">{`${topic}`}</span>
            </label>
          );
        } else {
          setSuccessfulTopicFollow(
            <label>
              Your've unfollowed <span className="text-bold">{`${topic}`}</span>
            </label>
          );
        }
      })
      .catch((error) => {});
    // topicRefetch();
    setTimeout(() => {
      setSuccessfulTopicFollowShow(false);
    }, 4000);
  };

  function hashtag(value, type, post_id) {
    let hashtagValue;
    if (type == "tag") {
      hashtagValue = value.replace("#", "");
      navigate(`/hashtag/${post_id}/${hashtagValue}`);
      // history.push({ pathname: `/hashtag/${post_id}/${hashtagValue}` });
    } else {
      hashtagValue = value.replace("@", "");
      navigate(`/${hashtagValue}`);
      // history.push({ pathname: `/profile/${hashtagValue}` });
    }

    return;
  }

  function replyPage(post, name, username, post_uuid) {
    var url = window.location.pathname;
    if (url.includes("hashtag")) {
      return;
    } else if (url.includes("profile")) {
      return;
    } else {
      navigate("/status/" + username + "/" + post_uuid);
      // history.push({
      //   pathname: "/status/" + username + "/" + post_uuid,
      // });
    }
  }

  function scroll() {
    window.scrollTo(0, 0);
  }

  // var prevScrollpos = window.pageYOffset;
  // window.onscroll = function () {
  //   var currentScrollPos = window.pageYOffset;
  //   if (prevScrollpos > currentScrollPos) {
  //     document.getElementById("footer").classList.remove("footerHide");
  //     document.getElementById("footer").className += " footerShow";

  //     document.getElementById("header_mobile").classList.remove("footerHide");
  //     document.getElementById("header_mobile").className += " footerShow";
  //   } else {
  //     document.getElementById("footer").classList.remove("footerShow");
  //     document.getElementById("footer").className += " footerHide";

  //     document.getElementById("header_mobile").classList.remove("footerShow");
  //     document.getElementById("header_mobile").className += " footerHide";
  //   }
  //   prevScrollpos = currentScrollPos;
  // };
  console.log(window.location.pathname + " dddddddddddd");
  function sharePost() {
    navigate("/compose/post", {
      state: { page: window.location.pathname, params: params },
    });
  }
  return (
    <>
      <div className={"header_mobile header-bg-white pb-3"} id="header_mobile">
        <Container>
          <Row>
            <Col className="text-left p-3">
              <h4 className="line-height-10">
                {/* <img
                  src={
                    process.env.REACT_APP_URL.replace("api", "") + "silence.png"
                  }
                  // src={"http://127.0.0.1:3000/silence.png"}
                  className="float-left"
                  width={32}
                />
                &nbsp;&nbsp; */}
                <Link to={"/home"}>
                  <label className="mt-2 text-bold color-blue line-height-15 cursorPointer">
                    {process.env.REACT_APP_YOUR_VARIABLE_NAME_HERE}
                  </label>
                </Link>
              </h4>
            </Col>
            {/* <Col className="text-right p-3">
              <NavLink
                to="/academics"
                className="textUnderlineNone mobile-tabs-icons color-black mb-0 fontSize16 hover mr-5px"
                exact
                activeClassName="mobile-tabs-icons-active"
                onClick={scroll}
              >
                <IoPeopleOutline size={21} className="mr-5px" />
                <br />
                <span className="fontSize-10">Menu</span>
              </NavLink>
            </Col> */}
          </Row>
          <Row>
            <Col className="text-center line-height-15">
              <NavLink
                to="/mentions"
                className={(navData) =>
                  navData.isActive
                    ? "mobile-tabs-icons-active textUnderlineNone mobile-tabs-icons color-black mb-0 fontSize16 hover"
                    : "textUnderlineNone mobile-tabs-icons color-black mb-0 fontSize16 hover"
                }
                onClick={scroll}
              >
                <IoAtSharp size={23} />
                <br />
                <span className="fontSize-12">Mentions</span>
                {mentionsCountQuery.isLoading ? (
                  ""
                ) : (
                  <>
                    {mentionsCountQuery.count == 0 ? (
                      ""
                    ) : (
                      <label className="mention-notifications">
                        {mentionsCountQuery.count}
                      </label>
                    )}
                  </>
                )}
              </NavLink>
            </Col>
            <Col className="text-center line-height-15">
              <NavLink
                to="/bookmarks"
                className={(navData) =>
                  navData.isActive
                    ? "mobile-tabs-icons-active textUnderlineNone mobile-tabs-icons color-black mb-0 fontSize16 hover"
                    : "textUnderlineNone mobile-tabs-icons color-black mb-0 fontSize16 hover"
                }
                onClick={scroll}
              >
                <BsBookmarkCheckFill size={23} />
                <br />
                <span className="fontSize-12">Bookmarks</span>
              </NavLink>
            </Col>
            <Col className="text-center line-height-15">
              <NavLink
                to="/likes"
                className={(navData) =>
                  navData.isActive
                    ? "mobile-tabs-icons-active textUnderlineNone mobile-tabs-icons color-black mb-0 fontSize16 hover"
                    : "textUnderlineNone mobile-tabs-icons color-black mb-0 fontSize16 hover"
                }
                onClick={scroll}
              >
                <BsHandThumbsUpFill size={23} />
                <br />
                <span className="fontSize-12">Likes</span>
              </NavLink>
            </Col>
            {/* <Col className="text-center line-height-10">
              <NavLink
                to="/topics"
                className="textUnderlineNone mobile-tabs-icons color-black mb-0 fontSize16 hover"
                exact
                activeClassName="mobile-tabs-icons-active"
                onClick={scroll}
              >
                <BsChatDots size={21} />
                <br />
                <span className="fontSize-10">Topics</span>
              </NavLink>
            </Col> */}
            {/* <Col className="text-center line-height-10">
              <NavLink
                to="/blocked"
                className={(navData) =>
                  navData.isActive
                    ? "mobile-tabs-icons-active textUnderlineNone mobile-tabs-icons color-black mb-0 fontSize16 hover"
                    : "textUnderlineNone mobile-tabs-icons color-black mb-0 fontSize16 hover"
                }
                onClick={scroll}
              >
                <IoBanOutline size={21} />
                <br />
                <span className="fontSize-10">Blocked</span>
              </NavLink>
            </Col> */}
            <Col className="text-center line-height-15">
              <label
                onClick={toggleDrawer}
                className="textUnderlineNone mobile-tabs-icons color-black mb-0 fontSize16 hover"
              >
                <IoGridOutline size={23} />
                <br />
                <span className="fontSize-12">Menu</span>
              </label>
            </Col>
          </Row>

          <Drawer
            open={isOpen}
            onClose={toggleDrawer}
            direction="left"
            className={"drawer"}
            size={"85%"}
          >
            <Row className="justify-content-center mt-3">
              <Col xs={10} sm={10} className="mb-0">
                <NavLink
                  to={{
                    pathname:
                      "/" + localStorage.getItem("username").replace("@", ""),
                  }}
                  data-tip={`${localStorage.getItem(
                    "fullname"
                  )} ${localStorage.getItem("username")}`}
                  data-place={"bottom"}
                  data-effect={"solid"}
                  className={(navData) =>
                    navData.isActive
                      ? "mobile-tabs-icons-active text-center textUnderlineNone color-grey line-height-20"
                      : "text-center textUnderlineNone color-grey line-height-20"
                  }
                >
                  <div
                    className={"profile-pic-drawer"}
                    style={{
                      backgroundImage:
                        "url(" +
                        process.env.REACT_APP_URL.replace("api", "") +
                        "public/profile/picture/" +
                        localStorage.getItem("username") +
                        "/" +
                        localStorage.getItem("profile_pic") +
                        ")",
                    }}
                  />
                  <div className="w-100 line-height-20 text-left mt-2 ">
                    <span className="text-bold fontSize-18 text-left">
                      {localStorage.getItem("fullname")}
                    </span>
                    <br />
                    <span>
                      @{localStorage.getItem("username").replace("@", "")}
                    </span>
                  </div>
                </NavLink>
              </Col>
              <Col xs={10} sm={10} className={"pt-0 mb-2"}>
                <div class="w-100">
                  <label className="timeline_hr_line w-100" />
                </div>
              </Col>
              <Col xs={10} sm={10} className="mb-3">
                <NavLink
                  to={`/following/${username.replace("@", "")}`}
                  className={(navData) =>
                    navData.isActive
                      ? "explore-options-link-active color-black textUnderlineNone cursorPointer line-height-20"
                      : "color-black textUnderlineNone cursorPointer explore-options-link line-height-20"
                  }
                >
                  Following
                  <br />
                  <label class="">
                    <label class="footnote fontSize14">
                      <span class="fontSize-20 text-color-brown">
                        {followingFollowersQuery.isLoading
                          ? 0
                          : followingFollowersQuery.data.followingCount}
                      </span>{" "}
                      People
                    </label>
                  </label>
                </NavLink>
              </Col>
              <Col xs={10} sm={10} className="mb-3">
                <NavLink
                  to={`/followers/${username.replace("@", "")}`}
                  className={(navData) =>
                    navData.isActive
                      ? "explore-options-link-active color-black textUnderlineNone cursorPointer line-height-20"
                      : "color-black textUnderlineNone cursorPointer explore-options-link line-height-20"
                  }
                >
                  Followers
                  <br />
                  <label class="">
                    <label class="footnote fontSize14">
                      <span class="fontSize-20 text-color-brown">
                        {followingFollowersQuery.isLoading
                          ? 0
                          : followingFollowersQuery.data.followersCount}
                      </span>{" "}
                      Followers
                    </label>
                  </label>
                </NavLink>
              </Col>

              <Col xs={10} sm={10} className="mb-3">
                <NavLink
                  to={`/subjects`}
                  className={(navData) =>
                    navData.isActive
                      ? "explore-options-link-active color-black textUnderlineNone cursorPointer line-height-20"
                      : "color-black textUnderlineNone cursorPointer explore-options-link line-height-20"
                  }
                >
                  Subjects
                  <br />
                  <label class="">
                    <label class="footnote fontSize14">
                      <span class="fontSize-20 text-color-brown">
                        {followingFollowersQuery.isLoading
                          ? 0
                          : followingFollowersQuery.data.followSubjectsCount}
                      </span>{" "}
                      Subjects following
                    </label>
                  </label>
                </NavLink>
              </Col>

              <Col xs={10} sm={10}>
                <NavLink
                  to={`/topics`}
                  className={(navData) =>
                    navData.isActive
                      ? "explore-options-link-active color-black textUnderlineNone cursorPointer line-height-20"
                      : "color-black textUnderlineNone cursorPointer explore-options-link line-height-20"
                  }
                >
                  Topics
                  <br />
                  <label class=" pb-0">
                    <label class="footnote fontSize14">
                      <span class="fontSize-20 text-color-brown">
                        {followingFollowersQuery.isLoading
                          ? 0
                          : followingFollowersQuery.data.followTopicsCount}
                      </span>{" "}
                      Topics following
                    </label>
                  </label>
                </NavLink>
              </Col>
              <Col xs={10} sm={10} className={"pt-0"}>
                <div class="w-100">
                  <label className="timeline_hr_line w-100" />
                </div>
              </Col>
              <Col xs={10} sm={10} className={"padding-15 mt-2"}>
                <NavLink
                  to={{
                    pathname:
                      "/" + localStorage.getItem("username").replace("@", ""),
                  }}
                  className={(navData) =>
                    navData.isActive
                      ? "explore-options-link-active color-black textUnderlineNone cursorPointer line-height-20"
                      : "color-black textUnderlineNone cursorPointer explore-options-link line-height-20"
                  }
                >
                  <label className={"explore-options w-100 mb-3 cursorPointer"}>
                    <IoPersonOutline size={21} />
                    &nbsp;&nbsp;&nbsp;Profile
                  </label>
                </NavLink>

                <NavLink
                  to={"/blocked"}
                  className={(navData) =>
                    navData.isActive
                      ? "explore-options-link-active color-black textUnderlineNone cursorPointer line-height-20"
                      : "color-black textUnderlineNone cursorPointer explore-options-link line-height-20"
                  }
                >
                  <label className={"explore-options w-100 mb-0 cursorPointer"}>
                    <IoBanSharp size={21} />
                    &nbsp;&nbsp;&nbsp;Blocked
                  </label>
                </NavLink>
              </Col>
              <Col xs={10} sm={10} className={"pt-0"}>
                <div class="w-100">
                  <label className="timeline_hr_line w-100" />
                </div>
              </Col>

              <Col xs={10} sm={10} className={"padding-15"}>
                <h5 class="mb-2 mt-2">
                  <label class="text-bold">Explore</label>
                </h5>
                <NavLink
                  to={"/academics"}
                  className={(navData) =>
                    navData.isActive
                      ? "explore-options-link-active color-black textUnderlineNone cursorPointer line-height-20"
                      : "color-black textUnderlineNone cursorPointer explore-options-link line-height-20"
                  }
                >
                  <label className={"explore-options w-100 mb-3 cursorPointer"}>
                    <IoPeopleOutline size={21} />
                    &nbsp;&nbsp;&nbsp;People
                  </label>
                </NavLink>

                <NavLink
                  to={"/subjects"}
                  className={(navData) =>
                    navData.isActive
                      ? "explore-options-link-active color-black textUnderlineNone cursorPointer line-height-20"
                      : "color-black textUnderlineNone cursorPointer explore-options-link line-height-20"
                  }
                >
                  <label className={"explore-options w-100 mb-3 cursorPointer"}>
                    <IoHardwareChipOutline size={21} />
                    &nbsp;&nbsp;&nbsp;Subjects
                  </label>
                </NavLink>

                <NavLink
                  to={"/topics"}
                  className={(navData) =>
                    navData.isActive
                      ? "explore-options-link-active color-black textUnderlineNone cursorPointer line-height-20"
                      : "color-black textUnderlineNone cursorPointer explore-options-link line-height-20"
                  }
                >
                  <label className={"explore-options w-100 mb-3 cursorPointer"}>
                    <IoScaleOutline size={21} />
                    &nbsp;&nbsp;&nbsp;Topics
                  </label>
                </NavLink>
              </Col>
              <Col xs={10} sm={10} className={"pt-0"}>
                <div class="w-100">
                  <label className="timeline_hr_line w-100" />
                </div>
              </Col>
              <Col xs={10} sm={10} className={"padding-15"}>
                <h5 class="mb-2 mt-2">
                  <label class="text-bold">I will be back</label>
                </h5>

                <label
                  onClick={handleLogout}
                  className={(navData) =>
                    navData.isActive
                      ? "explore-options-link-active color-black textUnderlineNone cursorPointer line-height-20"
                      : "color-black textUnderlineNone cursorPointer explore-options-link line-height-20"
                  }
                >
                  <label className={"explore-options w-100 mb-3 cursorPointer"}>
                    <IoLogOutOutline size={21} />
                    &nbsp;&nbsp;&nbsp;Log out
                  </label>
                </label>
              </Col>
            </Row>
          </Drawer>
        </Container>
      </div>
      <div className="header">
        <div
          className={
            followCountQuery.isSuccess && followCountQuery.count < 5
              ? "d-none"
              : "p-2 header-bg-white"
          }
        >
          {progressBar && <TopBarProgress />}
          {/* <Container> */}
          <Row className="align-items-center">
            <Col lg={7}>
              <Stack>
                <Row className="justify-content-center align-items-center relative">
                  <div className="edge-icon-header" />
                  {/* <Col lg={1}></Col> */}
                  {/* <Col lg={1}></Col> */}
                  {/* <Col lg={1}></Col> */}
                  <div className="top-header-icon">
                    <Tooltip
                      target=".home__tap"
                      content="Home"
                      position="bottom"
                    />
                    <Stack>
                      <NavLink
                        to="/home"
                        // data-pr-tooltip="Home"
                        // data-pr-position="bottom"
                        // data-tip={"Homsse"}
                        // data-place={"bottom"}
                        // data-effect={"solid"}
                        className={(navData) =>
                          navData.isActive
                            ? "icons-header-active text-center home__tap"
                            : "text-center icons-header home__tap"
                        }
                        onClick={scroll}
                      >
                        <BiHomeCircle size={27} />{" "}
                        {/* <ReactTooltip
                          arrowColor={"transparent"}
                          className="p-1 fontSize-12"
                        /> */}
                      </NavLink>
                    </Stack>
                  </div>

                  <div className="top-header-icon">
                    <Stack>
                      <Tooltip
                        target=".compose__post"
                        content="Compose post"
                        position="bottom"
                      />
                      <label
                        onClick={sharePost}
                        // data-tip={"Compose post"}
                        // data-place={"bottom"}
                        // data-effect={"solid"}
                        className="text-center icons-header compose__post"
                        exact
                        activeClassName="icons-header-active compose__post"
                      >
                        <BsPencil size={27} />{" "}
                        {/* <ReactTooltip
                          arrowColor={"transparent"}
                          className="p-1 fontSize-12"
                        /> */}
                      </label>
                    </Stack>
                  </div>

                  <div className="top-header-icon">
                    <Stack>
                      <Tooltip
                        target=".__messages"
                        content="Messages"
                        position="bottom"
                      />
                      <NavLink
                        to="/messages"
                        // data-tip={"Messages"}
                        // data-place={"bottom"}
                        // data-effect={"solid"}
                        className={(navData) =>
                          navData.isActive
                            ? "text-center icons-header-active text-center relative __messages"
                            : "text-center icons-header relative __messages"
                        }
                        onClick={scroll}
                      >
                        <HiOutlineEnvelope size={27} />
                        {msgNotificationCountQuery.isLoading ? (
                          ""
                        ) : (
                          <>
                            {msgNotificationCountQuery.count == 0 ? (
                              ""
                            ) : (
                              <label className="message-notification">
                                {msgNotificationCountQuery.count}
                              </label>
                            )}
                          </>
                        )}
                        {/* <ReactTooltip
                          arrowColor={"transparent"}
                          className="p-1 fontSize-12"
                        /> */}
                      </NavLink>
                    </Stack>
                  </div>
                  <Col lg={1}></Col>
                  <Col lg={1}></Col>
                  <Col lg={1}></Col>
                  <Col lg={5} className={"p-0"}>
                    <Stack gap={4}>
                      <Row className={`${searchFront} align-items-center`}>
                        <div
                          className={
                            is_search_front_active ? "col-2" : "d-none"
                          }
                        >
                          <span
                            className={"icons-header text-normal"}
                            onClick={deactivateSearchFront}
                          >
                            <IoArrowBack
                              size={20}
                              style={{ marginTop: "-3px" }}
                            />
                          </span>
                        </div>
                        <div
                          className={
                            is_search_front_active ? "d-none" : "col-2 p-0"
                          }
                        >
                          <Link to={"/home"}>
                            <div
                              className={"profile-pic-logo"}
                              style={{
                                backgroundImage:
                                  "url(" +
                                  process.env.REACT_APP_URL.replace("api", "") +
                                  "silence.png" +
                                  ")",
                              }}
                            />
                          </Link>
                        </div>
                        <div
                          className={
                            is_search_front_active ? "col-10" : "p-0 col-10"
                          }
                        >
                          <input
                            type="text"
                            placeholder={
                              "Search " +
                              process.env.REACT_APP_YOUR_VARIABLE_NAME_HERE
                            }
                            value={searchString}
                            className=" border-circle"
                            onChange={keywordSearch}
                            onClick={searchClicked}
                          />
                        </div>

                        {results.length > 0 ||
                        documents.length > 0 ||
                        topics.length > 0 ||
                        posts.length > 0 ? (
                          <div
                            className={
                              is_search_front_active
                                ? "search-results mt-2"
                                : "d-none"
                            }
                            onClick={deactivateSearchFront}
                          >
                            <div className="p-2 pr-0">
                              {posts.length > 0 ? (
                                <h4 className="text-color-brown">Posts</h4>
                              ) : (
                                ""
                              )}
                              {posts.map((item, index) => (
                                <Row
                                  key={index}
                                  className="justify-content-center align-items-center side-nav-topics mt-0 mb-0"
                                  onClick={() =>
                                    replyPage(
                                      item.post,
                                      item.first_name + " " + item.last_name,
                                      item.username,
                                      item.id
                                    )
                                  }
                                >
                                  <Col lg={12}>
                                    <Row className={"align-items-center"}>
                                      <Col lg={12}>
                                        <label className="line-height-20 cursorPointer">
                                          <Tagify
                                            onClick={(text, type) =>
                                              hashtag(text, type, item.id)
                                            }
                                            detectHashtags={true}
                                            detectMentions={true}
                                            tagStyle={tagStyle}
                                            mentionStyle={mentionStyle}
                                            detectLinks={true}
                                          >
                                            <Linkify
                                              componentDecorator={(
                                                decoratedHref,
                                                decoratedText,
                                                key
                                              ) => (
                                                <SecureLink
                                                  target="_blank"
                                                  href={decoratedHref}
                                                  key={key}
                                                  className={
                                                    "color-blue textUnderlineNone"
                                                  }
                                                >
                                                  {decoratedText}
                                                </SecureLink>
                                              )}
                                            >
                                              {!item.post ? (
                                                <label className="color-blue">
                                                  <IoAttach size={20} /> With
                                                  attachments
                                                </label>
                                              ) : item.post.length > 60 ? (
                                                item.post.slice(0, 60) + "..."
                                              ) : (
                                                item.post
                                              )}
                                            </Linkify>
                                          </Tagify>
                                        </label>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              ))}
                            </div>

                            <div className="p-2 pr-0">
                              {topics.length > 0 ? (
                                <h4 className="text-color-brown">Topics</h4>
                              ) : (
                                ""
                              )}
                              {topics.map((item, index) => (
                                <Row
                                  key={index}
                                  className="justify-content-center align-items-center side-nav-topics mt-0 mb-0"
                                >
                                  <Col lg={12}>
                                    <Row className={"align-items-center"}>
                                      <Link
                                        to={"/topicPosts?clicked=" + item.topic}
                                        className={
                                          "color-black textUnderlineNone"
                                        }
                                      >
                                        <Col lg={12}>
                                          <label className="line-height-20">
                                            <h5 className="cursorPointer">
                                              {item.topic.length > 27
                                                ? item.topic.slice(0, 27) +
                                                  "..."
                                                : item.topic}
                                              <br />
                                              <label
                                                className={
                                                  "footnote cursorPointer fontSize14"
                                                }
                                              >
                                                {item.subject}
                                              </label>
                                            </h5>
                                          </label>
                                        </Col>
                                      </Link>
                                      {/* <Col lg={5}>
                                        <Button
                                          type="button"
                                          className={
                                            user_id == item.user_id &&
                                            item.is_followed == 1
                                              ? "btn-follow-topics btn-sm native-button-dark-grey float-right fontBold no-shadow"
                                              : "btn-follow-user btn-sm native-button-dark-grey float-right fontBold no-shadow"
                                          }
                                          onClick={() =>
                                            clickedFollow(item.id, item.topic)
                                          }
                                        >
                                        </Button>
                                      </Col> */}
                                    </Row>
                                  </Col>
                                </Row>
                              ))}
                            </div>
                            <Row>
                              <div className={"padding-20 mt-0"}>
                                {documents.length > 0 ? (
                                  <h4 className="text-color-brown">
                                    Documents
                                  </h4>
                                ) : (
                                  ""
                                )}
                                {documents.map((doc, index) => (
                                  <a
                                    href={
                                      process.env.REACT_APP_URL.replace(
                                        "api",
                                        ""
                                      ) +
                                      "public/timeline/documents/" +
                                      doc.username +
                                      "/" +
                                      doc.file
                                    }
                                    target={"_blank"}
                                    className={"textUnderlineNone"}
                                  >
                                    <Row
                                      className={
                                        "align-items-center footnote docsSearchResults"
                                      }
                                    >
                                      <Col
                                        lg={12}
                                        key={index}
                                        className={"p-0"}
                                      >
                                        <div className={""}>
                                          {doc.format == "pdf" ? (
                                            <label className={"p-1"}>
                                              <FaRegFilePdf
                                                size={20}
                                                className={"color-red"}
                                              />
                                              &nbsp;
                                            </label>
                                          ) : doc.format == "docx" ? (
                                            <label className={"p-1"}>
                                              <FaRegFileWord
                                                size={20}
                                                className={"color-blue"}
                                              />
                                              &nbsp;
                                            </label>
                                          ) : doc.format == "csv" ? (
                                            <label className={"p-1"}>
                                              <FaFileCsv
                                                size={20}
                                                className={"color-green"}
                                              />
                                              &nbsp;
                                            </label>
                                          ) : doc.format == "xlsx" ? (
                                            <label className={"p-1"}>
                                              <FaRegFileExcel
                                                size={20}
                                                className={"color-green"}
                                              />
                                              &nbsp;
                                            </label>
                                          ) : doc.format == "pptx" ? (
                                            <label className={"p-1"}>
                                              <FaRegFilePowerpoint
                                                size={20}
                                                className={"color-orange"}
                                              />
                                              &nbsp;
                                            </label>
                                          ) : (
                                            ""
                                          )}
                                          {doc.title == ""
                                            ? doc.file.length > 30
                                              ? doc.file.slice(0, 30) + "..."
                                              : doc.file
                                            : (doc.title.length > 30
                                                ? doc.title.slice(0, 30) + "..."
                                                : doc.title) +
                                              "." +
                                              doc.format}
                                        </div>
                                      </Col>
                                    </Row>
                                  </a>
                                ))}
                              </div>

                              <div className={"mt-3"}>
                                {results.length > 0 ? (
                                  <h4 className="text-color-brown">People</h4>
                                ) : (
                                  ""
                                )}
                                {results.map((item, index) => (
                                  <Col
                                    lg={12}
                                    className="mb-0 mt-0"
                                    key={index}
                                  >
                                    <div className="p-0 mb-0 mt-0">
                                      <Row className="paddingLeftRight mb-0 mt-0">
                                        <NavLink
                                          to={`/${item.username}`}
                                          className={(navData) =>
                                            navData.isActive
                                              ? "side-nav-topics-active mb-0 side-nav-topics textUnderlineNone w-100 d-flex mb-0 p-2"
                                              : "side-nav-topics textUnderlineNone w-100 d-flex mb-0 p-2"
                                          }
                                        >
                                          <Col
                                            lg={12}
                                            className={"cursorPointer "}
                                          >
                                            <div
                                              className={
                                                "profile-pic cursorPointer float-left"
                                              }
                                              style={{
                                                backgroundImage:
                                                  "url(" +
                                                  imgURL +
                                                  "public/profile/picture/@" +
                                                  item.username +
                                                  "/" +
                                                  item.profile_pic +
                                                  ")",
                                              }}
                                            />
                                            &nbsp;&nbsp;{" "}
                                            <label className="line-height-20">
                                              <span className="text-bold cursorPointer">
                                                {item.fullname.length > 21
                                                  ? item.fullname.slice(0, 21) +
                                                    "..."
                                                  : item.fullname}
                                                <br />
                                                <label className="color-grey text-normal cursorPointer">
                                                  @{item.username}
                                                </label>
                                              </span>
                                              <br />
                                            </label>
                                          </Col>
                                        </NavLink>
                                      </Row>
                                    </div>
                                  </Col>
                                ))}
                              </div>
                            </Row>
                          </div>
                        ) : (
                          ""
                        )}
                      </Row>
                    </Stack>
                  </Col>
                  <Col lg={1}></Col>
                </Row>
              </Stack>
            </Col>

            <Col lg={5}>
              <Stack>
                <Row className="justify-content-end align-items-center">
                  <div className="top-header-icon-right-pic">
                    <Stack>
                      <Tooltip
                        target=".__profile"
                        content={`${localStorage.getItem("username")}`}
                        position="bottom"
                      />
                      <NavLink
                        to={{
                          pathname:
                            "/" +
                            localStorage.getItem("username").replace("@", ""),
                        }}
                        onClick={scroll}
                        // data-tip={`${localStorage.getItem("fullname")} ${localStorage.getItem("username")}`}
                        // data-place={"bottom"}
                        // data-effect={"solid"}
                        className={(navData) =>
                          navData.isActive
                            ? "icons-header-active __profile"
                            : "text-center icons-header-other textUnderlineNone color-grey __profile"
                        }
                      >
                        <div
                          className={"profile-pic-header"}
                          style={{
                            backgroundImage:
                              "url(" +
                              process.env.REACT_APP_URL.replace("api", "") +
                              "public/profile/picture/" +
                              localStorage.getItem("username") +
                              "/" +
                              localStorage.getItem("profile_pic") +
                              ")",
                          }}
                        />

                        {/* <span className="text-bold fontSize14 text-left line-height-20 float-left marginLeft-5 marginTop-2">
                          {localStorage.getItem("fullname").length > 20
                            ? localStorage.getItem("fullname").slice(0, 20) +
                              "..."
                            : localStorage.getItem("fullname")}
                        </span> */}
                        {/* <ReactTooltip
                          arrowColor={"transparent"}
                          className="p-1 fontSize-12"
                        /> */}
                      </NavLink>
                    </Stack>
                  </div>
                  <div className="top-header-icon-right">
                    <Stack>
                      <Tooltip
                        target=".__notifications"
                        content={"Notifications"}
                        position="bottom"
                      />
                      <NavLink
                        to={"/notifications"}
                        // data-tip={"Notifications"}
                        // data-place={"bottom"}
                        // data-effect={"solid"}
                        className={(navData) =>
                          navData.isActive
                            ? "text-center icons-header-active relative __notifications"
                            : "text-center icons-header-other relative __notifications"
                        }
                        onClick={scroll}
                      >
                        <BsBell size={27} />
                        {notificationCountQuery.isLoading ? (
                          ""
                        ) : (
                          <>
                            {notificationCountQuery.data.count == 0 ? (
                              ""
                            ) : (
                              <label className="all-notifications">
                                {notificationCountQuery.data.count}
                              </label>
                            )}
                          </>
                        )}
                        {/* <ReactTooltip
                          arrowColor={"transparent"}
                          className="p-1 fontSize-12"
                        /> */}
                      </NavLink>
                    </Stack>
                  </div>
                  <div className="top-header-icon-right">
                    <Stack>
                      <Tooltip
                        target=".__logout"
                        content={"Log out"}
                        position="bottom"
                      />
                      <label
                        onClick={handleLogout}
                        className="text-center icons-header-other __logout"
                        // data-tip={"Log out"}
                        // data-place={"bottom"}
                        // data-effect={"solid"}
                      >
                        <IoIosLogOut size={27} />{" "}
                        {/* <ReactTooltip
                          arrowColor={"transparent"}
                          className="p-1 fontSize-12"
                        /> */}
                      </label>
                    </Stack>
                  </div>

                  <Col lg={1}></Col>
                  {/* <Col lg={1}></Col> */}
                </Row>
              </Stack>
            </Col>
          </Row>
          {/* </Container> */}
          {successfulTopicFollowShow && (
            <Row>
              <Col lg={4}>
                <div className="copiedText color-white">
                  {successfulTopicFollow}
                </div>
              </Col>
            </Row>
          )}
        </div>
        <div className="sub-header p-3">
          <Container className="">
            <Row className="justify-content-center">
              <Col lg={8}>
                <Row className="justify-content-center">
                  <Col className="text-center">
                    <NavLink
                      to="/mentions"
                      className={(navData) =>
                        navData.isActive
                          ? "side-nav-profile-active textUnderlineNone side-nav-profile color-black mb-0 fontSize16 hover padding-15 footnote"
                          : "textUnderlineNone side-nav-profile color-black mb-0 fontSize16 hover padding-15 footnote"
                      }
                      onClick={scroll}
                    >
                      <IoAtSharp size={17} />
                      &nbsp;&nbsp;Mentions&nbsp;
                      {mentionsCountQuery.isLoading ? (
                        ""
                      ) : (
                        <>
                          {mentionsCountQuery.data.count == 0 ? (
                            ""
                          ) : (
                            <label className="mention-notifications">
                              {mentionsCountQuery.data.count}
                            </label>
                          )}
                        </>
                      )}
                    </NavLink>
                  </Col>

                  <Col className="text-center">
                    <NavLink
                      to="/bookmarks"
                      className={(navData) =>
                        navData.isActive
                          ? "side-nav-profile-active textUnderlineNone side-nav-profile color-black mb-0 fontSize16 hover padding-15 footnote"
                          : "textUnderlineNone side-nav-profile color-black mb-0 fontSize16 hover padding-15 footnote"
                      }
                      onClick={scroll}
                    >
                      <BsBookmarkCheckFill size={17} />
                      &nbsp;&nbsp;Bookmarks
                    </NavLink>
                  </Col>

                  <Col className="text-center">
                    <NavLink
                      to="/likes"
                      className={(navData) =>
                        navData.isActive
                          ? "side-nav-profile-active textUnderlineNone side-nav-profile color-black mb-0 fontSize16 hover padding-15 footnote"
                          : "textUnderlineNone side-nav-profile color-black mb-0 fontSize16 hover padding-15 footnote"
                      }
                      onClick={scroll}
                    >
                      <BsHandThumbsUpFill size={17} />
                      &nbsp;&nbsp;Likes
                    </NavLink>
                  </Col>

                  <Col className="text-center">
                    <NavLink
                      to="/topics"
                      className={(navData) =>
                        navData.isActive
                          ? "side-nav-profile-active textUnderlineNone side-nav-profile color-black mb-0 fontSize16 hover padding-15 footnote"
                          : "textUnderlineNone side-nav-profile color-black mb-0 fontSize16 hover padding-15 footnote"
                      }
                      onClick={scroll}
                    >
                      <BsChatTextFill size={17} />
                      &nbsp;&nbsp;Topics
                    </NavLink>
                  </Col>

                  <Col className="text-center">
                    <NavLink
                      to="/blocked"
                      className={(navData) =>
                        navData.isActive
                          ? "side-nav-profile-active textUnderlineNone side-nav-profile color-black mb-0 fontSize16 hover padding-15 footnote"
                          : "textUnderlineNone side-nav-profile color-black mb-0 fontSize16 hover padding-15 footnote"
                      }
                      onClick={scroll}
                    >
                      <IoBanSharp size={17} />
                      &nbsp;&nbsp;Blocked
                    </NavLink>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>

        <Modal
          show={logout}
          onHide={handleClose}
          backdropClassName="backdrop-login"
          centered
          className={"modal_bg_color"}
        >
          <Modal.Body>
            <Container className="p-1">
              <Row>
                <Col lg={12} className="mb-3">
                  <h4 className="fontBold">
                    Log out of {process.env.REACT_APP_YOUR_VARIABLE_NAME_HERE}?
                  </h4>
                  <label className="footnote">Log back in at any time.</label>
                </Col>

                <Col lg={12} xs={12} className="mb-3">
                  <Link to="/logout">
                    <label className="login-button mb-3 text-center rounded-pill">
                      Log out
                    </label>
                  </Link>

                  <label
                    className="join-now-button text-center rounded-pill"
                    onClick={handleClose}
                  >
                    Cancel
                  </label>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
      </div>
      <span id="footer">
        <Footer drawerState={isOpen} />
      </span>
    </>
  );
}
