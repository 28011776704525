import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Container, Row, Col, Button, Stack } from "react-bootstrap";
import SideNav from "./navigation/SideNavHome";
import RightNav from "./navigation/SidNavHomeRight";
import Header from "./navigation/HeaderHome";
import LikedPosts from "./features/Likes";
import { useParams } from "react-router-dom";
import axios from "axios";
import BeatLoader from "react-spinners/BeatLoader";
import { IoIosArrowRoundBack } from "react-icons/io";
import TopNav from "./navigation/TopNavProfile";
import HeaderToken from "./HeaderToken";
import { ClipLoader } from "react-spinners";

const headers = {
  Authorization: `${HeaderToken.Authorization}`,
  Accept: `${HeaderToken.Accept}`,
};
const LikesProfile = () => {
  // let history = useHistory();
  const baseURL = process.env.REACT_APP_URL;
  const { username } = useParams();
  const [userID, setUserID] = useState(0);

  useEffect(() => {
    // axios
    //   .get(baseURL + "/userid/get/" + username, { headers })
    //   .then((res) => {
    //     setUserID(res.data.userID);
    //     // alert(res.data.userID);
    //   })
    //   .catch((error) => {});
  }, []);

  const userIDQuery = useQuery({
    queryKey: ["userID", window.location.href],
    queryFn: async () => {
      const response = await axios.get(`${baseURL}/userid/get/${username}`, {
        headers,
      });
      const data = response.data;
      return data;
    },
  });

  return (
    <div className="body-login">
      <Header />
      <Container>
        <Row className="forced-margin justify-content-center">
          {/* <SideNav /> */}
          <RightNav />

          <Col lg={6} className={"p-0"}>
            <TopNav />
            <Stack>
              {!userIDQuery.isLoading ? (
                <div className="login-elements-containe border-hidde p-0 border-top-hidden">
                  <LikedPosts
                    userID={userIDQuery.data.userID}
                    username={username}
                  />
                </div>
              ) : (
                <div className="text-center mb-5 mt-5">
                  <ClipLoader size={25} color={"#333333"} />
                </div>
              )}
            </Stack>
          </Col>
          {/* <RightNav /> */}
        </Row>
      </Container>
    </div>
  );
};

export default LikesProfile;
