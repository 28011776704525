import React, { useState, useEffect, useCallback } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { Col, Row, Stack, Button } from "react-bootstrap";
import {
  useParams,
  useHistory,
  Link,
  NavLink,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import axios from "axios";
import TopicisToFollow from "../features/TopicsToFollow";
import Conversations from "../features/Conversations";
import { IoSendOutline, IoCloseOutline } from "react-icons/io5";
import { BiSend } from "react-icons/bi";
import { BsEmojiSmile } from "react-icons/bs";
import { HiOutlineDocumentText } from "react-icons/hi";
import { IoMdImages } from "react-icons/io";
import ReactTooltip from "react-tooltip";
import TopBarProgress from "react-topbar-progress-indicator";
import Emojies from "../../components/features/Emojies";
import HeaderToken from "../HeaderToken";
import { ClipLoader } from "react-spinners";

const headers = {
  Authorization: `${HeaderToken.Authorization}`,
  Accept: `${HeaderToken.Accept}`,
};

export default function Chats(props) {
  const baseURL = process.env.REACT_APP_URL;
  let url = "";

  const location = useLocation();
  const [queryParameters] = useSearchParams();
  let text = queryParameters.get("message");

  if (!text) {
    text = "";
  }

  const user_id = localStorage.getItem("user_id");
  const [name, setName] = useState("");
  const [ImgPath, setImgPath] = useState("");
  const [message, setMessage] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [images, setImages] = useState(null);
  const [preview, setPreview] = useState([]);
  const [previewDoc, setPreviewDoc] = useState([]);
  const [previewDocURL, setPreviewDocURL] = useState(null);
  const [documents, setDocuments] = useState(null);
  const [progressBar, setProgressBar] = useState(true);
  const [errorMsg, setErrorMsg] = useState(false);
  const [docTitle, setDocTitle] = useState({});
  const [hideEmoji, setHideEmoji] = useState(false);
  const [sendingIndicator, setSendingIndicator] = useState(false);

  const userDetailsQuery = useQuery({
    queryKey: ["userDetails"],
    queryFn: async () => {
      const response = await axios.get(
        `${baseURL}/chat/get/user/details/${props.username}`,
        { headers }
      );
      const data = response.data;
      return data;
    },
  });

  const { refetch } = userDetailsQuery;

  useEffect(() => {
    // axios
    //   .get(baseURL + "/chat/get/user/details/" + props.username)
    //   .then((res) => {
    //     setName(res.data.name);
    //     setImgPath(res.data.path);
    //   });

    setPreview([]);
    setImages(null);
    setPreviewDoc([]);
    setDocuments(null);
    setMessage(text);
    refetch();
  }, [props.username, window.location.href]);

  function textMessage(e) {
    let value = e.target.value;
    setMessage(value);
    setErrorMsg(false);
  }

  function emojies() {
    if (hideEmoji == true) {
      setHideEmoji(false);
    } else {
      setHideEmoji(true);
    }
  }

  function sendChat(event) {
    event.preventDefault();
    setSendingIndicator(true);
    const data = new FormData();
    if (images != null) {
      for (let i = 0; i < images.length; i++) {
        data.append("images[]", images[i]);
      }
    }

    if (documents != null) {
      for (let i = 0; i < documents.length; i++) {
        data.append("documents[]", documents[i]);
      }
    }

    if (docTitle.length >= 0) {
      for (let i = 0; i < docTitle.length; i++) {
        data.append("docTile", docTitle);
      }
    }

    data.append("chat_mate_username", props.username);
    data.append("user_id", user_id);
    data.append("message", message);
    axios({
      method: "POST",
      url: baseURL + "/create/conversation",
      data: data,
      headers: {
        Authorization: HeaderToken.Authorization,
        Accept: HeaderToken.Accept,
      },
    })
      .then((res) => {
        setSendingIndicator(false);
        setHideEmoji(false);
        setPreview([]);
        setImages(null);

        setPreviewDoc([]);
        setDocuments(null);

        setDocTitle([]);

        setMessage("");
        setPageCount(pageCount + 1);
        refetch();
      })
      .catch((error) => {});
  }

  function imageUpload(e) {
    setImages(e.target.files);

    setPreviewDoc([]);
    setDocuments(null);

    let images = [];
    for (let i = 0; i < e.target.files.length; i++) {
      images.push(URL.createObjectURL(e.target.files[i]));
    }
    setPreview(images);
  }

  function documentUpload(e) {
    if (e.target.files.length > 4) {
      setErrorMsg(true);
      setPreviewDoc([]);
      setDocuments(null);
    } else {
      setErrorMsg(false);
      setPreview([]);
      setImages(null);
      setDocuments(e.target.files);
      let documents = [];
      let documentName = [];
      for (let i = 0; i < e.target.files.length; i++) {
        documents.push(URL.createObjectURL(e.target.files[i]));
        documentName.push(e.target.files[i].name);
      }

      setPreviewDoc(documentName);
      setPreviewDocURL(documents);
    }
  }

  function removeMedia() {
    setPreview([]);
    setImages(null);

    setPreviewDoc([]);
    setDocuments(null);
  }

  function tags(value, index) {
    setDocTitle((docTitle) => Object.assign([], docTitle, { [index]: value }));
  }

  const onEmojiClick = (emojiData) => {
    setMessage((prevInput) => prevInput + emojiData.emoji);
    setHideEmoji(false);
  };

  return (
    <Col lg={5} className="">
      <div className={"border-hidden mb-0 pt-0 height-81 relative"}>
        <Row className={" p-3 align-items-center h-100"}>
          <Col lg={12} className="text-center mb-0">
            <Row className={"justify-content-center align-items-center"}>
              <Col lg={1}>
                {userDetailsQuery.isSuccess ? (
                  <div
                    className={"profile-pic"}
                    style={{
                      backgroundImage:
                        "url(" +
                        process.env.REACT_APP_URL.replace("api", "") +
                        userDetailsQuery.data.imgPath +
                        ")",
                    }}
                  />
                ) : (
                  ""
                )}
              </Col>
              <Col
                lg={11}
                className={
                  "text-left footnote pl-2 line-height-20 paddingLeft30 "
                }
              >
                <span className={"color-black fontBold"}>
                  {userDetailsQuery.isSuccess ? (
                    <div className="line-height-20">
                      {userDetailsQuery.data.name}
                      <br />
                      <span className="text-normal footnote">
                        @{props.username}
                      </span>
                    </div>
                  ) : (
                    <div className="text-center mb-5 mt-5">
                      <ClipLoader size={25} color={"#333333"} />
                    </div>
                  )}
                </span>
              </Col>
            </Row>
          </Col>

          <Col lg={12} className="height-60">
            <Conversations
              chat_mate_username={props.username}
              pageCount={pageCount}
            />
          </Col>

          <Col lg={12}>
            <div className={"border-rounded-div"}>
              <form onSubmit={sendChat}>
                {/* <Row>
                  <Col lg={12} style={{ border: "thin solid red" }}>
                    {progressBar && <TopBarProgress color={"red"} />}
                  </Col>
                </Row> */}
                <Row
                  className={
                    errorMsg == false && previewDoc.length > 0
                      ? "pt-0"
                      : "d-none"
                  }
                >
                  <Col lg={12}>
                    <label className={"w-100 color-red fontSize14 relative"}>
                      <label
                        className={"remove-doc-message cursorPointer"}
                        data-tip={"Remove"}
                        data-place={"top"}
                        data-effect={"solid"}
                        onClick={removeMedia}
                      >
                        <IoCloseOutline size={16} />
                        <ReactTooltip
                          arrowColor={"transparent"}
                          className="p-1 fontSize-12"
                        />
                      </label>
                    </label>
                  </Col>
                </Row>
                <Row className={errorMsg == true ? "pt-2" : "d-none"}>
                  <Col lg={12}>
                    <label
                      className={"fontSize14 alert alert-danger p-1 mb-0 mt-1"}
                    >
                      <strong>NB:</strong> You can only upload upto 4 documents
                      per send.
                    </label>
                  </Col>
                </Row>

                <Row
                  className={hideEmoji ? "" : "d-none"}
                  style={{ position: "absolute", bottom: 270, left: 270 }}
                >
                  <Col lg={12} className={"mt-3"}>
                    <Emojies handleClick={onEmojiClick} action={"post"} />
                  </Col>
                </Row>
                <Row
                  className={
                    previewDoc.length == 0
                      ? "d-none"
                      : errorMsg == true
                      ? "pt-1 pb-0 mb-0"
                      : "pt-3 pb-0 mb-0"
                  }
                >
                  {previewDoc.map((document, index) => {
                    return (
                      <Col lg={12} className={"mb-0"} key={index}>
                        <a
                          href={previewDocURL[index]}
                          target="_blank"
                          className={
                            "cursorPointer textUnderlineNone fontBold fontSize14"
                          }
                        >
                          <label
                            className={
                              "document-link-msg mb-2 footnote cursorPointer fontSize14"
                            }
                          >
                            {document.length > 20
                              ? document.slice(0, 20) + "..."
                              : document}
                          </label>
                        </a>
                        &nbsp;{" "}
                        <span className={"color-light-grey text-normal"}>
                          -
                        </span>
                        <label>
                          <input
                            type="text"
                            name="tags"
                            placeholder={"Add custom title"}
                            className={"tag-inputs d-non"}
                            value={docTitle[index]}
                            onChange={(e) => tags(e.target.value, index)}
                          />
                        </label>
                      </Col>
                    );
                  })}
                </Row>
                <Row className={preview.length == 0 ? "d-none" : "pt-3 pb-0"}>
                  {preview.map((img, index) => {
                    return (
                      <Col className={"col-6 p-0 relative"} key={index}>
                        <label
                          className={"remove-media-message cursorPointer"}
                          data-tip={"Remove"}
                          data-place={"top"}
                          data-effect={"solid"}
                          onClick={removeMedia}
                        >
                          <IoCloseOutline size={16} />
                          <ReactTooltip
                            arrowColor={"transparent"}
                            className="p-1 fontSize-12"
                          />
                        </label>
                        <div
                          className={"mb-0 border-radius-5"}
                          style={{
                            backgroundImage: "url(" + img + ")",
                            backgroundSize: "cover",
                            height: 200,
                            width: "100%",
                            border: "thin solid #ececec",
                            backgroundPosition: "center",
                          }}
                        />
                      </Col>
                    );
                  })}
                </Row>
                <Row className="align-items-center">
                  <Col lg={3} className="p-0">
                    <label
                      className={"share-icons-chats"}
                      data-tip={"Documents"}
                      data-place={"top"}
                      data-effect={"solid"}
                      htmlFor={"document"}
                    >
                      <HiOutlineDocumentText size={17} />
                      <ReactTooltip
                        arrowColor={"transparent"}
                        className="p-1 fontSize-12"
                      />
                    </label>
                    <label
                      className={"share-icons-chats"}
                      data-tip={"Media"}
                      data-place={"top"}
                      data-effect={"solid"}
                      htmlFor={"media"}
                    >
                      <IoMdImages size={17} />
                      <ReactTooltip arrowColor={"transparent"} />
                    </label>
                    <label
                      className={"share-icons-chats"}
                      data-tip={"Emojies"}
                      data-place={"top"}
                      data-effect={"solid"}
                      onClick={emojies}
                    >
                      <BsEmojiSmile size={16} />
                      <ReactTooltip
                        arrowColor={"transparent"}
                        className="p-1 fontSize-12"
                      />
                    </label>
                  </Col>
                  <Col lg={8}>
                    <input
                      type="text"
                      className={"no-shadow border-hidden"}
                      placeholder="Start a new message"
                      value={message}
                      onChange={textMessage}
                      disabled={sendingIndicator ? true : false}
                    />
                  </Col>
                  <Col lg={1} className="text-left p-0">
                    <label className={sendingIndicator ? "d-none" : ""}>
                      <Button
                        data-tip={"Send"}
                        data-place={"top"}
                        data-effect={"solid"}
                        type="submit"
                        className={
                          (previewDoc.length == 0 &&
                            preview.length == 0 &&
                            (message
                              ? message.trim().length
                              : message.length) == 0) ||
                          errorMsg == true
                            ? "color-light-grey-msg border-hidden no-shadow bg-color-transparent"
                            : "color-blue border-hidden no-shadow bg-color-transparent"
                        }
                        disabled={
                          previewDoc.length == 0 &&
                          preview.length == 0 &&
                          (message ? message.trim().length : message.length) ==
                            0
                            ? true
                            : false
                        }
                      >
                        <BiSend size={20} />
                        <ReactTooltip
                          arrowColor={"transparent"}
                          className="p-1 fontSize-12"
                        />
                      </Button>
                    </label>
                    <label
                      className={
                        sendingIndicator ? "w-100 text-right mt-2" : "d-none"
                      }
                    >
                      <ClipLoader size={18} color="#333333" />
                    </label>
                  </Col>
                </Row>
                <input
                  type="file"
                  name="media"
                  accept="image/jpg, image/jpeg, image/png, image/WebP, image/webp, image/gif"
                  id="media"
                  className={"d-none"}
                  onChange={imageUpload}
                />
                <input
                  type="file"
                  name="document"
                  accept="application/pdf, .docx, .doc, .csv, .xlsx, .pptx"
                  multiple
                  id="document"
                  className="d-none"
                  onChange={documentUpload}
                />
              </form>
            </div>
          </Col>
        </Row>
      </div>
    </Col>
  );
}

TopBarProgress.config({
  barColors: {
    0: "rgb(29, 155, 240)",
    "1.0": "rgb(29, 155, 240)",
  },
  shadowBlur: 0,
});
