import React, { useState, useEffect } from "react";
import { Col, Container, Row, Stack, Modal, Alert } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import Header from "./navigation/HeaderPassword";
import axios from "axios";
import { ClipLoader, FadeLoader } from "react-spinners";
import { Helmet } from "react-helmet";
export default function Password() {
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loader, setLoader] = useState(false);

  const baseURL = process.env.REACT_APP_URL;

  function passwordForm(e) {
    e.preventDefault();
    const data = new FormData();
    data.append("email", email);

    if (email.trim() == "") {
      setError("Enter email address or username");
      return;
    }

    setLoader(true);
    axios({
      method: "POST",
      url: `${baseURL}/forgot/password`,
      data: data,
    })
      .then((response) => {
        setSuccess(`Temporary password sent to ${response.data.email}`);
      })
      .catch((error) => {
        setError(error.response.data.message);
        setLoader(false);
        // console.log(error.response.data.message);
      });
  }

  return (
    <div className="body-login">
      <Helmet>
        <title>
          Forgot password | {process.env.REACT_APP_YOUR_VARIABLE_NAME_HERE}
        </title>
      </Helmet>
      <Container className="login password-container ">
        <Row className="h-100 align-items-center justify-content-center">
          <Col x={12} lg={6}>
            <Row className={"justify-content-center align-items-center"}>
              <Col xs={12} lg={10}>
                <div className={success ? "d-none" : "text-left w-100 mb-3"}>
                  <h3 className="fontBold">
                    Find your {process.env.REACT_APP_YOUR_VARIABLE_NAME_HERE}{" "}
                    account.
                    <br />
                    <label className="text-normal footnote">
                      Enter email address or username associated with <br />
                      your account.
                    </label>
                  </h3>
                </div>
                <Col lg={12} className={success ? "" : "d-none"}>
                  <Alert variant="secondary">{success}</Alert>

                  <Link to="/login">
                    <label className="login-button text-center no-shadow rounded-pill">
                      Log in
                    </label>
                  </Link>
                </Col>
                <Stack gap={4} className={success ? "d-none" : ""}>
                  <div className="login-elements-container border-hidden p-0">
                    <Stack gap={3} className={loader == true ? "d-none" : ""}>
                      <form onSubmit={passwordForm}>
                        <Alert
                          variant="danger"
                          className={error ? "" : "d-none"}
                        >
                          {error}
                        </Alert>
                        <input
                          type="text"
                          placeholder="Email address or username"
                          className="bg-color-white mb-3"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <Button
                          type="submit"
                          className="login-button no-shadow"
                        >
                          Search
                        </Button>
                      </form>
                      <div className="h-divider" />
                      <Link to="/">
                        <label className="join-now-button text-center no-shadow">
                          Cancel
                        </label>
                      </Link>
                    </Stack>
                    <div
                      className={
                        !loader || success ? "d-none" : "text-center fontSize14"
                      }
                    >
                      <ClipLoader size={25} color="rgb(51, 153, 204) " />
                    </div>
                  </div>
                  {/* <div className="text-center">
                    Discover, share and empower.
                  </div> */}
                </Stack>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
