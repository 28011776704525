import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Container, Row, Col, Button, Stack } from "react-bootstrap";
import SideNav from "./navigation/SideNavHome";
import RightNav from "./navigation/SidNavHomeRight";
import Header from "./navigation/HeaderHome";
import Follower from "./features/Followers";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import BeatLoader from "react-spinners/BeatLoader";
import { IoIosArrowRoundBack } from "react-icons/io";
import HeaderToken from "./HeaderToken";
import { ClipLoader } from "react-spinners";
import Footer from "./navigation/Footer";
import { Helmet } from "react-helmet";

const headers = {
  Authorization: `${HeaderToken.Authorization}`,
  Accept: `${HeaderToken.Accept}`,
};
function Followers(props) {
  const location = useLocation();
  const info = location.state;
  let navigate = useNavigate();
  const baseURL = process.env.REACT_APP_URL;
  const { username } = useParams();
  const [userID, setUserID] = useState(0);
  const current_username = localStorage.getItem("username");

  useEffect(() => {}, []);

  const userIDQuery = useQuery({
    queryKey: ["userID", window.location.href],
    queryFn: async () => {
      const response = await axios.get(`${baseURL}/userid/get/${username}`, {
        headers,
      });
      const data = response.data;
      return data;
    },
  });

  return (
    <div className="body-login">
      <Helmet>
        <title>
          People following{" "}
          {userIDQuery.isLoading ? "" : userIDQuery.data.fullname} (@{username})
          | {process.env.REACT_APP_YOUR_VARIABLE_NAME_HERE}
        </title>
      </Helmet>
      <Header />
      <Container>
        <Row className="forced-margin justify-content-center">
          {/* <SideNav /> */}
          <RightNav />

          <Col lg={6} className="p-0">
            <Stack>
              <div className={"login-elements-container border-hidde mb-5 p-0"}>
                <Row className={"align-items-center pb-0"}>
                  <Col lg={12} className={"pb-0"}>
                    <div className="p-3 pb-0 mb-0 border-botto">
                      <Row className={"align-items-center"}>
                        <Col lg={1} sm={1} xs={2}>
                          <label
                            className="back-arrow-post mb-0"
                            onClick={() => navigate(-1)}
                          >
                            <IoIosArrowRoundBack size={30} />
                          </label>
                        </Col>
                        <Col lg={11} sm={11} xs={10} className={""}>
                          <Stack gap={0} className={"line-height-20"}>
                            <span className="text-bold fontSize-20">
                              Followers
                              <span
                                className={
                                  current_username == `@${username}`
                                    ? "d-none"
                                    : "text-normal footnote"
                                }
                              >
                                <br />@{username}
                              </span>
                            </span>
                          </Stack>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>

                {!userIDQuery.isLoading ? (
                  <Follower
                    userID={userIDQuery.data.userID}
                    username={username}
                  />
                ) : (
                  <div className="text-center mb-5 mt-5">
                    <ClipLoader size={25} color={"#333333"} />
                  </div>
                )}
              </div>
            </Stack>
          </Col>
          {/* <RightNav /> */}
        </Row>
      </Container>
      <Footer
        postSentFeedback={info ? info.message : null}
        postSent={info ? info.success : null}
        postType={"compose"}
      />
    </div>
  );
}

export default Followers;
