import Button from "@restart/ui/esm/Button";
import React from "react";
import { Col, Container, Row, Stack } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
function scroll() {
  window.scrollTo(0, 0);
}
export default function Header() {
  return (
    <div>
      <div className="p-3 bg-color-white header d-none d-md-block d-lg-block d-xl-block d-xxl-block">
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <Stack>
                <Link to="/" className="black-color underline-none w-100">
                  <Row className={"align-items-center"}>
                    <Col lg={12}>
                      <div
                        className={"profile-pic-logo"}
                        style={{
                          backgroundImage:
                            "url(" +
                            process.env.REACT_APP_URL.replace("api", "") +
                            "silence.png" +
                            ")",
                        }}
                      />
                    </Col>
                  </Row>
                </Link>
              </Stack>
            </Col>
            <Col lg={6}>
              <Stack>
                <Row className="justify-content-end">
                  <Col lg={2}>
                    <Stack gap={4}>
                      <Link to="/login">
                        <label className="native-button-blue text-center fontBold w-100">
                          Log in
                        </label>
                      </Link>
                    </Stack>
                  </Col>
                  <Col lg={3}>
                    <Stack gap={4}>
                      <Link to="/login?q=signup-clicked">
                        <label className="native-button-dark-grey text-center fontBold w-100">
                          Sign up
                        </label>
                      </Link>
                    </Stack>
                  </Col>
                </Row>
              </Stack>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="forced-margin-entry">
        <Row className="header_mobile logo-string-mobile d-lg-none d-md-none d-xl-none d-xxl-none">
          <Col xs={12} sm={12} className="text-center">
            <Link to={"/"} className={"textUnderlineNone"} onClick={scroll}>
              <h3 className="text-bold color-blue">
                {process.env.REACT_APP_YOUR_VARIABLE_NAME_HERE}
              </h3>
            </Link>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg={6} className="p-0">
            <Row className={"mb-3 justify-content-center"}>
              <Col lg={4} sm={4} xs={4}>
                <label className="w-100">
                  <NavLink
                    to={"/"}
                    className={(navData) =>
                      navData.isActive
                        ? "mobile-tabs-icons-active color-grey textUnderlineNone mobile-tabs-icons fontBold cursorPointer"
                        : "color-grey textUnderlineNone mobile-tabs-icons fontBold cursorPointer"
                    }
                  >
                    <label className="text-center fontBold cursorPointer p-2 w-100">
                      Posts
                    </label>
                  </NavLink>
                </label>
              </Col>
              <Col lg={4} sm={4} xs={4}>
                <label className="w-100">
                  <NavLink
                    to={"/x/documents"}
                    className={(navData) =>
                      navData.isActive
                        ? "mobile-tabs-icons-active color-grey textUnderlineNone mobile-tabs-icons fontBold cursorPointer"
                        : "color-grey textUnderlineNone mobile-tabs-icons fontBold cursorPointer"
                    }
                  >
                    <label className="text-center fontBold cursorPointer p-2 w-100">
                      Documents
                    </label>
                  </NavLink>
                </label>
              </Col>
              <Col lg={4} sm={4} xs={4}>
                <label className="w-100">
                  <NavLink
                    to={"/x/media"}
                    className={(navData) =>
                      navData.isActive
                        ? "mobile-tabs-icons-active color-grey textUnderlineNone mobile-tabs-icons fontBold cursorPointer"
                        : "color-grey textUnderlineNone mobile-tabs-icons fontBold cursorPointer"
                    }
                  >
                    <label className="text-center fontBold cursorPointer p-2 w-100">
                      Media
                    </label>
                  </NavLink>
                </label>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
