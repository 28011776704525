import React, { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { Container, Row, Col, Button, Stack } from "react-bootstrap";
import SideNav from "./navigation/SideNavHome";
import RightNav from "./navigation/SidNavHomeRight";
import Header from "./navigation/HeaderHome";
import MyPolls from "./features/MyPolls";
const Polls = () => {
  const user_id = localStorage.getItem("user_id");
  return (
    <div className="body-login">
      <Header />
      <Container>
        <Row className="forced-margin">
          <SideNav />

          <Col lg={6}>
            <Stack>
              <div
                className={"login-elements-container border-hidden mb-5 p-0"}
              >
                <MyPolls userID={user_id} />
              </div>
            </Stack>
          </Col>
          <RightNav />
        </Row>
      </Container>
    </div>
  );
};

export default Polls;
