import React from "react";
import { Col, Row } from "react-bootstrap";

const PreviewMedia = (props) => {
  return (
    <Row className={props.preview.length > 0 ? "mb-2" : ""}>
      <Col
        lg={12}
        className={props.preview.length == 0 ? "d-none" : " mb-0 mt-0"}
      >
        {props.preview.length > 0 ? (
          <label
            className="fontSize14 cursorPointer unfollow-btn mb-1"
            onClick={props.clearAttachment}
          >
            Remove media
          </label>
        ) : (
          ""
        )}
      </Col>
      {props.preview.map((img, index) => {
        return (
          <Col
            className={
              props.preview.length == 1
                ? "col-12"
                : props.preview.length == 2
                ? "col-6 paddingSides" + index
                : props.preview.length == 3
                ? "col-4 threePaddingSides" + index
                : props.preview.length == 4
                ? "col-6 fourPaddingSides" + index
                : "col-6"
            }
            key={index}
          >
            <div
              className={
                props.preview.length == 2
                  ? "mb-0 border-radius" + index
                  : props.preview.length == 3
                  ? "borderRadiusImg" + index
                  : props.preview.length == 4
                  ? "borderRadiusImgFour" + index
                  : "mb-0 border-radius-5"
              }
              style={{
                backgroundImage: "url(" + img + ")",
                backgroundSize: "cover",
                height: 150,
                width: "100%",
                border: "thin solid #ececec",
                backgroundPosition: "center",
              }}
            />
          </Col>
        );
      })}
    </Row>
  );
};

export default PreviewMedia;
