import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { BeatLoader, ClipLoader, PuffLoader } from "react-spinners";
import HeaderToken from "./HeaderToken";
import {
  Col,
  Container,
  Row,
  Stack,
  Modal,
  Button,
  Dropdown,
} from "react-bootstrap";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import {
  IoMdImages,
  IoIosMic,
  IoIosHeartEmpty,
  IoIosHeart,
} from "react-icons/io";
import {
  IoBookOutline,
  IoPencil,
  IoCheckmarkCircleOutline,
  IoChatbox,
  IoChatboxOutline,
  IoShareOutline,
  IoEllipse,
  IoBanOutline,
  IoEllipsisHorizontal,
  IoPersonAddOutline,
  IoPersonRemoveOutline,
  IoPersonRemove,
  IoGridOutline,
  IoThumbsUp,
} from "react-icons/io5";
import {
  BsEmojiSmile,
  BsChatRightDots,
  BsFillBookmarkFill,
  BsBookmarkPlus,
  BsBookmark,
  BsXLg,
  BsChevronDoubleUp,
  BsChevronDoubleDown,
  BsChevronDoubleLeft,
  BsChevronDoubleRight,
  BsPencil,
  BsHandThumbsUp,
  BsHandThumbsUpFill,
  BsChatText,
  BsChatTextFill,
  BsBookmarks,
  BsBookmarksFill,
  BsBookmarkCheckFill,
} from "react-icons/bs";
import {
  FaPoll,
  FaRegFileExcel,
  FaRegFilePowerpoint,
  FaRegFilePdf,
  FaRegFileWord,
  FaFileCsv,
  FaFilePdf,
} from "react-icons/fa";
import {
  BiDotsHorizontalRounded,
  BiUserMinus,
  BiUserPlus,
  BiFlag,
  BiSad,
  BiShare,
} from "react-icons/bi";
import {
  HiOutlineDocumentText,
  HiOutlineTrash,
  HiCheck,
  HiOutlineRefresh,
} from "react-icons/hi";
import ReactTooltip from "react-tooltip";
// import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import mentionStyle from "./css/mentionStyle";
import tagStyle from "./css/tagStyle";
import axios from "axios";
import { Fragment } from "react";

const Posts = (props) => {
  const headers = {
    Authorization:
      props.postType == "entry"
        ? "Bearer 14|qMBbAFf6xefV5ukiQaCK9hC7DfcJ8H6KlFSnG36I"
        : `${HeaderToken.Authorization}`,
    Accept: `${HeaderToken.Accept}`,
  };
  // const [markLike, setMarkLike] = useState({});
  const location = useLocation();
  // const navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  let topicClicked = queryParameters.get("clicked");
  let from_post_id = queryParameters.get("from_post_id");
  let postsOnTopicsClicked = "home";
  if (props.postsOnTopicsClicked) {
    postsOnTopicsClicked = "topic";
  } else {
    if (props.homePosts == false) {
      postsOnTopicsClicked = "postsOnTopicsYouFollow";
    }
  }

  const baseURL = process.env.REACT_APP_URL;
  let getPostID = 0;
  if (props.postID) {
    getPostID = props.postID;
  }
  let profileUserID = 0;
  if (props.profileUserID) {
    profileUserID = props.profileUserID;
  }
  // alert(props.profileUserID);
  let url = "";
  if (props.postType == "entry") {
    const endpoint = props.endpoint;
    url = baseURL + "/entry/posts/entry/" + endpoint;
  } else {
    url =
      baseURL +
      "/posts/get/" +
      props.user_id +
      "/" +
      profileUserID +
      "/" +
      props.pagination +
      "/" +
      getPostID +
      "/" +
      props.postType +
      "?topicClicked=" +
      topicClicked +
      "&from_post_id=" +
      from_post_id;
  }

  const postQuery = useQuery({
    queryKey: ["posts", window.location.href],
    queryFn: async () => {
      const response = await axios.get(url, { headers });
      const data = response.data;
      return data;
    },
    staleTime: 15 * (60 * 1000),
    // cacheTime: 15 * (60 * 1000),
  });

  const { data, status, refetch } = postQuery;

  // console.log(postQuery.isFetching + " fetching");
  // console.log(postQuery.isLoading + " loading");

  useEffect(() => {
    // refetch();
  }, [props.homePosts, window.location.href]);
  return (
    <Fragment>
      {status == "loading" ? (
        <Col lg={12} className="mt-3 mb-3 text-center">
          <ClipLoader size={25} color={"#333333"} />
        </Col>
      ) : (
        <Col lg={12} className="mt-0 ">
          {data.length == 0 ? (
            <div
              className={
                "text-center login-elements-containe border-hidde no-shadow p-0"
              }
            >
              <h4 className="pt-4 pb-3">
                <span className="text-bold">Oops! No posts found.</span>
              </h4>
              {postsOnTopicsClicked == "postsOnTopicsYouFollow" ? (
                ""
              ) : (
                <div className="mb-4">
                  <br />
                  <Link
                    to={
                      props.postType == "entry"
                        ? "?q=_l_o_s"
                        : topicClicked
                        ? "/topics"
                        : "/academics"
                    }
                    className="native-button-dark-grey  textUnderlineNone fontBold"
                  >
                    {topicClicked
                      ? "Explore topics and subjects"
                      : "Who can I follow..?"}
                  </Link>
                </div>
              )}
              <label
                className={
                  props.postType != "postsOnTopicsYouFollow"
                    ? "d-none"
                    : "color-grey"
                }
              >
                {postsOnTopicsClicked == "topic"
                  ? `This topic has no posts at the moment.`
                  : "Posts related to topics you follow will appear here."}

                <br />
                <div
                  className={
                    props.postType != "postsOnTopicsYouFollow"
                      ? "d-none"
                      : "mt-4 mb-4"
                  }
                >
                  <Link to={"/topics"} className={" textUnderlineNone"}>
                    <label
                      className={
                        "native-button-dark-grey fontBold rounded-pill paddingLeft12 paddingRightt12"
                      }
                    >
                      {postsOnTopicsClicked == "topic"
                        ? "Explore and follow more topics."
                        : "Follow topics that interest you"}
                    </label>
                  </Link>
                </div>
              </label>
            </div>
          ) : (
            <>
              {data.map((item, index) => (
                <div
                  className={
                    item.is_sent == 1
                      ? "d-none"
                      : "w-100 p-3 timeline-content login-elements-container  mb-0 pb-0"
                  }
                  key={index}
                  // onClick={() =>
                  //   props.replyPage(
                  //     item.quotedData.quoted_post,
                  //     item.user.first_name + " " + item.user.last_name,
                  //     item.user.username,
                  //     item.posts.id
                  //   )
                  // }
                >
                  <Row className={item.mute == 0 ? "d-none" : ""}>
                    <Col lg={12} className={"fontSize14 mb-1"}>
                      <label className="pt-1">Post by</label>{" "}
                      <Link
                        to={
                          props.postType == "entry"
                            ? "?q=_l_o_s"
                            : "/" + item.user.username
                        }
                        className="color-black textUnderlineNone"
                      >
                        <span className="text-bold">
                          {item.user.first_name + " " + item.user.last_name}
                        </span>{" "}
                        <label className="footnote">
                          {" "}
                          @{item.user.username}
                        </label>
                      </Link>
                      <span
                        className="cursorPointer float-right unfollow-btn"
                        onClick={() => props.follow(item.user.id)}
                      >
                        Unfollow
                      </span>
                    </Col>
                    <Col lg={12} className="pb-3">
                      <div
                        className={
                          "paddingRight10 paddingLeft10 greyBG paddingTopDown w-100 fontSize14"
                        }
                      >
                        <label className="w-100 color-light-grey">
                          <label className="pt-1">
                            Let's improve your feed. {props.concernText}
                          </label>{" "}
                          <span
                            className="cursorPointer float-right undo-btn"
                            onClick={() => props.mutePost(item.posts.id)}
                          >
                            Undo
                          </span>
                          <span
                            className={
                              props.showSend == false
                                ? "d-none"
                                : "cursorPointer float-right send-btn"
                            }
                            onClick={() => props.sendComplaint(item.posts.id)}
                          >
                            Send
                          </span>
                        </label>
                      </div>

                      <div
                        className={
                          "fontSize14 paddingRight10 paddingLeft10 paddingTopDown options-not-relevant w-100 options-not-relevant-border color-light-grey"
                        }
                        onClick={() =>
                          props.fewerFeeds(
                            item.posts.id,
                            "This post isn't relevant",
                            1
                          )
                        }
                      >
                        <span className="text-bold">
                          This post isn't relevant
                        </span>
                        <label
                          className={
                            props.concernClickedOne == false
                              ? "d-none"
                              : "float-right"
                          }
                        >
                          <HiCheck size={20} className="check-green-color" />
                        </label>
                      </div>
                      <div
                        className={
                          "fontSize14 paddingRight10 paddingLeft10 paddingTopDown options-not-relevant w-100 color-light-grey"
                        }
                        onClick={() =>
                          props.fewerFeeds(
                            item.posts.id,
                            "This post contains nudity",
                            2
                          )
                        }
                      >
                        <span className="text-bold">
                          This post contains nudity
                        </span>
                        <label
                          className={
                            props.concernClickedTwo == false
                              ? "d-none"
                              : "float-right"
                          }
                        >
                          <HiCheck size={20} className="check-green-color" />
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <Row
                    className={
                      item.mute == 1 ? "d-none" : "justify-content-center"
                    }
                  >
                    <Row>
                      <Row className="align-items-center">
                        <Col
                          lg={12}
                          className={
                            item.posts.author_id != null
                              ? "mb-1 marginTop-15"
                              : "d-none"
                          }
                        >
                          <label className="footnote fontSize14 mt-2 w-100">
                            <Row
                              className={
                                "align-items-center justify-content-center"
                              }
                            >
                              <Col
                                lg={1}
                                xs={1}
                                sm={1}
                                className="text-right p-0 color-green"
                              >
                                <BiShare size={20} />
                              </Col>
                              <Col
                                lg={11}
                                sm={11}
                                xs={11}
                                className="pt-0 pb-0"
                              >
                                <Link
                                  to={
                                    props.postType == "entry"
                                      ? "?q=_l_o_s"
                                      : "../" +
                                        item.user.username.replace("@", "")
                                  }
                                  className={
                                    "textUnderlineNone underlineHover color-black fontBold"
                                  }
                                >
                                  {item.authorUsername ? (
                                    <span
                                      className="fontMedium"
                                      style={{ paddingLeft: 1.5 }}
                                    >{`${item.user.first_name} ${item.user.last_name}`}</span>
                                  ) : (
                                    <></>
                                  )}
                                </Link>{" "}
                                Reposted
                              </Col>
                            </Row>
                          </label>
                        </Col>
                      </Row>

                      <Col lg={1} xs={1} sm={1} className="p-0">
                        <NavLink
                          to={
                            props.postType == "entry"
                              ? "?q=_l_o_s"
                              : item.authorUsername
                              ? "/" + item.authorUsername.replace("@", "")
                              : "/" + item.user.username.replace("@", "")
                          }
                          className="textUnderlineNone color-black"
                        >
                          {item.authorUsername ? (
                            <div
                              className={"profile-pic"}
                              style={{
                                backgroundImage:
                                  "url(" +
                                  process.env.REACT_APP_URL.replace("api", "") +
                                  "public/profile/picture/" +
                                  item.authorUsername +
                                  "/" +
                                  item.authorProfilePic +
                                  ")",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                              }}
                            />
                          ) : (
                            <div
                              className={"profile-pic"}
                              style={{
                                backgroundImage:
                                  "url(" +
                                  process.env.REACT_APP_URL.replace("api", "") +
                                  "public/profile/picture/@" +
                                  item.user.username +
                                  "/" +
                                  item.user.profile_pic +
                                  ")",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                              }}
                            />
                          )}
                        </NavLink>
                      </Col>
                      <Col
                        lg={11}
                        xs={11}
                        sm={11}
                        className={"mobileTimelinePaddingLeft"}
                      >
                        <NavLink
                          to={
                            props.postType == "entry"
                              ? "?q=_l_o_s"
                              : item.authorUsername
                              ? "/" + item.authorUsername.replace("@", "")
                              : "/" + item.user.username.replace("@", "")
                          }
                          className="textUnderlineNone color-black "
                        >
                          {item.authorUsername ? (
                            <label className="text-bold mobile-font-sizes14 line-height-20">
                              {item.author}
                              <br />
                              <span
                                className={
                                  "text-normal color-grey mobile-font-sizes14 "
                                }
                              >
                                {item.authorUsername}
                              </span>
                            </label>
                          ) : (
                            <label className="line-height-20 text-bold mobile-font-sizes14 underlineHover">
                              {item.user.first_name} {item.user.last_name}
                              <br />
                              <label className="footnote cursorPointer text-normal mobile-font-sizes14 ">
                                @{item.user.username}
                              </label>
                            </label>
                          )}
                        </NavLink>
                        {item.authorUsername ? (
                          <>
                            <label
                              className={"cursorPointer float-right"}
                              data-tip={
                                item.reposted_check_follow
                                  ? "Unfollow " + item.authorUsername
                                  : "Follow " + item.authorUsername
                              }
                              data-place={"bottom"}
                              data-effect={"solid"}
                              onClick={() => props.follow(item.authorID)}
                            >
                              {!props.hidden[item.authorID] && (
                                <>
                                  {item.reposted_check_follow ? (
                                    <IoPersonRemove
                                      size={17}
                                      className="unfollow-icon"
                                    />
                                  ) : (
                                    <IoPersonAddOutline
                                      size={17}
                                      className="follow-icon"
                                    />
                                  )}
                                </>
                              )}

                              {!!props.hidden[item.authorID] && (
                                <label
                                  className={
                                    "disabled-bg-color timeline-disabled-bg-color  float-right fontBold no-shadow btn-sm btn btn-primary"
                                  }
                                >
                                  <ClipLoader size={15} color="#000" />
                                </label>
                              )}
                              <ReactTooltip
                                arrowColor={"transparent"}
                                className="p-1 fontSize-12"
                              />
                            </label>
                          </>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        lg={1}
                        xs={1}
                        sm={1}
                        className="p-0 d-none d-md-block d-lg-block d-xl-block d-xxl-block"
                      ></Col>
                      <Col
                        lg={11}
                        xs={12}
                        sm={11}
                        className={"mobilePaddingTimeline-0"}
                      >
                        <span className="text-bold">
                          <Col
                            lg={12}
                            className={
                              item.postSubject == null ? "d-none" : "p-0 mt-1"
                            }
                          >
                            <Link
                              to={
                                props.postType == "entry"
                                  ? "?q=_l_o_s"
                                  : item.postTopic == null
                                  ? ""
                                  : "/topicPosts?clicked=" +
                                    item.postTopic +
                                    "&from_post_id=" +
                                    item.posts.id
                              }
                              className={"textUnderlineNone underlineHover"}
                            >
                              <label
                                className={
                                  "topics fontSize14 cursorPointer textUnderlineNone underlineHover text-normal"
                                }
                              >
                                {item.postTopic == null ? (
                                  ""
                                ) : (
                                  <>
                                    <IoBookOutline size={15} /> {item.postTopic}
                                  </>
                                )}
                              </label>
                            </Link>
                          </Col>
                          {item.posts.post == null ? (
                            ""
                          ) : (
                            <label
                              className={
                                item.posts.post == null
                                  ? "d-none"
                                  : "whiteSpace cursorPointer marginBottom-15"
                              }
                            >
                              <Row
                                className={
                                  item.topics == null ||
                                  props.postType == "topic"
                                    ? "d-none"
                                    : "p-0"
                                }
                              >
                                <Col lg={12}>
                                  <Link
                                    to={
                                      props.postType == "entry"
                                        ? "?q=_l_o_s"
                                        : item.topics == null
                                        ? ""
                                        : "/topicPosts?clicked=" +
                                          item.topics.topic +
                                          "&from_post_id=" +
                                          item.posts.id
                                    }
                                  >
                                    <label
                                      className={
                                        "topics fontSize14 cursorPointer textUnderlineNone underlineHover text-normal"
                                      }
                                    >
                                      {item.topics == null ? (
                                        ""
                                      ) : (
                                        <>
                                          <IoBookOutline size={15} />{" "}
                                          {item.topics.topic}
                                        </>
                                      )}
                                    </label>
                                  </Link>
                                </Col>
                              </Row>
                              {item.posts.post.length > 150 ? (
                                <>
                                  <span
                                    onClick={() =>
                                      props.replyPage(
                                        item.authorUsername
                                          ? `${item.repost_original_posts}`
                                          : `${item.posts.post}`,
                                        item.authorUsername
                                          ? `${item.author}`
                                          : `${item.user.first_name} ${item.user.last_name}`,
                                        item.authorUsername
                                          ? `${item.authorUsername2}`
                                          : `${item.user.username}`,
                                        item.authorUsername
                                          ? item.repost_original_post_id
                                          : item.posts.id
                                      )
                                    }
                                    className="text-normal cursorPointer"
                                  >
                                    <span
                                      className={
                                        props.showMorePost == true
                                          ? "d-none"
                                          : "text-normal"
                                      }
                                    >
                                      <props.Tagify
                                        onClick={(text, type) =>
                                          props.hashtag(
                                            text,
                                            type,
                                            item.posts.id
                                          )
                                        }
                                        detectHashtags={true}
                                        detectMentions={true}
                                        tagStyle={props.tagStyle}
                                        mentionStyle={props.mentionStyle}
                                        detectLinks={true}
                                      >
                                        <props.Linkify
                                          componentDecorator={(
                                            decoratedHref,
                                            decoratedText,
                                            key
                                          ) => (
                                            <props.SecureLink
                                              target="_blank"
                                              href={decoratedHref}
                                              key={key}
                                              className={
                                                "color-blue textUnderlineNone"
                                              }
                                            >
                                              {decoratedText}
                                            </props.SecureLink>
                                          )}
                                        >
                                          {item.posts.post.slice(0, 150)}
                                        </props.Linkify>
                                      </props.Tagify>
                                    </span>
                                    <span
                                      className={
                                        props.showMorePost == true
                                          ? "text-normal"
                                          : "d-none"
                                      }
                                    >
                                      {item.posts.id == props.showMorePostId ? (
                                        <props.Tagify
                                          onClick={(text, type) =>
                                            props.hashtag(
                                              text,
                                              type,
                                              item.posts.id
                                            )
                                          }
                                          detectHashtags={true}
                                          detectMentions={true}
                                          tagStyle={props.tagStyle}
                                          mentionStyle={props.mentionStyle}
                                          detectLinks={true}
                                        >
                                          <props.Linkify
                                            componentDecorator={(
                                              decoratedHref,
                                              decoratedText,
                                              key
                                            ) => (
                                              <props.SecureLink
                                                target="_blank"
                                                href={decoratedHref}
                                                key={key}
                                                className={
                                                  "color-blue textUnderlineNone"
                                                }
                                              >
                                                {decoratedText}
                                              </props.SecureLink>
                                            )}
                                          >
                                            {item.posts.post}
                                          </props.Linkify>
                                        </props.Tagify>
                                      ) : (
                                        <props.Tagify
                                          onClick={(text, type) =>
                                            props.hashtag(
                                              text,
                                              type,
                                              item.posts.id
                                            )
                                          }
                                          detectHashtags={true}
                                          detectMentions={true}
                                          tagStyle={props.tagStyle}
                                          mentionStyle={props.mentionStyle}
                                          detectLinks={true}
                                        >
                                          <props.Linkify
                                            componentDecorator={(
                                              decoratedHref,
                                              decoratedText,
                                              key
                                            ) => (
                                              <props.SecureLink
                                                target="_blank"
                                                href={decoratedHref}
                                                key={key}
                                                className={
                                                  "color-blue textUnderlineNone"
                                                }
                                              >
                                                {decoratedText}
                                              </props.SecureLink>
                                            )}
                                          >
                                            {item.posts.post.slice(0, 150)}
                                          </props.Linkify>
                                        </props.Tagify>
                                      )}
                                    </span>
                                  </span>
                                  {props.showMorePost == true ? "" : ""}
                                  <span
                                    className="color-blue cursorPointer text-normal"
                                    onClick={() =>
                                      props.showMore(item.posts.id)
                                    }
                                  >
                                    {props.showMorePost == true &&
                                    item.posts.id == props.showMorePostId ? (
                                      " Show less..."
                                    ) : (
                                      <span className="text-normal">
                                        <span
                                          className={"color-black text-normal"}
                                        >
                                          ...
                                        </span>{" "}
                                        Show more
                                      </span>
                                    )}
                                  </span>
                                </>
                              ) : (
                                <label
                                  onClick={() =>
                                    props.replyPage(
                                      item.authorUsername
                                        ? `${item.repost_original_posts}`
                                        : `${item.posts.post}`,
                                      item.authorUsername
                                        ? `${item.author}`
                                        : `${item.user.first_name} ${item.user.last_name}`,
                                      item.authorUsername
                                        ? `${item.authorUsername2}`
                                        : `${item.user.username}`,
                                      item.authorUsername
                                        ? item.repost_original_post_id
                                        : item.posts.id
                                    )
                                  }
                                  className="cursorPointer"
                                >
                                  <props.Tagify
                                    onClick={(text, type) =>
                                      props.hashtag(text, type, item.posts.id)
                                    }
                                    detectHashtags={true}
                                    detectMentions={true}
                                    tagStyle={tagStyle}
                                    mentionStyle={mentionStyle}
                                    detectLinks={true}
                                  >
                                    <props.Linkify
                                      componentDecorator={(
                                        decoratedHref,
                                        decoratedText,
                                        key
                                      ) => (
                                        <props.SecureLink
                                          target="_blank"
                                          href={decoratedHref}
                                          key={key}
                                          className={
                                            "color-blue textUnderlineNone"
                                          }
                                        >
                                          {decoratedText}
                                        </props.SecureLink>
                                      )}
                                    >
                                      {item.posts.post}
                                    </props.Linkify>
                                  </props.Tagify>
                                </label>
                              )}
                              {props.repostsMedia.length == 0 ? (
                                ""
                              ) : (
                                <div className="col-12 mt-2">
                                  {props.repostsMedia.map(
                                    (mediaRepost, index) => (
                                      <>{index}</>
                                    )
                                  )}
                                </div>
                              )}
                            </label>
                          )}
                        </span>
                        <Row
                          className={item.media.length > 0 ? "mt-1" : "d-none"}
                        >
                          {item.media.map((img, index) => (
                            <Col
                              className={
                                item.media.length == 1
                                  ? "col-12"
                                  : item.media.length == 2
                                  ? "col-6 paddingSides" + index
                                  : item.media.length == 3
                                  ? "col-4 threePaddingSides" + index
                                  : item.media.length == 4
                                  ? "col-6 fourPaddingSides" + index
                                  : "col-6"
                              }
                              key={index}
                            >
                              <div
                                onClick={() =>
                                  props.getPhotos(
                                    item.user.username.replace("@", ""),
                                    item.posts.id,
                                    img.id,
                                    item.media,
                                    index,
                                    item.posts.post,
                                    item.did_repost_to_post,
                                    item.did_reply_to_post,
                                    item.liked_by_me,
                                    item.bookmarked_by_me,
                                    item.posts.created_at,
                                    item.user.first_name +
                                      " " +
                                      item.user.last_name,
                                    item.user.profile_pic,
                                    item.repost_count,
                                    item.like_count,
                                    item.reply_count,
                                    item.bookmark_count,
                                    img.file,

                                    item.authorUsername
                                      ? item.repost_original_polls
                                      : item.polls,
                                    item.did_i_vote,
                                    {
                                      days: item.pollDuration.days,
                                      hours: item.pollDuration.hours,
                                      minutes: item.pollDuration.minutes,
                                      seconds: item.pollDuration.seconds,
                                    },
                                    item.pollResults,
                                    item.votes,
                                    item.documents,
                                    item.subjects != null
                                      ? item.subjects.subject
                                      : null,
                                    item.topics != null
                                      ? item.topics.topic
                                      : null,
                                    item.authorUsername
                                      ? item.original_current_post_polls
                                      : item.current_post_polls,
                                    item.current_post_polls_closed
                                  )
                                }
                                className={
                                  item.media.length == 2
                                    ? "mb-0 border-radius" + index
                                    : item.media.length == 3
                                    ? "borderRadiusImg" + index
                                    : item.media.length == 4
                                    ? "borderRadiusImgFour" + index
                                    : "mb-0 border-radius-5"
                                }
                                style={{
                                  backgroundImage:
                                    "url(" +
                                    process.env.REACT_APP_URL.replace(
                                      "api",
                                      ""
                                    ) +
                                    "public/timeline/media/" +
                                    item.user.username +
                                    "/" +
                                    img.file +
                                    ")",
                                  backgroundSize: "cover",
                                  height:
                                    item.media.length == 1
                                      ? 300
                                      : item.media.length == 2
                                      ? 300
                                      : item.media.length == 4
                                      ? 150
                                      : 200,
                                  width: "100%",
                                  border: "thin solid #ececec",

                                  backgroundRepeat: "no-repeat",
                                  backgroundPosition: "center",
                                }}
                              />
                            </Col>
                          ))}
                        </Row>
                        <Row
                          className={
                            item.reposts_media.length > 0 ? "mt-1" : "d-none"
                          }
                        >
                          {item.reposts_media.map((img, index) => (
                            <Col
                              className={
                                item.reposts_media.length == 1
                                  ? "col-12"
                                  : item.reposts_media.length == 2
                                  ? "col-6 paddingSides" + index
                                  : item.reposts_media.length == 3
                                  ? "col-4 threePaddingSides" + index
                                  : item.reposts_media.length == 4
                                  ? "col-6 fourPaddingSides" + index
                                  : "col-6"
                              }
                              key={index}
                            >
                              <div
                                onClick={() =>
                                  props.getPhotos(
                                    item.authorUsername.replace("@", ""),
                                    img.original_post_id,
                                    img.id,
                                    item.reposts_media,
                                    index,
                                    item.repost_original_posts,
                                    item.repost_did_repost_to_post,
                                    item.repost_did_reply_to_post,
                                    item.liked_by_me,
                                    item.bookmarked_by_me,
                                    item.posts.created_at,
                                    item.author,
                                    item.authorProfilePic,
                                    item.repost_original_repost_count,
                                    item.repost_original_like_count,
                                    item.repost_original_reply_count,
                                    item.repost_original_bookmark_count,
                                    img.file
                                  )
                                }
                                className={
                                  item.reposts_media.length == 2
                                    ? "mb-0 border-radius" + index
                                    : item.reposts_media.length == 3
                                    ? "borderRadiusImg" + index
                                    : item.reposts_media.length == 4
                                    ? "borderRadiusImgFour" + index
                                    : "mb-0 border-radius-5"
                                }
                                style={{
                                  backgroundImage:
                                    "url(" +
                                    process.env.REACT_APP_URL.replace(
                                      "api",
                                      ""
                                    ) +
                                    "public/timeline/media/" +
                                    item.authorUsername.replace("@", "") +
                                    "/" +
                                    img.file +
                                    ")",
                                  backgroundSize: "cover",
                                  height:
                                    item.reposts_media.length == 1
                                      ? 300
                                      : item.reposts_media.length == 2
                                      ? 300
                                      : item.reposts_media.length == 4
                                      ? 150
                                      : 200,
                                  width: "100%",
                                  border: "thin solid #ececec",

                                  backgroundRepeat: "no-repeat",
                                  backgroundPosition: "center",
                                }}
                              />
                            </Col>
                          ))}
                        </Row>
                        <Row
                          className={
                            item.documents.length > 0 && item.posts.pos == null
                              ? "mt-0"
                              : item.documents.length > 0
                              ? "mt-1"
                              : "d-none"
                          }
                        >
                          {item.posts.post == null ? (
                            <span
                              className="send-btn fontMedium mb-0 text-center fontSize14"
                              style={{ width: 125 }}
                              onClick={() =>
                                props.replyPage(
                                  item.authorUsername
                                    ? `${item.repost_original_posts}`
                                    : `${item.posts.post}`,
                                  item.authorUsername
                                    ? `${item.author}`
                                    : `${item.user.first_name} ${item.user.last_name}`,
                                  item.authorUsername
                                    ? `${item.authorUsername2}`
                                    : `${item.user.username}`,
                                  item.authorUsername
                                    ? item.repost_original_post_id
                                    : item.posts.id
                                )
                              }
                            >
                              Open post <IoBookOutline size={17} />
                            </span>
                          ) : (
                            ""
                          )}
                          <div className={"padding-20 mt-0"}>
                            {item.documents.map((doc, index) => (
                              <a
                                href={
                                  process.env.REACT_APP_URL.replace("api", "") +
                                  "public/timeline/documents/" +
                                  item.user.username.replace("@", "") +
                                  "/" +
                                  doc.file
                                }
                                target={"_blank"}
                                className={"textUnderlineNone"}
                              >
                                <Row
                                  className={
                                    "align-items-center document-link-timeline"
                                  }
                                >
                                  <Col lg={12} key={index} className={"p-0"}>
                                    <div className={""}>
                                      {doc.format == "pdf" ? (
                                        <label className={"p-1"}>
                                          <FaRegFilePdf
                                            size={20}
                                            className={"color-red"}
                                          />
                                          &nbsp;
                                        </label>
                                      ) : doc.format == "docx" ? (
                                        <label className={"p-1"}>
                                          <FaRegFileWord
                                            size={20}
                                            className={"color-blue"}
                                          />
                                          &nbsp;
                                        </label>
                                      ) : doc.format == "csv" ? (
                                        <label className={"p-1"}>
                                          <FaFileCsv
                                            size={20}
                                            className={"color-green"}
                                          />
                                          &nbsp;
                                        </label>
                                      ) : doc.format == "xlsx" ? (
                                        <label className={"p-1"}>
                                          <FaRegFileExcel
                                            size={20}
                                            className={"color-green"}
                                          />
                                          &nbsp;
                                        </label>
                                      ) : doc.format == "pptx" ? (
                                        <label className={"p-1"}>
                                          <FaRegFilePowerpoint
                                            size={20}
                                            className={"color-orange"}
                                          />
                                          &nbsp;
                                        </label>
                                      ) : (
                                        ""
                                      )}
                                      {doc.title == ""
                                        ? doc.file.length > 30
                                          ? doc.file.slice(0, 30) + "..."
                                          : doc.file
                                        : (doc.title.length > 30
                                            ? doc.title.slice(0, 30) + "..."
                                            : doc.title) +
                                          "." +
                                          doc.format}
                                    </div>
                                  </Col>
                                </Row>
                              </a>
                            ))}
                          </div>
                        </Row>
                        <Row
                          className={
                            item.repostDocuments.length > 0 ? "mt-0" : "d-none"
                          }
                        >
                          {item.posts.post == null ? (
                            <span
                              className="send-btn fontBold mb-0 text-center"
                              style={{ width: 140 }}
                              onClick={() =>
                                props.replyPage(
                                  item.authorUsername
                                    ? `${item.repost_original_posts}`
                                    : `${item.posts.post}`,
                                  item.authorUsername
                                    ? `${item.author}`
                                    : `${item.user.first_name} ${item.user.last_name}`,
                                  item.authorUsername
                                    ? `${item.authorUsername2}`
                                    : `${item.user.username}`,
                                  item.authorUsername
                                    ? item.repost_original_post_id
                                    : item.posts.id
                                )
                              }
                            >
                              Open post <IoBookOutline size={17} />
                            </span>
                          ) : (
                            ""
                          )}
                          <div className={"padding-20"}>
                            {item.repostDocuments.map((doc, index) => (
                              <a
                                href={
                                  process.env.REACT_APP_URL.replace("api", "") +
                                  "public/timeline/documents/" +
                                  item.authorUsername.replace("@", "") +
                                  "/" +
                                  doc.file
                                }
                                target={"_blank"}
                                className={"textUnderlineNone"}
                              >
                                <Row
                                  className={
                                    "align-items-center document-link-timeline"
                                  }
                                >
                                  <Col lg={12} key={index} className={"p-0"}>
                                    <div className={""}>
                                      {doc.format == "pdf" ? (
                                        <label className="p-1">
                                          <FaRegFilePdf
                                            size={20}
                                            className={"color-red"}
                                          />
                                        </label>
                                      ) : doc.format == "docx" ? (
                                        <label className="p-1">
                                          <FaRegFileWord
                                            size={20}
                                            className={"color-blue"}
                                          />
                                        </label>
                                      ) : doc.format == "csv" ? (
                                        <label className="p-1">
                                          <FaFileCsv
                                            size={20}
                                            className={"color-green"}
                                          />
                                        </label>
                                      ) : doc.format == "xlsx" ? (
                                        <label className="p-1">
                                          <FaRegFileExcel
                                            size={20}
                                            className={"color-green"}
                                          />
                                        </label>
                                      ) : doc.format == "pptx" ? (
                                        <label className="p-1">
                                          <img
                                            src={
                                              process.env.REACT_APP_URL.replace(
                                                "api",
                                                ""
                                              ) + "/pptx_icons.png"
                                            }
                                            style={{ width: 40 }}
                                          />
                                        </label>
                                      ) : (
                                        ""
                                      )}
                                      {doc.title == ""
                                        ? doc.file.length > 30
                                          ? doc.file.slice(0, 30) + "..."
                                          : doc.file
                                        : (doc.title.length > 30
                                            ? doc.title.slice(0, 30) + "..."
                                            : doc.title) +
                                          "." +
                                          doc.format}
                                    </div>
                                  </Col>
                                </Row>
                              </a>
                            ))}
                          </div>
                        </Row>
                        <Row
                          className={
                            item.polls.length > 0 &&
                            item.quotedData.linked_quote_post_id !=
                              item.posts.id
                              ? "mt-2"
                              : "d-none"
                          }
                        >
                          <div
                            className={
                              item.did_i_vote == 1 || item.pollClosed == true
                                ? "col-12"
                                : "d-none"
                            }
                            onClick={() =>
                              props.replyPage(
                                item.authorUsername
                                  ? `${item.repost_original_posts}`
                                  : `${item.posts.post}`,
                                item.authorUsername
                                  ? `${item.author}`
                                  : `${item.user.first_name} ${item.user.last_name}`,
                                item.authorUsername
                                  ? `${item.authorUsername2}`
                                  : `${item.user.username}`,
                                item.authorUsername
                                  ? item.repost_original_post_id
                                  : item.posts.id
                              )
                            }
                          >
                            {item.pollResults.map((results, index) => (
                              <div
                                className={
                                  results.percentage == 0
                                    ? "w-100 resulst-polls-container-no-votes"
                                    : "w-100 resulst-polls-container"
                                }
                                key={index}
                              >
                                <label
                                  className={
                                    results.percentage == 0
                                      ? ""
                                      : "polls-voted color-black"
                                  }
                                  style={{
                                    width: results.percentage + "%",
                                  }}
                                />
                                <label className={"color-black"}>
                                  {results.options}{" "}
                                  {results.who_i_voted_for == 1 ? (
                                    <IoCheckmarkCircleOutline
                                      size={20}
                                      color={"#66b266"}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </label>
                                <label className={"float-right color-black"}>
                                  {results.percentage}%
                                </label>
                              </div>
                            ))}
                          </div>
                          <div
                            className={
                              item.did_i_vote == 1 || item.pollClosed == true
                                ? "d-none"
                                : "col-12 mt-2"
                            }
                          >
                            {item.polls.map((polls, index) => (
                              <Col lg={12} key={index}>
                                <div
                                  className={"col-12 polls fontBold"}
                                  onClick={() =>
                                    props.vote(polls.id, polls.post_id)
                                  }
                                >
                                  {polls.option}
                                </div>
                              </Col>
                            ))}
                          </div>
                          <Col lg={12}>
                            <label className="footnote">
                              {item.votes} {item.votes == 1 ? "vote" : "votes"}{" "}
                              -{" "}
                              {item.pollClosed == true
                                ? "Final results"
                                : item.pollDuration.days >= 1
                                ? item.pollDuration.days + "d left"
                                : item.pollDuration.hours >= 1 &&
                                  item.pollDuration.minutes >= 1
                                ? item.pollDuration.hours +
                                  "h and " +
                                  item.pollDuration.minutes +
                                  "m left"
                                : item.pollDuration.hours >= 1 &&
                                  item.pollDuration.minutes == 0
                                ? item.pollDuration.hours + "h left"
                                : item.pollDuration.minutes >= 1
                                ? item.pollDuration.minutes + "m left"
                                : "Final results"}
                            </label>
                          </Col>
                        </Row>
                        {item.quotedData.length == 0 ? (
                          ""
                        ) : (
                          <Row
                            className={
                              item.quotedData.linked_quote_post_id ==
                              item.posts.id
                                ? ""
                                : "d-none"
                            }
                          >
                            <Col lg={12}>
                              <div className="quote-post mt-1 whiteSpace">
                                <Row className="align-items-center">
                                  <Col sm={2} lg={2} xs={2}>
                                    <Link
                                      to={
                                        props.postType == "entry"
                                          ? "?q=_l_o_s"
                                          : "/" +
                                            item.quotedData.quoted_username.replace(
                                              "@",
                                              ""
                                            )
                                      }
                                      className={
                                        "color-black textUnderlineNone cursorPointer"
                                      }
                                    >
                                      <div
                                        className={"profile-pic"}
                                        style={{
                                          backgroundImage:
                                            "url(" +
                                            process.env.REACT_APP_URL.replace(
                                              "api",
                                              ""
                                            ) +
                                            "public/profile/picture/" +
                                            item.quotedData.quoted_username +
                                            "/" +
                                            item.quotedData.quoted_profile_pic +
                                            ")",
                                          backgroundRepeat: "no-repeat",
                                          backgroundPosition: "center",
                                        }}
                                      />
                                    </Link>
                                  </Col>
                                  <Col
                                    sm={7}
                                    lg={7}
                                    xs={8}
                                    className={
                                      "marginLeft-30 mobileTimelineMarginLeft"
                                    }
                                  >
                                    <Link
                                      to={
                                        props.postType == "entry"
                                          ? "?q=_l_o_s"
                                          : "/" +
                                            item.quotedData.quoted_username.replace(
                                              "@",
                                              ""
                                            )
                                      }
                                      className={
                                        "color-black textUnderlineNone cursorPointer line-height-20 "
                                      }
                                    >
                                      <label className="line-height-20 cursorPointer fontSize14">
                                        <span className="text-bold">
                                          {item.quotedData.quoted_fullname}
                                        </span>
                                        <br />
                                        <label className="footnote fontSize14">
                                          {item.quotedData.quoted_username}
                                        </label>
                                      </label>
                                    </Link>
                                  </Col>
                                  <Col
                                    sm={3}
                                    lg={3}
                                    xs={2}
                                    className="p-0 mobile-hid"
                                  >
                                    {item.quotedData.quoted_userid ==
                                    props.user_id ? (
                                      ""
                                    ) : (
                                      <Fragment>
                                        <label
                                          className={
                                            "cursorPointer float-right"
                                          }
                                          data-tip={
                                            item.quotedData
                                              .quoted_do_i_follow_this_person
                                              ? "Unfollow " +
                                                item.quotedData.quoted_username
                                              : "Follow " +
                                                item.quotedData.quoted_username
                                          }
                                          data-place={"bottom"}
                                          data-effect={"solid"}
                                          onClick={() =>
                                            props.follow(
                                              item.quotedData.quoted_userid
                                            )
                                          }
                                        >
                                          {!props.hidden[
                                            item.quotedData.quoted_userid
                                          ] && (
                                            <>
                                              {item.quotedData
                                                .quoted_do_i_follow_this_person ? (
                                                <IoPersonRemove
                                                  size={17}
                                                  className="unfollow-icon"
                                                />
                                              ) : (
                                                <IoPersonAddOutline
                                                  size={17}
                                                  className="follow-icon"
                                                />
                                              )}
                                            </>
                                          )}

                                          {!!props.hidden[
                                            item.quotedData.quoted_userid
                                          ] && (
                                            <label
                                              className={
                                                "disabled-bg-color timeline-disabled-bg-color  float-right fontBold no-shadow btn-sm btn btn-primary"
                                              }
                                            >
                                              <ClipLoader
                                                size={15}
                                                color="#000"
                                              />
                                            </label>
                                          )}
                                          <ReactTooltip
                                            arrowColor={"transparent"}
                                            className="p-1 fontSize-12"
                                          />
                                        </label>

                                        {/* {!props.hidden[
                                          item.quotedData.quoted_userid
                                        ] && (
                                          <Button
                                            onClick={() =>
                                              props.follow(
                                                item.quotedData.quoted_userid
                                              )
                                            }
                                            className={
                                              !item.quotedData
                                                .quoted_do_i_follow_this_person
                                                ? "native-button-dark-grey btn-follow-user float-right fontBold no-shadow btn-sm"
                                                : "native-button-dark-grey btn-follow-topics float-right fontBold no-shadow btn-sm"
                                            }
                                          ></Button>
                                        )}

                                        {!!props.hidden[
                                          item.quotedData.quoted_userid
                                        ] && (
                                          <label
                                            className={
                                              "disabled-bg-color timeline-disabled-bg-color  float-right fontBold no-shadow btn-sm btn btn-primary"
                                            }
                                          >
                                            <ClipLoader
                                              size={15}
                                              color="#000"
                                            />
                                          </label>
                                        )} */}
                                      </Fragment>
                                    )}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={12} className="mt-2">
                                    <div
                                      className={
                                        item.quotedData.quotedSubject == null
                                          ? "d-none"
                                          : ""
                                      }
                                    >
                                      <Link
                                        to={
                                          props.postType == "entry"
                                            ? "?q=_l_o_s"
                                            : item.quotedData.quotedTopic ==
                                              null
                                            ? "d-none"
                                            : "/topicPosts?clicked=" +
                                              item.quotedData.quotedTopic +
                                              "&from_post_id=" +
                                              item.quotedData.quoted_post_id
                                        }
                                      >
                                        <label
                                          className={
                                            "topics fontSize14 cursorPointer textUnderlineNone underlineHover text-normal"
                                          }
                                        >
                                          {item.quotedData.quotedTopic ==
                                          null ? (
                                            ""
                                          ) : (
                                            <>
                                              <IoBookOutline size={15} />{" "}
                                              {item.quotedData.quotedTopic}
                                            </>
                                          )}
                                        </label>
                                      </Link>
                                    </div>
                                    <div
                                      className={"cursorPointer w-100"}
                                      onClick={() =>
                                        props.replyPage(
                                          `${item.quotedData.quoted_post}`,
                                          `${item.quotedData.quoted_fullname}`,
                                          `${item.quotedData.quoted_username}`.replace(
                                            "@",
                                            ""
                                          ),
                                          `${item.quotedData.quoted_post_id}`
                                        )
                                      }
                                    >
                                      {item.quotedData.quoted_post == null ? (
                                        ""
                                      ) : item.quotedData.quoted_post.length >
                                        150 ? (
                                        <>
                                          {props.showMoreQuotedPostText ==
                                          true ? (
                                            <props.Tagify
                                              onClick={(text, type) =>
                                                props.hashtag(
                                                  text,
                                                  type,
                                                  item.quotedData.quoted_post_id
                                                )
                                              }
                                              detectHashtags={true}
                                              detectMentions={true}
                                              tagStyle={props.tagStyle}
                                              mentionStyle={props.mentionStyle}
                                              detectLinks={true}
                                            >
                                              <props.Linkify
                                                componentDecorator={(
                                                  decoratedHref,
                                                  decoratedText,
                                                  key
                                                ) => (
                                                  <props.SecureLink
                                                    target="_blank"
                                                    href={decoratedHref}
                                                    key={key}
                                                    className={
                                                      "color-blue textUnderlineNone"
                                                    }
                                                  >
                                                    {decoratedText}
                                                  </props.SecureLink>
                                                )}
                                              >
                                                {item.quotedData.quoted_post}
                                              </props.Linkify>
                                            </props.Tagify>
                                          ) : (
                                            <props.Tagify
                                              onClick={(text, type) =>
                                                props.hashtag(
                                                  text,
                                                  type,
                                                  item.quotedData.quoted_post_id
                                                )
                                              }
                                              detectHashtags={true}
                                              detectMentions={true}
                                              tagStyle={props.tagStyle}
                                              mentionStyle={props.mentionStyle}
                                              detectLinks={true}
                                            >
                                              <props.Linkify
                                                componentDecorator={(
                                                  decoratedHref,
                                                  decoratedText,
                                                  key
                                                ) => (
                                                  <props.SecureLink
                                                    target="_blank"
                                                    href={decoratedHref}
                                                    key={key}
                                                    className={
                                                      "color-blue textUnderlineNone"
                                                    }
                                                  >
                                                    {decoratedText}
                                                  </props.SecureLink>
                                                )}
                                              >
                                                {item.quotedData.quoted_post.slice(
                                                  0,
                                                  150
                                                )}
                                              </props.Linkify>
                                            </props.Tagify>
                                          )}

                                          <span className={"text-normal"}>
                                            ...{" "}
                                            <label
                                              className={
                                                "color-blue cursorPointer"
                                              }
                                              onClick={props.showMoreQuotedPost}
                                            >
                                              {props.showMoreQuotedPostText ==
                                              true
                                                ? " Show less"
                                                : " Show more"}
                                            </label>
                                          </span>
                                        </>
                                      ) : (
                                        <label
                                          onClick={() =>
                                            props.replyPage(
                                              item.quotedData.quoted_post,
                                              item.quotedData.quoted_fullname,
                                              item.quotedData.quoted_username.replace(
                                                "@",
                                                ""
                                              ),
                                              item.quotedData.quoted_post_id
                                            )
                                          }
                                          className="cursorPointer"
                                        >
                                          <props.Tagify
                                            onClick={(text, type) =>
                                              props.hashtag(
                                                text,
                                                type,
                                                item.quotedData.quoted_post_id
                                              )
                                            }
                                            detectHashtags={true}
                                            detectMentions={true}
                                            tagStyle={props.tagStyle}
                                            mentionStyle={props.mentionStyle}
                                            detectLinks={true}
                                          >
                                            <props.Linkify
                                              componentDecorator={(
                                                decoratedHref,
                                                decoratedText,
                                                key
                                              ) => (
                                                <props.SecureLink
                                                  target="_blank"
                                                  href={decoratedHref}
                                                  key={key}
                                                  className={
                                                    "color-blue textUnderlineNone"
                                                  }
                                                >
                                                  {decoratedText}
                                                </props.SecureLink>
                                              )}
                                            >
                                              {item.quotedData.quoted_post}
                                            </props.Linkify>
                                          </props.Tagify>
                                        </label>
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                                <Row
                                  className={
                                    item.quotedData.quoted_documents.length > 0
                                      ? "mt-0 paddingLeft-13 paddingRight-13"
                                      : "d-none"
                                  }
                                >
                                  {item.quotedData.quoted_documents.map(
                                    (doc, index) => (
                                      <Col lg={12} key={index} className="">
                                        <a
                                          href={
                                            process.env.REACT_APP_URL.replace(
                                              "api",
                                              ""
                                            ) +
                                            "public/timeline/documents/" +
                                            item.quotedData.quoted_username.replace(
                                              "@",
                                              ""
                                            ) +
                                            "/" +
                                            doc.file
                                          }
                                          target={"_blank"}
                                          className={"textUnderlineNone"}
                                        >
                                          <Row
                                            className={
                                              "align-items-center document-link-timeline"
                                            }
                                          >
                                            <Col
                                              lg={12}
                                              key={index}
                                              className={"p-0"}
                                            >
                                              <div className={""}>
                                                {doc.format == "pdf" ? (
                                                  <label className="p-1">
                                                    <FaRegFilePdf
                                                      size={20}
                                                      className={"color-red"}
                                                    />
                                                  </label>
                                                ) : doc.format == "docx" ? (
                                                  <label className="p-1">
                                                    <FaRegFileWord
                                                      size={20}
                                                      className={"color-blue"}
                                                    />
                                                  </label>
                                                ) : doc.format == "csv" ? (
                                                  <label className="p-1">
                                                    <FaFileCsv
                                                      size={20}
                                                      className={"color-green"}
                                                    />
                                                  </label>
                                                ) : doc.format == "xlsx" ? (
                                                  <label className="p-1">
                                                    <FaRegFileExcel
                                                      size={20}
                                                      className={"color-green"}
                                                    />
                                                  </label>
                                                ) : doc.format == "pptx" ? (
                                                  <label className="p-1">
                                                    <FaRegFilePowerpoint
                                                      size={20}
                                                      className={"color-orange"}
                                                    />
                                                  </label>
                                                ) : (
                                                  ""
                                                )}
                                                {doc.title == ""
                                                  ? doc.file.length > 30
                                                    ? doc.file.slice(0, 30) +
                                                      "..."
                                                    : doc.file
                                                  : (doc.title.length > 30
                                                      ? doc.title.slice(0, 30) +
                                                        "..."
                                                      : doc.title) +
                                                    "." +
                                                    doc.format}
                                              </div>
                                            </Col>
                                          </Row>
                                        </a>
                                      </Col>
                                    )
                                  )}
                                </Row>
                                <Row
                                  className={
                                    item.quotedData.quoted_media.length > 0
                                      ? "mt-1"
                                      : "d-none"
                                  }
                                >
                                  {item.quotedData.quoted_media.map(
                                    (img, index) => (
                                      <Col
                                        className={
                                          item.quotedData.quoted_media.length ==
                                          1
                                            ? "col-12"
                                            : item.quotedData.quoted_media
                                                .length == 2
                                            ? "col-6 paddingSides" + index
                                            : item.quotedData.quoted_media
                                                .length == 3
                                            ? "col-4 threePaddingSides" + index
                                            : item.quotedData.quoted_media
                                                .length == 4
                                            ? "col-6 fourPaddingSides" + index
                                            : "col-6"
                                        }
                                      >
                                        <div
                                          onClick={() =>
                                            props.getPhotos(
                                              item.quotedData.quoted_username.replace(
                                                "@",
                                                ""
                                              ),
                                              item.quotedData.quoted_post_id,
                                              img.id,
                                              item.quotedData.quoted_media,
                                              index,
                                              item.quotedData.quoted_post,

                                              item.quotedData
                                                .quoted_did_repost_to_post,
                                              item.quotedData
                                                .quoted_did_reply_to_post,
                                              item.quotedData
                                                .quoted_liked_by_me,
                                              item.quotedData
                                                .quoted_bookmarked_by_me,
                                              item.quotedData.quoted_post_date,
                                              item.quotedData.quoted_fullname,
                                              item.quotedData
                                                .quoted_profile_pic,
                                              item.quotedData
                                                .quoted_repost_count,
                                              item.quotedData.quoted_like_count,
                                              item.quotedData
                                                .quoted_reply_count,
                                              item.quotedData
                                                .quoted_bookmark_count,
                                              img.file
                                            )
                                          }
                                          className={
                                            item.quotedData.quoted_media
                                              .length == 2
                                              ? "mb-0 border-radius" + index
                                              : item.quotedData.quoted_media
                                                  .length == 3
                                              ? "borderRadiusImg" + index
                                              : item.quotedData.quoted_media
                                                  .length == 4
                                              ? "borderRadiusImgFour" + index
                                              : "mb-0 border-radius-5"
                                          }
                                          style={{
                                            backgroundImage:
                                              "url(" +
                                              process.env.REACT_APP_URL.replace(
                                                "api",
                                                ""
                                              ) +
                                              "public/timeline/media/" +
                                              item.quotedData.quoted_username.replace(
                                                "@",
                                                ""
                                              ) +
                                              "/" +
                                              img.file +
                                              ")",
                                            backgroundSize: "cover",
                                            height:
                                              item.quotedData.quoted_media
                                                .length == 1
                                                ? 300
                                                : item.quotedData.quoted_media
                                                    .length == 2
                                                ? 300
                                                : item.quotedData.quoted_media
                                                    .length == 4
                                                ? 150
                                                : 200,
                                            width: "100%",
                                            border: "thin solid #ececec",

                                            backgroundRepeat: "no-repeat",
                                            backgroundPosition: "center",
                                          }}
                                        />
                                      </Col>
                                    )
                                  )}
                                </Row>
                                <Row
                                  className={
                                    item.polls.length > 0 ? "mt-2" : "d-none"
                                  }
                                >
                                  <div
                                    className={
                                      item.did_i_vote == 1 ||
                                      item.pollClosed == true
                                        ? "col-12"
                                        : "d-none"
                                    }
                                  >
                                    {item.pollResults.map((results, index) => (
                                      <div
                                        className={
                                          results.percentage == 0
                                            ? "w-100 resulst-polls-container-no-votes"
                                            : "w-100 resulst-polls-container"
                                        }
                                        key={index}
                                      >
                                        <label
                                          className={
                                            results.percentage == 0
                                              ? ""
                                              : "polls-voted color-black"
                                          }
                                          style={{
                                            width: results.percentage + "%",
                                          }}
                                        />
                                        <label className={"color-black"}>
                                          {results.options}{" "}
                                          {results.who_i_voted_for == 1 ? (
                                            <IoCheckmarkCircleOutline
                                              size={20}
                                              color={"#66b266"}
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </label>
                                        <label
                                          className={"float-right color-black"}
                                        >
                                          {results.percentage}%
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                  <div
                                    className={
                                      item.did_i_vote == 1 ||
                                      item.pollClosed == true
                                        ? "d-none"
                                        : "col-12"
                                    }
                                  >
                                    {item.polls.map((polls, index) => (
                                      <Col lg={12} key={index}>
                                        <div
                                          className={"col-12 polls fontBold"}
                                          onClick={() =>
                                            props.vote(polls.id, polls.post_id)
                                          }
                                        >
                                          {polls.option}
                                        </div>
                                      </Col>
                                    ))}
                                  </div>
                                  <Col lg={12}>
                                    <label className="footnote">
                                      {item.votes}{" "}
                                      {item.votes == 1 ? "vote" : "votes"} -{" "}
                                      {item.pollClosed == true
                                        ? "Final results"
                                        : item.pollDuration.days >= 1
                                        ? item.pollDuration.days + "d left"
                                        : item.pollDuration.hours >= 1 &&
                                          item.pollDuration.minutes >= 1
                                        ? item.pollDuration.hours +
                                          "h and " +
                                          item.pollDuration.minutes +
                                          "m left"
                                        : item.pollDuration.hours >= 1 &&
                                          item.pollDuration.minutes == 0
                                        ? item.pollDuration.hours + "h left"
                                        : item.pollDuration.minutes >= 1
                                        ? item.pollDuration.minutes + "m left"
                                        : "Final results"}
                                    </label>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={12} className="mt-2">
                                    <label className="footnote fontSize14">
                                      {item.quotedData.quoted_post_date}
                                      <br />
                                      <label
                                        onClick={() =>
                                          props.addLike(
                                            item.quotedData.quoted_post_id,
                                            "home_page"
                                          )
                                        }
                                        className={
                                          item.quotedData.quoted_liked_by_me
                                            ? "anchor-link hover color-blue mobile-font-sizes"
                                            : "anchor-link hover color-grey mobile-font-sizes"
                                        }
                                        data-tip={"Like"}
                                        data-place={"bottom"}
                                        data-effect={"solid"}
                                      >
                                        {item.quotedData.quoted_like_count}{" "}
                                        Likes
                                        <ReactTooltip
                                          arrowColor={"transparent"}
                                          className="p-1 fontSize-12"
                                        />
                                      </label>
                                      &nbsp;&nbsp;&nbsp;
                                      <label
                                        onClick={() =>
                                          props.replyPage(
                                            item.quotedData.quoted_post,
                                            item.quotedData.quoted_fullname,
                                            item.quotedData.quoted_username,
                                            item.quotedData.quoted_post_id
                                          )
                                        }
                                        className={
                                          item.quotedData
                                            .quoted_did_reply_to_post
                                            ? "anchor-link hover color-blue mobile-font-sizes"
                                            : "anchor-link hover color-grey mobile-font-sizes"
                                        }
                                        data-tip={"Reply"}
                                        data-place={"bottom"}
                                        data-effect={"solid"}
                                      >
                                        {item.quotedData.quoted_reply_count}{" "}
                                        Replies
                                        <ReactTooltip
                                          arrowColor={"transparent"}
                                          className="p-1 fontSize-12"
                                        />
                                      </label>{" "}
                                      &nbsp;&nbsp;&nbsp;
                                      <label
                                        onClick={() =>
                                          props.Bookmark(
                                            item.quotedData.quoted_post_id,
                                            "home_page"
                                          )
                                        }
                                        className={
                                          item.quotedData
                                            .quoted_bookmarked_by_me
                                            ? "anchor-link hover color-blue mobile-font-sizes"
                                            : "anchor-link hover color-grey mobile-font-sizes"
                                        }
                                        data-tip={"Bookmark"}
                                        data-place={"bottom"}
                                        data-effect={"solid"}
                                      >
                                        {item.quotedData.quoted_bookmark_count}{" "}
                                        Bookmarks
                                        <ReactTooltip
                                          arrowColor={"transparent"}
                                          className="p-1 fontSize-12"
                                        />
                                      </label>{" "}
                                      &nbsp;&nbsp;&nbsp;
                                      <label
                                        className={
                                          item.quotedData
                                            .quoted_did_repost_to_post
                                            ? "color-blue mobile-font-sizes"
                                            : "text-silver mobile-font-sizes"
                                        }
                                        data-tip={"Just reposts"}
                                        data-place={"bottom"}
                                        data-effect={"solid"}
                                      >
                                        {item.quotedData.quoted_repost_count}{" "}
                                        Reposts
                                        <ReactTooltip
                                          arrowColor={"transparent"}
                                          className="p-1 fontSize-12"
                                        />
                                      </label>
                                    </label>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        )}
                        <Col lg={12} className="fontSize14 color footnote mt-2">
                          {item.posts.created_at}
                        </Col>
                        <Col lg={12} className="mt-2 text-right">
                          <span className="footnote float-left">
                            {item.authorUsername
                              ? item.repost_original_like_count
                              : !props.markLike[item.posts.id]
                              ? item.like_count
                              : !!props.markLike[item.posts.id]
                              ? item.liked_by_me == true
                                ? item.like_count - 1
                                : item.like_count + 1
                              : item.like_count - 1}{" "}
                            Likes
                          </span>
                          &nbsp;&nbsp;&nbsp;
                          <span className="footnote">
                            {item.authorUsername
                              ? item.repost_original_reply_count
                              : item.reply_count}{" "}
                            Replies
                          </span>{" "}
                          &nbsp;&nbsp;&nbsp;
                          {/* <label className="footnote fontCountSize">
                              {item.authorUsername
                                ? item.repost_original_bookmark_count
                                : item.bookmark_count}{" "}
                              Bookmarks
                            </label> */}
                          <span className="footnote">
                            {item.authorUsername
                              ? item.repost_original_repost_count
                              : item.repost_count}{" "}
                            Reposts
                          </span>
                        </Col>
                        <Col lg={12} className="mt-2">
                          <div className="interactive-icon-divider w-100" />
                        </Col>
                        <Col lg={12} className="mt-0 p-0">
                          <Stack className="stack">
                            <Row className="justify-content-center align-items-center mt-0 mb-0">
                              <Col lg={3} xs={4} sm={3} className="text-center">
                                <label
                                  className="action-likes-timeline w-100 text-center mb-0 mt-0 "
                                  onClick={() =>
                                    props.addLike(
                                      item.authorUsername
                                        ? item.repost_original_post_id
                                        : item.posts.id,
                                      "home_page"
                                    )
                                  }
                                >
                                  {!!props.markLike[item.posts.id] &&
                                  !item.liked_by_me ? (
                                    <label className="action-likes-true fontMedium interest__font__size__mobile">
                                      <BsHandThumbsUpFill size={20} /> <br />
                                      Like
                                    </label>
                                  ) : (
                                    <>
                                      {item.liked_by_me &&
                                      !props.markLike[item.posts.id] ? (
                                        <label className="action-likes-true fontMedium interest__font__size__mobile font__weight__mobile">
                                          <BsHandThumbsUpFill size={20} />
                                          <br />
                                          Like
                                        </label>
                                      ) : (
                                        <label className="cursorPointer fontMedium interest__font__size__mobile">
                                          <BsHandThumbsUp size={20} /> <br />
                                          Like
                                        </label>
                                      )}
                                    </>
                                  )}
                                </label>
                              </Col>
                              <Col lg={3} xs={4} sm={3} className="ext-center">
                                <label
                                  className="action-replies-timeline w-100 text-center mb-0 mt-0 cursorPointer"
                                  onClick={() =>
                                    props.reply(
                                      item.authorUsername
                                        ? `${item.repost_original_posts}`
                                        : `${item.posts.post}`,
                                      item.authorUsername
                                        ? `${item.author}`
                                        : `${item.user.first_name} ${item.user.last_name}`,
                                      item.authorUsername
                                        ? `${item.authorUsername2}`
                                        : `${item.user.username}`,
                                      item.authorUsername
                                        ? `${item.repost_original_post_id}`
                                        : `${item.posts.id}`,
                                      item.authorUsername
                                        ? `${item.authorProfilePic}`
                                        : `${item.user.profile_pic}`,
                                      item.authorUsername
                                        ? item.repost_original_polls
                                        : item.polls,
                                      item.did_i_vote,
                                      {
                                        days: item.pollDuration.days,
                                        hours: item.pollDuration.hours,
                                        minutes: item.pollDuration.minutes,
                                        seconds: item.pollDuration.seconds,
                                      },
                                      item.pollResults,
                                      item.votes,
                                      item.authorUsername
                                        ? item.reposted_media
                                        : item.media,
                                      item.documents,
                                      item.subjects != null
                                        ? item.subjects.subject
                                        : null,
                                      item.topics != null
                                        ? item.topics.topic
                                        : null,
                                      item.authorUsername
                                        ? item.original_current_post_polls
                                        : item.current_post_polls,
                                      item.pollClosed,
                                      "reply",
                                      props.postType
                                    )
                                  }
                                >
                                  {item.did_reply_to_post == true ||
                                  item.repost_did_reply_to_post ? (
                                    <label
                                      className={
                                        item.did_reply_to_post == true ||
                                        item.repost_did_reply_to_post
                                          ? "action-replies-true fontMedium cursorPointer interest__font__size__mobile"
                                          : ""
                                      }
                                    >
                                      <BsChatTextFill size={20} /> <br />
                                      Reply
                                    </label>
                                  ) : (
                                    <label className="fontMedium cursorPointer interest__font__size__mobile">
                                      <BsChatText size={20} />
                                      <br /> Reply
                                    </label>
                                  )}
                                </label>
                              </Col>
                              {/* <Col
                                lg={3}
                                xs={3}
                                sm={3}
                                className="pt-0 d-none d-md-block d-lg-block d-xl-block d-xxl-block"
                              ></Col> */}
                              <Col
                                lg={3}
                                xs={4}
                                sm={3}
                                className="text-center d-none d-md-block d-lg-block d-xl-block d-xxl-block"
                              >
                                <label
                                  className="action-bookmarks-timeline w-100 text-center mb-0 mt-0"
                                  onClick={() =>
                                    props.Bookmark(
                                      item.authorUsername
                                        ? item.repost_original_post_id
                                        : item.posts.id,
                                      "home_page"
                                    )
                                  }
                                >
                                  {!!props.markBookmark[item.posts.id] &&
                                  !item.bookmarked_by_me ? (
                                    <span className="action-bookmarks-true fontMedium">
                                      <BsBookmarkCheckFill size={20} /> <br />
                                      Bookmark
                                    </span>
                                  ) : (
                                    <>
                                      {item.bookmarked_by_me &&
                                      !props.markBookmark[item.posts.id] ? (
                                        <label className="action-bookmarks-true fontMedium interest__font__size__mobile">
                                          <BsBookmarkCheckFill size={20} />
                                          <br />
                                          Bookmark
                                        </label>
                                      ) : (
                                        <label className="cursorPointer fontMedium interest__font__size__mobile">
                                          <BsBookmarkPlus size={20} /> <br />
                                          Bookmark
                                        </label>
                                      )}
                                    </>
                                  )}

                                  {/* {item.bookmarked_by_me == true ? (
                                    <span className="action-bookmarks-true cursorPointer fontMedium">
                                      <BsBookmarkCheckFill
                                        size={20}
                                        className=""
                                      />
                                      <br />
                                      Bookmarked
                                    </span>
                                  ) : (
                                    <label className="cursorPointer fontMedium">
                                      {" "}
                                      <BsBookmarkPlus size={20} /> <br />
                                      Bookmark
                                    </label>
                                  )} */}
                                </label>
                              </Col>

                              <Col
                                lg={3}
                                xs={4}
                                sm={3}
                                className="text-center p-0"
                              >
                                {/* <label className="light-bold">Repost</label> */}
                                <Dropdown>
                                  <Dropdown.Toggle
                                    style={{
                                      // borderRadius: 10,
                                      border: "hidden",
                                      background: "#fff",
                                      // paddingTop: 8,
                                      // paddingBottom: 8,
                                      boxShadow: "none",
                                      backgroundColor: "transparent",
                                      width: "100%",
                                    }}
                                  >
                                    <label
                                      className={
                                        item.did_repost_to_post
                                          ? "icons-header-true icons-header w-100 interest__font__size__mobile"
                                          : "fontMedium  text-center action-menu-timeline w-100 interest__font__size__mobile"
                                      }
                                    >
                                      <IoGridOutline size={20} />
                                      <br /> More
                                    </label>
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      className="drop-toggle-padding d-md-none d-lg-none d-xl-none d-xxl-none"
                                      onClick={() =>
                                        props.Bookmark(
                                          item.authorUsername
                                            ? item.repost_original_post_id
                                            : item.posts.id,
                                          "home_page"
                                        )
                                      }
                                    >
                                      {!!props.markBookmark[item.posts.id] &&
                                      !item.bookmarked_by_me ? (
                                        <span className="action-bookmarks-true fontMedium">
                                          <BsBookmarkCheckFill size={16} />
                                          &nbsp;&nbsp;&nbsp;Bookmark
                                        </span>
                                      ) : (
                                        <>
                                          {item.bookmarked_by_me &&
                                          !props.markBookmark[item.posts.id] ? (
                                            <label className="action-bookmarks-true fontMedium interest__font__size__mobile">
                                              <BsBookmarkCheckFill size={16} />
                                              &nbsp;&nbsp;&nbsp;Bookmark
                                            </label>
                                          ) : (
                                            <label className="cursorPointer fontMedium interest__font__size__mobile">
                                              <BsBookmarkPlus size={16} />
                                              &nbsp;&nbsp;&nbsp;Bookmark
                                            </label>
                                          )}
                                        </>
                                      )}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() =>
                                        props.reply(
                                          item.authorUsername
                                            ? `${item.repost_original_posts}`
                                            : `${item.posts.post}`,
                                          item.authorUsername
                                            ? `${item.author}`
                                            : `${item.user.first_name} ${item.user.last_name}`,
                                          item.authorUsername
                                            ? `${item.authorUsername2}`
                                            : `${item.user.username}`,
                                          item.authorUsername
                                            ? `${item.repost_original_post_id}`
                                            : `${item.posts.id}`,
                                          item.authorUsername
                                            ? `${item.authorProfilePic}`
                                            : `${item.user.profile_pic}`,
                                          item.authorUsername
                                            ? item.repost_original_polls
                                            : item.polls,
                                          item.did_i_vote,
                                          {
                                            days: item.pollDuration.days,
                                            hours: item.pollDuration.hours,
                                            minutes: item.pollDuration.minutes,
                                            seconds: item.pollDuration.seconds,
                                          },
                                          item.pollResults,
                                          item.votes,
                                          item.authorUsername
                                            ? item.reposted_media
                                            : item.media,
                                          item.documents,
                                          item.subjects != null
                                            ? item.subjects.subject
                                            : null,
                                          item.topics != null
                                            ? item.topics.topic
                                            : null,
                                          item.authorUsername
                                            ? item.original_current_post_polls
                                            : item.current_post_polls,
                                          item.pollClosed,
                                          "repost",
                                          props.postType
                                        )
                                      }
                                      className={
                                        item.authorUsername
                                          ? !item.author_original_post_ownership
                                            ? "hover drop-toggle-padding fontMedium"
                                            : "d-none"
                                          : item.post_ownership == true
                                          ? "d-none"
                                          : "hover drop-toggle-padding fontMedium"
                                      }
                                    >
                                      <BiShare size={16} />
                                      &nbsp;&nbsp;&nbsp;Repost
                                      {item.did_repost_to_post == true ? (
                                        <span className="float-right">
                                          <IoEllipse
                                            size={10}
                                            className="color-blue"
                                          />
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </Dropdown.Item>

                                    <Dropdown.Item
                                      onClick={() =>
                                        props.reply(
                                          item.authorUsername
                                            ? `${item.repost_original_posts}`
                                            : `${item.posts.post}`,
                                          item.authorUsername
                                            ? `${item.author}`
                                            : `${item.user.first_name} ${item.user.last_name}`,
                                          item.authorUsername
                                            ? `${item.authorUsername2}`
                                            : `${item.user.username}`,
                                          item.authorUsername
                                            ? `${item.repost_original_post_id}`
                                            : `${item.posts.id}`,
                                          item.authorUsername
                                            ? `${item.authorProfilePic}`
                                            : `${item.user.profile_pic}`,
                                          item.authorUsername
                                            ? item.repost_original_polls
                                            : item.polls,
                                          item.did_i_vote,
                                          {
                                            days: item.pollDuration.days,
                                            hours: item.pollDuration.hours,
                                            minutes: item.pollDuration.minutes,
                                            seconds: item.pollDuration.seconds,
                                          },
                                          item.pollResults,
                                          item.votes,
                                          item.authorUsername
                                            ? item.reposted_media
                                            : item.media,
                                          item.documents,
                                          item.subjects != null
                                            ? item.subjects.subject
                                            : null,
                                          item.topics != null
                                            ? item.topics.topic
                                            : null,
                                          item.authorUsername
                                            ? item.original_current_post_polls
                                            : item.current_post_polls,
                                          item.pollClosed,
                                          "quote",
                                          props.postType
                                        )
                                      }
                                      className="drop-toggle-padding fontMedium"
                                    >
                                      <BsPencil size={16} />
                                      &nbsp;&nbsp;&nbsp;Quote post
                                      {item.did_quote_post == true ? (
                                        <span className="float-right">
                                          <IoEllipse
                                            size={10}
                                            className="color-blue"
                                          />
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      className={
                                        item.authorUsername
                                          ? !item.author_original_post_ownership
                                            ? "drop-toggle-padding fontMedium"
                                            : "d-none"
                                          : !item.post_ownership
                                          ? "drop-toggle-padding fontMedium"
                                          : "d-none"
                                      }
                                      onClick={() =>
                                        props.dm(
                                          item.authorUsername
                                            ? item.authorUsername2
                                            : item.user.username,
                                          item.authorUsername
                                            ? item.repost_original_posts
                                            : item.posts.post
                                        )
                                      }
                                    >
                                      <BsChatRightDots size={16} />
                                      &nbsp;&nbsp;&nbsp;Send direct message
                                    </Dropdown.Item>

                                    <Dropdown.Item
                                      onClick={() =>
                                        props.follow(
                                          item.authorUsername
                                            ? item.authorID
                                            : item.user.id
                                        )
                                      }
                                      className={
                                        item.authorUsername
                                          ? !item.author_original_post_ownership
                                            ? "drop-toggle-padding fontMedium"
                                            : "d-none"
                                          : item.post_ownership
                                          ? "d-none"
                                          : "drop-toggle-padding fontMedium"
                                      }
                                    >
                                      {item.do_follow_this_person == true ? (
                                        <>
                                          <BiUserMinus size={16} />
                                          &nbsp;&nbsp;&nbsp;Unfollow{" "}
                                        </>
                                      ) : (
                                        <>
                                          <BiUserPlus size={16} />
                                          &nbsp;&nbsp;&nbsp;Follow{" "}
                                        </>
                                      )}
                                      @
                                      {item.authorUsername
                                        ? item.authorUsername2
                                        : item.user.username}
                                    </Dropdown.Item>

                                    <Dropdown.Item
                                      onClick={() =>
                                        props.block(
                                          item.authorUsername
                                            ? item.authorID
                                            : item.user.id
                                        )
                                      }
                                      className={
                                        item.authorUsername
                                          ? !item.author_original_post_ownership
                                            ? "drop-toggle-padding fontMedium"
                                            : "d-none"
                                          : item.post_ownership
                                          ? "d-none"
                                          : item.authorUsername
                                          ? item.quoted_did_i_block_user
                                            ? "color-red drop-toggle-padding fontMedium"
                                            : "drop-toggle-padding fontMedium"
                                          : item.did_i_block_user == true
                                          ? "color-red drop-toggle-padding fontMedium"
                                          : "drop-toggle-padding fontMedium"
                                      }
                                    >
                                      <IoBanOutline size={16} />
                                      &nbsp;&nbsp;&nbsp;
                                      {item.authorUsername
                                        ? item.quoted_did_i_block_user
                                          ? "Unblock"
                                          : "Block"
                                        : item.did_i_block_user == true
                                        ? "Unblock"
                                        : "Block"}{" "}
                                      @
                                      {item.authorUsername
                                        ? item.authorUsername2
                                        : item.user.username}
                                    </Dropdown.Item>

                                    <Dropdown.Item
                                      onClick={() =>
                                        props.mutePost(
                                          item.authorUsername
                                            ? item.repost_original_post_id
                                            : item.posts.id
                                        )
                                      }
                                      className={
                                        item.authorUsername
                                          ? !item.author_original_post_ownership
                                            ? "drop-toggle-padding fontMedium"
                                            : "d-none"
                                          : item.post_ownership
                                          ? "d-none"
                                          : "drop-toggle-padding fontMedium"
                                      }
                                    >
                                      <BiSad size={16} />
                                      &nbsp;&nbsp;&nbsp;I don't want to see this
                                      post
                                    </Dropdown.Item>
                                    {/* <Dropdown.Item
                                        onClick={() =>
                                          props.follow(
                                            item.authorUsername
                                              ? item.authorID
                                              : item.user.id
                                          )
                                        }
                                        className={
                                          item.authorUsername
                                            ? item.author_original_post_ownership
                                            : item.post_ownership
                                            ? "d-none"
                                            : "fontSize14 d-none"
                                        }
                                      >
                                        <BiFlag size={16} />
                                        &nbsp;&nbsp;&nbsp;Report post
                                      </Dropdown.Item> */}

                                    <Dropdown.Item
                                      onClick={() =>
                                        props.deletePost(
                                          item.authorUsername
                                            ? item.repost_original_post_id
                                            : item.posts.id
                                        )
                                      }
                                      className={
                                        item.authorUsername
                                          ? item.author_original_post_ownership
                                            ? "drop-toggle-padding fontMedium"
                                            : "d-none"
                                          : item.post_ownership
                                          ? "drop-toggle-padding fontMedium"
                                          : "d-none"
                                      }
                                    >
                                      <HiOutlineTrash size={16} />
                                      &nbsp;&nbsp;&nbsp;Delete post
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </Col>
                            </Row>
                          </Stack>
                        </Col>
                      </Col>
                    </Row>
                  </Row>
                </div>
              ))}
            </>
          )}

          {props.postSent && (
            <Row className={props.postType == "entry" ? "d-none" : ""}>
              <Col lg={4}>
                <div className="copiedText color-white">
                  {props.postSentFeedback}
                </div>
              </Col>
            </Row>
          )}
        </Col>
      )}
    </Fragment>
  );
};

export default Posts;
