import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import ReactTooltip from "react-tooltip";

const PreviewDocuments = (props) => {
  const [documentName, setDocumentName] = useState();
  return (
    <Row>
      <Col lg={12} className={props.previewDoc.length == 0 ? "d-none" : "mt-2"}>
        {props.previewDoc.length > 0 ? (
          <label
            className="fontSize14 cursorPointer unfollow-btn mb-1"
            onClick={props.clearAttachment}
          >
            Remove documents
          </label>
        ) : (
          ""
        )}
      </Col>
      {props.previewDoc.map((document, index) => {
        return (
          <Col lg={12} key={index}>
            <a
              href={props.previewDocURL[index]}
              target="_blank"
              className={"cursorPointer textUnderlineNone fontBold fontSize14"}
            >
              <label
                className={"document-link footnote cursorPointer"}
                data-tip={document}
                data-place={"right"}
                data-effect={"solid"}
              >
                {" "}
                {document.length > 25
                  ? document.slice(0, 25) + "..."
                  : document}
                <ReactTooltip arrowColor={"transparent"} />
              </label>
            </a>
            <label className="d-none d-md-inline d-lg-inline d-xl-inline d-xxl-inline">
              <input
                type="text"
                name="tags"
                placeholder={"Add title (Optional)"}
                className={"tag-inputs d-non"}
                value={props.docTitle[index]}
                // value={props.previewDoc[index]}
                onChange={(e) => props.tags(e.target.value, index)}
              />
            </label>
          </Col>
        );
      })}
    </Row>
  );
};

export default PreviewDocuments;
