import React, { useState, useEffect, useRef, useCallback } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Tagify } from "react-tagify";
import Linkify from "react-linkify";
import { SecureLink } from "react-secure-link";
import { Link } from "react-router-dom";

import {
  Col,
  Container,
  Row,
  Stack,
  Modal,
  Button,
  Dropdown,
} from "react-bootstrap";

import { IoAddSharp } from "react-icons/io5";
import axios from "axios";
import HeaderToken from "../HeaderToken";
import { ClipLoader } from "react-spinners";

const headers = {
  Authorization: `${HeaderToken.Authorization}`,
  Accept: `${HeaderToken.Accept}`,
};

export default function TopicisToFollow(props) {
  const queryClient = useQueryClient();
  const baseURL = process.env.REACT_APP_URL;

  const [hidden, setHidden] = useState({});
  const [count, setCount] = useState(0);

  const topicQuery = useQuery({
    queryKey: ["topics"],
    queryFn: async () => {
      const response = await axios.get(
        baseURL + "/follow/topics/" + props.userID,
        {
          headers,
        }
      );
      const data = response.data;
      return data;
    },
    staleTime: 1000 * 60 * 60 * 4,
    cacheTime: 1000 * 60 * 60 * 24,
    refetchOnWindowFocus: false,
  });
  // const fetchTopics = async () => {
  //   const res = await fetch(baseURL + "/follow/topics/" + props.userID, {
  //     headers,
  //   });
  //   return res.json();
  // };

  // const topics_ = useQuery("topics", fetchTopics, { retry: 3 });

  const { data: topicsData, status, refetch: topicRefetch } = topicQuery;

  useEffect(() => {
    // queryClient.invalidateQueries({ queryKey: ["topics"] }, { exact: true });
  }, [count]);

  const clickedFollow = (topic_id) => {
    setCount(count + 1);

    setHidden({ ...hidden, [topic_id]: !hidden[topic_id] });
    const data = new FormData();
    data.append("user_id", props.userID);
    data.append("topic_id", topic_id);
    axios({
      method: "POST",
      url: baseURL + "/follow/topics",
      data: data,
      headers: {
        Authorization: HeaderToken.Authorization,
        Accept: HeaderToken.Accept,
      },
    })
      .then((res) => {
        setHidden({ ...hidden, [topic_id]: hidden[topic_id] });
        queryClient.invalidateQueries(
          { queryKey: ["topics"] },
          { exact: true }
        );
      })
      .catch((error) => {});
  };

  return (
    <>
      {topicQuery.isLoading ? (
        <div className="col-12 text-center p-5 color-blue">
          <ClipLoader size={25} color={"#333333"} />
        </div>
      ) : (
        <>
          {topicQuery.length == 0 ? (
            <>no data</>
          ) : (
            topicQuery.data.topics.map((item, index) => (
              <Row className="justify-content-center align-items-center side-nav-topics mt-0 mb-0">
                <Col lg={12}>
                  <Row className={"align-items-center"}>
                    <Col lg={8} sm={8} xs={8}>
                      <Link
                        to={"/topicPosts?clicked=" + item.topic}
                        className={"color-black textUnderlineNone"}
                      >
                        <label className="line-height-20">
                          <span className="cursorPointer text-bold">
                            {item.topic.length > (props.search ? 20 : 16)
                              ? item.topic.slice(0, props.search ? 20 : 16) +
                                "..."
                              : item.topic}
                          </span>
                          <br />
                          <label
                            className={"footnote cursorPointer fontSize14"}
                          >
                            {item.subject.length > (props.search ? 27 : 22)
                              ? item.subject.slice(0, props.search ? 27 : 22) +
                                "..."
                              : item.subject}
                          </label>
                        </label>
                      </Link>
                    </Col>
                    <Col lg={4} sm={4} xs={4}>
                      {!hidden[item.id] && (
                        <Button
                          type="button"
                          className={
                            "native-button-dark-grey btn-follow-user float-right fontBold no-shadow btn-sm"
                          }
                          onClick={() => clickedFollow(item.id)}
                        ></Button>
                      )}
                      {!!hidden[item.id] && (
                        <label
                          className={
                            "disabled-bg-color float-right fontBold no-shadow btn-sm btn btn-primary"
                          }
                        >
                          <ClipLoader size={15} color="#000" />
                        </label>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            ))
          )}
        </>
      )}
    </>
  );
}
