import React, { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { Container, Row, Col, Button, Stack } from "react-bootstrap";
import BeatLoader from "react-spinners/BeatLoader";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  IoAtSharp,
  IoAttachSharp,
  IoChatboxEllipsesOutline,
} from "react-icons/io5";
import { IoIosHeart } from "react-icons/io";
import { BsPencil } from "react-icons/bs";
import { Tagify } from "react-tagify";
import { Linkify } from "react-easy-linkify";
import { SecureLink } from "react-secure-link";
import mentionStyle from "../css/mentionStyle";
import tagStyle from "../css/tagStyle";
import HeaderToken from "../HeaderToken";
import { ClipLoader } from "react-spinners";
import { BiShare } from "react-icons/bi";

const headers = {
  Authorization: `${HeaderToken.Authorization}`,
  Accept: `${HeaderToken.Accept}`,
};

const Notifies = (props) => {
  // let history = useHistory();

  const url = process.env.REACT_APP_URL;
  const imgURL = process.env.REACT_APP_URL.replace("api", "");

  const notificationQuery = useQuery({
    queryKey: ["notificationDetails"],
    queryFn: async () => {
      const response = await axios.get(
        `${url}/notification/details/${props.userID}`,
        { headers }
      );
      const data = response.data;
      return data;
    },
  });

  function unBlock(blocked_user_id) {
    axios
      .get(url + "/block/user/" + props.userID + "/" + blocked_user_id)
      .then((res) => {});
  }

  // const message = (username) => {
  //   history.push({ pathname: `/messages/${username}` });
  // };

  const replyPage = () => {};
  return (
    <div className="p-3">
      {notificationQuery.isLoading ? (
        <div className="col-12 text-center p-5 color-blue">
          <ClipLoader size={25} color={"#333333"} />
        </div>
      ) : (
        <>
          {notificationQuery.data.length == 0 ? (
            <div lg={12} className="text-center mb-3 mt-3 fontSize-20">
              <h3>You have no notifications.</h3>
              <label className="footnote mb-3">
                Keeping you updated.
                <br />
                Your notifications will show up here
              </label>
            </div>
          ) : (
            <Row>
              {notificationQuery.data.map((item, index) => (
                <Col lg={12} className="" key={index}>
                  <div className="p-2 side-nav-topics">
                    <Row>
                      <Col lg={12} className="text-left">
                        <span className="text-bold fontSize14 color-grey">
                          {item.repost_id > 0 ? (
                            <BiShare size={20} className="color-red" />
                          ) : item.quote_id > 0 && item.postB ? (
                            <BsPencil size={18} className="footnote" />
                          ) : item.is_liked == 1 ? (
                            <IoIosHeart size={18} color={"#cc99cc"} />
                          ) : (
                            <IoAtSharp size={18} className="color-blue" />
                          )}
                          {item.repost_id > 0
                            ? ` Reposted by ${item.firstNameB} ${item.lastNameB}`
                            : item.is_liked == 1
                            ? ` Liked by ${item.firstNameB} ${item.lastNameB}`
                            : item.quote_id > 0 && item.postB
                            ? ` Quoted by ${item.firstNameB} ${item.lastNameB}`
                            : " Mentions"}
                        </span>
                      </Col>
                    </Row>
                    {item.quote_id > 0 && item.postB ? (
                      <Row className={item.quote_id > 0 ? "mb-2" : "d-none"}>
                        <Col lg={12}>
                          <label>
                            {item.postB.length > 100 ? (
                              <label
                                onClick={() =>
                                  props.replyPage(
                                    item.postB,
                                    item.firstNameB + " " + item.lastNameB,
                                    item.usernameB,
                                    item.postB_id
                                  )
                                }
                                className="cursorPointer"
                              >
                                <Tagify
                                  onClick={(text, type) =>
                                    props.hashtag(text, type, item.postB_id)
                                  }
                                  detectHashtags={true}
                                  detectMentions={true}
                                  tagStyle={tagStyle}
                                  mentionStyle={mentionStyle}
                                  detectLinks={true}
                                >
                                  <Linkify
                                    componentDecorator={(
                                      decoratedHref,
                                      decoratedText,
                                      key
                                    ) => (
                                      <SecureLink
                                        target="_blank"
                                        href={decoratedHref}
                                        key={key}
                                        className={
                                          "color-blue textUnderlineNone"
                                        }
                                      >
                                        {decoratedText}
                                      </SecureLink>
                                    )}
                                  >
                                    {`${item.postB.slice(0, 100)}... `}
                                  </Linkify>
                                </Tagify>
                                <span className="color-blue">See more</span>
                              </label>
                            ) : (
                              <label
                                onClick={() =>
                                  props.replyPage(
                                    item.postB,
                                    item.firstNameB + " " + item.lastNameB,
                                    item.usernameB,
                                    item.postB_id
                                  )
                                }
                                className="cursorPointer"
                              >
                                <Tagify
                                  onClick={(text, type) =>
                                    props.hashtag(text, type, item.postB_id)
                                  }
                                  detectHashtags={true}
                                  detectMentions={true}
                                  tagStyle={tagStyle}
                                  mentionStyle={mentionStyle}
                                  detectLinks={true}
                                >
                                  <Linkify
                                    componentDecorator={(
                                      decoratedHref,
                                      decoratedText,
                                      key
                                    ) => (
                                      <SecureLink
                                        target="_blank"
                                        href={decoratedHref}
                                        key={key}
                                        className={
                                          "color-blue textUnderlineNone"
                                        }
                                      >
                                        {decoratedText}
                                      </SecureLink>
                                    )}
                                  >
                                    {item.postB}
                                  </Linkify>
                                </Tagify>
                              </label>
                            )}
                            <br />
                            <label className="footnote">
                              {item.quoted_date}
                            </label>
                          </label>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                    <Row
                      className={
                        item.quote_id > 0 && item.postB
                          ? "paddingLeftRight"
                          : ""
                      }
                    >
                      <div
                        className={
                          item.quote_id > 0 && item.postB
                            ? "quote-post d-flex"
                            : "row"
                        }
                      >
                        <Col lg={1}>
                          <div
                            className={"profile-pic"}
                            style={{
                              backgroundImage:
                                "url(" +
                                imgURL +
                                "public/profile/picture/@" +
                                item.username +
                                "/" +
                                item.profile_pic +
                                ")",
                            }}
                          />
                        </Col>
                        <Col lg={11} className="paddingLeft20">
                          <Row>
                            <Col lg={12}>
                              <div className={""}>
                                <span className="fontBold color-black">
                                  {item.first_name + " " + item.last_name}
                                </span>
                                <label className={"footnote cursorPointer"}>
                                  &nbsp; @{item.username}
                                </label>
                              </div>
                            </Col>
                            <Col lg={12}>
                              <IoAttachSharp
                                size={20}
                                className={
                                  item.media == 1 || item.documents == 1
                                    ? "color-green"
                                    : "d-none"
                                }
                              />
                              {item.post.length > 100 ? (
                                <label
                                  className="text-normal cursorPointer"
                                  onClick={() =>
                                    props.replyPage(
                                      item.post,
                                      item.first_name + " " + item.last_name,
                                      item.username,
                                      item.is_liked == 1
                                        ? item.post_id
                                        : item.postA_id
                                    )
                                  }
                                >
                                  <Tagify
                                    onClick={(text, type) =>
                                      props.hashtag(
                                        text,
                                        type,
                                        item.is_liked == 1
                                          ? item.post_id
                                          : item.repost_id > 0
                                          ? item.postA_id
                                          : item.quote_id
                                      )
                                    }
                                    tagStyle={tagStyle}
                                    mentionStyle={mentionStyle}
                                    detectLinks={true}
                                    detectHashtags={true}
                                    detectMentions={true}
                                  >
                                    {item.post.slice(0, 100)}...{" "}
                                    <span className="color-blue text-normal">
                                      See more...
                                    </span>
                                  </Tagify>
                                </label>
                              ) : (
                                <label
                                  className="text-normal cursorPointer"
                                  onClick={() =>
                                    props.replyPage(
                                      item.post,
                                      item.first_name + " " + item.last_name,
                                      item.username,
                                      item.is_liked == 1
                                        ? item.post_id
                                        : item.postA_id
                                    )
                                  }
                                >
                                  <Tagify
                                    onClick={(text, type) =>
                                      props.hashtag(
                                        text,
                                        type,
                                        item.is_liked == 1
                                          ? item.post_id
                                          : item.postA_id
                                      )
                                    }
                                    tagStyle={tagStyle}
                                    mentionStyle={mentionStyle}
                                    detectLinks={true}
                                    detectHashtags={true}
                                    detectMentions={true}
                                  >
                                    {item.post}
                                  </Tagify>
                                </label>
                              )}
                              <br />
                              <label className="color-grey fontSize14">
                                {item.is_liked == 1
                                  ? "Liked - "
                                  : item.repost_id > 0
                                  ? "Reposted - "
                                  : item.quote_id > 0 && item.postB
                                  ? "Sent - "
                                  : "Mentioned - "}
                                {item.is_liked == 1
                                  ? item.created_at
                                  : item.repost_id > 0
                                  ? item.created_at
                                  : item.created_at}
                              </label>
                            </Col>
                          </Row>
                        </Col>
                      </div>
                    </Row>
                  </div>
                </Col>
              ))}
            </Row>
          )}
        </>
      )}
    </div>
  );
};

export default Notifies;
