import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { Col, Row, Stack, Button } from "react-bootstrap";
import { useParams, useHistory, Link, NavLink } from "react-router-dom";
import axios from "axios";
import TopicisToFollow from "../features/TopicsToFollow";
import AcademicsToFollow from "../features/AcademicsToFollow";
import HeaderToken from "../HeaderToken";

const headers = {
  Authorization: `${HeaderToken.Authorization}`,
  Accept: `${HeaderToken.Accept}`,
};

export default function MessasgePallet(props) {
  const baseURL = process.env.REACT_APP_URL;
  let url = "";
  const [followText, setFollowText] = useState("Following...");
  const [followBtn, setFollowBtn] = useState("btn btn-primary btn-sm");
  const [followCount, setFollowCount] = useState(0);
  const user_id = localStorage.getItem("user_id");

  const getPeople = async () => {
    const baseURL = process.env.REACT_APP_URL;
    url = baseURL + "/get/people/" + user_id;
    const res = await fetch(url, {
      headers,
    }); //await axios.get(url);
    return res.json();
  };

  const {
    data,
    error,
    failureCount,
    isError,
    isFetchedAfterMount,
    isFetching,
    isIdle,
    isLoading,
    isPreviousData,
    isStale,
    isSuccess,
    refetch,
    remove,
    status,
  } = useQuery("people", getPeople);

  useEffect(() => {}, [window.location.href, followCount]);

  function follow(user_id) {
    var following_user_id = localStorage.getItem("user_id");
    var followed_user_id = user_id;

    const formData = new FormData();
    formData.append("following_user_id", following_user_id);
    formData.append("followed_user_id", followed_user_id);
    const url = baseURL;
    axios({
      method: "POST",
      url: url + "/following/create",
      data: formData,
      headers: {
        Authorization: HeaderToken.Authorization,
        Accept: HeaderToken.Accept,
      },
    }).then((response) => {
      // setFollowCount(followCount + 1);
    });
    refetch();
  }

  function unfollowText() {
    setFollowBtn("btn-sm btn btn-outline-danger rounded-pill");
    setFollowText("Unfollow");
  }

  function followingText() {
    setFollowBtn("btn-sm rounded-pill");
    setFollowText("Following...");
  }

  return (
    <Col lg={5} className="p-0">
      <div className={"border-hidde border-1-grey mb-0 pt-0 height-81"}>
        <Row className={"align-items-center h-100"}>
          <Col lg={12} className="text-center">
            <Row className={"justify-content-center"}>
              <Col lg={9} className={"text-left footnote"}>
                <h2>
                  <span className="color-black">Select a message</span>
                </h2>
                Choose from your existing conversations, start a new one, or
                just keep swimming.
                <br />
                <Button
                  onClick={props.newMessage}
                  className={"native-button-blue mt-4 no-shadow fontBold"}
                >
                  Start a new message
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Col>
  );
}
