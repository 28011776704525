import React, { Component, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { Container, Row, Col, Button, Stack } from "react-bootstrap";
import SideNav from "./navigation/SideNavHome";
import RightNav from "./navigation/SidNavHomeRight";
import Header from "./navigation/HeaderHome";
import { useLocation, useNavigate } from "react-router-dom";
import { IoIosArrowRoundBack } from "react-icons/io";
import Notifies from "./features/Notifications";
import Footer from "./navigation/Footer";
import { Helmet } from "react-helmet";

function Notifications(props) {
  const location = useLocation();
  const info = location.state;
  useEffect(() => {}, []);

  let navigate = useNavigate();
  const user_id = localStorage.getItem("user_id");

  function replyPage(post, name, username, post_uuid) {
    var url = window.location.pathname;
    if (url.includes("hashtag")) {
      return;
    } else if (url.includes("profile")) {
      return;
    } else {
      navigate("/status/" + username + "/" + post_uuid);
      // history.push({
      //   pathname: "/status/" + username + "/" + post_uuid,
      // });
    }
  }

  function hashtag(value, type, post_id) {
    let hashtagValue;
    if (type == "tag") {
      hashtagValue = value.replace("#", "");
      navigate(`/hashtag/${post_id}/${hashtagValue}`);
      // history.push({ pathname: `/hashtag/${post_id}/${hashtagValue}` });
    } else {
      hashtagValue = value.replace("@", "");
      navigate(`/profile/${hashtagValue}`);
      // history.push({ pathname: `/profile/${hashtagValue}` });
    }

    return;
  }

  return (
    <div className="body-login">
      <Helmet>
        <title>
          Notifications | {process.env.REACT_APP_YOUR_VARIABLE_NAME_HERE}
        </title>
      </Helmet>
      <Header />
      <Container>
        <Row className="forced-margin justify-content-center">
          {/* <SideNav /> */}
          <RightNav />

          <Col lg={6} className="p-0">
            <Stack>
              <div className={"login-elements-container border-hidde mb-5 p-0"}>
                <Row className={"align-items-center pb-0"}>
                  <Col lg={12} className={"pb-0"}>
                    <div className="p-3 pb-0 mb-0 border-botto">
                      <Row className={"align-items-center"}>
                        <Col lg={1} sm={1} xs={2}>
                          <label
                            className="back-arrow-post mb-0"
                            onClick={() => navigate(-1)}
                          >
                            <IoIosArrowRoundBack size={30} />
                          </label>
                        </Col>
                        <Col lg={11} sm={11} xs={10} className={""}>
                          <span className="text-bold fontSize-20">
                            Notifications
                          </span>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>

                <Notifies
                  userID={user_id}
                  replyPage={replyPage}
                  hashtag={hashtag}
                />
              </div>
            </Stack>
          </Col>
          {/* <RightNav /> */}
        </Row>
      </Container>
      <Footer
        postSentFeedback={info ? info.message : null}
        postSent={info ? info.success : null}
        postType={"compose"}
      />
    </div>
  );
}

export default Notifications;
